export const mockRoles = [
  {
    // Full project access
    owningResourceId: '66df41c671ad46003d52dbc2',
    name: 'OWNER_ROLE',
    description: 'Role for owner with full access',
    systemDefined: false,
    permissions: [
      {
        resource: '*',
        resourceId: '*',
        verb: '*',
        action: '*',
      },
    ],
    service: 'projects',
    owningResource: 'project',
  },
  {
    // Full access to investments service
    owningResourceId: '66df41c671ad46003d52dbc2',
    name: 'INVESTMENT_MANAGER',
    description: 'Full access to investment management',
    systemDefined: false,
    permissions: [
      {
        resource: 'investments',
        resourceId: '*',
        verb: '*',
        action: '*',
      },
    ],
    service: 'projects',
    owningResource: 'project',
  },
  {
    // Mixed permissions across different services
    owningResourceId: '66df41c671ad46003d52dbc2',
    name: 'PROJECT_COORDINATOR',
    description: 'Selected permissions for project coordination',
    systemDefined: false,
    permissions: [
      {
        resource: 'document',
        resourceId: '*',
        verb: 'GET',
        action: 'downloadFilesBasedOnProjectId',
      },
      {
        resource: 'document',
        resourceId: '*',
        verb: 'POST',
        action: 'uploadDocuments',
      },
      {
        resource: 'member',
        resourceId: '*',
        verb: 'GET',
        action: 'getMembers',
      },
      {
        resource: 'comment',
        resourceId: '*',
        verb: 'POST',
        action: 'createComment',
      },
      {
        resource: 'comment',
        resourceId: '*',
        verb: 'GET',
        action: 'getAllComments',
      },
      {
        resource: 'location',
        resourceId: '*',
        verb: 'GET',
        action: 'getLocation',
      },
    ],
    service: 'projects',
    owningResource: 'project',
  },
];
