import { useQuery } from '@tanstack/react-query';
import { getProjectMembers } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectMembers(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.members, projectId],
    queryFn: () => getProjectMembers(projectId),
    placeholderData: [],
  });
}
