import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { Title } from 'app/common/Typography';
import styles from './modules/RequestActions.module.css';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useRequestsRespond } from './hooks/useRequestsRespond';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useNavigate, useParams } from 'react-router-dom';
import { checkIsInvestmentRequest } from 'app/utils/helpers';
import { requestActions } from 'app/utils/constant';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const initialValues = {
  declineReason: '',
};

function RequestActions({ request }) {
  const { projectId } = useParams();
  const isInProject = !!projectId;
  const navigate = useNavigate();
  const { mutate: respondToRequest, isPending: isResponding } = useRequestsRespond(isInProject);

  const [state, setState] = useState({
    openModal: false,
    confirmationModal: false,
    modalAction: null,
  });

  const toggleModal = (action = null) => {
    setState((prevState) => ({
      ...prevState,
      openModal: !prevState.openModal,
      modalAction: action,
    }));
  };

  const toggleConfirmationModal = () => {
    setState((prevState) => ({
      ...prevState,
      confirmationModal: !state.confirmationModal,
    }));
  };

  const handleYesProceed = (values, { setSubmitting } = {}) => {
    const response = {
      request,
      action: state.modalAction,
      declineReason: values?.declineReason,
    };

    const isInvestmentRequest = checkIsInvestmentRequest(request.invitationType);

    toggleConfirmationModal();
    toggleModal();

    respondToRequest(response, {
      onSuccess: () => {
        const isDeclined = response.invitationAction === requestActions.DECLINE;
        const shouldRedirect = isDeclined && !isInvestmentRequest && isInProject;

        if (shouldRedirect) {
          navigate('/requests', { replace: true });
        }
      },
      onSettled: () => setSubmitting?.(false),
    });
  };

  return (
    <>
      <Box className={styles.respondButtons}>
        <LoadingButton
          className={`${styles.button} ${styles.declineButton}`}
          variant='contained'
          color='error'
          onClick={() => toggleModal('decline')}
        >
          Decline
        </LoadingButton>
        <LoadingButton
          className={`${styles.button} ${styles.acceptButton}`}
          variant='contained'
          color='success'
          onClick={() => toggleModal('accept')}
        >
          Accept
        </LoadingButton>
      </Box>
      <Modal open={state.openModal} onClose={toggleModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <Box display='flex' justifyContent='space-between' alignItems='center' p={2}>
            <Title sx={{ m: 0 }}>{state.modalAction}</Title>
            <IconButton onClick={toggleModal}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Typography sx={{ p: 3, fontSize: '1rem', fontWeight: '450' }}>
            <span>Are you sure want to {state.modalAction} this request?</span>
          </Typography>
          <ModalDivider />
          <Box display='flex' justifyContent='flex-end' gap={2} p={2}>
            <LoadingButton
              color='error'
              variant='outlined'
              onClick={toggleModal}
              disabled={isResponding}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={() =>
                state.modalAction === 'accept' ? handleYesProceed() : toggleConfirmationModal()
              }
              loading={isResponding}
            >
              Yes Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Modal open={state.confirmationModal} onClose={toggleConfirmationModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '30%',
          }}
        >
          <Box py={1} px={2} justifyContent='space-between' alignItems='center' display='flex'>
            <Title>Confirmation</Title>
            <IconButton onClick={toggleConfirmationModal}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.requestDecline()}
            onSubmit={handleYesProceed}
          >
            {({ values, touched, errors, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Box p={2}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name='declineReason'
                    placeholder='Reason for decline'
                    value={values.declineReason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.declineReason && Boolean(errors.declineReason)}
                    helperText={touched.declineReason && errors.declineReason}
                    disabled={isSubmitting}
                  />
                </Box>
                <Box display='flex' justifyContent='flex-end' gap={2} p={2}>
                  <LoadingButton
                    color='error'
                    variant='outlined'
                    onClick={toggleConfirmationModal}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    loading={isSubmitting}
                  >
                    Yes Proceed
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default RequestActions;
