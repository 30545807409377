import React, { useReducer, useRef } from "react";
import {
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Tooltip,
  styled,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddEditMembers from "../LeadsData/Members/MembersAddEdit";
import { useProjectRoles } from "../LeadsData/Roles/hooks/useProjectRoles";
import { useServices } from "app/hooks/useServices";
import { useProjectMembers } from "../LeadsData/Members/hooks/useProjectMembers";
import { useProject } from 'app/contexts/ProjectContext';
import { useParams } from "react-router-dom";
import {
  StyledAddButton, StyledIcon, IconDivider,
  StledCardTitle, MembersCardBody, StyledAvatar, MembersBodyCard,
  GPName, GPRole, GPPhoneNumber, GPEmailId, StyledMenuIcon
} from "./StyledComponent";

const SET_MENU_AND_MODAL = "SET_MENU_AND_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  modalState: {
    add: false,
    edit: false,
  },
};

const MembersSection = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedMemberRef = useRef(null);
  const { projectId } = useParams();

  const { data: members } = useProjectMembers(projectId);
  const { data: services } = useServices();
  const { data: roles } = useProjectRoles(projectId, services);

  const handleOpenModal = (action, member = null) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { modalState: { [action]: true } },
    });
  };

  const handleCloseModal = (action) => {
    selectedMemberRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  return (
    <>
      <Box
        sx={{ margin: "20px 38px 20px 20px" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StledCardTitle>
          Members
        </StledCardTitle>
        <StyledAddButton
          startIcon={<StyledIcon />}
          onClick={() => handleOpenModal("add")}>
          Add Members
        </StyledAddButton>
      </Box>

      <IconDivider orientation="vertical" />
      <Box sx={{ overflow: 'auto', display: 'flex' }}>
        <MembersCardBody>
          {members && members.length > 0 ? (

            members.map((member) => (
              <MembersBodyCard key={member.id}>
                <CardContent sx={{ textAlign: "center" }}>
                  <StyledMenuIcon
                    onClick={() => handleOpenModal("edit", member)}
                  >
                    <MoreVertIcon />
                  </StyledMenuIcon>
                  <StyledAvatar
                    src="https://th.bing.com/th/id/R.70511d5d811763466ab10d1b049c821a?rik=vfoZemsxnNW4cg&riu=http%3a%2f%2fwww.floridapublicmedia.org%2fwp-content%2fuploads%2f2017%2f03%2fexplorer.png&ehk=ZE02GJZXgIepaFGWfE54zhvVy44jGK0zRfh7LzximU8%3d&risl=&pid=ImgRaw&r=0"
                    alt={member.memberName}
                  />
                  <GPName>
                    {member.memberName}
                  </GPName>
                  <GPRole>
                    {member?.userRoles[0]}
                  </GPRole>
                  <GPPhoneNumber>
                    {member.phone}
                  </GPPhoneNumber>
                  <GPEmailId>
                    {member.email}
                  </GPEmailId>
                </CardContent>
              </MembersBodyCard>
            ))
          ) : (
            <Box sx={{ width: "100%", padding: "7rem" }}>
              No Members Added
            </Box>
          )}
        </MembersCardBody>
      </Box>

      {(state.modalState.add || state.modalState.edit) && (
        <AddEditMembers
          selectedMember={selectedMemberRef.current}
          roles={roles}
          projectMembers={members}
          closeModal={() =>
            handleCloseModal(state.modalState.add ? "add" : "edit")
          }
        />
      )}
    </>
  );
};

export default MembersSection;
