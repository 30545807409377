import React from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Button,
  Container,
  Typography,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components/index.js';
import { Span } from 'app/common/Typography.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConstructionLenderView from './ConstructionLenderView';
import ConstructionLenderAddEdit from './ConstructionLenderAddEdit';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectCL } from './hooks/useProjectCL';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const ConstructionLenderSection = () => {
  const { projectId } = useProject();
  const { data: constructionLenders } = useProjectCL(projectId);
  const [selectedLenderIndex, setSelectedLenderIndex] = React.useState(null);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const handleSelectLender = (index) => setSelectedLenderIndex(index);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const selectedLender = constructionLenders && constructionLenders[selectedLenderIndex];

  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {(!constructionLenders || constructionLenders.length === 0) ? (
          <>
            <Title>Construction Lender</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Construction Lender
                </Typography>
                <Button onClick={toggleDialog} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </>
        ) : selectedLender ? (
          <ConstructionLenderView
            lender={selectedLender}
            handleBack={() => handleSelectLender(null)}
          />
        ) : (
          <>
            <Box
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >
              <Title>Construction Lender</Title>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button onClick={toggleDialog}>+ ADD</Button>
              </Box>
            </Box>
            <Box
              overflow='auto'
              border={'2px solid #E5E5E7'}
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              marginBottom={1}
            >
              <ProductTable>
                <TableHead style={{ backgroundColor: 'var(--tableHead-bg-color)' }}>
                  <TableRow color='var(--tableHead-row-color)'>
                    <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.NO</TableCell>
                    <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                      LENDER NAME
                    </TableCell>
                    <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                      EMAIL ADDRESS
                    </TableCell>
                    <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                      PHONE NUMBER
                    </TableCell>
                    <TableCell
                      sx={{ px: 0, width: '5%', color: 'var(--tableHead-row-color)' }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {constructionLenders?.map((lender, index) => (
                    <TableRow key={lender?.id} hover>
                      <TableCell
                        align='left'
                        sx={{
                          px: 2,
                          textTransform: 'capitalize',
                          fontWeight: 500,
                          fontSize: '14px',
                          color: 'var(--tableBody-row-color)',
                        }}
                      >
                        {`${(index + 1).toString().padStart(2, '0')}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 0,
                          fontWeight: 500,
                          fontSize: '14px',
                          color: 'var(--tableBody-row-color)',
                        }}
                        align='left'
                      >
                        {lender?.lenderName}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 0,
                          fontWeight: 500,
                          fontSize: '14px',
                          color: 'var(--tableBody-row-color)',
                        }}
                        align='left'
                      >
                        {lender?.lenderEmail}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 0,
                          fontWeight: 500,
                          fontSize: '14px',
                          color: 'var(--tableBody-row-color)',
                        }}
                        align='left'
                      >
                        {lender?.lenderPhone}
                      </TableCell>
                      <TableCell align='left' sx={{ color: 'var(--tableBody-row-color)' }}>
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }
                        >
                          <StyledItem onClick={() => handleSelectLender(index)}>
                            <Icon>
                              <VisibilityIcon />
                            </Icon>
                            <Span>View</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ProductTable>
            </Box>
          </>
        )}
      </Box>
      {isOpenDialog && <ConstructionLenderAddEdit closeDialog={toggleDialog} />}
    </>
  );
};

export default ConstructionLenderSection;
