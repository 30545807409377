import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, Typography, IconButton } from '@mui/material';
import styled from './Notification.module.css';
import CloseIcon from '@mui/icons-material/Close';

function Notification({ message }) {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;
  return (
    <Card className={styled.container}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent className={`${styled.cardContent}`}>
        <Typography variant='body2' component='p'>
          {message || "No notifications yet"}
        </Typography>
        <CardActions className={`${styled.cardActions}`}>
          {/* <Button
            className={`${styled.button} ${styled.declineButton}`}
            color='error'
            variant='contained'
          >
            Decline
          </Button>
          <Button
            className={`${styled.button} ${styled.acceptButton}`}
            variant='contained'
            color='success'
          >
            Accept
          </Button> */}
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default Notification;
