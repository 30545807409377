export const mockCurrentUser = {
  name: 'John',
  surname: 'Doe',
  dob: '01/01/1990',
  phone: '+09526544110',
  email: 'john.doe@example.com',
  address1: '123 Main St',
  address2: 'Suite 100',
  country: 'USA',
  city: 'New York',
  state: 'NY',
  zipCode: '10001',
};
