import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import LeadLeaseSuccess from './LeadLeaseSuccess';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectBuildingCreateUpdate } from './hooks/useProjectBuildingCreateUpdate';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const AddEditBuilding = ({ open, onClose, selectedBuilding }) => {
  const [successOpen, setSuccessOpen] = useState(false);
  const [submittedBuildingName, setSubmittedBuildingName] = useState(
    selectedBuilding?.buildingName,
  );
  const { projectId } = useProject();

  const { mutate: createUpdateBuilding } = useProjectBuildingCreateUpdate(
    projectId,
    selectedBuilding,
  );

  const handleSuccessClose = useCallback(() => {
    setSuccessOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    let timer;

    if (successOpen) {
      timer = setTimeout(handleSuccessClose, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [successOpen, handleSuccessClose]);

  const initialValues = {
    buildingName: selectedBuilding?.buildingName || '',
    units: [],
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (!selectedBuilding) {
      setSubmittedBuildingName(values.buildingName);
    }

    createUpdateBuilding(values, {
      onSettled: () => setSubmitting(false),
      onSuccess: () => setSuccessOpen(true),
    });
  };

  return (
    <Dialog open={open}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas.addBuilding()}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, setTouched }) => (
          <Form style={{ width: '580px', height: '500px', position: 'relative' }}>
            <Box
              sx={{
                paddingY: 2,
                paddingX: 1,
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Title fontSize='22px' fontWeight='600' color='var(--add-Building)'>
                Add Building
              </Title>
              <IconButton onClick={onClose}>
                <CloseIcon style={{ fontSize: 20, color: 'var(--closeIcon)' }} />
              </IconButton>
            </Box>
            <ModalDivider />

            <Field
              as={TextField}
              fullWidth
              name='buildingName'
              placeholder='Enter Building name or number'
              variant='standard'
              size='small'
              sx={{ marginTop: 2, paddingX: 2 }}
              error={touched.buildingName && Boolean(errors.buildingName)}
              helperText={<ErrorMessage name='buildingName' />}
              InputProps={{
                readOnly: !!selectedBuilding,
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'var(--add-Unit-bg)',
                marginBottom: '10px',
                marginTop: '20px',
                paddingInline: '20px',
              }}
            >
              <Typography variant='subtitle2' fontSize='20px' fontWeight='600'>
                Unit(s)
              </Typography>
              <Typography
                onClick={() => {
                  setTouched({
                    buildingName: true,
                    units: values.units.map((_, index) => ({
                      unitName: true,
                      space: true,
                    })),
                  });

                  if (!values.buildingName.trim()) {
                    setFieldValue('buildingName', values.buildingName, true);
                  } else {
                    const lastUnit = values.units[values.units.length - 1];
                    if (!lastUnit || (lastUnit.unitName && lastUnit.space)) {
                      setFieldValue('units', [...values.units, { unitName: '', space: '' }]);
                    }
                  }
                }}
                variant='text'
                color='var(--add-Unit-color)'
                fontWeight='500'
                fontSize='14'
                style={{ cursor: 'pointer' }}
              >
                + Add Unit
              </Typography>
            </div>

            {values.units.length > 0 && (
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  paddingInline: '20px',
                  marginBottom: '20px',
                }}
              >
                <Grid container spacing={2}>
                  {values.units.map((unit, index) => (
                    <>
                      <Grid item xs={12} sm={5.5}>
                        <InputLabel
                          sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            mb: 1,
                            color: 'var(--inputLabel-color)',
                          }}
                          htmlFor={`units[${index}].unitName`}
                        >
                          Unit Name
                        </InputLabel>
                        <Field
                          as={TextField}
                          name={`units[${index}].unitName`}
                          id={`units[${index}].unitName`}
                          size='small'
                          placeholder='Enter name'
                          fullWidth
                          error={
                            touched.units &&
                            touched.units[index]?.unitName &&
                            Boolean(errors.units?.[index]?.unitName)
                          }
                          helperText={<ErrorMessage name={`units[${index}].unitName`} />}
                          style={{ marginRight: '10px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5.5}>
                        <InputLabel
                          sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            mb: 1,
                            color: 'var(--inputLabel-color)',
                          }}
                          htmlFor={`units[${index}].space`}
                        >
                          Space (Sq ft)
                        </InputLabel>
                        <Field
                          as={TextField}
                          name={`units[${index}].space`}
                          id={`units[${index}].space`}
                          size='small'
                          type='number'
                          placeholder='Enter space'
                          fullWidth
                          inputProps={{ min: 1 }}
                          error={
                            touched.units &&
                            touched.units[index]?.space &&
                            Boolean(errors.units?.[index]?.space)
                          }
                          helperText={<ErrorMessage name={`units[${index}].space`} />}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        mt={1}
                      >
                        <IconButton
                          onClick={() => {
                            const updatedUnits = values.units.filter((_, i) => i !== index);
                            setFieldValue('units', updatedUnits);
                          }}
                          color='error'
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            )}
            <FormControl error={touched?.units && errors?.units}>
              {touched?.units && errors?.units && (
                <FormHelperText>{!Array.isArray(errors.units) && errors.units}</FormHelperText>
              )}
            </FormControl>

            <div
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                right: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button onClick={onClose} variant='outlined' color='error'>
                Cancel
              </Button>
              <Button type='submit' variant='contained' color='primary'>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <LeadLeaseSuccess
        open={successOpen}
        buildingName={submittedBuildingName}
        handleClose={handleSuccessClose}
      />
    </Dialog>
  );
};

export default AddEditBuilding;
