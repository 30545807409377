import { useState } from 'react';
import { Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { calculateTomorrow, formatDecimal, handleAxiosError } from 'app/utils/helpers';
import { Form, Formik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';
import { validationSchemas } from 'app/utils/validationSchemas';
import { Title } from 'app/common/Typography';
import { useProjectContract } from './hooks/useContract';
import { useProjectContractCreateUpdate } from './hooks/useContractCreateUpdate';
import { convertDateArrayToFormattedString } from 'app/utils/helpers';

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '90px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'var(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--dialogDivider)',
});


const LeadsContractDetails = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: contract } = useProjectContract(projectId);
  const { mutate: updateContract } = useProjectContractCreateUpdate(projectId);

  const [open, setOpen] = useState(false);

  const initialValues = {
    ...contract,
    effectiveDate: contract?.effectiveDate?convertDateArrayToFormattedString(contract?.effectiveDate):null,
    feasibilityEndDate: contract?.feasibilityEndDate?convertDateArrayToFormattedString(contract?.feasibilityEndDate):null,
    estimatedClosingDate: contract?.estimatedClosingDate?convertDateArrayToFormattedString(contract?.estimatedClosingDate):null,
  };

  console.log(contract?.effectiveDate,"contract?.effectiveDate");
  console.log(contract?.feasibilityEndDate,"contract?.feasibilityEndDate");
  console.log(contract?.estimatedClosingDate,"contract?.estimatedClosingDate")

  const infoPairs = [
    {
      key: 'EFFECTIVE DATE',
      value:initialValues?.effectiveDate? initialValues?.effectiveDate.format('MM-DD-YYYY'):'N/A',
    },
    { key: 'FEASIBILITY DUE DATE', value:initialValues?.feasibilityEndDate? initialValues?.feasibilityEndDate.format('MM-DD-YYYY'):'N/A' },
    { key: 'CLOSING DATE', value: initialValues?.estimatedClosingDate?initialValues?.estimatedClosingDate.format('MM-DD-YYYY'):'/N/A' },
    { key: 'EARNEST MONEY', value: initialValues.earnestMoney },
    { key: 'OPTIONS MONEY', value: initialValues.optionsMoney },
  ];

  const toggleModal = () => setOpen((prev) => !prev);

  const handleSubmit = (values, { setSubmitting }) => {
    const formattedDates = {
      effectiveDate: values.effectiveDate.toISOString(),
      feasibilityEndDate: values.feasibilityEndDate.toISOString(),
      estimatedClosingDate: values.estimatedClosingDate.toISOString(),
    };

    updateContract(
      { ...values, ...formattedDates },
      {
        onSuccess: toggleModal,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} sx={{ border: '1px solid #DADCE0', marginTop: '1%', marginBottom: '1%' }} marginRight={2} >
        <Box style={{ backgroundColor: 'var(--tableHead-bg-color)' }} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} padding={2}>
          <Title sx={{ m: 0 }}>Contract Details</Title>
          <Button
            style={{ color: 'var(--editButton)', fontSize: '14px' }}
            onClick={toggleModal}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} />
            Edit
          </Button>
        </Box>
        <Divider />
        <InfoContainer item lg={6} md={6} sm={12} xs={12} padding={2}>
          {infoPairs.map(({ key, value }, index) => {
            let output;
            switch (true) {
              case typeof value === 'string':
                output = value;
                break;
              case typeof value === 'number' && !isNaN(value):
                output = `$${value.toFixed(2)}`;
                break;
              default:
                output = '$0.00';
                break;
            }
            return (
              <Box key={index}>
                <Key>{key}</Key>
                <Value className='basicvalues'>{output}</Value>
              </Box>
            );
          })}
        </InfoContainer>
      </Box>

      <Dialog open={open} onClose={toggleModal}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle>Contract Details</DialogTitle>
          <IconButton onClick={toggleModal} sx={{ marginRight: 2 }}>
            <Close />
          </IconButton>
        </Box>
        <DialogDivider />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.contract()}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, errors, touched, setFieldValue, handleBlur, handleChange }) => {
            const handleDateChange = (name, value) => {
              handleChange({
                target: {
                  name,
                  value,
                },
              });
            };

            const handlePriceChange = (e) => {
              const { name, value } = e.target;

              setFieldValue(name, formatDecimal(value, 2));
            };

            return (
              <Form>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid item xs={12}>
                        <DatePicker
                          disablePast
                          name='effectiveDate'
                          label='Effective Date'
                          value={values.effectiveDate}
                          onChange={(value) => handleDateChange('effectiveDate', value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              onBlur: handleBlur,
                              error: Boolean(touched.effectiveDate && errors.effectiveDate),
                              helperText: touched.effectiveDate && errors.effectiveDate,
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          disablePast
                          label='Feasibility Due Date'
                          name='feasibilityEndDate'
                          minDate={calculateTomorrow(values.effectiveDate)}
                          value={values.feasibilityEndDate}
                          onChange={(value) => handleDateChange('feasibilityEndDate', value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              onBlur: handleBlur,
                              helperText: touched.feasibilityEndDate && errors.feasibilityEndDate,
                              error: Boolean(
                                errors.feasibilityEndDate && touched.feasibilityEndDate,
                              ),
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          disablePast
                          label='Closing Date'
                          name='estimatedClosingDate'
                          minDate={calculateTomorrow(values.feasibilityEndDate)}
                          value={values.estimatedClosingDate}
                          onChange={(value) => handleDateChange('estimatedClosingDate', value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              onBlur: handleBlur,
                              helperText:
                                touched.estimatedClosingDate && errors.estimatedClosingDate,
                              error: Boolean(
                                errors.estimatedClosingDate && touched.estimatedClosingDate,
                              ),
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Earnest money'
                        type='number'
                        name='earnestMoney'
                        value={values.earnestMoney}
                        onChange={handlePriceChange}
                        onBlur={handleBlur}
                        helperText={touched.earnestMoney && errors.earnestMoney}
                        error={Boolean(errors.earnestMoney && touched.earnestMoney)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Options money'
                        type='number'
                        name='optionsMoney'
                        value={values.optionsMoney}
                        onChange={handlePriceChange}
                        onBlur={handleBlur}
                        helperText={touched.optionsMoney && errors.optionsMoney}
                        error={Boolean(errors.optionsMoney && touched.optionsMoney)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: 2,
                    }}
                  >
                    <LoadingButton
                      color='error'
                      variant='outlined'
                      onClick={toggleModal}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LeadsContractDetails;
