import React from 'react';
import { Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import useUserAuth from 'app/hooks/userUserAuth';
import { handleAxiosError } from 'app/utils/helpers';
import { StyledCard, Logo, Container, Title, Subtitle, EmailLabel, FooterText, CustomLoadinButton } from './StyleComponent';
import { StyledTextField } from 'app/common/Typography';

const validationSchema = Yup.object().shape({
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters in length')
    .required('Password is required!')
    .test(
      'Password',
      'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character',
      function (value) {
        const passwordRegex =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~`!@#$%^&()\-_=+{}[\]\\|;:"<>,./?*]).{8,}$/;
        return passwordRegex.test(value);
      },
    ),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

const initialValues = {
  password: '',
  confirmPassword: ''
};

const Password = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleFormSubmit = async (values) => {
    try {
    navigate('../register-success');
    } catch (error) {
      if (error.response.status === 401) {
        navigate('../not-registered', {
          state: { redirect: location.state?.redirect },
        });
      } else {
        handleAxiosError(error);
      }
    }
  };

  return (
    <Container>
      <Logo>flackre</Logo>

      <StyledCard>
        <Title>Create Password</Title>
        <Subtitle>Create a password for your account</Subtitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <EmailLabel>Password</EmailLabel>
              <StyledTextField
                fullWidth
                size='small'
                type='password'
                name='password'
                placeholder='password'
                variant='outlined'
                onBlur={handleBlur}
                value={values.password}
                onChange={handleChange}
                helperText={touched.password && errors.password}
                error={Boolean(errors.password && touched.password)}
                sx={{ marginBottom: '24px' }}
              />
              <EmailLabel>Confirm Password</EmailLabel>
              <StyledTextField
                fullWidth
                size='small'
                type='password'
                name='confirmPassword'
                placeholder='confirm password'
                variant='outlined'
                onBlur={handleBlur}
                value={values.confirmPassword}
                onChange={handleChange}
                helperText={touched.confirmPassword && errors.confirmPassword}
                error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                sx={{ marginBottom: '24px' }}
              />
              <CustomLoadinButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
                fullWidth
              >
                Submit
              </CustomLoadinButton>
            </form>
          )}
        </Formik>

      </StyledCard>

      <FooterText>
        Having trouble? Please contact <br />
        <a href="mailto:help@flackre.com" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#282A2D' }}>
          help@flackre.com
        </a>{' '}
        for further support
      </FooterText>
      <FooterText>
        <a href='#' className="custom-link">
          Terms & Conditions
        </a>{' '}
        |{' '}
        <a href='#' className="custom-link">
          Privacy Policy
        </a>
      </FooterText>
    </Container>
  );
};

export default Password;
