import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUpdateProjectBuilding } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectBuildingCreateUpdate(projectId, selectedBuilding) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values) => {
      const isUpdate = !!selectedBuilding;

      const payloadData = {
        projectId,
        buildingName: values.buildingName,
        units: [...(selectedBuilding?.units || []), ...(values.units || [])],
        ...(isUpdate ? { id: selectedBuilding.id } : {}),
      };

      console.log(payloadData);

      return createUpdateProjectBuilding(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [...queryKeys.project.buildings, projectId] }),
  });
}
