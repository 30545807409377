import { useQuery } from '@tanstack/react-query';
import { getAllUsers } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useAllUsers(selectedMember) {
  return useQuery({
    queryKey: queryKeys.allUsers,
    queryFn: getAllUsers,
    placeholderData: [],
    enabled: !selectedMember,
  });
}