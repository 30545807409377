import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addUserInvestmentPayment, addUserInvestments } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useUserInvestmentsAdd(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const paymentDetails = [];

      const {
        isAcceptedInvestor,
        fullName:investorName,
        investorUserId,
        interestedAmount,
        interestedShares,
        investedAmount,
        investedShares,
        paymentMode,
        paymentDate,
      } = data;

      const paymentDetailItem = {
        investedAmount,
        investedShares,
        paymentMode: paymentMode.toUpperCase(),
        paymentDate: paymentDate.toISOString(),
      };

      if (investedAmount) {
        paymentDetails.push(paymentDetailItem);
      }

      const firstRequestData = {
        investorName,
        investorUserId,
        interestedAmount,
        interestedShares,
        paymentDetails,
      };

      return isAcceptedInvestor
        ? await addUserInvestmentPayment(projectId, investorUserId, paymentDetailItem)
        : await addUserInvestments(projectId, firstRequestData);
    },
    onSuccess: (_, { investorUserId }) =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.userInvestments, projectId, investorUserId],
      }),
  });
}
