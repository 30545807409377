import React from 'react'
import {
    List,
    ListItem,
    Radio,
    RadioGroup,
    FormControlLabel,
    Box,
    TextField,
    Modal,
    Typography,
    Avatar,
    Link,
    Button,
    IconButton, Grid, Card, Divider, ListItemText, styled
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { LinksAddIcon, StyledcrossIcon } from 'app/common/icons';
import { StyledBox1,StyledModalTitle,StyledCrossIcon,
    StyledBox2,StyledBox3,LinkContainer,AddLinkButton,ModalButtonCancle,
    ModalButtonSave,StyledBox4,StyledLabel,StyledTextField
 } from './StyledComponents';

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
    // marginLeft: theme.spacing(1),

}));

const ReferenceLinksModal = ({ handleCloseModal }) => {

    const [links, setLinks] = React.useState(['']);

    const handleAddLink = () => {
        setLinks([...links, '']);
    };

    const handleRemoveLink = (index) => {
        const updatedLinks = links.filter((_, i) => i !== index);
        setLinks(updatedLinks);
    };

    const handleChangeLink = (index, value) => {
        const updatedLinks = [...links];
        updatedLinks[index] = value;
        setLinks(updatedLinks);
    };

    const handleSaveLinks = () => {
        console.log('Saved Links:', links);
        handleCloseModal();
    };
    return (
        <>
            <StyledBox1>
                <StyledBox2  >
                    <StyledModalTitle>Add Reference Link</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <StyledBox3>
                    <StyledLabel>
                        URL
                    </StyledLabel>
                    {links.map((link, index) => (

                        <LinkContainer key={index}>
                            {/* <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={link}
                                    onChange={(e) => handleChangeLink(index, e.target.value)}
                                /> */}
                            <StyledTextField
                                name='Area'
                                placeholder='e.g. :www.flackre.com'
                                onChange={(e) => handleChangeLink(index, e.target.value)}
                                value={link}
                                fullWidth
                                variant='outlined'
                            />
                            <CloseIconButton onClick={() => handleRemoveLink(index)}>
                                <StyledcrossIcon />
                            </CloseIconButton>
                        </LinkContainer>
                    ))}
                    <AddLinkButton
                        startIcon={<LinksAddIcon />}
                        onClick={handleAddLink}
                    >
                        Rerence Links
                    </AddLinkButton>
                </StyledBox3>
                <StyledBox4  >
                    <ModalButtonCancle
                        variant='outlined'
                        onClick={handleCloseModal}
                    // disabled={isSubmitting}
                    >
                        Cancel
                    </ModalButtonCancle>
                    <ModalButtonSave
                        type='submit'
                        onClick={handleSaveLinks}
                    // loading={isSubmitting}
                    >
                        Save
                    </ModalButtonSave>
                </StyledBox4>
            </StyledBox1>
        </>
    )
}

export default ReferenceLinksModal
