import { API_ENDPOINTS } from './apiConstants';
import { axiosService } from 'app/services/axiosService';
import { notify } from 'app/services/notify';

export const fetchLeads = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.PROJECTS.LIST_PROJECTS);
    return response.data.data.records;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const fetchNewLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_NEW_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const fetchOngoingLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_ONGOING_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCancelledLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_CANCELLED_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOnHoldLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_ONHOLD_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCompletedLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_COMPLETED_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getProjectDetails = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.PROJECTS.GET_PROJECT_DETAILS(id));
  return response.data.data.records[0];
};

export const fetchCountsOfProjects = async () => {
  const response = await axiosService.Get(API_ENDPOINTS.PROJECTS.GET_PROJECTS_COUNT);
  return response.data.data;
};

export const getProjectLocation = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.LOCATIONS.GET_PROJECT_LOCATION(id));
  return response.data.data;
};

export const getProjectDocuments = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.DOCUMENTS.GET_DOCUMENTS(id));
  return response.data.data;
};

export const fetchLeadsDocByUserId = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.DOCUMENTS.GET_ALL_DOCUMENTS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getProjectMembers = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.MEMBERS.GET_PROJECT_MEMBERS(id));
  return response.data.data;
};

export const getProjectLLC = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.LLC.GET_LLC_DETAILS(id));
  return response.data.data;
};

export const fetchUserByEmail = async (email) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_USER_DETAILS_BY_EMAIL(email));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectLeadInformation = async (obj) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.PROJECTS.CREATE_PROJECT, obj);
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectDeleteLeadInformation = async (projectID) => {
  try {
    const result = await axiosService.Delete(API_ENDPOINTS.PROJECTS.DELETE_PROJECT(projectID));
    return result;
  } catch (error) {
    throw error;
  }
};

export const deleteProjectDocument = async (fileId) => {
  const result = await axiosService.Delete(
    API_ENDPOINTS.DOCUMENTS.DELETE_DOCUMENTS_BY_FILE_ID(fileId),
  );
  return result.data;
};

export const getProjectClosingContract = async (projectID) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.CLOSING_CONTRACT.GET_CLOSING_CONTRACT(projectID),
  );

  return response.data.data;
};

export const createUpdateProjectClosingContract = async (projectID, obj) =>
  await axiosService.Post(
    API_ENDPOINTS.CLOSING_CONTRACT.CREATE_UPDATE_CLOSING_CONTRACT(projectID),
    obj,
  );

export const updateProjectBasic = async (projectID, obj) =>
  await axiosService.Put(API_ENDPOINTS.PROJECTS.UPDATE_PROJECT(projectID), obj);

export const createUpdateProjectLocation = async (projectID, obj) =>
  await axiosService.Post(API_ENDPOINTS.LOCATIONS.CREATE_UPDATE_PROJECT_LOCATION(projectID), obj);

export const uploadProjectDocuments = async (projectID, obj) => {
  const response = await axiosService.Post(
    API_ENDPOINTS.DOCUMENTS.UPLOAD_DOCUMENTS(projectID),
    obj,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data.data;
};

export const getProjectRoles = async (projectId) => {
  const response = await axiosService.Get(API_ENDPOINTS.ROLES.GET_CREATE_PROJECT_ROLES(projectId));
  return response.data;
};

export const createProjectRole = async (projectId, data) =>
  await axiosService.Post(API_ENDPOINTS.ROLES.GET_CREATE_PROJECT_ROLES(projectId), data);

export const updateProjectRole = async (projectId, roleName, data) =>
  await axiosService.Put(API_ENDPOINTS.ROLES.UPDATE_DELETE_PROJECT_ROLE(projectId, roleName), data);

export const deleteProjectRole = async (projectId, roleName) =>
  await axiosService.Delete(API_ENDPOINTS.ROLES.UPDATE_DELETE_PROJECT_ROLE(projectId, roleName));

export const getAllUsers = async () => {
  const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_ALL_USERS);
  return response.data.data;
};

export const getUserDetailsById = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_USER_DETAILS_BY_ID(id));
  return response.data.data;
};

export const addProjectMember = async (projectID, obj) =>
  await axiosService.Post(API_ENDPOINTS.MEMBERS.ASSIGN_PROJECT_MEMBERS(projectID), obj);

export const updateProjectMemberRole = async (projectID, obj) =>
  await axiosService.Put(API_ENDPOINTS.MEMBERS.UPDATE_PROJECT_MEMBER_ROLE(projectID), obj);

export const deleteProjectMember = async (projectID, obj) =>
  await axiosService.Delete(API_ENDPOINTS.MEMBERS.DELETE_PROJECT_MEMBER(projectID), obj);

export const createUpdateProjectLLC = async (projectID, obj) =>
  await axiosService.Post(API_ENDPOINTS.LLC.CREATE_UPDATE_LLC(projectID), obj);

export const updateProjectType = async (projectID, obj) =>
  await axiosService.Put(API_ENDPOINTS.PROJECTS.UPDATE_PROJECT_TYPE(projectID), obj);

export const getProjectContract = async (projectID) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.CONTRACTDETAILS.GET_CONTRACT_DETAILS(projectID),
  );
  return response.data.data[0];
};

export const createUpdateProjectContract = async (projectID, obj) =>
  await axiosService.Post(
    API_ENDPOINTS.CONTRACTDETAILS.CREATE_UPDATE_CONTRACT_DETAILS(projectID),
    obj,
  );

export const createUpdateProjectInvestments = async (projectID, obj) =>
  await axiosService.Post(
    API_ENDPOINTS.INVESTMENT_DETAILS.CREATE_UPDATE_PROJECT_INVESTMENTS(projectID),
    obj,
  );

export const getProjectInvestments = async (projectID) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.INVESTMENT_DETAILS.GET_PROJECT_INVESTMENTS(projectID),
  );
  return response.data.data;
};

export const getProjectInvestmentDetails = async (projectID) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.INVESTMENT_DETAILS.GET_PROJECT_INVESTMENT_DETAILS(projectID),
  );
  return response.data.data;
};

export const getUserInvestmentDetails = async (projectID, investorUserId) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.INVESTMENT_DETAILS.GET_USER_INVESTMENT_DETAILS(projectID, investorUserId),
  );
  return response.data.data;
};

export const addUserInvestments = async (projectID, obj) =>
  await axiosService.Post(API_ENDPOINTS.INVESTMENT_DETAILS.ADD_USER_INVESTMENTS(projectID), obj);

export const addUserInvestmentPayment = async (projectID, investorUserId, obj) =>
  await axiosService.Post(
    API_ENDPOINTS.INVESTMENT_DETAILS.ADD_USER_INVESTMENT_PAYMENT(projectID, investorUserId),
    obj,
  );

export const getProjectExpenses = async (projectID) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.EXPENSES_DETAILS.GET_EXPENSES_DETAILS(projectID),
  );
  return response.data.data;
};

export const getProjectExpensesByInvoice = async (projectID, invoiceNo, expenseId) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.EXPENSES_DETAILS.GET_EXPENSES_BY_INVOICE(projectID, invoiceNo, expenseId),
  );
  return response.data.data;
};

export const createProjectExpenses = async (projectID, obj) =>
  await axiosService.Post(API_ENDPOINTS.EXPENSES_DETAILS.CREATE_EXPENSES_DETAILS(projectID), obj);

export const updateProjectExpensesByInvoice = async (projectID, invoiceNo, expenseId, data) =>
  await axiosService.Post(
    API_ENDPOINTS.EXPENSES_DETAILS.UPDATE_EXPENSES_BY_INVOICE(projectID, invoiceNo, expenseId),
    data,
  );

export const getProjectBuildings = async (projectID) => {
  const result = await axiosService.Get(API_ENDPOINTS.LEASE_SALE.GET_BUILDINGS(projectID));
  return result.data.data;
};

export const getProjectBuildingUnitLeases = async (projectID, unitId) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.LEASE_SALE.GET_BUILDING_UNIT_LEASES(projectID, unitId),
  );
  return result.data.data;
};

export const getProjectBuildingUnitSales = async (projectID, unitId) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.LEASE_SALE.GET_BUILDING_UNIT_SALES(projectID, unitId),
  );
  return result.data.data;
};

export const createUpdateProjectBuilding = async (projectID, obj) =>
  await axiosService.Post(API_ENDPOINTS.LEASE_SALE.CREATE_UPDATE_BUILDING(projectID), obj);

export const createUpdateProjectBuildingUnits = async (projectID, buildingId, obj) =>
  await axiosService.Post(
    API_ENDPOINTS.LEASE_SALE.CREATE_UPDATE_BUILDING_UNITS(projectID, buildingId),
    obj,
  );

export const deleteProjectBuildingUnitLease = async (projectID, leaseId) =>
  await axiosService.Delete(
    API_ENDPOINTS.LEASE_SALE.DELETE_BUILDING_UNIT_LEASE(projectID, leaseId),
  );

export const deleteProjectBuildingUnitSale = async (projectID, saleId) =>
  await axiosService.Delete(API_ENDPOINTS.LEASE_SALE.DELETE_BUILDING_UNIT_SALE(projectID, saleId));

export const createUpdateProjectBuildingLease = async (projectID, data) =>
  await axiosService.Post(API_ENDPOINTS.LEASE_SALE.CREATE_UPDATE_LEASE(projectID), data);

export const createUpdateProjectBuildingSale = async (projectID, data) =>
  await axiosService.Post(API_ENDPOINTS.LEASE_SALE.CREATE_UPDATE_SALE(projectID), data);

export const getProjectGCAll = async (projectID) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.GENERAL_CONTRACTOR.GET_PROJECT_GC_ALL(projectID),
  );
  return result.data.data;
};

export const getProjectGCById = async (projectID, GCID) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.GENERAL_CONTRACTOR.GET_PROJECT_GC_BY_ID(projectID, GCID),
  );
  return result.data.data;
};

export const createProjectGC = async (projectID, data) =>
  await axiosService.Post(API_ENDPOINTS.GENERAL_CONTRACTOR.CREATE_PROJECT_GC(projectID), data);

export const updateProjectGC = async (projectID, GCID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.GENERAL_CONTRACTOR.UPDATE_PROJECT_GC(projectID, GCID),
    data,
  );

export const addProjectGCManager = async (projectID, GCID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.GENERAL_CONTRACTOR.ADD_PROJECT_GC_MANAGER(projectID, GCID),
    data,
  );

export const updateProjectGCManager = async (projectID, GCID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.GENERAL_CONTRACTOR.UPDATE_PROJECT_GC_MANAGER(projectID, GCID),
    data,
  );

export const addProjectGCChangeOrder = async (projectID, GCID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.GENERAL_CONTRACTOR.ADD_PROJECT_GC_CHANGE_ORDER(projectID, GCID),
    data,
  );

export const updateProjectGCChangeOrder = async (projectID, GCID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.GENERAL_CONTRACTOR.UPDATE_PROJECT_GC_CHANGE_ORDER(projectID, GCID),
    data,
  );

export const addProjectGCPayment = async (projectID, GCID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.GENERAL_CONTRACTOR.ADD_PROJECT_GC_PAYMENT(projectID, GCID),
    data,
  );

export const updateProjectGCPayment = async (projectID, GCID, paymentId, data) =>
  await axiosService.Put(
    API_ENDPOINTS.GENERAL_CONTRACTOR.UPDATE_PROJECT_GC_PAYMENT(projectID, GCID, paymentId),
    data,
  );

export const getProjectPMAll = async (projectID) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.PROPERTY_MANAGEMENT.GET_PROJECT_PM_ALL(projectID),
  );
  return result.data.data;
};

export const getProjectPMByID = async (projectID, PMID) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.PROPERTY_MANAGEMENT.GET_PROJECT_PM_BY_ID(projectID, PMID),
  );
  return result;
};

export const createProjectPM = async (projectID, data) =>
  await axiosService.Post(API_ENDPOINTS.PROPERTY_MANAGEMENT.CREATE_PROJECT_PM(projectID), data);

export const updateProjectPM = async (projectID, PMID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.PROPERTY_MANAGEMENT.UPDATE_PROJECT_PM(projectID, PMID),
    data,
  );

export const addProjectPMContactPerson = async (projectID, PMID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.PROPERTY_MANAGEMENT.ADD_PROJECT_PM_CONTACT_PERSON(projectID, PMID),
    data,
  );

export const updateProjectPMContactPerson = async (projectID, PMID, data) =>
  await axiosService.Put(
    API_ENDPOINTS.PROPERTY_MANAGEMENT.UPDATE_PROJECT_PM_CONTACT_PERSON(projectID, PMID),
    data,
  );

export const getProjectInvitations = async (projectID) => {
  const response = await axiosService.Get(
    API_ENDPOINTS.INVITATIONS.GET_PROJECT_INVITATIONS(projectID),
  );
  return response.data;
};

export const getProjectCLAll = async (projectID) => {
  const result = await axiosService.Get(
    API_ENDPOINTS.CONSTRUCTION_LENDER.GET_PROJECT_CL_ALL(projectID),
  );
  return result?.data?.data;
};

export const createProjectCL = async (projectID, data) =>
  await axiosService.Post(API_ENDPOINTS.CONSTRUCTION_LENDER.CREATE_PROJECT_CL(projectID), data);

export const updateProjectCL = async (projectID, lenderID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.CONSTRUCTION_LENDER.UPDATE_PROJECT_CL(projectID, lenderID),
    data,
  );

export const addProjectCLLoanPayment = async (projectID, lenderID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.CONSTRUCTION_LENDER.ADD_PROJECT_CL_LOAN_PAYMENT(projectID, lenderID),
    data,
  );

export const updateProjectCLLoanPayment = async (projectID, lenderID, data) =>
  await axiosService.Put(
    API_ENDPOINTS.CONSTRUCTION_LENDER.UPDATE_PROJECT_CL_LOAN_PAYMENT(projectID, lenderID),
    data,
  );

export const addProjectCLLoanWithdrawal = async (projectID, lenderID, data) =>
  await axiosService.Post(
    API_ENDPOINTS.CONSTRUCTION_LENDER.ADD_PROJECT_CL_LOAN_WITHDRAWAL(projectID, lenderID),
    data,
  );

export const updateProjectCLLoanWithdrawal = async (projectID, lenderID, data) =>
  await axiosService.Put(
    API_ENDPOINTS.CONSTRUCTION_LENDER.UPDATE_PROJECT_CL_LOAN_WITHDRAWAL(projectID, lenderID),
    data,
  );

export const auditLogs = async (projectID) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.AUDITLOGS.LOCATION(projectID));
    return result?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserLLC = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.USERLLC.GET_USER_LLC_DETAILS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const saveUserLLCInfo = async (obj) => {
  try {
    await axiosService.Post(API_ENDPOINTS.USERLLC.CREATE_UPDATE_USER_LLC, obj);
  } catch (error) {
    throw error;
  }
};
