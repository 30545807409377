import { useQuery } from '@tanstack/react-query';
import { getUserDetailsById } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useUserDetailsById(userId) {
  return useQuery({
    queryKey: [...queryKeys.userInfo, userId],
    queryFn: () => getUserDetailsById(userId),
    placeholderData: {},
    select: (data) => data[userId],
    staleTime: 1000 * 60 * 2,
    gcTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
  });
}
