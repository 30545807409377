import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Paper, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import GPSearchFilter from './GPSearchFilter';
import GeneralPartnertTable from './GeneralPartnertTable';
import Header from 'app/common/Header';
import { ContainerHeader } from 'app/common/Typography';
import { fetchLeads } from 'app/contexts/leadActions';
import { notify } from 'app/services/notify';
import {
  fetchCancelledLeadsWithPagination,
  fetchCompletedLeadsWithPagination,
  fetchLeadsDocByUserId,
  fetchLeadsWithPagination,
  fetchNewLeadsWithPagination,
  fetchOngoingLeadsWithPagination,
  fetchOnHoldLeadsWithPagination,
} from 'app/contexts/projectActions';
import { handleAxiosError } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: '1px solid #DADCE0',
  fontWeight: '500',
  textTransform: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: '#82B485',
    height: '3px',
    width: '125px',
  },
  '& .Mui-selected': {
    fontWeight: '700',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '14px',
}));

const StyledTabPanel = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const ListingItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

function TabPanel({ value, index, children }) {
  return <StyledTabPanel hidden={value !== index}>{value === index && children}</StyledTabPanel>;
}

const projectTypeToFetchFunction = {
  lead: fetchLeadsWithPagination,
  newproject: fetchNewLeadsWithPagination,
  ongoing: fetchOngoingLeadsWithPagination,
  cancelled: fetchCancelledLeadsWithPagination,
  onhold: fetchOnHoldLeadsWithPagination,
  completed: fetchCompletedLeadsWithPagination,
};

const ListingLayout = ({ type }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [listingData, setListingData] = useState(null);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const location = useLocation();

  const fetchProjectsData = async () => {
    try {
      setLoading(true);
      const fetchProjects = projectTypeToFetchFunction[type];
      const projectsList = await fetchProjects(pageNo + 1, pageSize);
      setListingData(projectsList);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setPageNo(0);
  };
  useEffect(() => {
    fetchProjectsData();
  }, [pageNo, pageSize, location.pathname]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleProjectClick = (projectId) => {
    if (type === 'lead') {
      navigate(`/listing/${projectId}`);
    } else {
      navigate(`/myprojects/${projectId}`);
    }
  };

  const leadCreation = () => {
    if (type === 'lead') {
      navigate(`../listing/newlead`);
    } else {
      navigate(`../myprojects/newlead`)
    }
  }

  return (
    <ContainerHeader>
      <Header
        buttonVisible={true}
        buttonName={type === 'lead' ? 'New Listing' : 'New Project'}
        headerName={type === 'lead' ? 'Listings' : 'Projects'}
        onButtonClick={leadCreation}
      />

      <StyledTabs value={activeTab} onChange={handleChange} textColor='inherit'>
        <StyledTab label='All' />
        <StyledTab label='General Partner' />
        {/* <StyledTab label='Investor' />
        <StyledTab label='Realtor' /> */}
      </StyledTabs>

      <TabPanel value={activeTab} index={0}>
        <GeneralPartnertTable
          listingData={listingData}
          onProjectClick={handleProjectClick}
          loading={loading}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <GeneralPartnertTable
          listingData={listingData}
          onProjectClick={handleProjectClick}
          loading={loading}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <GeneralPartnertTable
          listingData={listingData}
          onProjectClick={handleProjectClick}
          loading={loading}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={3}>
        <GeneralPartnertTable
          listingData={listingData}
          onProjectClick={handleProjectClick}
          loading={loading}
        />
      </TabPanel>
      {listingData && listingData.totalNoOfRecords && listingData.totalNoOfRecords > 5 ? (
          <TablePagination
              sx={{ px: 2 }}
              page={pageNo}
              component='div'
              rowsPerPage={pageSize}
              count={listingData?.totalNoOfRecords || 0}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          />
      ) : (
          ''
      )}
    </ContainerHeader>
  );
};

export default ListingLayout;