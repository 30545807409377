import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { useMemo } from 'react';

const ProductTable = styled(Table)(({ theme }) => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none', color: '#272937', fontSize: '0.875rem', fontWeight: 500 },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  fontSize: '1rem',
  '& th': {
    fontSize: '0.875rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
    '& th': {
      fontSize: '0.8125rem',
    },
    '& td': {
      fontSize: '0.8125rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    '& th': {
      fontSize: '0.75rem',
    },
    '& td': {
      fontSize: '0.75rem',
    },
  },
}));

const TableHeader = () => (
  <TableHead bgcolor='#F2F9FC'>
    <TableRow>
      {[
        { label: 'S.NO.', span: 1 },
        { label: 'ROLE NAME', span: 2 },
        { label: 'SERVICES', span: 5 },
        { label: 'ASSIGNED TO', span: 5 },
        { label: 'CREATED BY', span: 2 },
        { label: 'UPDATED ON', span: 2 },
        { label: '', span: 1 },
      ].map((header, index) => (
        <TableCell
          key={index}
          sx={{
            color: '#64748B',
            px: index === 0 ? 2 : 'inherit',
          }}
          colSpan={header.span}
        >
          {header.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

function RolesTable({ roles, isOnHoldOrCancelled, handleMenuOpen }) {
  const renderedTable = useMemo(
    () => (
      <TableBody>
        {roles?.length > 0 &&
          roles.map((role, index) => (
            <TableRow key={role.name} hover>
              <TableCell>{`${(index + 1).toString().padStart(2, '0')}`}</TableCell>
              <TableCell colSpan={2}>{role.name}</TableCell>
              <TableCell colSpan={5}>
                {role?.services.map(([serviceName, service], serviceIndex, array) => (
                  <Chip
                    key={serviceName}
                    label={service.displayName}
                    sx={{ mt: 0.5, mr: serviceIndex < array.length - 1 ? 0.5 : 0 }}
                  />
                ))}
              </TableCell>
              <TableCell colSpan={5}>
                {/* {role.assignedTo.map((member, memberIndex, array) => (
                <Chip
                  key={memberIndex}
                  label={member}
                  sx={{ mt: 0.5, mr: memberIndex < array.length - 1 ? 0.5 : 0 }}
                />
              ))} */}
              </TableCell>
              <TableCell colSpan={2}>{role.createdBy}</TableCell>
              <TableCell colSpan={2}>{/* {convertUTCDateToLocal(role.updatedOn)} */}</TableCell>
              <TableCell align='center'>
                <IconButton
                  aria-label='more'
                  onClick={(event) => handleMenuOpen(event, role)}
                  disabled={isOnHoldOrCancelled}
                >
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    ),
    [roles, isOnHoldOrCancelled, handleMenuOpen],
  );

  return (
    <div>
      <Box overflow='auto' m={2} mt={0} border='2px solid #E5E5E7'>
        <ProductTable>
          <TableHeader />
          {renderedTable}
        </ProductTable>
      </Box>
    </div>
  );
}

export default RolesTable;
