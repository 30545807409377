import { useQuery } from '@tanstack/react-query';
import { getProjectBuildings } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export const useProjectBuldings = (projectId) => {
  return useQuery({
    queryKey: [...queryKeys.project.buildings, projectId],
    queryFn: () => getProjectBuildings(projectId),
    placeholderData: [],
  });
};
