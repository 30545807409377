import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUpdateProjectClosingContract } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectClosingCreateUpdate(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) =>
      createUpdateProjectClosingContract(projectId, {
        projectId,
        ...data,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.closing, projectId],
      }),
  });
}
