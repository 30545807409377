import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addProjectMember } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectMembersAdd(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (membersData) => {
      const memberRequests = membersData.map((member) => addProjectMember(projectId, member));
      return await Promise.all(memberRequests);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.members, projectId],
      }),
  });
}
