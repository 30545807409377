import { useQuery } from '@tanstack/react-query';
import { getServices } from 'app/contexts/userAuthActions';
import { queryKeys } from 'app/react-query/queryKeys';

const normalizeActions = (actions) =>
  actions.reduce(
    (acc, action) => {
      const isRead = action.verbs[0] === 'GET';

      if (isRead) {
        acc.read[action.identifier] = action;
      } else {
        acc.write[action.identifier] = action;
      }

      return acc;
    },
    {
      read: {},
      write: {},
    },
  );

const transformServiceStructure = (service) => ({
  ...service,
  actions: normalizeActions(service.actions),
});

const transformServicesToMap = (services) =>
  services.map((service) => [service.name, transformServiceStructure(service)]);

export function useServices() {
  return useQuery({
    queryKey: queryKeys.services,
    queryFn: getServices,
    select: transformServicesToMap,
    placeholderData: [],
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
