import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  styled,
  Modal,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { Title } from 'app/common/Typography';
import OnGoingDialog from './OnGoingDialog';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const OnGoingConfirmationModal = ({ openModal, handleCancel, projectId }) => {
  const [openDetailStatus, setOpenDetailStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleYesProceedClick = () => {
    setOpenDetailStatus(true);
  };

  const handleCloseDialog = () => {
    setOpenDetailStatus(false);
    handleCancel();
  };

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <SubtitleDivider />
      <Modal open={openModal} onClose={handleCancel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '32%',
          }}
        >
          <Box
            sx={{
              p: 2,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Confirmation</Title>
            <IconButton onClick={handleCancel}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              marginLeft: '4px',
            }}
          >
            <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
              <Typography style={{ fontSize: '16px', fontWeight: '450' }}>
                {`You don't have sufficient funds to move the project to Ongoing`}
              </Typography>
              <Typography style={{ fontSize: '16px', fontWeight: '450', marginTop: '16px' }}>
                {`I will go with`}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOption === 'loan'}
                      onChange={handleCheckboxChange}
                      value='loan'
                    />
                  }
                  label='Loan/Finance'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOption === 'cash'}
                      onChange={handleCheckboxChange}
                      value='cash'
                    />
                  }
                  label='Cash'
                />
              </Box>
            </Box>

            <ModalDivider />
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                p: 2,
              }}
            >
              <LoadingButton
                color='error'
                sx={{ height: '40px', mt: 0.5 }}
                variant='outlined'
                onClick={handleCancel}
                style={{ boxShadow: 'none' }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                onClick={handleYesProceedClick}
                style={{ boxShadow: 'none' }}
                disabled={!selectedOption}
              >
                Yes Proceed
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      <OnGoingDialog
        isOpenDialog={openDetailStatus}
        closeDialog={handleCloseDialog}
        selectedOption={selectedOption}
        projectId={projectId}
      />
    </>
  );
};

export default OnGoingConfirmationModal;
