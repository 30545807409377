// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestCard_requestCard__TWcsz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--requestCard-border);
  padding: 1rem;
  & span,
  p {
    font-weight: 500;
  }
}

.RequestCard_buttonsContainer__3Q\\+Tq {
  display: flex;
  gap: 1rem;
}

.RequestCard_mobileRequestCard__xWvyW {
  flex-direction: column;
  align-items: flex-start;
  & .RequestCard_buttonsContainer__3Q\\+Tq {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/views/dashboard/Requests/modules/RequestCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;EACb;;IAEE,gBAAgB;EAClB;AACF;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB;IACE,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":[".requestCard {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  border-radius: 4px;\r\n  border: 1px solid var(--requestCard-border);\r\n  padding: 1rem;\r\n  & span,\r\n  p {\r\n    font-weight: 500;\r\n  }\r\n}\r\n\r\n.buttonsContainer {\r\n  display: flex;\r\n  gap: 1rem;\r\n}\r\n\r\n.mobileRequestCard {\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  & .buttonsContainer {\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requestCard": `RequestCard_requestCard__TWcsz`,
	"buttonsContainer": `RequestCard_buttonsContainer__3Q+Tq`,
	"mobileRequestCard": `RequestCard_mobileRequestCard__xWvyW`
};
export default ___CSS_LOADER_EXPORT___;
