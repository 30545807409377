import React, { useState, useRef} from 'react';
import { Box, Autocomplete} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { notify } from 'app/services/notify';
import { Formik } from 'formik';
import { StyledBox1, StyledModalTitle, StyledBox2, StyledCrossIcon, StyledBox3, StyledTextField, StyledLabel, StyledBox4, ModalButtonCancle, ModalButtonSave } from '../StyleComponents';
import { designationOptions } from 'app/utils/constant';
import { saveUserLLCInfo } from 'app/contexts/leadActions';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';
import { useUserAuth } from 'app/hooks/userUserAuth';

const OrganizationModal = ({ fetchData, llcDetails, handleCloseModal }) => {
  const [selectDesignation, setSelectDesignation] = useState(null);
  const { userNameJWT } = useUserAuth();
  const [leadLocationData, setLeadLocationData] = useState({
    search: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: null,
    longitude: null,
  });
  const searchInputRef = useRef(null);

  const initialValues = {
    organizationName: llcDetails?.llcName || "",
    licenseNumber: llcDetails?.einNum || "",
    Address1: llcDetails?.address1,
    Address2: llcDetails?.address2,
    City: llcDetails?.city,
    State: llcDetails?.state,
    zipCode: llcDetails?.zipCode,
    Country: llcDetails?.country,
  };

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .required("Organization Name is required")
      .min(2, "Organization Name must be at least 2 characters"),
    licenseNumber: Yup.string()
      .required("EIN/License Number is required"),
    Address1: Yup.string()
      .required("Address1 is required"),
    City: Yup.string()
      .required("City is required"),
    State: Yup.string()
      .required("State is required"),
    zipCode: Yup.string()
      .required("ZIP Code is required")
      .min(6, "ZIP Code must be at least 6 characters")
      .matches(/^\d{6}(-\d{4})?$/, "Invalid ZIP Code format"),
    Country: Yup.string()
      .required("Country is required"),
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const organizationData = {
        llcName: values.organizationName,
        einNum: values.licenseNumber,
        address1: values.Address1,
        address2: values.Address2,
        city: values.City,
        state: values.State,
        zipCode: values.zipCode,
        country: values.Country,
        designation: values.designation
      };
      if (llcDetails) {
        const updateLLC = {
          id: llcDetails?.id,
          ...organizationData
        };
        await saveUserLLCInfo(userNameJWT, updateLLC);
      } else {
        await saveUserLLCInfo(userNameJWT, organizationData);
      }
      await fetchData();
      handleCloseModal();
    } catch (error) {
      notify.error(`Error in Manager Details: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePlacesChanged = (setFieldValue) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setLeadLocationData((prevState) => {
      const updatedData = {
        ...prevState,
        ...addressData,
        search: place.formatted_address,
      };

      setFieldValue('Address1', updatedData.address1);
      setFieldValue('Address2', updatedData.address2);
      setFieldValue('City', updatedData.city);
      setFieldValue('State', updatedData.state);
      setFieldValue('zipCode', updatedData.zipCode);
      setFieldValue('Country', updatedData.country);
      setFieldValue('latitude', updatedData.latitude);
      setFieldValue('longitude', updatedData.longitude);

      return updatedData;
    });
  };

  return (
    <>
      <StyledBox1>
        <StyledBox2>
          <StyledModalTitle>{llcDetails ? 'Update' : 'Add'} Organization</StyledModalTitle>
          <StyledCrossIcon onClick={handleCloseModal}>
            <CloseIcon />
          </StyledCrossIcon>
        </StyledBox2>

        <StyledBox3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <StyledLabel>Organization Name</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="organizationName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organizationName}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.organizationName && !!errors.organizationName}
                    helperText={touched.organizationName && errors.organizationName}
                    disabled={isSubmitting}
                  />

                  <StyledLabel>EIN/License Number</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="licenseNumber"
                    onChange={handleChange}
                    value={values.licenseNumber}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.licenseNumber && !!errors.licenseNumber}
                    helperText={touched.licenseNumber && errors.licenseNumber}
                    disabled={isSubmitting}
                  />

                  <StyledLabel>Designation</StyledLabel>
                  <Autocomplete
                    size="small"
                    value={selectDesignation}
                    name="designation"
                    onChange={(event, newValue) => {
                      setSelectDesignation(newValue || null);
                      setFieldValue('designation', newValue ? newValue.label : '');
                    }}
                    options={designationOptions}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder="Select Designation"
                        error={touched.designation && !!errors.designation}
                        helperText={touched.designation && errors.designation}
                        disabled={isSubmitting}
                      />
                    )}
                  />

                  <StyledLabel>Address1</StyledLabel>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                    onUnmount={() => (searchInputRef.current = null)}
                    onPlacesChanged={() => handlePlacesChanged(setFieldValue)}
                  >
                    <StyledTextField
                      fullWidth
                      type="search"
                      name="Address1"
                      size="small"
                      placeholder="Search address"
                      variant="outlined"
                      value={values.Address1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.Address1 && !!errors.Address1}
                      helperText={touched.Address1 && errors.Address1}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>

                  <StyledLabel>Address2</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="Address2"
                    onChange={handleChange}
                    value={values.Address2}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={isSubmitting}
                  />

                  <StyledLabel>City</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="City"
                    onChange={handleChange}
                    value={values.City}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.City && !!errors.City}
                    helperText={touched.City && errors.City}
                    disabled={isSubmitting}
                  />

                  <StyledLabel>State</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="State"
                    onChange={handleChange}
                    value={values.State}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.State && !!errors.State}
                    helperText={touched.State && errors.State}
                    disabled={isSubmitting}
                  />

                  <StyledLabel>ZIP Code</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="zipCode"
                    onChange={handleChange}
                    value={values.zipCode}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.zipCode && !!errors.zipCode}
                    helperText={touched.zipCode && errors.zipCode}
                    disabled={isSubmitting}
                  />

                  <StyledLabel>Country</StyledLabel>
                  <StyledTextField
                    type="text"
                    name="Country"
                    onChange={handleChange}
                    value={values.Country}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.Country && !!errors.Country}
                    helperText={touched.Country && errors.Country}
                    disabled={isSubmitting}
                  />

                  <StyledBox4>
                    <ModalButtonCancle
                      variant="outlined"
                      onClick={handleCloseModal}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </ModalButtonCancle>
                    <ModalButtonSave type="submit" loading={isSubmitting}>
                      {llcDetails ? 'Update' : 'Save'}
                    </ModalButtonSave>
                  </StyledBox4>
                </Box>
              </form>
            )}
          </Formik>
        </StyledBox3>
      </StyledBox1>
    </>
  );
};

export default OrganizationModal;
