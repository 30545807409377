import React, { useState } from 'react';
import { Modal, Typography, Grid, IconButton, Box, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Title } from 'app/common/Typography';
import LeaseAddEdit from './LeaseAddEdit';
import { useProject } from 'app/contexts/ProjectContext';
import DescriptionIcon from '@mui/icons-material/Description';
import { DownloadIcon } from 'app/common/icons';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';

const SectionTitle = styled(Typography)(({ theme }) => ({
  height: '37px',
  backgroundColor: 'var(--section-Title)',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  fontSize: '14px',
  fontWeight: '500',
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const handleDownload = (base64Content, fileName, mimeType) => {
  try {
    const byteCharacters = atob(base64Content); // Decoding base64 content
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger download and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed:', error);
  }
};

const LeaseView = ({ open, onClose, selectedUnit, leaseData, tenantData, selectedBuilding }) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const { projectId } = useProject();
  const { data: documents } = useProjectDocuments(projectId);

  const handleEditClick = () => setIsOpenEdit(true);

  const handleFormClose = () => {
    setIsOpenEdit(false);
    onClose();
  };

  const matchedDocuments =
    leaseData?.leaseDocuments?.map((docId) => {
      return documents.find((doc) => doc.metadata.fileId === docId);
    }) || [];

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '48%',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              p: 1,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Details View</Title>
            <IconButton onClick={onClose}>
              <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box container spacing={2} p={1}>
            <Grid
              mb={2}
              p={1}
              item
              xs={12}
              sx={{
                border: '1px solid var(--LeadLeaseDetailModel-grid-border)',
                height: '93px',
                marginTop: '10px',
                backgroundColor: 'var(--LeadLeaseDetailModel-grid-color)',
              }}
            >
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h6'>
                  Lease Space: <strong>{leaseData?.leaseSpace} Sq fts</strong>
                </Typography>
                <Box display='flex' flexDirection='column' alignItems='flex-end'>
                  <Typography
                    sx={{
                      marginRight: 2,
                      color: 'var(--editButton)',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                    onClick={handleEditClick}
                  >
                    <BorderColorOutlinedIcon fontSize='14px' /> Edit
                  </Typography>
                  <Typography fontSize='14px' fontWeight='500' variant='body2'>
                    Lease Period: {convertUTCDateToLocal(new Date(...leaseData.leaseStartDate))} -{' '}
                    {convertUTCDateToLocal(new Date(...leaseData.leaseEndDate))}
                  </Typography>
                </Box>
              </Box>

              <Box display='flex' flexDirection='column' mt={2}>
                <Typography
                  fontSize='14px'
                  fontWeight='500'
                  variant='body2'
                  color='var(--typoGraphy-rent-year-text)'
                >
                  {leaseData?.rentSqFtPerYear} rent/year •{' '}
                  {convertUTCDateToLocal(new Date(...leaseData?.leaseTerm))} lease term • ₹
                  {leaseData?.securityDeposit} Security deposit
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              spacing={2}
              mb={2}
              p={1}
              sx={{
                border: '1px solid #DFDFE7',
                backgroundColor: 'var(--LeadLeaseDetailModel-grid-color)',
              }}
            >
              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='subtitle2'>
                  Lease Fixturization Date
                </Typography>
                <Typography variant='body2'>
                  {leaseData?.leaseFixturizationDate
                    ? convertUTCDateToLocal(new Date(...leaseData.leaseFixturizationDate))
                    : 'Date not available'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='subtitle2'>
                  Lease Execution Date
                </Typography>
                <Typography variant='body2'>
                  {leaseData?.leaseExecutionDate
                    ? convertUTCDateToLocal(new Date(...leaseData.leaseExecutionDate))
                    : 'Date not available'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='subtitle2'>
                  NNN
                </Typography>
                <Typography variant='body2'>{leaseData?.nnn}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='body2'>
                  Rent Increase Per Year:
                </Typography>
                <Typography variant='body2'>{leaseData?.rentIncreasePerYear}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='body2'>
                  Free Rent Months:
                </Typography>
                <Typography variant='body2'>{leaseData?.freeRentMonths}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='body2'>
                  First Month Rent Date:
                </Typography>
                <Typography variant='body2'>
                  {leaseData?.firstMonthRentDate
                    ? convertUTCDateToLocal(new Date(...leaseData.firstMonthRentDate))
                    : 'Date not available'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='body2'>
                  Realtor Commission:
                </Typography>
                <Typography variant='body2'>{leaseData?.realtorCommission}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='var(--typoGraphy-text)' fontWeight='500' variant='body2'>
                  Realtor Rebate:
                </Typography>
                <Typography variant='body2'>{leaseData?.realtorRebate}</Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SectionTitle>Lease Documents:</SectionTitle>
              {matchedDocuments && matchedDocuments.length > 0 ? (
                <Box display='flex' gap={2} p={1}>
                  {matchedDocuments.map((doc, index) => {
                    const isPdf = doc?.metadata?.documentType === 'application/pdf';
                    const isWord =
                      doc?.metadata?.documentType === 'application/msword' ||
                      doc?.metadata?.documentType ===
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

                    return (
                      doc && (
                        <Box
                          key={index}
                          display='flex'
                          alignItems='center'
                          sx={{
                            border: '1px solid #E0E0E0',
                            borderRadius: '5px',
                            padding: '5px 5px',
                            minWidth: '200px',
                            backgroundColor: 'var(--box-backgroundColor)',
                          }}
                        >
                          {isPdf ? (
                            <img
                              src='/assets/images/pdf-icon.png'
                              alt='PDF'
                              style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                          ) : isWord ? (
                            <DescriptionIcon
                              style={{ marginRight: 8, color: 'var(--discriptionIcon-true)' }}
                            />
                          ) : (
                            <DescriptionIcon
                              style={{ marginRight: 8, color: 'var(--discriptionIcon-false)' }}
                            />
                          )}

                          <Typography variant='body2' sx={{ flexGrow: 1 }}>
                            {doc.metadata.documentName}
                          </Typography>

                          <IconButton
                            onClick={() =>
                              handleDownload(
                                doc.content,
                                `${doc.metadata.documentName}`,
                                doc.metadata.documentType,
                              )
                            }
                            sx={{ color: 'var(--downloadIcon)' }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      )
                    );
                  })}
                </Box>
              ) : (
                <Typography>No documents available</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <SectionTitle>Comments:</SectionTitle>
              <Typography p={1} color='var(--typoGraphy-text)' fontWeight='500' variant='body2'>
                {leaseData?.comments || 'Sample comments will be shown here'}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={2}
              p={2}
              sx={{
                border: '1px solid var(--LeadLeaseDetailModel-grid-border)',
                backgroundColor: 'var(--LeadLeaseDetailModel-grid-color)',
              }}
            >
              <Grid item xs={6}>
                <Typography
                  variant='subtitle2'
                  fontSize='14px'
                  color='var(--typography-text1)'
                  fontWeight='500'
                >
                  LLC DETAILS
                </Typography>
                <Typography fontSize='14px'>
                  <strong>{tenantData?.llcDetails?.llcName || 'N/A'}</strong>
                </Typography>
                <Typography fontSize='14px'>
                  {tenantData?.llcDetails?.phoneNo || 'N/A'} • {tenantData?.llcDetails?.email}
                </Typography>
                <Typography variant='body2'>
                  {tenantData?.llcDetails?.address || 'No Address'} • {tenantData?.llcDetails?.city}
                  • {tenantData?.llcDetails?.state}
                </Typography>
                <Typography variant='body2'>
                  {tenantData?.llcDetails?.country} • {tenantData?.llcDetails?.zipCode}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant='subtitle2'
                  fontSize='14px'
                  color='var(--typography-text1)'
                  fontWeight='500'
                >
                  CONTACT PERSON
                </Typography>
                <Typography fontSize='14px'>
                  <strong>{tenantData?.contactPerson || 'N/A'}</strong>
                </Typography>
                <Typography fontSize='14px'>
                  {tenantData?.phoneNo} • {tenantData?.email}
                </Typography>
                <Typography variant='body2'>
                  {tenantData?.address || 'No Address'} • {tenantData?.city}• {tenantData?.state}
                </Typography>
                <Typography variant='body2'>
                  {tenantData?.country} • {tenantData?.zipCode}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <LeaseAddEdit
        open={isOpenEdit}
        onClose={handleFormClose}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        leaseData={leaseData}
        tenantData={tenantData}
      />
    </>
  );
};

export default LeaseView;
