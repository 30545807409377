import RequestCard from './RequestCard';
import styles from './modules/RequestsList.module.css';
import List from '@mui/material/List';

function RequestsList({ requests }) {
  return (
    <List className={styles.container} disablePadding>
      {requests.map((request) => (
        <RequestCard key={request.id} request={request} />
      ))}
    </List>
  );
}

export default RequestsList;
