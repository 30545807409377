export const mockLocation = {
  createdDate: '2024-09-09T19:02:49.144',
  updatedDate: '2024-09-09T19:02:49.144',
  id: '66df46591cb27033668ae7e9',
  projectId: null,
  address1: '97, Chambers Street',
  address2: '',
  state: 'New York',
  zipCode: '10007',
  city: 'New York',
  country: 'United States',
  latitude: '40.71489469999999',
  longitude: '-74.00762329999999',
};
