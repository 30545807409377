import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'app/react-query/queryKeys';
import { getUserInvestmentDetails } from 'app/contexts/projectActions';

export function useUserInvestments(projectId, investorUserId) {
  return useQuery({
    queryKey: [...queryKeys.project.userInvestments, projectId, investorUserId],
    queryFn: () => getUserInvestmentDetails(projectId, investorUserId),
    placeholderData: [],
    select: (data) => data.at(-1),
    gcTime: 1000 * 60 * 3,
  });
}
