import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import { usePermissionsExpansion } from './hooks/usePermissionsExpansion';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

function RoleView({ role, closeModal }) {
  const [expandedPermissions, handlePermissionsExpand] = usePermissionsExpansion();

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { xs: '95vw', sm: '70vw', md: '60vw', lg: '50vw' },
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle>View Role</DialogTitle>
        <IconButton onClick={closeModal} sx={{ marginRight: 2 }}>
          <Close />
        </IconButton>
      </Box>
      <DialogDivider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>Name: {role.name}</Typography>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {role.services.map(([serviceName, service]) => {
              const readPermissions = Object.values(service.actions.read).filter((permission) =>
                role.permissions[serviceName]?.read.includes(permission.identifier),
              );
              const writePermissions = Object.values(service.actions.write).filter((permission) =>
                role.permissions[serviceName]?.write.includes(permission.identifier),
              );

              return (
                <Grid item xs={12} key={serviceName}>
                  <Accordion
                    elevation={0}
                    disableGutters
                    sx={{
                      backgroundColor: '#F2F3F5',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore fontSize='large' fontWeight='600' />}
                      sx={{
                        flexDirection: 'row-reverse',
                        '& .MuiAccordionSummary-expandIconWrapper': {
                          transform: 'rotate(-90deg)',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                          transform: 'rotate(0deg)',
                        },
                      }}
                    >
                      <Typography variant='h6' fontWeight='600'>
                        {service.displayName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        {readPermissions.length > 0 && (
                          <Grid item xs={12}>
                            <Accordion
                              elevation={0}
                              disableGutters
                              expanded={expandedPermissions.includes(`${serviceName}-read`)}
                              onChange={(_, shouldExpand) =>
                                handlePermissionsExpand(`${serviceName}-read`, shouldExpand)
                              }
                              sx={{
                                backgroundColor: 'inherit',
                              }}
                            >
                              <AccordionSummary
                                expandIcon={
                                  expandedPermissions.includes(`${serviceName}-read`) ? (
                                    <RemoveIcon />
                                  ) : (
                                    <AddIcon />
                                  )
                                }
                                sx={{
                                  display: 'inline-flex',
                                  flexDirection: 'row-reverse',
                                  gap: 1,
                                  '& .MuiAccordionSummary-expandIconWrapper': {
                                    transform: 'none !important',
                                  },
                                }}
                              >
                                <Typography>Read Permissions</Typography>
                              </AccordionSummary>
                              <AccordionDetails sx={{ ml: 4 }}>
                                <Grid container>
                                  {readPermissions.map((permission) => (
                                    <Grid item xs={12} sm={6} md={4} key={permission.identifier}>
                                      <FormControlLabel
                                        label={permission.displayName}
                                        control={<Checkbox checked={true} />}
                                        inputprops={{
                                          readOnly: true,
                                        }}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        )}
                        {writePermissions.length > 0 && (
                          <Grid item xs={12}>
                            <Accordion
                              elevation={0}
                              disableGutters
                              expanded={expandedPermissions.includes(`${serviceName}-write`)}
                              onChange={(_, shouldExpand) =>
                                handlePermissionsExpand(`${serviceName}-write`, shouldExpand)
                              }
                              sx={{
                                backgroundColor: 'inherit',
                              }}
                            >
                              <AccordionSummary
                                expandIcon={
                                  expandedPermissions.includes(`${serviceName}-write`) ? (
                                    <RemoveIcon />
                                  ) : (
                                    <AddIcon />
                                  )
                                }
                                sx={{
                                  display: 'inline-flex',
                                  flexDirection: 'row-reverse',
                                  gap: 1,
                                  '& .MuiAccordionSummary-expandIconWrapper': {
                                    transform: 'none !important',
                                  },
                                }}
                              >
                                <Typography>Write Permissions</Typography>
                              </AccordionSummary>
                              <AccordionDetails sx={{ ml: 4 }}>
                                <Grid container>
                                  {writePermissions.map((permission) => (
                                    <Grid item xs={12} sm={6} md={4} key={permission.identifier}>
                                      <FormControlLabel
                                        label={permission.displayName}
                                        control={<Checkbox checked={true} />}
                                        inputprops={{
                                          readOnly: true,
                                        }}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <DialogDivider />
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' gap={2} alignItems='center'>
              <Typography variant='h6' fontWeight='600'>
                Assigned to
              </Typography>
              <Typography fontWeight='600'>-</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DialogDivider />
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' gap={2}>
              <Box display='flex' alignItems='center'>
                <Typography variant='subtitle1' fontStyle='italic'>
                  Created by:
                </Typography>
                <Typography fontWeight='600'>-</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Typography variant='subtitle1' fontStyle='italic'>
                  Last updated on:
                </Typography>
                <Typography fontWeight='600'>-</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default RoleView;
