import React, { useState } from "react";
import {Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LCDeleteIcon } from "app/common/icons";
import { StyledModalContainer,DeleteIcon,MainImageContainer,MainImage,
    ThumbnailsContainer,ThumbnailImage,ImagesViewBox2,ImagesStyledCross
} from "./StyledComponents";


const images = [
    "https://wp-assets.stessa.com/wp-content/uploads/2022/09/23170335/modern-apartment-building.jpg",
    "https://i.pinimg.com/originals/1d/2c/f6/1d2cf6c38d74fcbfbd4fa0695d5a4b4a.jpg",
    "https://th.bing.com/th/id/OIP.3Ok8gnh9SIdwCFqXlLK_fwHaEK?rs=1&pid=ImgDetMain",
    "https://tse2.mm.bing.net/th?id=OIP.0xwO3PKv3UZs2gJkSKuYAAAAAA&pid=Api&P=0&h=180",
    "https://th.bing.com/th/id/OIP.3Ok8gnh9SIdwCFqXlLK_fwHaEK?rs=1&pid=ImgDetMain",
    "https://i.pinimg.com/originals/24/9f/9a/249f9a9748df7c1c83fa54986fb7405a.jpg",
    "https://th.bing.com/th/id/OIP.3Ok8gnh9SIdwCFqXlLK_fwHaEK?rs=1&pid=ImgDetMain",
    "https://i.pinimg.com/originals/1d/2c/f6/1d2cf6c38d74fcbfbd4fa0695d5a4b4a.jpg",
    "https://th.bing.com/th/id/OIP.3Ok8gnh9SIdwCFqXlLK_fwHaEK?rs=1&pid=ImgDetMain",
    "https://i.pinimg.com/originals/1d/2c/f6/1d2cf6c38d74fcbfbd4fa0695d5a4b4a.jpg",
    "https://wp-assets.stessa.com/wp-content/uploads/2022/09/23170335/modern-apartment-building.jpg",
    "https://i.pinimg.com/originals/1d/2c/f6/1d2cf6c38d74fcbfbd4fa0695d5a4b4a.jpg",
    "https://th.bing.com/th/id/OIP.3Ok8gnh9SIdwCFqXlLK_fwHaEK?rs=1&pid=ImgDetMain",
    "https://i.pinimg.com/originals/1d/2c/f6/1d2cf6c38d74fcbfbd4fa0695d5a4b4a.jpg",
    "https://th.bing.com/th/id/OIP.3Ok8gnh9SIdwCFqXlLK_fwHaEK?rs=1&pid=ImgDetMain",
    "https://i.pinimg.com/originals/1d/2c/f6/1d2cf6c38d74fcbfbd4fa0695d5a4b4a.jpg",
];

const ImagesViewModal = ({ handleCloseImageModal }) => {
    const [selectedImage, setSelectedImage] = useState(images[0]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    return (

        <>
            <StyledModalContainer>
                <ImagesStyledCross onClick={handleCloseImageModal}>
                    <CloseIcon />
                </ImagesStyledCross>

                <MainImageContainer>
                    <MainImage src={selectedImage} alt="Selected" />
                    <DeleteIcon >
                        <LCDeleteIcon />
                    </DeleteIcon>
                </MainImageContainer>


                {/* Thumbnails */}
                <ThumbnailsContainer>
                    {images.map((image, index) => (
                        <Box key={index} onClick={() => handleImageClick(image)}>
                            <ThumbnailImage
                                src={image}
                                alt={`Thumbnail ${index + 1}`}
                                isSelected={image === selectedImage}
                            />
                        </Box>
                    ))}
                </ThumbnailsContainer>
            </StyledModalContainer>
        </>
    );
};

export default ImagesViewModal;

