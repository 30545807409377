import React, { useState } from 'react';
import { Box, Typography, Button, ButtonGroup, styled } from '@mui/material';
import LeaseAddEdit from './LeaseAddEdit';
import AddEditBuilding from './AddEditBuilding';
import LeaseCard from './LeaseCard';
import SaleCard from './SaleCard';
import BuyerAddEdit from './BuyerAddEdit';
import SectionLoader from 'app/common/SectionLoader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useProjectBuildingUnitLeases } from './hooks/useProjectBuldingUnitLeases';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectBuildingUnitSales } from './hooks/useProjectBuldingUnitSales';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const BuildingView = ({ selectedBuilding, handleBackToBuildings }) => {
  const [showLeaseForm, setShowLeaseForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(selectedBuilding.units?.[0]);
  const [viewMode, setViewMode] = useState('lease');
  const [showLeadSaleForm, setShowLeadSaleForm] = useState(false);
  const { projectId } = useProject();

  const { data: unitLeases, isPending: isPendingLease } = useProjectBuildingUnitLeases(
    projectId,
    selectedUnit?.unitId,
  );
  const { data: unitSales, isPending: isPendingSale } = useProjectBuildingUnitSales(
    projectId,
    selectedUnit?.unitId,
  );

  const isLoading = isPendingLease || isPendingSale;

  const handleSelectUnit = (unit) => setSelectedUnit(unit);

  const handleAddSaleClick = () => {
    setShowLeadSaleForm(true);
  };

  const handleCloseForm = () => {
    setShowLeadSaleForm(false);
  };

  const handleOpenLeaseForm = () => {
    setShowLeaseForm(true);
  };

  const handleSaveBuilding = () => setShowModal(false);

  const handleCloseLeaseForm = () => {
    setShowLeaseForm(false);
  };

  const filteredUnits = (selectedBuilding?.units || []).filter((unit) =>
    unit.unitName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      <Box style={{ padding: '20px' }}>
        <Typography variant='h6' style={{ marginTop: '3px', marginBottom: '15px' }}>
          <span style={{ cursor: 'pointer' }} onClick={handleBackToBuildings}>
            <ArrowBackIcon sx={{ marginBottom: '-4px', width: '5%' }} />
          </span>
          {selectedBuilding?.buildingName || 'Building Name'}
        </Typography>

        <Box style={{ display: 'flex', flexDirection: 'row', gap: '20px', overflow: 'auto' }}>
          <Box
            style={{
              width: '280px',
              padding: '10px',
              border: '1px solid var(--box-border)',
              backgroundColor: 'var(--box-backgroundColor)',
            }}
          >
            <input
              type='text'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size='small'
              placeholder='Search with unit name...'
              style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
            />
            <Button
              onClick={() => setShowModal(true)}
              variant='text'
              style={{ fontSize: '13px', fontWeight: '500' }}
            >
              + Add New Unit
            </Button>

            <Box
              style={{
                maxHeight: '300px',
                overflowY: 'auto',
                marginTop: '10px',
              }}
            >
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {filteredUnits.length > 0 ? (
                  filteredUnits.map((unit, index) => (
                    <li
                      key={unit.unitId}
                      onClick={() => handleSelectUnit(unit)}
                      style={{
                        backgroundColor:
                          selectedUnit?.unitId === unit.unitId
                            ? 'var(--li-backgroundColor)'
                            : 'transparent',
                        marginBottom: '8px',
                        padding: '10px',
                        borderLeft:
                          selectedUnit?.unitId === unit.unitId && '5px solid var(--li-borderLeft)',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      {unit.unitName || `Unit ${index + 1}`}
                    </li>
                  ))
                ) : (
                  <li>No units available</li>
                )}
              </ul>
            </Box>
          </Box>

          <Box
            style={{
              width: '75%',
              maxHeight: '300px',
              overflowY: 'auto',
              border: '1px solid #E3EDF5',
              borderRadius: '5px',
              padding: '20px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
              <div>
                <Typography variant='h6'>{selectedUnit?.unitName || 'Unit Name'}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  {selectedUnit?.space
                    ? `${selectedUnit.space} Sq ft`
                    : 'Space (Sq ft) not available'}
                </Typography>
              </div>
              <ButtonGroup disableElevation variant='outlined' aria-label='Lease/Sale button group'>
                <Button
                  sx={{
                    width: '158px',
                    height: '35px',
                    color:
                      viewMode === 'lease'
                        ? 'var(--lease-text-select-color)'
                        : 'var(--lease-text-Deselect-color)',
                    backgroundColor:
                      viewMode === 'lease'
                        ? 'var(--lease-text-select-bg)'
                        : 'var(--lease-text-Deselect-bg)', // Active = blue, inactive = white
                    fontWeight: '500',
                    '&:hover': {
                      backgroundColor:
                        viewMode === 'lease'
                          ? 'var(--lease-text-select-hover-bg)'
                          : 'var(--lease-text-select-nothover-bg)', // Keep active color, hover for inactive
                      color:
                        viewMode === 'lease'
                          ? 'var(--lease-text-select-hover-color)'
                          : 'var(--lease-text-select-nothover-color)', // Inactive hover text stays black
                    },
                  }}
                  onClick={() => setViewMode('lease')}
                >
                  Lease
                </Button>
                <Button
                  sx={{
                    width: '158px',
                    height: '35px',
                    color:
                      viewMode === 'sale'
                        ? 'var(--lease-text-select-color)'
                        : 'var(--lease-text-Deselect-color)',
                    backgroundColor:
                      viewMode === 'sale'
                        ? 'var(--lease-text-select-bg)'
                        : 'var(--lease-text-Deselect-bg)', // Active = blue, inactive = white
                    fontWeight: '500',
                    '&:hover': {
                      backgroundColor:
                        viewMode === 'sale'
                          ? 'var(--lease-text-select-hover-bg)'
                          : 'var(--lease-text-select-nothover-bg)', // Keep active color, hover for inactive
                      color:
                        viewMode === 'sale'
                          ? 'vaar(--lease-text-select-hover-color)'
                          : 'var(--lease-text-select-nothover-color)', // Inactive hover text stays black
                    },
                  }}
                  onClick={() => setViewMode('sale')}
                >
                  Sale
                </Button>
              </ButtonGroup>
            </Box>
            <ModalDivider />
            {viewMode === 'lease' ? (
              <>
                <div
                  style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant='h6'>Lease Space</Typography>
                  <Button variant='text' onClick={handleOpenLeaseForm}>
                    + Add Lease
                  </Button>
                </div>
                <Box style={{ maxHeight: '300px', marginTop: '10px' }}>
                  {isLoading ? (
                    <SectionLoader />
                  ) : unitLeases && unitLeases.length > 0 ? (
                    unitLeases.map((lease) => (
                      <LeaseCard
                        key={lease?.id}
                        leaseId={lease?.id}
                        unitDetails={lease?.unitDetails}
                        selectedBuilding={selectedBuilding}
                        selectedUnit={selectedUnit}
                      />
                    ))
                  ) : (
                    <Typography>No Lease Details Available</Typography>
                  )}
                </Box>
              </>
            ) : (
              <>
                <div
                  style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant='h6'>Sale Space</Typography>
                  <Button variant='text' onClick={handleAddSaleClick}>
                    + Add Sale
                  </Button>
                </div>

                <Box style={{ maxHeight: '400px', marginTop: '5px' }}>
                  {isLoading ? (
                    <SectionLoader />
                  ) : unitSales && unitSales.length > 0 ? (
                    unitSales.map((sale) => {
                      if (!sale.unitDetails) return null;
                      return (
                        <SaleCard
                          key={sale?.id}
                          saleId={sale?.id}
                          unitDetails={sale?.unitDetails}
                          selectedBuilding={selectedBuilding}
                          selectedUnit={selectedUnit}
                        />
                      );
                    })
                  ) : (
                    <Typography>No Sale Details Available</Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <LeaseAddEdit
        open={showLeaseForm}
        onClose={handleCloseLeaseForm}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
      />
      <BuyerAddEdit
        open={showLeadSaleForm}
        onClose={handleCloseForm}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
      />
      <AddEditBuilding
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSaveBuilding}
        selectedBuilding={selectedBuilding}
      />
    </>
  );
};

export default BuildingView;
