import { useFormik } from 'formik';
import { TextField, InputAdornment, Grid } from '@mui/material';
import { Avatar, SendIcon } from './commentFormStyles';
import { notify } from 'app/services/notify';
import { useUserAuth } from 'app/hooks/userUserAuth';

const CommentForm = ({ handleSubmit, initialText = '' }) => {
  const { user } = useUserAuth();

  const getAvatarBackground = (email) => {
    if (!email) {
      notify.error('Error - Email not provided');
      return '';
    }

    const hashCode = email.split('').reduce((acc, char) => {
      acc = (acc << 5) - acc + char.charCodeAt(0);
      return acc & acc;
    }, 0);

    const index = Math.abs(hashCode) % colorCodes.length;
    return colorCodes[index];
  };
  const colorCodes = ['#c8792b', '#4ba75e', '#ddbc3f', '#449ecf', '#ab84b0', '#c8792b', '#cb3c34'];

  const formik = useFormik({
    initialValues: {
      text: initialText,
    },
    validate: (values) => {
      const errors = {};
      if (!values.text.trim()) {
        errors.text = 'Comment cannot be empty';
      }
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values.text);
      resetForm();
    },
  });

  const getAvatarContent = (name, surname, email) => {
    const firstName = name || '';
    const lastName = surname || '';
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    return {
      initials,
      backgroundColor: getAvatarBackground(email),
    };
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container alignItems='center'>
        <Grid item>
          {/* <Avatar
            sx={{
              cursor: 'pointer',
              height: 35,
              width: 35,
              fontSize: 15,
              fontWeight: 700,
              backgroundColor:
                user && getAvatarContent(user.name, user.surname, user.email).backgroundColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {user && getAvatarContent(user.name, user.surname, user.email).initials}
          </Avatar> */}
        </Grid>
        <Grid item xs>
          <label>Write your comment</label>
          <TextField
            size='small'
            type='text'
            fullWidth
            name='text'
            placeholder="Write your comment"
            variant='outlined'
            value={formik.values.text}
            onChange={formik.handleChange}
            error={formik.touched.text && Boolean(formik.errors.text)}
            helperText={formik.touched.text && formik.errors.text}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SendIcon
                    className='material-icons'
                    onClick={() => {
                      if (formik.values.text.trim()) {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    send
                  </SendIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CommentForm;
