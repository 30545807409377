import React from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Modal,
  IconButton,
  styled,
  Typography,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useProject } from 'app/contexts/ProjectContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { parseAddressComponents } from 'app/utils/helpers';
import { StandaloneSearchBox } from '@react-google-maps/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectBuildingUnitLeaseCreateUpdate } from './hooks/useProjectBuildingUnitLeaseCreateUpdate';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '5px',
  backgroundColor: 'var(--modalDivider)',
});

const TenantAddEdit = ({
  openModal,
  onClose,
  onBack,
  selectedBuilding,
  selectedUnit,
  leaseData,
  tenantData,
  leaseId
}) => {
  const { projectId } = useProject();
  const { mutate: createUpdateLease } = useProjectBuildingUnitLeaseCreateUpdate(
    projectId,
    tenantData,
  );

  const searchInputRef = React.useRef(null);
  const searchInputRef1 = React.useRef(null);

  const parseAddressComponents1 = (place) => {
    let LLCAddress1 = '';
    let LLCAddress2 = '';
    let LLCCountry = '';
    let LLCCity = '';
    let LLCState = '';
    let LLCZIPCode = '';
    let latitude = null;
    let longitude = null;

    if (place.address_components) {
      const {
        address_components,
        geometry: { location },
        name,
      } = place;
      const { lat, lng } = location;
      latitude = lat();
      longitude = lng();

      address_components.forEach((component) => {
        const [type] = component.types;
        const longName = component.long_name;

        switch (type) {
          case 'landmark':
          case 'subpremise':
          case 'premise':
            LLCAddress2 += LLCAddress2 ? `, ${longName}` : longName;
            break;
          case 'route':
          case 'street_address':
          case 'street_number':
            LLCAddress1 += LLCAddress1 ? `, ${longName}` : longName;
            break;
          case 'country':
            LLCCountry = longName;
            break;
          case 'locality':
          case 'postal_town':
          case 'administrative_area_level_3':
            LLCCity ||= longName;
            break;
          case 'administrative_area_level_1':
            LLCState = longName;
            break;
          case 'postal_code':
            LLCZIPCode = longName;
            break;
          default:
            break;
        }
      });

      if (!LLCAddress1 && name) {
        LLCAddress1 = name;
      }
    }
    return {
      LLCAddress1,
      LLCAddress2,
      LLCCountry,
      LLCCity,
      LLCState,
      LLCZIPCode,
      latitude,
      longitude,
    };
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents1(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  const handlePlacesChanged1 = (setValues) => {
    const [place] = searchInputRef1.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  const initialValues = {
    LLCName: tenantData?.llcDetails?.llcName || '',
    LLCNumber: tenantData?.llcDetails?.llcNumber || '',
    LLCPhoneNumber: tenantData?.llcDetails?.phoneNo || '',
    LLCEmail: tenantData?.llcDetails?.email || '',
    LLCAddress1: tenantData?.llcDetails?.address || '',
    LLCAddress2: tenantData?.llcDetails?.LLCAddress2 || '',
    LLCCity: tenantData?.llcDetails?.city || '',
    LLCState: tenantData?.llcDetails?.state || '',
    LLCCountry: tenantData?.llcDetails?.country || '',
    LLCZIPCode: tenantData?.llcDetails?.zipCode || '',

    contactPerson: tenantData?.contactPerson || '',
    tenantEmail: tenantData?.email || '',
    tenantPhoneNo: tenantData?.phoneNo || '',
    address1: tenantData?.address || '',
    address2: tenantData?.address2 || '',
    city: tenantData?.city || '',
    state: tenantData?.state || '',
    country: tenantData?.country || '',
    zipCode: tenantData?.zipCode || '',
  };

  const handleSave = (values, { setSubmitting }) => {
    createUpdateLease(
      {
        updatedTenantData: values,
        leaseData,
        selectedBuilding,
        selectedUnit,
        leaseId
      },
      {
        onSettled: () => setSubmitting(false),
        onSuccess: onClose,
      },
    );
  };

  return (
    <Modal open={openModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
          height: '90vh',
        }}
      >
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Title>Add Tenant & LLC Details</Title>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', marginLeft: '15px' }} mb={2}>
          <Typography sx={{ fontWeight: '500', fontSize: '16px', color: 'var(--text-content)' }}>
            LLC Details
          </Typography>
        </Box>
        <ModalDivider />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.tenant()}
          onSubmit={handleSave}
        >
          {({ values, errors, touched, handleChange, handleBlur, setValues, isSubmitting }) => (
            <Form>
              <Grid container spacing={3} p={2}>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCName'
                  >
                    LLC Name
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='LLCName'
                    id='LLCName'
                    placeholder='Enter LLC Name'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCName && Boolean(errors.LLCName)}
                    helperText={<ErrorMessage name='LLCName' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCNumber'
                  >
                    LLC Number
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    id='LLCNumber'
                    placeholder='Enter LLC Number'
                    name='LLCNumber'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCNumber && Boolean(errors.LLCNumber)}
                    helperText={<ErrorMessage name='LLCNumber' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCPhoneNumber'
                  >
                    LLC Phone Number
                  </InputLabel>
                  <Field
                    as={TextField}
                    id='LLCPhoneNumber'
                    placeholder='Enter Phone number'
                    size='small'
                    name='LLCPhoneNumber'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        'e',
                        'E',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCPhoneNumber && Boolean(errors.LLCPhoneNumber)}
                    helperText={<ErrorMessage name='LLCPhoneNumber' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCEmail'
                  >
                    Email
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='LLCEmail'
                    id='LLCEmail'
                    placeholder='Enter Email'
                    fullWidth
                    error={touched.LLCEmail && Boolean(errors.LLCEmail)}
                    helperText={<ErrorMessage name='LLCEmail' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='standard-basic'
                  >
                    Address
                  </InputLabel>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                    onUnmount={() => (searchInputRef.current = null)}
                    onPlacesChanged={() => handlePlacesChanged(setValues)}
                  >
                    <TextField
                      sx={{
                        marginTop: '0',
                        '& .MuiOutlinedInput-root': {
                          height: '36px',
                          padding: '0px',
                          '& input': {
                            padding: '8px 12px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          height: 'auto',
                        },
                      }}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      type='search'
                      name='LLCAddress1'
                      id='standard-basic'
                      placeholder='Enter address'
                      value={values?.LLCAddress1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            LLCAddress1: '',
                            LLCCountry: '',
                            LLCCity: '',
                            LLCState: '',
                            LLCZIPCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.LLCAddress1 && errors.LLCAddress1}
                      error={Boolean(errors.LLCAddress1 && touched.LLCAddress1)}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCAddress2'
                  >
                    Address 2
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='LLCAddress2'
                    id='LLCAddress2'
                    placeholder='Enter address'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCAddress2 && Boolean(errors.LLCAddress2)}
                    helperText={<ErrorMessage name='LLCAddress2' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCCity'
                  >
                    City
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='LLCCity'
                    id='LLCCity'
                    placeholder='Enter city'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCCity && Boolean(errors.LLCCity)}
                    helperText={<ErrorMessage name='LLCCity' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCState'
                  >
                    State
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='LLCState'
                    id='LLCState'
                    placeholder='Enter state'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCState && Boolean(errors.LLCState)}
                    helperText={<ErrorMessage name='LLCState' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCCountry'
                  >
                    Country
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='LLCCountry'
                    id='LLCCountry'
                    placeholder='Enter country'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCCountry && Boolean(errors.LLCCountry)}
                    helperText={<ErrorMessage name='LLCCountry' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='LLCZIPCode'
                  >
                    ZIP Code
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    name='LLCZIPCode'
                    id='LLCZIPCode'
                    placeholder='Enter ZIP Code'
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCZIPCode && Boolean(errors.LLCZIPCode)}
                    helperText={<ErrorMessage name='LLCZIPCode' />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: '500', fontSize: '16px', color: 'var(--text-content)' }}
                  >
                    Tenant Contact Information
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='contactPerson'
                  >
                    Contact Person
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='contactPerson'
                    id='contactPerson'
                    placeholder='Enter name'
                    fullWidth
                    error={touched.contactPerson && Boolean(errors.contactPerson)}
                    helperText={<ErrorMessage name='contactPerson' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='tenantEmail'
                  >
                    Email
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='tenantEmail'
                    id='tenantEmail'
                    placeholder='Enter email'
                    fullWidth
                    error={touched.tenantEmail && Boolean(errors.tenantEmail)}
                    helperText={<ErrorMessage name='tenantEmail' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='tenantPhoneNo'
                  >
                    Phone Number
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        'e',
                        'E',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    name='tenantPhoneNo'
                    id='tenantPhoneNo'
                    placeholder='Enter phone number'
                    fullWidth
                    error={touched.tenantPhoneNo && Boolean(errors.tenantPhoneNo)}
                    helperText={<ErrorMessage name='tenantPhoneNo' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='address1'
                  >
                    Address
                  </InputLabel>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef1.current = searchBox)}
                    onUnmount={() => (searchInputRef1.current = null)}
                    onPlacesChanged={() => handlePlacesChanged1(setValues)}
                  >
                    <TextField
                      placeholder='Enter address'
                      sx={{
                        marginTop: '0',
                        '& .MuiOutlinedInput-root': {
                          height: '36px',
                          padding: '0px',
                          '& input': {
                            padding: '8px 12px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          height: 'auto',
                        },
                      }}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      type='search'
                      name='address1'
                      id='standard-basic'
                      value={values?.address1}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (!value) {
                          setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            address1: '',
                            country: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.address1 && errors.address1}
                      error={Boolean(errors.address1 && touched.address1)}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='address2'
                  >
                    Address 2
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    name='address2'
                    id='address2'
                    type='text'
                    placeholder='Enter address'
                    fullWidth
                    error={touched.address2 && Boolean(errors.address2)}
                    helperText={<ErrorMessage name='address2' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='city'
                  >
                    City
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='city'
                    id='city'
                    placeholder='Enter city'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.city && Boolean(errors.city)}
                    helperText={<ErrorMessage name='city' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='state'
                  >
                    State
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    name='state'
                    id='state'
                    placeholder='Enter state'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.state && Boolean(errors.state)}
                    helperText={<ErrorMessage name='state' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='country'
                  >
                    Country
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    name='country'
                    id='country'
                    placeholder='Enter country'
                    type='text'
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    error={touched.country && Boolean(errors.country)}
                    helperText={<ErrorMessage name='country' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: 1,
                      color: 'var(--inputLabel-color)',
                    }}
                    htmlFor='zipCode'
                  >
                    ZIP Code
                  </InputLabel>
                  <Field
                    as={TextField}
                    size='small'
                    type='number'
                    name='zipCode'
                    id='zipCode'
                    placeholder='Enter ZIP Code'
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={<ErrorMessage name='zipCode' />}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  textAlign='center'
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={onBack}
                      startIcon={<ArrowBackIcon size='small' />}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      variant='outlined'
                      color='error'
                      disabled={isSubmitting}
                      onClick={onClose}
                    >
                      Cancel
                    </LoadingButton>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default TenantAddEdit;
