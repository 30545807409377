export const mockUsers = [
  {
    name: 'John',
    surName: 'Doe',
    email: 'john.doe@example.com',
    contactPhone: '+14155552671',
    cognitoUserId: 'a1b2c3d4-e5f6-4a3b-8c7d-9e0f1a2b3c4d',
  },
  {
    name: 'Emma',
    surName: 'Smith',
    email: 'emma.smith@example.com',
    contactPhone: '+442071234567',
    cognitoUserId: 'b2c3d4e5-f6a7-5b4c-9d8e-0f1a2b3c4d5e',
  },
  {
    name: 'Liam',
    surName: 'Johnson',
    email: 'liam.johnson@example.com',
    contactPhone: '+61291234567',
    cognitoUserId: 'c3d4e5f6-a7b8-6c5d-0e9f-1a2b3c4d5e6f',
  },
  {
    name: 'Olivia',
    surName: 'Brown',
    email: 'olivia.brown@example.com',
    contactPhone: '+33123456789',
    cognitoUserId: 'd4e5f6a7-b8c9-7d6e-1f0a-2b3c4d5e6f7g',
  },
  {
    name: 'Noah',
    surName: 'Garcia',
    email: 'noah.garcia@example.com',
    contactPhone: '+4915123456789',
    cognitoUserId: 'e5f6a7b8-c9d0-8e7f-2a1b-3c4d5e6f7g8h',
  },
  {
    name: 'Ava',
    surName: 'Martinez',
    email: 'ava.martinez@example.com',
    contactPhone: '+81345678901',
    cognitoUserId: 'f6a7b8c9-d0e1-9f8g-3b2c-4d5e6f7g8h9i',
  },
  {
    name: 'Ethan',
    surName: 'Lopez',
    email: 'ethan.lopez@example.com',
    contactPhone: '+551123456789',
    cognitoUserId: 'g7b8c9d0-e1f2-0a9h-4c3d-5e6f7g8h9i0j',
  },
  {
    name: 'Sophia',
    surName: 'Lee',
    email: 'sophia.lee@example.com',
    contactPhone: '+8621123456789',
    cognitoUserId: 'h8c9d0e1-f2g3-1b0i-5d4e-6f7g8h9i0j1k',
  },
  {
    name: 'Mason',
    surName: 'Wang',
    email: 'mason.wang@example.com',
    contactPhone: '+6591234567',
    cognitoUserId: 'i9d0e1f2-g3h4-2c1j-6e5f-7g8h9i0j1k2l',
  },
  {
    name: 'Isabella',
    surName: 'Kim',
    email: 'isabella.kim@example.com',
    contactPhone: '+82212345678',
    cognitoUserId: 'j0e1f2g3-h4i5-3d2k-7f6g-8h9i0j1k2l3m',
  },
];
