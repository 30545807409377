// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
    width: 100%;
    /* border-bottom: 1px solid #DADCE0; */
}

.tabs-container .MuiTab-root {
    /* min-width: 80px; */
    margin-right: 4%;
  }

.tabs-container .MuiTabs-indicator {
    background-color: #82B485;
    height: 2.2px;
    border-radius: 2px;
}

.tab-items{
    margin-left: 12%;
}
.tab-item {
    text-transform: none;
    color: #80868B;
    font-weight: 500;
}
.tab-item.Mui-selected {
    color: #17181B;
    font-weight: 700;
}

.tab-content{
    margin-left: 12%;
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/dashboard/profile/ProfileTabs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sCAAsC;AAC1C;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;EAClB;;AAEF;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".tabs-container {\r\n    width: 100%;\r\n    /* border-bottom: 1px solid #DADCE0; */\r\n}\r\n\r\n.tabs-container .MuiTab-root {\r\n    /* min-width: 80px; */\r\n    margin-right: 4%;\r\n  }\r\n\r\n.tabs-container .MuiTabs-indicator {\r\n    background-color: #82B485;\r\n    height: 2.2px;\r\n    border-radius: 2px;\r\n}\r\n\r\n.tab-items{\r\n    margin-left: 12%;\r\n}\r\n.tab-item {\r\n    text-transform: none;\r\n    color: #80868B;\r\n    font-weight: 500;\r\n}\r\n.tab-item.Mui-selected {\r\n    color: #17181B;\r\n    font-weight: 700;\r\n}\r\n\r\n.tab-content{\r\n    margin-left: 12%;\r\n    margin-top: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
