import CustomToast from 'app/common/CustomToast';
import { toast } from 'react-toastify';

class NotifyService {
  #activeToasts = new Map();

  success(message, options = {}) {
    return this._showToast(message, 'success', options);
  }

  error(message, options = {}) {
    return this._showToast(message, 'error', options);
  }

  info(message, options = {}) {
    return this._showToast(message, 'info', options);
  }

  warning(message, options = {}) {
    return this._showToast(message, 'warning', options);
  }

  isActive(toastId) {
    return this.#activeToasts.has(toastId);
  }

  _createToast(message, severity, options) {
    return toast(<CustomToast message={message} severity={severity} />, options);
  }

  _handleToastWithId(message, severity, { toastId, ...options }) {
    if (this.#activeToasts.has(toastId)) return;

    this.#activeToasts.set(toastId, true);
    this._createToast(message, severity, {
      ...options,
      toastId,
      onClose: () => this.#activeToasts.delete(toastId),
    });
  }

  _showToast(message, severity, options = {}) {
    const { toastId } = options;

    return toastId
      ? this._handleToastWithId(message, severity, options)
      : this._createToast(message, severity, options);
  }
}

export const notify = new NotifyService();
