import { styled } from '@mui/material/styles';
import { Button, Card, Typography, Divider, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Container for the page
export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '6px',
  boxSizing: 'border-box',
}));

// Logo typography
export const Logo = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: '700',
  color: theme.palette.primary.main,
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

// Card styling
export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  minWidth: '400px',
  padding: '28px 32px',
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
}));

// Title typography
export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.88rem',
  fontWeight: 540,
  color: theme.palette.text.primary,
  textAlign: 'left',
  lineHeight: '1.4',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

export const ThankYouTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.88rem',
  fontWeight: 580,
  color: theme.palette.text.primary,
  textAlign: 'center',
  lineHeight: '1.4',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

export const SecondaryTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.88rem',
  fontWeight: 500,
  color: theme.palette.text.lightdarkprimary,
  textAlign: 'left',
  lineHeight: '1.4',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

export const NotRegisteredTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.88rem',
  fontWeight: 700,
  color: theme.palette.text.lightdarkprimary,
  textAlign: 'left',
  lineHeight: '1.4',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

// Subtitle typography
export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 500,
  color: theme.palette.text.secondary,
  textAlign: 'left',
  marginBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
    marginBottom: '16px',
  },
}));

export const InstructionText = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 500,
  color: theme.palette.text.secondary,
  textAlign: 'left',
  marginBottom: '12px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
    marginBottom: '10px',
  },
}));

// Email label typography
export const EmailLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  color: theme.palette.text.textfield,
  marginBottom: '8px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}));

// Divider styling
export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.text.secondary,
  margin: '10px 0px 20px 0px',
  [theme.breakpoints.down('sm')]: {
    margin: '16px 0',
  },
}));

// Button styling
export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  borderColor: theme.palette.text.lightdark,
  height: '36px',
  borderRadius: '6px',
  textTransform: 'none',
  color: theme.palette.text.lightdark,
  [theme.breakpoints.down('sm')]: {
    height: '32px',
  },
}));

export const CustomLoadinButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: '16px',
  borderRadius: '6px',
  textTransform: 'none',
  backgroundColor: theme.palette.text.buttonbg,
  color: theme.palette.text.white,
  '&:hover': {
    backgroundColor: '#005792',
  },
  marginBottom: '10px'
}));

export const CustomLoadingBackButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: '16px',
  borderRadius: '6px',
  textTransform: 'none',
  marginBottom: '10px'
}));

export const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  width: '100%',
  marginBottom: '20px'
}));

// Footer text typography
export const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.lightdark, // General text color
  textAlign: 'center',
  marginTop: '20px',
  '& .custom-link': {
    fontWeight: 'bold',
    color: '#7375C7', 
    textDecoration: 'underline', 
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.625rem',
    marginTop: '16px',
  },
}));

