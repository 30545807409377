// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_container__dKMqj {
	padding: 0 !important;
	width: 26rem;
	position: fixed;
	top: 6%;
	right: 8%;
	border-radius: 10px;
	box-shadow: 0px 0px 14px 0px #797272;
	z-index: 9999;
	& .Notification_cardContent__ey9sS {
		padding: 1.5rem 4rem 1.5rem 2rem;
	}
	& .Notification_cardActions__01y14 {
		padding: 0;
		margin: 0;
	}
	& span {
		font-weight: 500;
	}
	& p {
		margin-bottom: 0.5rem;
	}

	& .Notification_button__YOpux {
		border-radius: 2px;
	}

	& .Notification_acceptButton__GQlJD,
	& .Notification_declineButton__r8DOW {
		width: 6rem;
	}

	& .Notification_acceptButton__GQlJD {
		background-color: var(--container-acceptButton);
	}

	& .Notification_declineButton__r8DOW {
		background-color:var(--container-declineButton);
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/components/NotificationBar/Notification.module.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,YAAY;CACZ,eAAe;CACf,OAAO;CACP,SAAS;CACT,mBAAmB;CACnB,oCAAoC;CACpC,aAAa;CACb;EACC,gCAAgC;CACjC;CACA;EACC,UAAU;EACV,SAAS;CACV;CACA;EACC,gBAAgB;CACjB;CACA;EACC,qBAAqB;CACtB;;CAEA;EACC,kBAAkB;CACnB;;CAEA;;EAEC,WAAW;CACZ;;CAEA;EACC,+CAA+C;CAChD;;CAEA;EACC,+CAA+C;CAChD;AACD","sourcesContent":[".container {\r\n\tpadding: 0 !important;\r\n\twidth: 26rem;\r\n\tposition: fixed;\r\n\ttop: 6%;\r\n\tright: 8%;\r\n\tborder-radius: 10px;\r\n\tbox-shadow: 0px 0px 14px 0px #797272;\r\n\tz-index: 9999;\r\n\t& .cardContent {\r\n\t\tpadding: 1.5rem 4rem 1.5rem 2rem;\r\n\t}\r\n\t& .cardActions {\r\n\t\tpadding: 0;\r\n\t\tmargin: 0;\r\n\t}\r\n\t& span {\r\n\t\tfont-weight: 500;\r\n\t}\r\n\t& p {\r\n\t\tmargin-bottom: 0.5rem;\r\n\t}\r\n\r\n\t& .button {\r\n\t\tborder-radius: 2px;\r\n\t}\r\n\r\n\t& .acceptButton,\r\n\t& .declineButton {\r\n\t\twidth: 6rem;\r\n\t}\r\n\r\n\t& .acceptButton {\r\n\t\tbackground-color: var(--container-acceptButton);\r\n\t}\r\n\r\n\t& .declineButton {\r\n\t\tbackground-color:var(--container-declineButton);\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Notification_container__dKMqj`,
	"cardContent": `Notification_cardContent__ey9sS`,
	"cardActions": `Notification_cardActions__01y14`,
	"button": `Notification_button__YOpux`,
	"acceptButton": `Notification_acceptButton__GQlJD`,
	"declineButton": `Notification_declineButton__r8DOW`
};
export default ___CSS_LOADER_EXPORT___;
