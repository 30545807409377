import React from 'react';
import { Box, Typography, Button, Paper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: '#f4f6f8',
        minHeight: '100%',
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Dashboard
      </Typography>

      <Paper
        elevation={2}
        sx={{
          padding: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            You are almost there..
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Complete the details to set up your pending profile.
          </Typography>
        </Box>
        <Button variant="contained" color="primary" sx={{ textTransform: 'none' }} onClick={() => {
              navigate('../profile');
            }}>
          Complete Profile Now
        </Button>
      </Paper>
    </Box>
  );
};

export default Dashboard;
