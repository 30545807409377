import React from 'react'
import { IconButton, Divider, ListItemText, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DescriptionIcon, LCDeleteIcon } from 'app/common/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    StyledBox1, StyledModalTitle, StyledCrossIcon, StyledBox2,
    StyledBox3, StyledBox4, FileDropArea, DragDropText,
    SupportedFilesText, UploadLink, UploadedList, ModalButtonCancle,
    ModalButtonSave, FilesListSec, ItemText, ItemIcon,
} from './StyledComponents';

const AddImagesModal = ({ handleCloseModal }) => {

    const [uploadedFiles, setUploadedFiles] = React.useState([]);


    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(
            (file) =>
                ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type) &&
                file.size <= 10 * 1024 * 1024
        );
        setUploadedFiles((prev) => [...prev, ...validFiles]);
    };
    

    const handleRemoveFile = (index) => {
        setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSave = () => {
        // Logic to save uploaded files
        handleCloseModal();
    };
    return (
        <>
            <StyledBox1>
                <StyledBox2  >
                    <StyledModalTitle>Add Images</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <StyledBox3>

                    <FileDropArea>
                        <input
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            id="file-upload"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="file-upload">
                            <DragDropText>
                                Drag and Drop or <span style={{ textDecoration: "underline" }}>Click To Upload</span>
                            </DragDropText>
                        </label>
                    </FileDropArea>
                    <SupportedFilesText>
                        Supported files : .png, .jpg, .jpeg Max file size :10MB
                    </SupportedFilesText>
                    <Divider />
                    <UploadLink>
                        Uploaded Images
                    </UploadLink>
                    <UploadedList>
                        {uploadedFiles.map((doc, index) => (
                            <FilesListSec key={doc.id}>
                                <ItemIcon>
                                    <DescriptionIcon />
                                </ItemIcon>
                                <ListItemText
                                    primary={
                                        <ItemText>
                                            {doc?.name}
                                        </ItemText>
                                    }
                                />
                                <IconButton edge="end" onClick={() => handleRemoveFile(index)}>
                                    <LCDeleteIcon />
                                </IconButton>
                            </FilesListSec>
                        ))}
                    </UploadedList>
                </StyledBox3>

                <StyledBox4  >
                    <ModalButtonCancle
                        variant='outlined'
                        onClick={handleCloseModal}
                    // disabled={isSubmitting}
                    >
                        Cancel
                    </ModalButtonCancle>
                    <ModalButtonSave
                        type='submit'
                    // loading={isSubmitting}
                    >
                        {true ? 'Update' : 'Save'}
                    </ModalButtonSave>
                </StyledBox4>
            </StyledBox1>
        </>
    )
}

export default AddImagesModal
