/**
 * @fileoverview Default request handlers for mocking API responses in tests.
 * @module mocks/defaultRequestHandlers
 */

import { delay, http, HttpResponse } from 'msw';
import mockData from './mockData';
import { API_ENDPOINTS } from 'app/contexts/apiConstants';

/**
 * Base URL for all API requests.
 * @constant {string}
 */
export const BASE_URL = 'https://dev.flackre.com';

/**
 * Default project ID for testing.
 * @constant {string}
 */
const defaultProjectId = mockData.project.basic[0].data.records[0].id;

/**
 * Handlers for current user-related requests.
 * @type {Array}
 */
const currentUserHandlers = [
  http.get(`${BASE_URL}${API_ENDPOINTS.USERS.GET_PROFILE_DETAILS}`, () =>
    HttpResponse.json(mockData.user),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.DOCUMENTS.GET_PROFILE_IMAGE}`, () =>
    HttpResponse.json(mockData.user.profileImage),
  ),
];

/**
 * Handlers for services-related requests.
 * @type {Array}
 */
const servicesHandlers = [
  http.get(`${BASE_URL}${API_ENDPOINTS.SERVICES.GET_SERVICES}`, () =>
    HttpResponse.json(mockData.services),
  ),
];

/**
 * Handlers for project-related requests.
 * These handlers cover various project details including basic info,
 * location, LLC details, documents, members, contracts, investments,
 * expenses, leases, closing details, general contracts, and property management.
 * @type {Array}
 */
const projectHandlers = [
  http.get(`${BASE_URL}${API_ENDPOINTS.PROJECTS.GET_PROJECT_DETAILS(defaultProjectId)}`, () =>
    HttpResponse.json(mockData.project.basic[0]),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.LOCATIONS.GET_PROJECT_LOCATION(defaultProjectId)}`, () =>
    HttpResponse.json(mockData.project.location),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.LLC.GET_LLC_DETAILS(defaultProjectId)}`, () =>
    HttpResponse.json(mockData.project.llc),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.DOCUMENTS.GET_DOCUMENTS(defaultProjectId)}`, () =>
    HttpResponse.json(mockData.project.documents),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.MEMBERS.GET_PROJECT_MEMBERS(defaultProjectId)}`, () =>
    HttpResponse.json(mockData.project.members),
  ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.CONTRACTDETAILS.GET_CONTRACT_DETAILS(defaultProjectId)}`,
    () => HttpResponse.json(mockData.project.contract),
  ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.INVESTMENT_DETAILS.GET_PROJECT_INVESTMENTS(defaultProjectId)}`,
    () => HttpResponse.json(mockData.project.investment),
  ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.EXPENSES_DETAILS.GET_EXPENSES_DETAILS(defaultProjectId)}`,
    () => HttpResponse.json(mockData.project.expenses),
  ),
  // http.get(`${BASE_URL}${API_ENDPOINTS.LEASEDETAILS.GET_LEASE_DETAILS(defaultProjectId)}`, () =>
  //   HttpResponse.json(mockData.project.lease),
  // ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.CLOSING_CONTRACT.GET_CLOSING_CONTRACT(defaultProjectId)}`,
    () => HttpResponse.json(mockData.project.closing),
  ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.GENERAL_CONTRACTOR.GET_PROJECT_GC_ALL(defaultProjectId)}`,
    () => HttpResponse.json(mockData.project.generalContract),
  ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.PROPERTY_MANAGEMENT.GET_PROJECT_PM_ALL(defaultProjectId)}`,
    () => HttpResponse.json(mockData.project.propertyManagement),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.ROLES.GET_CREATE_PROJECT_ROLES(defaultProjectId)}`, () =>
    HttpResponse.json(mockData.project.roles),
  ),
  http.post(
    `${BASE_URL}${API_ENDPOINTS.ROLES.GET_CREATE_PROJECT_ROLES(defaultProjectId)}`,
    () => new HttpResponse('success', { status: 200 }),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.USERS.GET_ALL_USERS}`, () =>
    HttpResponse.json(mockData.users),
  ),
  http.get(
    `${BASE_URL}${API_ENDPOINTS.INVITATIONS.GET_PROJECT_INVITATIONS(defaultProjectId)}`,
    async () => HttpResponse.json(mockData.project.invitations),
  ),
  http.put(
    `${BASE_URL}${API_ENDPOINTS.USERS.EDIT_PROFILE_DETAILS(
      mockData.project.members.data[0].userId,
    )}`,
    async () => {
      await delay(1000);
      return new HttpResponse('success', { status: 200 });
    },
  ),
  http.post(
    `${BASE_URL}${API_ENDPOINTS.MEMBERS.ASSIGN_PROJECT_MEMBERS(defaultProjectId)}`,
    async () => new HttpResponse('success', { status: 200 }),
  ),
  http.put(
    `${BASE_URL}${API_ENDPOINTS.MEMBERS.UPDATE_PROJECT_MEMBER_ROLE(defaultProjectId)}`,
    async () => new HttpResponse('success', { status: 200 }),
  ),
  http.delete(
    `${BASE_URL}${API_ENDPOINTS.MEMBERS.DELETE_PROJECT_MEMBER(defaultProjectId)}`,
    () => new HttpResponse('success', { status: 200 }),
  ),
  http.get(`${BASE_URL}${API_ENDPOINTS.INVITATIONS.GET_ALL_INVITATIONS}`, async () => {
    return HttpResponse.json(mockData.user.requests);
  }),
  http.post(`${BASE_URL}${API_ENDPOINTS.INVITATIONS.RESPOND_TO_INVITATION}`, async () => {
    return new HttpResponse('success', { status: 200 });
  }),
];

/**
 * Combined array of all default request handlers.
 * This includes both user-related and project-related handlers.
 * @type {Array}
 */
const defaultRequestHandlers = [...currentUserHandlers, ...servicesHandlers, ...projectHandlers];

export default defaultRequestHandlers;
