import { API_ENDPOINTS } from './apiConstants';
import { axiosService } from 'app/services/axiosService';

export const invitationsGetAll = async () => {
  const response = await axiosService.Get(API_ENDPOINTS.INVITATIONS.GET_ALL_INVITATIONS);
  return response.data;
};

export const invitationGetById = async (id) => {
  const response = await axiosService.Get(API_ENDPOINTS.INVITATIONS.GET_INVITATION_BY_ID(id));
  return response.data;
};

export const invitationGetByToken = async (token) => {
  const response = await axiosService.Get(API_ENDPOINTS.INVITATIONS.GET_INVITATION_BY_TOKEN(token));
  return response.data;
};

export const respondToInvitation = async (response) =>
  await axiosService.Post(API_ENDPOINTS.INVITATIONS.RESPOND_TO_INVITATION, response);

export const respondToInvestment = async (response) =>
  await axiosService.Post(API_ENDPOINTS.INVITATIONS.RESPOND_TO_INVESTMENT, response);
