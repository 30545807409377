import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  StyledCard,
  Logo,
  Container,
  Title,
  Subtitle,
  FooterText,
  CustomLoadinButton,
  ThankYouTitle,
} from './StyleComponent';
import { RegisterSuccessIcon } from 'app/common/icons';

const RegisterSuccess = () => {
  const navigate = useNavigate();

  const handleSignIn = async () => {
      navigate('../signin');
  };

  return (
    <Container>
      <Logo>flackre</Logo>

      <StyledCard>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
  <RegisterSuccessIcon />
  <ThankYouTitle>Thanks for Registering with us</ThankYouTitle>
  <Subtitle>Now you can start exploring the flackre</Subtitle>
</div>

        <CustomLoadinButton type='submit' variant='contained' fullWidth onClick={handleSignIn}>
          Sign In
        </CustomLoadinButton>
      </StyledCard>

      <FooterText>
        Having trouble? Please contact <br />
        <a
          href='mailto:help@flackre.com'
          style={{ fontWeight: 'bold', textDecoration: 'none', color: '#282A2D' }}
        >
          help@flackre.com
        </a>{' '}
        for further support
      </FooterText>
      <FooterText>
        <a href='#' className='custom-link'>
          Terms & Conditions
        </a>{' '}
        |{' '}
        <a href='#' className='custom-link'>
          Privacy Policy
        </a>
      </FooterText>
    </Container>
  );
};

export default RegisterSuccess;
