import { QueryCache, QueryClient } from '@tanstack/react-query';
import { handleAxiosError } from 'app/utils/helpers';

export const queryClientOptions = {
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      onError: handleAxiosError,
    },
  },
  queryCache: new QueryCache({
    onError: handleAxiosError,
  }),
};

export const queryClient = new QueryClient(queryClientOptions);
