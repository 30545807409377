import { createContext, useState } from 'react';
import { useProfileDetails } from '../views/dashboard/profile/hooks/useProfileDetails';
// import { useProfileImage } from '../views/dashboard/profile/hooks/useProfileImage';
import { jwtDecode } from 'jwt-decode';
import { clearStorages, getAuthToken, getRememberedEmail, rememberEmail } from './localStorage';
import { useQueryClient } from '@tanstack/react-query';
import { useServices } from 'app/hooks/useServices';

const decodeJWTAuthToken = () => {
  try {
    const authToken = getAuthToken();
    if (authToken) {
      const decodedToken = jwtDecode(authToken);
      return { userNameJWT: decodedToken.username, expirationTokenTime: decodedToken.exp };
    }
    return { userNameJWT: null, expirationTokenTime: null };
  } catch (error) {
    return { userNameJWT: null, expirationTokenTime: null };
  }
};

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [authState, setAuthState] = useState(null);

  const { userNameJWT, expirationTokenTime } = decodeJWTAuthToken();

  const authToken = getAuthToken();
  const isTokenFresh =
    !!authToken && (expirationTokenTime ? Date.now() < expirationTokenTime * 1000 : false);

  const reloadAuthState = () => setAuthState(Math.random() + authState);

  const { data: user } = useProfileDetails(isTokenFresh);
  // const { data: profileImage } = useProfileImage(user);
  const { data: services } = useServices();

  const userLogout = () => {
    clearStorages();
    queryClient.clear();
    reloadAuthState();
  };

  return (
    <UserAuthContext.Provider
      value={{
        user,
        services,
        userNameJWT,
        // profileImage,
        reloadAuthState,
        expirationTokenTime,
        rememberEmail,
        getRememberedEmail,
        getAuthToken,
        userLogout,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthContext;
