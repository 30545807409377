import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useProjectDocumentsUpload } from '../../Documents/hooks/useProjectDocumentsUpload';
import { projectTypes } from 'app/utils/constant';
import { useProjectInvestmentsCreateUpdate } from '../../Investments/hooks/useProjectInvestmentsCreateUpdate';
import { useProjectContractCreateUpdate } from '../../Contract/hooks/useContractCreateUpdate';
import { useProjectUpdateType } from './useProjectUpdateType';
import { useProjectClosingCreateUpdate } from '../../Closing/hooks/useProjectClosingCreateUpdate';

import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectUpdateStage(projectId, stage,previousPType) {
  const queryClient = useQueryClient();

  const { mutateAsync: uploadDocuments } = useProjectDocumentsUpload(projectId);
  const { mutateAsync: createInvestments } = useProjectInvestmentsCreateUpdate(projectId);
  const { mutateAsync: createContract } = useProjectContractCreateUpdate(projectId);
  const { mutateAsync: createClosing } = useProjectClosingCreateUpdate(projectId);
  const { mutateAsync: updateType } = useProjectUpdateType(projectId);

  return useMutation({
    mutationFn: async ({ documents, investments, contract, closing, reason }) => {

      const requestsArrayByStage = {
        [projectTypes.NEW_PROJECT]: [
          // () => uploadDocuments(documents),
          () => createInvestments(investments),
          () => createContract(contract),
          () =>
            updateType({
              id: projectId,
              projectType: projectTypes.NEW_PROJECT,
              previousPType: projectTypes.NEW_PROJECT,
            }),
        ],
        [projectTypes.ON_GOING]: [
          // () => uploadDocuments(documents),
          () => createClosing(closing),
          () =>
            updateType({
              id: projectId,
              projectType: projectTypes.ON_GOING,
              previousPType: projectTypes.ON_GOING,
            }),
        ],
        [projectTypes.ON_HOLD]: [
          () =>
            updateType({
              id: projectId,
              projectType: projectTypes.ON_HOLD,
              previousPType,
              reason,
            }),
        ],
        [projectTypes.CANCELLED]: [
          () =>
            updateType({
              id: projectId,
              projectType: projectTypes.CANCELLED,
              previousPType,
              reason,
            }),
        ],
        [projectTypes.PREVIOUS]: [
          () =>
            updateType({
              id: projectId,
              projectType: previousPType,
              reason: '',
            }),
        ],
      };

      const requests = requestsArrayByStage[stage];

      return await Promise.all(requests.map((request) => request()));
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.projectsCounts],
      }),
  });
}
