import { useMutation, useQueryClient } from '@tanstack/react-query';
import { respondToInvestment, respondToInvitation } from 'app/contexts/requestsActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { requestActions } from 'app/utils/constant';
import { checkIsInvestmentRequest } from 'app/utils/helpers';

export function useRequestsRespond(isInProject) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (response) => {
      const { request, action, declineReason } = response;
      const { id: invitationId, token: invitationToken, projectId, invitationType } = request;

      const responseData = {
        invitationId,
        invitationToken,
        invitationAction: action.toUpperCase(),
        projectId,
        declineReason,
      };

      const isInvestmentRequest = checkIsInvestmentRequest(invitationType);

      return isInvestmentRequest
        ? await respondToInvestment(responseData)
        : await respondToInvitation(responseData);
    },
    onMutate: (response) => {
      const {
        request: { id: invitationId, projectId },
      } = response;

      const previousRequests = queryClient.getQueryData(queryKeys.requests);
      const previousProjectDetails = queryClient.getQueryData([
        ...queryKeys.project.basic,
        projectId,
      ]);

      queryClient.setQueryData(queryKeys.requests, (oldData) =>
        oldData.filter((request) => request.id !== invitationId),
      );

      if (!isInProject) {
        queryClient.removeQueries({
          queryKey: [...queryKeys.project.basic, projectId],
        });
      }
      
      return { previousRequests, previousProjectDetails };
    },
    onSuccess: (_, response) => {
      const {
        action,
        request: { invitationType, projectId },
      } = response;

      const isInvestmentRequest = checkIsInvestmentRequest(invitationType);
      const isAccepted = action === requestActions.ACCEPT;

      if (!isAccepted) return;

      if (!isInvestmentRequest) {
        queryClient.invalidateQueries({ queryKey: queryKeys.projectsCounts });
      }

      if (isInProject) {
        const projectQueries = [
          [...queryKeys.project.requests, projectId],
          [...queryKeys.project.members, projectId],
          [...queryKeys.project.investments, projectId],
        ];

        projectQueries.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });
      }
    },
    onError: (_, { previousRequests, previousProjectDetails }) => {
      queryClient.setQueryData(queryKeys.requests, previousRequests);

      queryClient.setQueryData(
        [...queryKeys.project.basic, previousProjectDetails.projectId],
        previousProjectDetails,
      );
    },
  });
}
