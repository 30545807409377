export const mockRequests = [
  {
    id: '67a1b2c3d4e5f6g7h8i9j0k1',
    email: 'engineer@example.com',
    userId: '5dad315c-dcd3-443a-ab92-89ceb9d70835',
    projectId: '66df41c671ad46003d52dbc2',
    token: '9876fedc-ba98-7654-3210-fedcba987654',
    status: 'PENDING',
    expirationTime: '2024-10-15 14:30:00',
    createdTime: '2024-10-14 14:30:00',
    roles: ['Engineer'],
  },
  {
    id: '68b2c3d4e5f6g7h8i9j0k1l2',
    email: 'gp@example.com',
    userId: '3dad315c-dcd3-443a-ab92-89ceb9d70833',
    projectId: '66df41c671ad46003d52dbc2',
    token: '8765edcb-a987-6543-2109-edcba9876543',
    status: 'PENDING',
    expirationTime: '2024-10-20 10:15:30',
    createdTime: '2024-10-19 10:15:30',
    roles: ['General Partner'],
  },
  {
    id: '69c3d4e5f6g7h8i9j0k1l2m3',
    email: 'newuser@example.com',
    userId: '7dad315c-dcd3-443a-ab92-89ceb9d70837',
    projectId: '66df41c671ad46003d52dbc2',
    token: '7654dcba-9876-5432-1098-dcba98765432',
    status: 'PENDING',
    expirationTime: '2024-10-25 09:45:00',
    createdTime: '2024-10-24 09:45:00',
    roles: ['Investor'],
  },
];
