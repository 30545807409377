import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Paragraph, H1, CustomLabel, StyledTextField } from '../../../../common/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';
import { GoogleMap } from '@react-google-maps/api';
import { notify } from 'app/services/notify';
import { DEFAULT_CENTER } from 'app/utils/constant';

const containerStyle = {
  width: '100%',
  height: '263px',
};

const noLocationSelectedToastId = 'noLocationSelectedToastId';

const validationSchema = Yup.object().shape({
  address1: Yup.string().required('Address1 is required!'),
  city: Yup.string().required('City is required!'),
  state: Yup.string().required('State is required!'),
  zipCode: Yup.string()
    .matches(/^\d+$/, 'Zip Code must contain only digits')
    .required('Zip Code is required!'),
  country: Yup.string().required('Country is required'),
});

const Location = ({ formData, setFormData, setDisable }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const searchInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isAnyFieldEmpty =
      !formData.city ||
      !formData.state ||
      !formData.country ||
      !formData.zipCode ||
      !formData.latitude ||
      !formData.longitude;
    setDisable(isAnyFieldEmpty);
  }, [formData]);

  const initialValues = {
    search: formData.search,
    address1: formData.address1,
    address2: formData.address2,
    state: formData.state,
    zipCode: formData.zipCode,
    city: formData.city,
    country: formData.country,
    latitude: formData.latitude,
    longitude: formData.longitude,
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    try {
      setIsLoading(true);
      const geocoder = new window.google.maps.Geocoder();

      const results = await new Promise((resolve, reject) => {
        geocoder.geocode(
          { location: new window.google.maps.LatLng(lat, lng) },
          (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
              resolve(results[0]);
            } else {
              console.error('Geocode failed:', status);
              reject(new Error('Geocode was not successful for the following reason: ' + status));
            }
          },
        );
      });

      const addressData = parseAddressComponents(results);
      setFormData({
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        latitude: lat,
        longitude: lng,
        search: '',
        ...addressData,
      });

      if (markerRef.current) {
        markerRef.current.setPosition({ lat, lng });
      }

      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
      }
    } catch (error) {
      console.error('Places search or details error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlacesChanged = (search) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const addressData = parseAddressComponents(place);

    setFormData((prevState) => ({
      ...prevState,
      ...addressData,
      search,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const handleChangeWithValidation = (e, handleChange) => {
    if (!formData.latitude || !formData.longitude) {
      // Check if the toast with the specified ID is already active
      if (!notify.isActive(noLocationSelectedToastId)) {
        // Show new error toast with the specified ID
        notify.error('Please select a location from either the search or the map first.', {
          toastId: noLocationSelectedToastId,
        });
      }
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    handleChange(e);
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;

    // Set the initial center if no existing coordinates
    if (!formData.latitude || !formData.longitude) {
      mapRef.current.setCenter(DEFAULT_CENTER);
    }
  };

  return (
    <div className='lead'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setFormData({ ...formData, location: values });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ marginTop: '15px' }}>
            <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                
              <CustomLabel>Address1</CustomLabel>
                <StandaloneSearchBox
                  onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                  onUnmount={() => (searchInputRef.current = null)}
                  onPlacesChanged={() => handlePlacesChanged(values.search)}
                >
                  <StyledTextField
                    fullWidth
                    type='search'
                    name='address1'
                    size='small'
                    id='standard-basic'
                    placeholder='Search address'
                    variant='outlined'
                    value={values.address1}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const { value } = e.target;
                      handleChange(e);
                      if (!value)
                        setFormData((prevState) => ({
                          ...prevState,
                          search: '',
                          address1: '',
                          address2: '',
                          country: '',
                          city: '',
                          state: '',
                          zipCode: '',
                          latitude: null,
                          longitude: null,
                        }));
                    }}
                    error={Boolean(errors.address1 && touched.address1)}
                    helperText={touched.address1 && errors.address1}
                  />
                </StandaloneSearchBox>
                
              </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomLabel>Address2(optional)</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  name='address2'
                  value={values.address2}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  disabled={isLoading}
                />
                </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>City</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  name='city'
                  value={values.city}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.city && errors.city}
                  error={Boolean(errors.city) && touched.city}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>State</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  name='state'
                  value={values.state}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.state && errors.state}
                  error={Boolean(errors.state && touched.state)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>Zip Code</CustomLabel>
                <StyledTextField
                  sx={{ mb: 2 }}
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  name='zipCode'
                  value={values.zipCode}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.zipCode && errors.zipCode}
                  error={Boolean(errors.zipCode && touched.zipCode)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>Country</CustomLabel>
                <StyledTextField
                  fullWidth
                  sx={{ mb: 2 }}
                  size='small'
                  variant='outlined'
                  name='country'
                  value={values.country}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.country && errors.country}
                  error={Boolean(errors.country && touched.country)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        onLoad={handleMapLoad}
        onUnmount={() => {
          mapRef.current = null;
        }}
        onClick={handleMapClick}
      >
        <Marker
          animation={window.google.maps.Animation.DROP}
          draggable={true}
          onLoad={(marker) => {
            markerRef.current = marker;
          }}
          onUnmount={() => {
            markerRef.current = null;
          }}
          onDragEnd={handleMapClick}
        />
      </GoogleMap>
    </div>
  );
};

export default Location;
