import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addProjectGCPayment, updateProjectGCPayment } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectGCPaymentAddUpdate(contractor, payment) {
  const queryClient = useQueryClient();

  const isEditingMode = !!payment;

  return useMutation({
    mutationFn: async (data) => {
      const paymentHistoryItemPayload = {
        totalAmount: data.totalAmount,
        amountPaid: data.amountPaid,
        paidBy: data.paidBy,
        paidTo: data.paidTo,
        paymentType: data.paymentType,
        comments: data.comments,
      };

      const newPaymentPayload = {
        totalAmount: paymentHistoryItemPayload.totalAmount,
        expenseScreen: false,
        paymentHistory: [paymentHistoryItemPayload],
      };
      
      return isEditingMode
        ? await updateProjectGCPayment(
            contractor?.projectId,
            contractor?.id,
            payment?.paymentId,
            paymentHistoryItemPayload,
          )
        : await addProjectGCPayment(contractor?.projectId, contractor?.id, newPaymentPayload);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.generalContractor, contractor?.projectId],
      }),
  });
}
