import { styled } from '@mui/material/styles';
import { Box, TextField, Typography, Avatar, Link, Button, IconButton, Grid, Card, Divider, ListItemText } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//OrganiZationTab.jsx

export const StyledCard = styled(Card)(({ theme }) => ({
    width: '80%',
    borderRadius: '10px',
    backgroundColor: theme.palette.text.white,
    border: `1px solid ${theme.palette.text.border}`,
    [theme.breakpoints.down('md')]: {
        width: '95%',
    },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.75rem',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'auto',
    },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    fontWeight: 600,
    letterSpacing: "-1%",
    color: theme.palette.text.title,
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
        marginLeft: "1%",
    },
}));

// export const LoadingCircle = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     marginTop: '1%',
//     justifyContent: 'center',
//     alignItems: 'center',
// }))

export const LoadingCircle = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    [theme.breakpoints.down('sm')]: {
        height: '80px', // Adjust height for small screens
    },
}));



export const TextBox = styled(Box)(({ theme }) => ({
    height: '73px',
    display: 'flex',
    marginLeft: '2%',
    alignItems: 'center',
    float: 'left'
}))

export const StyleAddOrgButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.text.lavendorblue,
    color: theme.palette.text.white,
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    padding:'8px 1rem 8px 1rem',
    cursor: 'pointer',
    margin: '0',
    "&:hover": {
        backgroundColor: theme.palette.text.lavendorblue,
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const StyledIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.text.white,
    fontSize: "1.25rem",
}));

export const NoOrgTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
    },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
    width: '100vw',
    marginLeft: '-2.5%',
    backgroundColor: theme.palette.text.border,
}))

//OrganizationModal.jsx
export const StyledBox1 = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '0',
    right: '0',
    transform: 'translate(0, 0)',
    backgroundColor: theme.palette.text.white,
    borderRadius: '4px',
    border: '1px solid #D6DEE9',
    width: '35%',
    height: '100vh',
    overflowY: 'auto',
    zIndex: 9999,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }));
  

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.75rem',
    fontWeight: '500',
    color: theme.palette.text.primary,
    textTransform: 'none',
    marginLeft: '4%',
}))

export const StyledCrossIcon = styled(IconButton)(({ theme }) => ({
    fontSize: '1.25rem',
    color: theme.palette.text.cancleIcon
}))

export const InactiveModalTitle = styled(Typography)(({ theme }) => ({
    fontWeight: '700',
    fontSize: '1.875rem',
}))

export const InactiveModalSubTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginTop: '7%',
    marginBottom: '7%'

}))

export const InactiveModalButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
}))
export const InactiveModalCancleBtn = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.text.cancleButtonBG,
    color: theme.palette.text.cancleButton,
    border: 'none',
    borderRadius: '6px',
    width: '80px',
}))

export const InactiveModalInactiveBtn = styled(Button)(({ theme }) => ({
    backgroundColor: '#CE443C',
    color: theme.palette.text.white,
    border: 'none',
    borderRadius: '6px',
    width: '125px',
    "&:hover": {
        backgroundColor: "#CE443C",
    },
    textTransform: 'none'
}))

export const StyledBox2 = styled(Box)(({ theme }) => ({
    padding: '4px',
    paddingBottom: '0',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '5%'
}))

export const StyledBox3 = styled(Box)(({ theme }) => ({
    padding: '4px',
    paddingTop: '0',
    margin: '0 10px 0 10px',
    overflowY: 'auto',
    maxHeight: 'calc(100%-75px)'
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: '44px',
    },
    width: '98%',
    color: theme.palette.text.textfield,
    marginLeft: '2%',
    borderRadius: '3px',
    border: '1px  #BDC1C6',
    '& .MuiInputLabel-root': {
        color: theme.palette.text.textfield,
    },
}));

export const StyledLabel = styled('label')(({ theme }) => ({
    display: 'block',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    marginTop: '1rem',
    marginLeft: '2%'
}))

export const StyledBox4 = styled(Box)(({ theme }) => ({
    display: 'flex',
    float: 'right',
    gap: '8%',
    marginTop: '4%',
    marginBottom: '3%'
}))


export const ModalButtonCancle = styled(LoadingButton)(({ theme }) => ({
    boxShadow: 'none',
    height: '30px',
    backgroundColor: theme.palette.text.cancleButtonBG,
    color: theme.palette.text.cancleButton,
    width: '88px',
    border: 'none',
    padding: '20px',
    textTransform: 'none'
}))

export const ModalButtonSave = styled(LoadingButton)(({ theme }) => ({
    boxShadow: 'none',
    height: '30px',
    padding: '20px',
    backgroundColor: theme.palette.text.buttonbg,
    color: theme.palette.text.white,
    textTransform: 'none',
    "&:hover": {
        backgroundColor: theme.palette.text.buttonbg,
    },
}))

export const ButtonSkip = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    height: '30px',
    color: theme.palette.text.buttonbg,
    textTransform: 'none',
}))

//OganizationDetailCard.jsx

export const StyledCardBox1 = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2%'
}))

export const StyledCardBox2 = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2%'
}))

export const StyledCardTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    margin: 0,
}))

export const StyledCardBox3 = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
}))


export const StyledEditIcon = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.text.lavendorblue,
    border: `1px solid ${theme.palette.text.lavendorblue}`,
    borderRadius: '6px',
    fontWeight: '500',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        width: '100%',
    },
}));

export const OrgStatus = styled(Box)(({ theme }) => ({
    color: theme.palette.text.white,
    padding: '2px 8px',
    borderRadius: '4px',
    fontSize: ' 0.75rem',
    fontWeight: 'bold',
    marginLeft: '8px'
}))

export const OrgStatusModal = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    backgroundColor: theme.palette.text.white,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',
    padding: '24px',
    borderRadius: '16px'
}))

export const OrgDetailsBox1 = styled(Box)(({ theme }) => ({
    marginTop: '16px',
    marginLeft: '2%'
}))

export const OrgCardModalBox2 = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px'
}))


export const OrgDetailsBox2 = styled(Box)(({ theme }) => ({
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'space-between'
}))

export const StyledOrgContentKey = styled(Typography)(({ theme }) => ({
    fontSize: ' 0.875rem',
    fontWeight: '500',
    lineHeight: '17.04px',
    letterSpacing: '-2%',
    color: theme.palette.text.primary
}))

export const StyledOrgContentValue = styled(Typography)(({ theme }) => ({
    fontSize: '1.125rem',
    fontWeight: '600',
    color: theme.palette.text.lightdark
}))

export const LinkText = styled(Button)(({ theme }) => ({
    color: theme.palette.text.link,
    textTransform: 'none',
    padding: '0',
    fontSize: '0.875rem',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '2%',
    marginBottom: '2%'
}))

export const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
    color: theme.palette.text.delete,
    width: '20px',
    height: '20px'
}))


export const StyledInactiveIcon = styled(VisibilityOffIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
    width: '20px',
    height: '20px'
}))

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({

    '& .MuiTypography-root': {
        color: theme.palette.text.primary
    }
}));

export const StyledListItemDeleteText = styled(ListItemText)(({ theme }) => ({

    '& .MuiTypography-root': {
        color: theme.palette.text.delete
    }
}));


//ViewOrganizationModal.jsx
export const ViewOrgTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.875rem',
    fontWeight: '500',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    marginLeft: '4%',
}))

export const VieOrgDetailsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5%',
    marginBottom: '16px',
    marginTop: '4%'
}))

export const VieOrgDetailsTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: '600',
    lineHeight: '24.36px',
    margin: '0',
    color: theme.palette.text.title,
}))

export const VieOrgDetailsTitle1 = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    width: '90%',
    padding: '16px',
    marginLeft: '5%',
    border: `1px solid ${theme.palette.text.border} `,
    backgroundColor: theme.palette.text.white
}))

export const StyledEinNum = styled(Typography)(({ theme }) => ({
    color: '#202124',
    fontWeight: '500',
    fontSize: '1rem',
    marginBottom: '4px'
}))

export const StyledOrgSubTitle = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '1.24rem',
    color: theme.palette.text.primary,
    marginBottom: '4px'
}))

export const StyledOrgSubHeading = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    fontSize: '0.8125rem',
    color: theme.palette.text.subTitle,
    marginBottom: '0.25rem'
}))

export const AssProjectStatus = styled(Box)(({ theme }) => ({
    backgroundColor: '#46BC99',
    color: theme.palette.text.white,
    padding: '4px 12px',
    borderRadius: '3px',
    fontSize: '0.875rem',
    fontWeight: '500',
    display: 'inline-block'
}))

export const StyledSubTitleDesig = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '1rem',
    color: theme.palette.text.lightdark
}))

export const ViewDetailsLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.link,
    display: 'block',
    marginTop: '25px',
    textDecoration: 'underline'
}))

// PersonalInfo.jsx
export const AvatarImg = styled(Avatar)(({ theme }) => ({
    width: "6.5rem",
    height: "6.5rem",
    border: "1px solid #000000",
    marginTop: "2.25rem",
    marginLeft: "2.25rem",
    [theme.breakpoints.down("sm")]: {
        width: "5rem",
        height: "5rem",
        marginTop: "1rem",
        marginLeft: "1rem",
    },
}));

export const DataGrid1 = styled(Grid)(({ theme }) => ({
    display: "flex",
    gap: "6rem",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        gap: "2rem",
    },
}));

export const DataBox = styled(Box)(({ theme }) => ({
    marginTop: '1.5625rem',
    marginBottom: '1.5625rem'
}))

export const DataBox2 = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: "12rem",
    marginTop: "1rem",
    [theme.breakpoints.down("md")]: {
        gap: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "1.5rem",
    },
}));

export const ProfileKey = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
    },
}));

export const ProfileValue = styled(Typography)(({ theme }) => ({
    fontSize: "1.125rem",
    fontWeight: "600",
    color: theme.palette.text.lightdark,
    [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
    },
}));

//ContactDetails.jsx
export const ContactDataBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8rem',
    marginLeft: '2%',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        gap: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '1.5rem',
        marginLeft: '0',
    },
}));

export const EmailBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const VerifyBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
    },
}));

export const CheckCircleicon = styled(CheckCircleIcon)(({ theme }) => ({
    color: theme.palette.text.LoadingIcon
}))

// UserAddress.jsx
export const AddressBox = styled(Box)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.text.primary,
    margin: '13.5px 0',
    padding: '0 2%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.85rem',
        padding: '0 1%',
        wordWrap: 'break-word',
    },
}));

export const AddressTextBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
        margin: '5px 0',
    },
}));

//PersonalInfoModal.jsx
export const UploadButton = styled(Box)(({ theme }) => ({
    borderRadius: '50%',
    width: '132px',
    height: '132px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    overflow: 'hidden',
    position: 'relative',
    border: `2px solid ${theme.palette.text.primary}`,
    cursor: 'pointer',
}));
export const CenteredBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '35px',
    marginTop: '38px',
}));

//AddContactNumberModal.jsx
export const CountryCodeField = styled(TextField)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
    border: "none",
    padding: "0 8px",
}));

export const StyledVerticalDivider = styled(Divider)(({ theme }) => ({
    height: "40px",
    margin: "0 8px",
    backgroundColor: "#ccc",
}));

export const VerifyButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    fontSize: "14px",
    color: "#518EF8",
}));

export const OtpBox = styled(Box)({
    marginTop: "29px",
    marginLeft: '2%',
});

export const ContactTextBox = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    color: theme.palette.text.primary,
}));

export const OtpInput = styled("input")(({ isFilled, theme }) => ({
    width: "57px",
    height: "67px",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: '600',
    border: `1px solid ${isFilled ? "#007bff" : "#ccc"}`,
    borderRadius: "4px",
    backgroundColor: "#F3F5F9",
    color: theme.palette.text.primary,
    outline: "none",
    marginRight: "8px",
    '&:focus': {
        borderColor: "#0277BD",
    },
}));

export const ExpiryText = styled(Box)({
    marginTop: "16px",
    color: "#2E3134",
    fontSize: "14px",
});

export const Code = styled('span')({
    fontWeight: '600',
    fontSize: '15px',
    color: '#282A2D'
})

//UserInfo.jsx
export const Wrapper = styled(Box)({
    width: '89%',
    marginTop: "1.5rem",
    marginLeft: '12%',
    maxHeight: "80vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "10px",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
    },
});









