import React, { useState } from 'react';
import {
    StyledBox1, StyledModalTitle, StyledCrossIcon, StyledBox2,
    StyledBox3, StyledBox4, ModalButtonCancle,
    ModalButtonSave, OrgStatusModal,
    OrgCardModalBox2, InactiveModalTitle, InactiveModalSubTitle, InactiveModalButtonBox, InactiveModalCancleBtn,
    InactiveModalInactiveBtn
} from './StyledComponents';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Card, CardMedia, Modal } from '@mui/material';

const sampleImages = [
    'https://tse3.mm.bing.net/th?id=OIP.w4vHPYfDoyD9cS6BMAWEDwHaHa&pid=Api&P=0&h=180',
    'https://homeshiftteam.com/images/blog/others/land-real-estate.webp',
    'https://www.investopedia.com/thmb/yykxeXgS1D1U8NHWKTbWo0jaMRA=/680x440/filters:fill(auto,1)/houses_and_land-5bfc3326c9e77c0051812eb3.jpg',
    'https://services-media.propertylogic.net/blog/1454924560/build_a_home.jpeg',
    'https://cdn.carrot.com/uploads/sites/46759/2020/12/8-3-1600x1067.jpg',
    'https://tse3.mm.bing.net/th?id=OIP.Rd2KgpcFmN22nvg7yLMoCwHaE7&pid=Api&P=0&h=180',
    'https://tse3.mm.bing.net/th?id=OIP.w4vHPYfDoyD9cS6BMAWEDwHaHa&pid=Api&P=0&h=180',
    'https://homeshiftteam.com/images/blog/others/land-real-estate.webp',
];

const ModalDeleteImages = ({ handleClose, confirmDelete, setConfirmDelete }) => {

    const [images, setImages] = useState(sampleImages);
    const [selectedImages, setSelectedImages] = useState([]);

    const handleSelectImage = (image) => {
        setSelectedImages((prevSelected) =>
            prevSelected.includes(image)
                ? prevSelected.filter((img) => img !== image)
                : [...prevSelected, image]
        );
    };

    const handleDeleteImages = () => {
        const remainingImages = images.filter(image => !selectedImages.includes(image));
        setImages(remainingImages);
        setSelectedImages([]);
        handleCloseConfirmDeleteModal();
        handleClose();
    };

    const handleSelectAll = () => {
        if (selectedImages.length === images.length) {
            setSelectedImages([]);
        } else {
            setSelectedImages(images);
        }
    };

    const handleConfirmDelete = () => {
        if (selectedImages.length > 0) {
            setConfirmDelete(true);
        }
    }

    const handleCloseConfirmDeleteModal = () => {
        setConfirmDelete(false);
    }
    return (
        <>
            <StyledBox1>
                <StyledBox2>
                    <StyledModalTitle>Delete Images</StyledModalTitle>
                    <StyledCrossIcon onClick={handleClose}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <StyledBox3>
                    {/* <Button onClick={handleSelectAll}>
                        {selectedImages.length === images.length ? 'Deselect All' : 'Select All'}
                    </Button> */}
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        {images.map((image) => (
                            <Grid item xs={4} key={image}>
                                <Card
                                    onClick={() => handleSelectImage(image)}
                                    style={{
                                        border: selectedImages.includes(image) ? '3px solid #4CB64C' : 'none',
                                        position: 'relative',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="213"
                                        width='213'
                                        image={image}
                                        alt="uploaded image"
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <StyledBox4  >
                        <ModalButtonCancle
                            variant='outlined'
                            onClick={handleClose}
                        // disabled={isSubmitting}
                        >
                            Cancel
                        </ModalButtonCancle>
                        <ModalButtonSave
                            onClick={handleConfirmDelete}
                            sx={{ backgroundColor: '#CE443C' }}
                            type='submit'
                        // loading={isSubmitting}
                        >
                            Delete
                        </ModalButtonSave>

                        <Modal
                            open={confirmDelete}
                            onClose={handleCloseConfirmDeleteModal}
                        >
                            <OrgStatusModal>
                                <OrgCardModalBox2>
                                    <InactiveModalTitle>
                                        Delete Image(s)
                                    </InactiveModalTitle>
                                    <StyledCrossIcon onClick={handleCloseConfirmDeleteModal}>
                                        <CloseIcon />
                                    </StyledCrossIcon>
                                </OrgCardModalBox2>
                                <InactiveModalSubTitle>
                                    {`Are you sure want to delete the ${selectedImages.length} images`}
                                </InactiveModalSubTitle>
                                <InactiveModalButtonBox>
                                    <InactiveModalCancleBtn onClick={handleCloseConfirmDeleteModal}>
                                        Cancel
                                    </InactiveModalCancleBtn>
                                    <InactiveModalInactiveBtn onClick={handleDeleteImages}>
                                        Proceed
                                    </InactiveModalInactiveBtn>
                                </InactiveModalButtonBox>
                            </OrgStatusModal>
                        </Modal>
                    </StyledBox4>
                </StyledBox3>
            </StyledBox1>
        </>
    )
}

export default ModalDeleteImages;