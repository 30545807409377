export const mockMembers = [
  {
    id: '66df41c618e7b84b66ca8456',
    email: 'john.doe@example.com',
    phone: '+09526544110',
    memberName: 'John Doe',
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: '66df41c671ad46003d52dbc2',
    token: '',
    userRoles: ['Owner'],
    status: 'GRANTED',
  },
  {
    id: '66df41c618e7b84b66ca8451',
    email: 'owner@example.com',
    phone: '+14567890123',
    memberName: 'Alice Johnson',
    userId: '1dad315c-dcd3-443a-ab92-89ceb9d70831',
    projectId: '66df41c671ad46003d52dbc2',
    token: '',
    userRoles: ['Owner'],
    status: 'GRANTED',
  },
  {
    id: '66df41c618e7b84b66ca8452',
    email: 'investor@example.com',
    phone: '+15678901234',
    memberName: 'Bob Smith',
    userId: '2dad315c-dcd3-443a-ab92-89ceb9d70832',
    projectId: '66df41c671ad46003d52dbc2',
    token: '',
    userRoles: ['Attorney', 'Investor'],
    status: 'GRANTED',
  },
  {
    id: '66df41c618e7b84b66ca8453',
    email: 'gp@example.com',
    phone: '+16789012345',
    memberName: 'Charlie Brown',
    userId: '3dad315c-dcd3-443a-ab92-89ceb9d70833',
    projectId: '66df41c671ad46003d52dbc2',
    token: '',
    userRoles: ['General Partner'],
    status: 'PENDING',
  },
  {
    id: '66df41c618e7b84b66ca8454',
    email: 'architect@example.com',
    phone: '+17890123456',
    memberName: 'Diana Prince',
    userId: '4dad315c-dcd3-443a-ab92-89ceb9d70834',
    projectId: '66df41c671ad46003d52dbc2',
    token: '',
    userRoles: ['Architect', 'Investor'],
    status: 'GRANTED',
  },
  {
    id: '66df41c618e7b84b66ca8455',
    email: 'engineer@example.com',
    phone: '+18901234567',
    memberName: 'Ethan Hunt',
    userId: '5dad315c-dcd3-443a-ab92-89ceb9d70835',
    projectId: '66df41c671ad46003d52dbc2',
    token: '',
    userRoles: ['Engineer', 'Architect'],
    status: 'PENDING',
  },
];
