import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  TextField,
  Autocomplete,
  styled,
  Container,
  Box,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import { Title } from 'app/common/Typography';
import AddUpdateExpensesDialog from './AddUpdateExpensesDialog.jsx';
import { DownloadIcon } from 'app/common/icons';
import ExpenseInvoice from './ExpenseInvoice.jsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { documentTypes, expensesTypes } from 'app/utils/constant.js';
import { notify } from 'app/services/notify.js';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments.js';
import { useProjectExpenses } from './hooks/useProjectExpenses.js';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const AutoComplete = styled(Autocomplete)(() => ({
  width: 170,
  marginRight: 10,
  '& .MuiOutlinedInput-root': {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
  },
}));

const cellStyle = {
  width: '12.5%',
  textAlign: 'left',
  padding: '8px',
};

const tableBodyCellStyle = {
  fontWeight: 500,
  fontSize: '14px',
  align: 'left',
  paddingLeft: '0.7%',
};

const expenseTypeParam = 'expenseType';

const ExpensesSection = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: documents } = useProjectDocuments(projectId);
  const { data: expenses } = useProjectExpenses(projectId);

  const [openRow, setOpenRow] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const expensesDocuments = useMemo(
    () => documents.filter((doc) => doc.metadata.type === documentTypes.EXPENSES),
    [documents],
  );

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  const toggleModal = () => setIsOpenDialog((prevState) => !prevState);

  const handleInvoiceDownload = (expense) => {
    try {
      expensesDocuments.forEach((doc) => {
        if (
          expense?.paymentDetails[expense?.paymentDetails?.length - 1].paymentDocuments?.includes(
            doc.metadata.fileId,
          )
        ) {
          handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
        }
      });
    } catch (error) {
      notify.error(error);
    }
  };

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notify.error(error);
    }
  };

  const availableExpensesTypes = expensesTypes.map((type) => type.label);

  const handleFilterChange = (_, newValue) =>
    setSearchParams((searchParams) => {
      availableExpensesTypes.includes(newValue)
        ? searchParams.set(expenseTypeParam, newValue)
        : searchParams.delete(expenseTypeParam);
      return searchParams;
    });

  const expensesFilter = useMemo(() => {
    const expensesFilterURLString = searchParams.get(expenseTypeParam);
    const validFilter = availableExpensesTypes.includes(expensesFilterURLString)
      ? expensesFilterURLString
      : '';

    return validFilter;
  }, [searchParams, availableExpensesTypes]);

  const filteredExpenses = useMemo(
    () =>
      expensesFilter
        ? expenses?.filter((expense) =>
            expense.paymentDetails.some((detail) => detail.expenseType === expensesFilter),
          )
        : expenses,
    [expenses, expensesFilter],
  );

  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {!expenses || expenses?.length === 0 ? (
          <>
            <Title>Expenses</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Expenses.
                </Typography>
                <Button onClick={toggleModal} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </>
        ) : (
          <>
            <Box
              marginLeft={3}
              marginRight={3}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                  alignItems: 'center',
                }}
              >
                <Title>Expenses</Title>
                <AutoComplete
                  sx={{ marginLeft: '5%' }}
                  value={expensesFilter}
                  onChange={handleFilterChange}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  options={availableExpensesTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Expense Type'
                      variant='outlined'
                      fullWidth
                    />
                  )}
                />
              </Box>
              <LoadingButton
                variant='contained'
                onClick={toggleModal}
                disabled={isOnHoldOrCancelled}
              >
                + Add Expenses
              </LoadingButton>
            </Box>
            <Box
              sx={{
                marginLeft: 3,
                marginRight: 3,
                marginTop: 1,
                marginBottom: 1,
                overflowY: 'auto',
                border: '2px solid var(--border-color)',
                maxHeight: '400px',
                position: 'relative',
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'var(--tableHead-bg-color)',
                  }}
                >
                  <TableRow color='var(--tableHead-row-color)'>
                    <TableCell />
                    <TableCell sx={{ ...cellStyle, paddingLeft: '1.5%  ' }}>S.NO.</TableCell>
                    <TableCell sx={cellStyle}>EXPENSES NAME</TableCell>
                    <TableCell sx={cellStyle}>TOTAL AMOUNT</TableCell>
                    <TableCell sx={cellStyle}>PAID AMOUNT</TableCell>
                    <TableCell sx={cellStyle}>BALANCE</TableCell>
                    <TableCell sx={cellStyle}>EXPENSE TYPE</TableCell>
                    <TableCell sx={cellStyle}>PAID TO</TableCell>
                    <TableCell sx={cellStyle}>STATUS</TableCell>
                    <TableCell sx={cellStyle}>INVOICE NO.</TableCell>
                    <TableCell sx={cellStyle}>INVOICE</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredExpenses &&
                    filteredExpenses.map((expense, index) => {
                      const latestPaymentDetail =
                        expense?.paymentDetails?.length > 0 ? expense?.paymentDetails?.at(-1) : {};
                      return (
                        <>
                          <TableRow
                            hover
                            key={expense.id}
                            sx={{ cursor: 'pointer', color: 'var(--tableBody-row-color)' }}
                            onClick={() => handleRowClick(expense.id)}
                          >
                            <TableCell />
                            <TableCell sx={tableBodyCellStyle}>
                              <div
                                style={{
                                  fontWeight: 100,
                                  fontSize: '14px',
                                  align: 'left',
                                  width: '60px',
                                  color: 'var(--tableBody-cell-color)',
                                }}
                              >
                                <span>
                                  {openRow === expense.id ? (
                                    <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} />
                                  ) : (
                                    <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                                  )}
                                </span>
                                <span style={{ textDecoration: 'underline' }}>
                                  {(index + 1).toString().padStart(2, '0')}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              {latestPaymentDetail?.expensesName}
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>{expense?.totalAmount}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              {latestPaymentDetail?.paidAmount}
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              {latestPaymentDetail?.balance || '00'}
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              {latestPaymentDetail?.expenseType}
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              {latestPaymentDetail?.paidTo}
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              {latestPaymentDetail?.status}
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>{expense?.invoiceNo}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {expense?.paymentDetails?.length > 0
                                    ? expensesDocuments
                                        ?.filter((doc) =>
                                          latestPaymentDetail?.paymentDocuments?.includes(
                                            doc.metadata.fileId,
                                          ),
                                        )
                                        .map((doc) => (
                                          <div key={doc.metadata.fileId}>
                                            {doc.metadata.documentName}
                                          </div>
                                        ))
                                    : 'N/A'}
                                </div>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleInvoiceDownload(expense);
                                  }}
                                  disabled={expense?.paymentDetails?.length === 0}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                              <Collapse in={openRow === expense.id} timeOut='auto' unmountOnExit>
                                <Box>
                                  <ExpenseInvoice
                                    selectedExpense={expense}
                                    expensesDocuments={expensesDocuments}
                                  />
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Box>
      {isOpenDialog && <AddUpdateExpensesDialog closeDialog={toggleModal} />}
    </>
  );
};

export default ExpensesSection;
