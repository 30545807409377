import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProjectBuildingUnitLease } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export const useProjectBuildingUnitLeaseDelete = (projectId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (leaseId) => deleteProjectBuildingUnitLease(projectId, leaseId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.buildingUnitLeases, projectId],
      }),
  });
};
