import { useQuery } from '@tanstack/react-query';
import { getProjectDocuments } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectDocuments(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.documents, projectId],
    queryFn: () => getProjectDocuments(projectId),
    staleTime: Infinity,
    gcTime: Infinity,
    placeholderData: [],
  });
}