import { useQuery } from '@tanstack/react-query';
import { getProjectBuildingUnitSales } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectBuildingUnitSales(projectId, unitId) {
  return useQuery({
    queryKey: [...queryKeys.project.buildingUnitSales, projectId, unitId],
    queryFn: () => getProjectBuildingUnitSales(projectId, unitId),
    placeholderData: [],
    enabled: !!unitId,
  });
}
