import React, { useRef } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Container,
  Button,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import CLLoanWithdrawalAddEdit from './CLLoanWithdrawalAddEdit';
import { convertUTCDateToLocal } from 'app/utils/helpers';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const CLLoanWithdrawals = ({ lender }) => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const selectedWithdrawal = useRef();

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickOpen = () => {
    selectedWithdrawal.current = null;
    toggleDialog();
  };

  const handleClickUpdate = (withdrawal) => {
    selectedWithdrawal.current = withdrawal;
    toggleDialog();
  };

  const loanWithdrawal = lender?.loanWithdrawal;

  return (
    <>
      <SubtitleDivider />
      {loanWithdrawal.length === 0 ? (
        <>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Loan Withdrawal Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Loan Withdrawal Details.
                </Typography>
                <Button onClick={handleClickOpen} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <>
          <Box
            marginLeft={3}
            marginRight={3}
            marginTop={1}
            marginBottom={1}
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Title sx={{ marginLeft: '0px' }}>Loan Withdrawal Details</Title>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Button
                disabled={lender?.loanBalance === 0}
                sx={{
                  color:
                    lender?.loanBalance === 0
                      ? 'var(--LoanWithdrawalDetails-add-button-onTrue)'
                      : 'primary',
                }}
                onClick={handleClickOpen}
              >
                + ADD
              </Button>
            </Box>
          </Box>
          <Box
            overflow='auto'
            border={'2px solid #E5E5E7'}
            marginLeft={3}
            marginRight={3}
            marginBottom={1}
          >
            <ProductTable marginLeft={3} marginRight={3}>
              <TableHead style={{ backgroundColor: 'var(--tableHead-bg-color)' }}>
                <TableRow color='var(--tableHead-row-color)'>
                  <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.NO.</TableCell>
                  <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>
                    LOAN AVAILABLE AMOUNT
                  </TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                    LOAN WITHDRAWAL AMOUNT
                  </TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                    INVOICE NO
                  </TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                    PAID DATE
                  </TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>PAID TO</TableCell>
                  <TableCell
                    sx={{ px: 0, width: '5%', color: 'var(--tableHead-row-color)' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loanWithdrawal?.map((withdrawal, index) => (
                  <TableRow key={withdrawal.withdrawalId} hover>
                    <TableCell
                      align='left'
                      sx={{
                        px: 2,
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                    >
                      {`${(index + 1).toString().padStart(2, '0')}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {withdrawal?.balance}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                    >
                      {withdrawal?.withdrawalAmount}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {withdrawal?.invoiceNumber}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {convertUTCDateToLocal(withdrawal?.paidDate)}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {withdrawal?.paidTo}
                    </TableCell>
                    <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => handleClickUpdate(withdrawal)}>
                          <Icon>
                            <UpdateIcon />
                          </Icon>
                          <Span>Update</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </ProductTable>
          </Box>
        </>
      )}
      {isOpenDialog && (
        <CLLoanWithdrawalAddEdit
          lender={lender}
          withdrawal={selectedWithdrawal.current}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
};

export default CLLoanWithdrawals;
