import React, { useState } from 'react';
import LeaseView from './LeaseView';
import LeaseAddEdit from './LeaseAddEdit';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { useProjectBuildingUnitLeaseDelete } from './hooks/useProjectBuildingUnitLeaseDelete';
import { useProject } from 'app/contexts/ProjectContext';

const LeaseCard = ({ leaseId, unitDetails, selectedBuilding, selectedUnit }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (action) => setOpenModal(action);
  const handleClose = () => setOpenModal(null);

  const leaseData = unitDetails?.leaseDetails || {};
  const tenantData = unitDetails?.tenantDetails || {};

  const { projectId } = useProject();
  const { mutateAsync: deleteLease } = useProjectBuildingUnitLeaseDelete(projectId);

  const handleDelete = async () => await deleteLease(leaseId);

  return (
    <div className='lease-card' style={styles.leaseCard}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            fontSize='18px'
            justifyContent='space-between'
            color='#565960'
            alignItems='center'
          >
            <Typography variant='h6'>
              Lease Space: <strong>{leaseData.leaseSpace} Sq fts</strong>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton onClick={() => handleOpen('view')}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton onClick={() => handleOpen('edit')}>
                  <BorderColorOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => handleOpen('delete')}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'grey',
              fontSize: '14px',
            }}
            variant='body2'
          >
            {leaseData.rentSqFtPerYear ? `${leaseData.rentSqFtPerYear} rent/year • ` : ''}
            {leaseData.leaseTerm} lease term • ₹{leaseData?.securityDeposit || ''} Security deposit
          </Typography>
          <Typography fontSize='14px' fontWeight='500' variant='body2'>
            Lease Period: {convertUTCDateToLocal(new Date(...leaseData?.leaseStartDate))} -{' '}
            {convertUTCDateToLocal(new Date(...leaseData?.leaseEndDate))}
          </Typography>
        </Grid>
        <Grid item xs={6} style={styles.section}>
          <Typography variant='subtitle2'>TENANT DETAILS</Typography>
          <Typography variant='body2'>
            <strong>{tenantData?.contactPerson || 'N/A'}</strong>
          </Typography>
          <Typography variant='body2'>
            {tenantData?.phoneNo} • {tenantData?.email}
          </Typography>
          <Typography variant='body2'>
            {tenantData?.address || 'No Address'} • {tenantData?.city}• {tenantData?.state}
          </Typography>
          <Typography variant='body2'>
            {tenantData?.country} • {tenantData?.zipCode}
          </Typography>
        </Grid>

        <Grid item xs={6} style={styles.section}>
          <Typography variant='subtitle2'>LEASE DETAILS</Typography>
          <Typography variant='body2'>
            <strong>Lease Term: </strong>
            {leaseData.leaseTerm || 'N/A'}
          </Typography>
          <Typography variant='body2'>
            <strong>Rent Increase Per Year: </strong>
            {leaseData.rentIncreasePerYear || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <LeaseView
        open={openModal === 'view'}
        onClose={handleClose}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        leaseData={leaseData}
        tenantData={tenantData}
      />
      <LeaseAddEdit
        open={openModal === 'edit'}
        onClose={handleClose}
        tenantData={tenantData}
        leaseData={leaseData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        leaseId={leaseId}
      />
      <LeadDeleteConfirmation
        open={openModal === 'delete'}
        onClose={handleClose}
        name={'Lease Space'}
        from={`from building ${selectedBuilding?.buildingName}`}
        handleSubmit={handleDelete}
      />
    </div>
  );
};

const styles = {
  leaseCard: {
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    padding: 16,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: 16,
    marginTop: 16,
  },
  section: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: 8,
    marginTop: 8,
  },
};

export default LeaseCard;
