import React from "react";
import { Box, Modal, CardContent, Typography, CircularProgress } from "@mui/material";
import { EmailBox, VerifyBox, ContactDataBox, StyleAddOrgButton, StyledCard, StyledBox, StyledTitle, StyledEditIcon, StyledDivider, StyledIcon, LoadingCircle, TextBox, NoOrgTitle, StyledOrgContentKey, StyledOrgContentValue, CheckCircleicon } from "../StyleComponents";
import AddContactNumberModal from "./AddContactNumberModal";
import EditIcon from "@mui/icons-material/Edit";
import { useUserAuth } from "app/hooks/userUserAuth";

const ContactDetails = () => {
    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { user } = useUserAuth();  // Get user data from the hook

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    return (
        <>
            <StyledCard variant="outlined">
                <CardContent>
                    <StyledBox>
                        <StyledTitle>
                            Contact Details
                        </StyledTitle>
                        {user ? (  // Check if user data is available
                            <StyledEditIcon
                                variant="outlined"
                                onClick={handleOpenModal}
                                startIcon={<EditIcon />}
                            >
                                Edit Contact Number
                            </StyledEditIcon>
                        ) : (
                            <StyleAddOrgButton onClick={handleOpenModal} startIcon={<StyledIcon />}>
                                Contact Number
                            </StyleAddOrgButton>
                        )}
                    </StyledBox>
                    <StyledDivider />
                    <Box>
                        {loading ? (
                            <LoadingCircle>
                                <CircularProgress />
                            </LoadingCircle>
                        ) : user ? (  // Check if user data exists
                            <ContactDataBox>
                                {/* Email Section */}
                                <EmailBox>
                                    <StyledOrgContentKey>Email</StyledOrgContentKey>
                                    <StyledOrgContentValue>{user?.email}</StyledOrgContentValue>
                                    <VerifyBox>
                                        <CheckCircleicon fontSize="small" />
                                        <Typography variant="caption" color="#28B446" ml={0.5}>
                                            VERIFIED
                                        </Typography>
                                    </VerifyBox>
                                </EmailBox>
                                {user?.phone &&
                                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                                        <StyledOrgContentKey>Contact Number</StyledOrgContentKey>
                                        <StyledOrgContentValue>{user?.phone || "No contact number available"}</StyledOrgContentValue>
                                        <VerifyBox>
                                            <CheckCircleicon fontSize="small" />
                                            <Typography variant="caption" color="#28B446" ml={0.5}>
                                                VERIFIED
                                            </Typography>
                                        </VerifyBox>
                                    </Box>
                                }
                            </ContactDataBox>
                        ) : (
                            <TextBox>
                                <NoOrgTitle>No details available</NoOrgTitle>
                            </TextBox>
                        )}
                    </Box>
                </CardContent>
            </StyledCard>
            <Modal open={openModal} onClose={handleCloseModal}>
                <AddContactNumberModal handleCloseModal={handleCloseModal} openModal={openModal} />
            </Modal>
        </>
    );
};

export default ContactDetails;
