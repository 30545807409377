import React, { useState } from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useProject } from 'app/contexts/ProjectContext';
import { LoadingButton } from '@mui/lab';
import { convertUTCDateToLocal, formatCurrency, formatDecimal } from 'app/utils/helpers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CompanySection from './CompanySection';
import LenderSection from './LenderSection';
import { Form, Formik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectClosingCreateUpdate } from './hooks/useProjectClosingCreateUpdate';
import { useProjectClosing } from './hooks/useProjectClosing';
import { dateFormats } from 'app/utils/constant';
import dayjs from 'dayjs';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'var(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: 'var(--subtitleDivider1)',
});

const LeadsClosingDetails = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: closing } = useProjectClosing(projectId);
  const { mutate: updateClosing } = useProjectClosingCreateUpdate(projectId);

  const [openDialog, setOpenDialog] = useState(false);

  const initialValues = {
    purchasePrice: closing?.purchasePrice,
    closingCost: closing?.closingCost,
    realtorRebate: closing?.realtorRebate,
    closingDate: dayjs(closing?.closingDate),
  };

  const toggleDialog = () => setOpenDialog((prevState) => !prevState);

  const handleSubmit = (values, { setSubmitting }) => {
    const dataWithISODate = {
      ...closing,
      purchasePrice: parseFloat(values.purchasePrice),
      closingCost: parseFloat(values.closingCost),
      realtorRebate: parseFloat(values.realtorRebate),
      closingDate: new Date(values.closingDate).toISOString(),
    };

    updateClosing(dataWithISODate, {
      onSuccess: toggleDialog,
      onSettled: () => setSubmitting(false),
    });
  };

  const infoPairs = [
    { key: 'PURCHASE PRICE', value: formatCurrency(closing?.purchasePrice) },
    { key: 'CLOSING COST', value: formatCurrency(closing?.closingCost) },
    { key: 'REALTOR REBATE', value: formatCurrency(closing?.realtorRebate) },
    {
      key: 'CLOSING DATE',
      value: convertUTCDateToLocal(closing?.closingDate, dateFormats.short),
    },
  ];

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <Box marginRight={2} padding={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title>Closing Details :</Title>
            <Button
              onClick={toggleDialog}
              style={{ color: 'var(--editButton)', fontSize: '14px' }}
              disabled={isOnHoldOrCancelled}
            >
              <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
            </Button>
          </Box>
          <InfoContainer>
            {infoPairs.map(({ key, value }, index) => (
              <Box key={index}>
                <Key>{key}</Key>
                <ValueContainer>
                  <Value className='basicvalues'>{value}</Value>
                </ValueContainer>
              </Box>
            ))}
          </InfoContainer>
        </Box>

        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'var(--card-background)' }}>
          <CompanySection closing={closing} />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'var(--card-background)' }}>
          <LenderSection closing={closing} />
        </Card>
      </div>

      <Dialog open={openDialog} onClose={toggleDialog}>
        <DialogTitle>Edit Closing Details</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.closing()}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleBlur }) => {
            const handlePriceChange = (e) => {
              const { name, value } = e.target;
              const parsedValue = parseFloat(value);

              setFieldValue(name, formatDecimal(parsedValue, 2));
            };

            return (
              <Form>
                <DialogContent>
                  <TextField
                    fullWidth
                    margin='dense'
                    label='Purchase Price'
                    name='purchasePrice'
                    type='number'
                    value={values.purchasePrice}
                    onChange={handlePriceChange}
                    onBlur={handleBlur}
                    error={touched.purchasePrice && Boolean(errors.purchasePrice)}
                    helperText={touched.purchasePrice && errors.purchasePrice}
                    disabled={isSubmitting}
                  />
                  <TextField
                    fullWidth
                    margin='dense'
                    label='Closing Cost'
                    name='closingCost'
                    type='number'
                    value={values.closingCost}
                    onChange={handlePriceChange}
                    onBlur={handleBlur}
                    error={touched.closingCost && Boolean(errors.closingCost)}
                    helperText={touched.closingCost && errors.closingCost}
                    disabled={isSubmitting}
                  />
                  <TextField
                    fullWidth
                    margin='dense'
                    label='Realtor Rebate'
                    name='realtorRebate'
                    type='number'
                    value={values.realtorRebate}
                    onChange={handlePriceChange}
                    onBlur={handleBlur}
                    error={touched.realtorRebate && Boolean(errors.realtorRebate)}
                    helperText={touched.realtorRebate && errors.realtorRebate}
                    disabled={isSubmitting}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      name='closingDate'
                      label='Closing Date'
                      value={values.closingDate}
                      onChange={(value) => {
                        handleChange({
                          target: { name: 'closingDate', value },
                        });
                      }}
                      slotProps={{
                        textField: {
                          margin: 'dense',
                          fullWidth: true,
                          onBlur: handleBlur,
                          error: Boolean(touched.closingDate && errors.closingDate),
                          helperText: touched.closingDate && errors.closingDate,
                        },
                      }}
                      disabled={isSubmitting}
                    />
                  </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                  <LoadingButton onClick={toggleDialog} color='error' disabled={isSubmitting}>
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='outlined'
                    color='primary'
                    type='submit'
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default LeadsClosingDetails;
