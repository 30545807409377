export const API_BASE_URL = '/api/v1';

export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: `${API_BASE_URL}/authentication/login`,
    SEND_OTP: `${API_BASE_URL}/authentication/sendOtp`,
  },
  REGISTRATION: {
    REGISTER: `${API_BASE_URL}/registration/register`,
    CONFIRM_REGISTRATION: `${API_BASE_URL}/registration/confirmRegistration`,
    RESEND_CONFIRMATION_CODE: `${API_BASE_URL}/registration/resendConfirmationCode`,
  },
  USERS: {
    GET_PROFILE_DETAILS: `${API_BASE_URL}/users/getUserDetails`,
    EDIT_PROFILE_DETAILS: (userNameJWT) => `${API_BASE_URL}/users/updateUser/${userNameJWT}`,
    GET_USER_DETAILS_BY_EMAIL: (email) => `${API_BASE_URL}/users/getUsers/${email}`,
    GET_USER_DETAILS_BY_ID: (id) => `${API_BASE_URL}/users/getUsers?userIds=${id}`,
    GET_ALL_USERS: `${API_BASE_URL}/users/getAllUsers`,
  },
  PROJECTS: {
    LIST_PROJECTS: `${API_BASE_URL}/projects/lead/listProjects`,
    LIST_NEW_PROJECTS: `${API_BASE_URL}/projects/newproject/listProjects`,
    LIST_ONGOING_PROJECTS: `${API_BASE_URL}/projects/ongoing/listProjects`,
    LIST_CANCELLED_PROJECTS: `${API_BASE_URL}/projects/cancelled/listProjects`,
    LIST_ONHOLD_PROJECTS: `${API_BASE_URL}/projects/onhold/listProjects`,
    LIST_COMPLETED_PROJECTS: `${API_BASE_URL}/projects/completed/listProjects`,
    GET_PROJECT_DETAILS: (id) => `${API_BASE_URL}/projects/${id}/getProjectDetails`,
    UPDATE_PROJECT: (projectId) => `${API_BASE_URL}/projects/${projectId}/updateProject`,
    UPDATE_PROJECT_TYPE: (projectId) => `${API_BASE_URL}/projects/${projectId}/updateProjectType`,
    CREATE_PROJECT: `${API_BASE_URL}/projects/createProject`,
    DELETE_PROJECT: (projectId) => `${API_BASE_URL}/projects/${projectId}/deleteProject`,
    GET_PROJECTS_COUNT: `${API_BASE_URL}/projects/getProjectsCount`,
  },
  ROLES: {
    GET_CREATE_PROJECT_ROLES: (projectId) => `${API_BASE_URL}/roles/project/${projectId}`,
    UPDATE_DELETE_PROJECT_ROLE: (projectId, roleName) =>
      `${API_BASE_URL}/roles/project/${projectId}/role/${roleName}`,
  },
  SERVICES: {
    GET_SERVICES: `${API_BASE_URL}/resources`,
  },
  LOCATIONS: {
    GET_PROJECT_LOCATION: (id) => `${API_BASE_URL}/locations/${id}/getProjectLocation`,
    CREATE_UPDATE_PROJECT_LOCATION: (projectId) =>
      `${API_BASE_URL}/locations/${projectId}/createProjectLocation`,
  },
  DOCUMENTS: {
    GET_DOCUMENTS: (id) => `${API_BASE_URL}/documents/${id}/getDocuments`,
    GET_PROFILE_IMAGE: `${API_BASE_URL}/documents/getProfileImage`,
    GET_ALL_DOCUMENTS: `${API_BASE_URL}/documents/getDocuments`,
    UPLOAD_DOCUMENTS: (projectId) => `${API_BASE_URL}/documents/${projectId}/uploadDocuments`,
    UPLOAD_PROFILE_IMAGE: `${API_BASE_URL}/documents/uploadProfileImage`,
    DELETE_DOCUMENTS_BY_FILE_ID: (fileId) => `${API_BASE_URL}/documents/deleteDocument/${fileId}`,
  },
  MEMBERS: {
    GET_PROJECT_MEMBERS: (projectId) => `${API_BASE_URL}/members/${projectId}/getProjectMembers`,
    ASSIGN_PROJECT_MEMBERS: (projectId) =>
      `${API_BASE_URL}/members/${projectId}/assignProjectMembers`,
    UPDATE_PROJECT_MEMBER_ROLE: (projectId) =>
      `${API_BASE_URL}/members/${projectId}/updateMemberRole`,
    DELETE_PROJECT_MEMBER: (projectId) =>
      `${API_BASE_URL}/members/${projectId}/deleteProjectMembers`,
  },
  LLC: {
    GET_LLC_DETAILS: (id) => `${API_BASE_URL}/llc/${id}/getLlcDetails`,
    CREATE_UPDATE_LLC: (projectId) => `${API_BASE_URL}/llc/${projectId}/createLlc`,
  },
  USERLLC: {
    GET_USER_LLC_DETAILS: (userId) => `${API_BASE_URL}/llc/${userId}/getUserLlc`,
    CREATE_UPDATE_USER_LLC:(userId) => `${API_BASE_URL}/llc/${userId}/createUserLlc`,
    DELETE_USER_LLC: (id) => `${API_BASE_URL}/llc/deleteUserLlc?id=${id}`,
  },
  COMMENTS: {
    GET_ALL_COMMENTS: (projectId) => `${API_BASE_URL}/comments/${projectId}/getAllComments`,
    CREATE_COMMENT: (projectId) => `${API_BASE_URL}/comments/${projectId}/addComment`,
    CREATE_COMMENT_REPLY: (projectId, parentId) =>
      `${API_BASE_URL}/comments/${projectId}/${parentId}/addReply`,
    UPDATE_COMMENT: (commentId, projectId) =>
      `${API_BASE_URL}/comments/${projectId}/updateComment/${commentId}`,
    DELETE_COMMENT: (commentId, projectId) =>
      `${API_BASE_URL}/comments/${projectId}/deleteComment/${commentId}`,
  },
  CONTRACTDETAILS: {
    GET_CONTRACT_DETAILS: (projectId) =>
      `${API_BASE_URL}/contractDetails/${projectId}/getContractDetails`,
    CREATE_UPDATE_CONTRACT_DETAILS: (projectId) =>
      `${API_BASE_URL}/contractDetails/${projectId}/createContractDetails`,
  },
  CLOSING_CONTRACT: {
    GET_CLOSING_CONTRACT: (projectId) =>
      `${API_BASE_URL}/closingContract/${projectId}/getClosingContract`,
    CREATE_UPDATE_CLOSING_CONTRACT: (projectId) =>
      `${API_BASE_URL}/closingContract/${projectId}/createClosingContract`,
  },
  INVESTMENT_DETAILS: {
    GET_PROJECT_INVESTMENTS: (projectId) =>
      `${API_BASE_URL}/investments/${projectId}/getInvestments`,
    CREATE_UPDATE_PROJECT_INVESTMENTS: (projectId) =>
      `${API_BASE_URL}/investments/${projectId}/createInvestments`,
    GET_PROJECT_INVESTMENT_DETAILS: (projectId) =>
      `${API_BASE_URL}/investments/${projectId}/getInvestmentDetails`,
    GET_USER_INVESTMENT_DETAILS: (projectId, investmentUserId) =>
      `${API_BASE_URL}/investments/${projectId}/getInvestmentDetails?investmentUserId=${investmentUserId}`,
    ADD_USER_INVESTMENTS: (projectId) => `${API_BASE_URL}/investments/${projectId}/addInvestment`,
    ADD_USER_INVESTMENT_PAYMENT: (projectId, investmentUserId) =>
      `${API_BASE_URL}/investments/${projectId}/addInvestmentPayment/${investmentUserId}`,
  },
  EXPENSES_DETAILS: {
    GET_EXPENSES_DETAILS: (projectId) => `${API_BASE_URL}/expenses/${projectId}/getExpenses`,
    GET_EXPENSES_BY_INVOICE: (projectId, invoiceNo, expenseId) =>
      `${API_BASE_URL}/expenses/${projectId}/getByInvoiceNo?invoiceNo=${invoiceNo}&id=${expenseId}`,
    CREATE_EXPENSES_DETAILS: (projectId) => `${API_BASE_URL}/expenses/${projectId}/createExpenses`,
    UPDATE_EXPENSES_BY_INVOICE: (projectId, invoiceNo, expenseId) =>
      `${API_BASE_URL}/expenses/${projectId}/updateBalance/${invoiceNo}?id=${expenseId}`,
  },
  LEASE_SALE: {
    GET_BUILDINGS: (projectId) => `${API_BASE_URL}/lease/${projectId}/getBuilding`,
    GET_BUILDING_UNIT_LEASES: (projectId, unitId) =>
      `${API_BASE_URL}/lease/${projectId}/getLease?unitId=${unitId}`,
    GET_BUILDING_UNIT_SALES: (projectId, unitId) =>
      `${API_BASE_URL}/sales/${projectId}/getSales?unitId=${unitId}`,
    CREATE_UPDATE_BUILDING: (projectId) => `${API_BASE_URL}/lease/${projectId}/addBuilding`,
    CREATE_UPDATE_BUILDING_UNITS: (projectId, buildingId) =>
      `${API_BASE_URL}/lease/${projectId}/addUnits?buildingId=${buildingId}`,
    DELETE_BUILDING_UNIT_LEASE: (projectId, leaseId) =>
      `${API_BASE_URL}/lease/${projectId}/deleteLease?id=${leaseId}`,
    DELETE_BUILDING_UNIT_SALE: (projectId, saleId) =>
      `${API_BASE_URL}/sales/${projectId}/deleteSales?id=${saleId}`,
    CREATE_UPDATE_LEASE: (projectId) => `${API_BASE_URL}/lease/${projectId}/createLease`,
    CREATE_UPDATE_SALE: (projectId) => `${API_BASE_URL}/sales/${projectId}/createSales`,
    GET_ALL_LEASE: (projectId) => `${API_BASE_URL}/lease/${projectId}/getAllLease`,
    GET_ALL_SALES: (projectId) => `${API_BASE_URL}/sales/${projectId}/getAllSales`,
  },
  GENERAL_CONTRACTOR: {
    GET_PROJECT_GC_ALL: (projectId) => `${API_BASE_URL}/gc/${projectId}/getAllGeneralContractor`,
    GET_PROJECT_GC_BY_ID: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/getGeneralContractor?id=${contractorID}`,
    CREATE_PROJECT_GC: (projectId) => `${API_BASE_URL}/gc/${projectId}/createGeneralContractor`,
    UPDATE_PROJECT_GC: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/createGeneralContractor?id=${contractorID}`,
    ADD_PROJECT_GC_MANAGER: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/addManagerDetails?generalContractorId=${contractorID}`,
    UPDATE_PROJECT_GC_MANAGER: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/updateManagerDetails?generalContractorId=${contractorID}`,
    ADD_PROJECT_GC_CHANGE_ORDER: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/addChangeOrder?generalContractorId=${contractorID}`,
    UPDATE_PROJECT_GC_CHANGE_ORDER: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/updateChangeOrder?generalContractorId=${contractorID}`,
    ADD_PROJECT_GC_PAYMENT: (projectId, contractorID) =>
      `${API_BASE_URL}/gc/${projectId}/addPayment?generalContractorId=${contractorID}`,
    UPDATE_PROJECT_GC_PAYMENT: (projectId, contractorID, paymentId) =>
      `${API_BASE_URL}/gc/${projectId}/updatePayment?id=${contractorID}&paymentId=${paymentId}`,
  },
  PROPERTY_MANAGEMENT: {
    GET_PROJECT_PM_ALL: (projectId) =>
      `${API_BASE_URL}/property-management/${projectId}/getAllPropertyManagement`,
    GET_PROJECT_PM_BY_ID: (projectId, propertyID) =>
      `${API_BASE_URL}/property-management/${projectId}/getPropertyManagement?id=${propertyID}`,
    CREATE_PROJECT_PM: (projectId) =>
      `${API_BASE_URL}/property-management/${projectId}/createPropertyManagement`,
    UPDATE_PROJECT_PM: (projectId, propertyID) =>
      `${API_BASE_URL}/property-management/${projectId}/createPropertyManagement?id=${propertyID}`,
    ADD_PROJECT_PM_CONTACT_PERSON: (projectId, propertyID) =>
      `${API_BASE_URL}/property-management/${projectId}/addContactPerson?propertyId=${propertyID}`,
    UPDATE_PROJECT_PM_CONTACT_PERSON: (projectId, propertyID) =>
      `${API_BASE_URL}/property-management/${projectId}/updateContactPerson?propertyId=${propertyID}`,
  },
  CONSTRUCTION_LENDER: {
    GET_PROJECT_CL_ALL: (projectId) =>
      `${API_BASE_URL}/lender-details/${projectId}/getLenderDetails`,
    CREATE_PROJECT_CL: (projectId) =>
      `${API_BASE_URL}/lender-details/${projectId}/createLenderDetails`,
    UPDATE_PROJECT_CL: (projectId, lenderID) =>
      `${API_BASE_URL}/lender-details/${projectId}/createLenderDetails?id=${lenderID}`,
    ADD_PROJECT_CL_LOAN_PAYMENT: (projectId, lenderID) =>
      `${API_BASE_URL}/lender-details/${projectId}/addLoanPayment?lenderId=${lenderID}`,
    ADD_PROJECT_CL_LOAN_WITHDRAWAL: (projectId, lenderID) =>
      `${API_BASE_URL}/lender-details/${projectId}/addLoanWithdrawal?lenderId=${lenderID}`,
    UPDATE_PROJECT_CL_LOAN_PAYMENT: (projectId, lenderID) =>
      `${API_BASE_URL}/lender-details/${projectId}/updateloanPayments?lenderId=${lenderID}`,
    UPDATE_PROJECT_CL_LOAN_WITHDRAWAL: (projectId, lenderID) =>
      `${API_BASE_URL}/lender-details/${projectId}/updateLoanWithdrawal?lenderId=${lenderID}`,
  },
  INVITATIONS: {
    GET_ALL_INVITATIONS: `${API_BASE_URL}/invitations/getInvitations`,
    GET_INVITATION_BY_ID: (id) => `${API_BASE_URL}/invitations/getInvitations/${id}`,
    GET_INVITATION_BY_TOKEN: (token) =>
      `${API_BASE_URL}/invitations/getInvitationsByToken/${token}`,
    GET_PROJECT_INVITATIONS: (projectId) =>
      `${API_BASE_URL}/invitations/${projectId}/getInvitations`,
    RESPOND_TO_INVITATION: `${API_BASE_URL}/invitations/respond`,
    RESPOND_TO_INVESTMENT: `${API_BASE_URL}/invitations/investmentRespond`,
  },

  AUDITLOGS: {
    AUDITLOG: (projectId) => `${API_BASE_URL}/audit/${projectId}/getActivities`,
  },
};
