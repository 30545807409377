import { mockCurrentUser } from './mockCurrentUser';
import { mockServices } from './mockServices';
import { mockUserRequests } from './mockUserRequests';
import { mockUsers } from './mockUsers';
import { mockBasicData } from './Project/mockBasicData';
import { mockLocation } from './Project/mockLocation';
import { mockMembers } from './Project/mockMembers';
import { mockRequests } from './Project/mockRequests';
import { mockRoles } from './Project/mockRoles';

/**
 * Comprehensive mock data object containing all mock data sets.
 * Use this object to simulate API responses or populate components in tests.
 *
 * @type {Object}
 */
const mockData = {
  user: {
    data: mockCurrentUser,
    requests: mockUserRequests,
    profileImage: {
      data: {},
    },
  },
  users: {
    data: mockUsers,
  },
  services: mockServices,
  project: {
    basic: mockBasicData,
    llc: {
      data: {},
    },
    location: {
      data: mockLocation,
    },
    documents: {},
    members: {
      data: mockMembers,
    },
    invitations: {
      data: mockRequests,
    },
    roles: mockRoles,
    contract: {
      data: {},
    },
    investment: {
      data: {},
    },
    expenses: {
      data: {},
    },
    lease: {
      data: {},
    },
    closing: {
      data: {},
    },
    generalContract: {
      data: {},
    },
    propertyManagement: {
      data: {},
    },
  },
};

export default mockData;
