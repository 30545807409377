import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  styled,
} from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { formatCurrency, formatDecimal } from 'app/utils/helpers';
import { useProjectClosingCreateUpdate } from './hooks/useProjectClosingCreateUpdate';
import { Formik } from 'formik';
import { Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import { validationSchemas } from 'app/utils/validationSchemas';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const InfoBox = styled(Box)({
  flex: '1 1 calc(25% - 10px)',
  marginBottom: '20px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'var(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: 'var(--subtitleDivider1)',
});

const LenderSection = ({ closing }) => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { mutate: updateLender } = useProjectClosingCreateUpdate(projectId);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const lenderDetails = closing?.lenderDetails;

  const initialValues = {
    ...lenderDetails,
  };

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleSubmit = (values, { setSubmitting }) => {
    const payloadData = {
      ...closing,
      lenderDetails: values,
    };

    updateLender(payloadData, {
      onSuccess: toggleDialog,
      onSettled: () => setSubmitting(false),
    });
  };

  const infoPairs = [
    { key: 'LENDER NAME', value: lenderDetails.lenderName },
    { key: 'CONTACT NUMBER', value: lenderDetails.lenderContactNumber },
    { key: 'EMAIL ADDRESS', value: lenderDetails.lenderEmail },
    { key: 'LEASE TERM', value: lenderDetails.leaseTerm },
    { key: 'DOWN PAYMENT', value: formatCurrency(lenderDetails.downPayment) },
    { key: 'LOAN AMOUNT', value: formatCurrency(lenderDetails.loanAmount) },
    { key: 'INTEREST RATE', value: lenderDetails.interestRate },
  ];

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>Lender Details :</Title>
          <Button
            onClick={toggleDialog}
            style={{ color: 'val(--editButton)', fontSize: '14px' }}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
          </Button>
        </Box>
        <InfoContainer>
          {infoPairs.map(({ key, value }, index) => (
            <InfoBox key={index}>
              <Key>{key}</Key>
              <ValueContainer>
                <Value className='basicvalues'>{value ?? ''}</Value>
              </ValueContainer>
            </InfoBox>
          ))}
        </InfoContainer>
      </Box>

      <Dialog open={isOpenDialog} onClose={toggleDialog} fullWidth>
        <DialogTitle>Edit Lender Details</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.lender()}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue, handleChange, handleBlur }) => {
            const handlePriceChange = (e) => {
              const { name, value } = e.target;
              const parsedValue = parseFloat(value);

              setFieldValue(name, formatDecimal(parsedValue, 2));
            };
            return (
              <Form>
                <DialogContent>
                  {[
                    { label: 'Lender Name', name: 'lenderName' },
                    { label: 'Contact Number', name: 'lenderContactNumber' },
                    { label: 'Email Address', name: 'lenderEmail' },
                    { label: 'Lease Term', name: 'leaseTerm' },
                    { label: 'Down Payment', name: 'downPayment', type: 'number' },
                    { label: 'Loan Amount', name: 'loanAmount', type: 'number' },
                    { label: 'Interest Rate', name: 'interestRate', type: 'number' },
                  ].map((field) => (
                    <TextField
                      key={field.name}
                      fullWidth
                      margin='dense'
                      name={field.name}
                      label={field.label}
                      value={values[field.name]}
                      type={field.type ? field.type : 'text'}
                      onChange={field.type === 'number' ? handlePriceChange : handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched[field.name] && errors[field.name])}
                      helperText={touched[field.name] && errors[field.name]}
                      disabled={isSubmitting}
                    />
                  ))}
                </DialogContent>
                <DialogActions>
                  <LoadingButton onClick={toggleDialog} color='error'>
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='outlined'
                    color='primary'
                    type='submit'
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LenderSection;
