import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { formatCurrency } from 'app/utils/helpers';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 25,
  borderRadius: 12.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(213, 235, 248, 1)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(90deg, rgba(38, 45, 226, 1) 0%, rgba(42, 150, 222, 1) 100%)',
  },
}));

const InvestmentsDiagram = ({ investments }) => {
  const { totalShare = 0, availableShares = 0, eachSharePrice = 0 } = investments || {};

  const soldShares = totalShare - availableShares;

  const soldPercentage = (soldShares / totalShare) * 100;

  const investmentData = [
    {
      value: availableShares,
      label: 'Available Shares',
      color: 'var(--available-shares)',
    },
    {
      value: `${soldShares} / ${totalShare}`,
      label: 'Sold Shares',
      color: 'var(--sold-Shares)',
    },
    {
      value: formatCurrency(eachSharePrice),
      label: 'Each share price',
      color: 'var(--each-Share-Price)',
    },
  ];

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='center'
        gap={1}
      >
        <Box display='flex' justifyContent='flex-start' gap={4}>
          {investmentData.map((item, index) => (
            <Box key={index}>
              <Typography variant='h5' color={item.color} fontWeight='600'>
                {item.value}
              </Typography>
              <Typography color='text.primary' variant='subtitle1' fontWeight='500'>
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box width='100%'>
          <BorderLinearProgress variant='determinate' value={soldPercentage} />
        </Box>
      </Box>
    </>
  );
};
export default InvestmentsDiagram;
