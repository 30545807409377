import '../fake-db';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Themes } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import { UserAuthProvider } from './contexts/UserAuthContext';
import { store } from './redux/store';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './react-query/queryClient';
import '../app/components/Theme/themeColors.css';
import { toast } from 'react-toastify';
import { LoadScript } from '@react-google-maps/api';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import Notification from './components/NotificationBar/Notification'; // Import Notification component
import { Outlet } from 'react-router-dom';
import { BASE_URL } from 'mocks/defaultRequestHandlers';

const libraries = ['places'];
const GOOGLE_MAPS_API_KEY = 'AIzaSyCDUIkyDMVrgLh4Ax0Ki8DHcWAktv7qPTs';

const App = () => {
  const [message, setMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false); // State to control visibility

  useEffect(() => {
    const socketUrl = `${BASE_URL}/websocket`;

    const client = new Client({
      brokerURL: socketUrl,
      webSocketFactory: () => new SockJS(socketUrl),
      debug: (str) => console.log(str),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      console.log('WebSocket connection established');
      // toast.success('WebSocket connection established');

      client.subscribe('/topic/notifications', (message) => {
        console.log('Message received:', message.body);
        setMessage(message.body);
        setShowNotification(true);

        // setTimeout(() => {
        //   setShowNotification(false);
        // }, 5000);

        // toast.info(`New Message: ${message.body}`);
      });
    };

    client.onStompError = (error) => {
      console.error('WebSocket STOMP error:', error);
      toast.error('WebSocket STOMP error occurred!');
    };

    client.activate();

    return () => {
      if (client.connected) {
        client.deactivate();
        console.log('WebSocket connection closed');
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider>
          <Themes>
            <LoadScript
              googleMapsApiKey={GOOGLE_MAPS_API_KEY}
              libraries={libraries}
              loadingElement={<div>Loading...</div>}
              defer={true}
            >
              <UserAuthProvider>
                <Outlet />
                {showNotification && <Notification message={message} />}
              </UserAuthProvider>
            </LoadScript>
          </Themes>
        </SettingsProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
