import React, { useState } from "react";
import { Modal, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { ModalButtonCancle, ModalButtonSave } from "app/views/dashboard/profile/StyleComponents"; 
import { ModalBox, ModalTitle, ModalSubtitle, RadioGroupStyle, ModalButtons  } from "./Typography";
const StatusChangeModal = ({ open, onClose, onProceed, radioOptions, proceedText = "Proceed" }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleProceed = () => {
    if (selectedOption) {
      onProceed(selectedOption);
    } else {
      alert("Please select an option.");
    }
  };

  return (
    <Modal open={open} onClose={onClose} CloseIcon>
      <ModalBox>
        
        <ModalTitle>Move to</ModalTitle>
        
        <ModalSubtitle>
          You are about to move the listing to
        </ModalSubtitle>

        <RadioGroup value={selectedOption} onChange={handleRadioChange}>
          <RadioGroupStyle>
            {radioOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroupStyle>
        </RadioGroup>

        <ModalButtons>
          <ModalButtonCancle onClick={onClose}>Cancel</ModalButtonCancle>
          <ModalButtonSave onClick={handleProceed}>
            {proceedText}
          </ModalButtonSave>
        </ModalButtons>
      </ModalBox>
    </Modal>
  );
};

export default StatusChangeModal;
