import React from "react";
import PersonalInfo from "./PersonalInfo";
import ContactDetails from "./ContactDetails";
import UserAddress from "./UserAddress";
import { Box } from "@mui/material";
import { Wrapper } from "../StyleComponents";
const UserInfo = () => {
  return (
    <Wrapper>
      <PersonalInfo />
      <Box sx={{ marginTop: '0.8125rem' }} />
      <ContactDetails />
      <Box sx={{ marginTop: '0.8125rem' }} />
      <UserAddress />
      <Box sx={{ marginBottom: '2rem' }} />
    </Wrapper>
  );
};

export default UserInfo;