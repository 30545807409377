import React, { useState } from 'react'
import { Typography, Box, Button, Grid, IconButton, Modal, Link, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import { notify } from 'app/services/notify';
import { deleteUserLLCInformation } from 'app/contexts/leadActions';
import OrganizationModal from './OrganizationModal';
import ViewOrganizationModal from './ViewOrganizationModal';
import {
    StyledCardBox1, StyledCardBox2, StyledCardTitle,
    StyledCardBox3, StyledEditIcon, OrgStatus, OrgStatusModal,
    OrgDetailsBox1, OrgDetailsBox2, StyledOrgContentKey, StyledOrgContentValue,
    LinkText, OrgCardModalBox2, StyledCrossIcon, InactiveModalTitle, InactiveModalSubTitle,
    InactiveModalButtonBox, InactiveModalCancleBtn, InactiveModalInactiveBtn, StyledDeleteIcon,
    StyledInactiveIcon, StyledListItemText, StyledListItemDeleteText
} from '../StyleComponents';

const OrganizationDetailCard = ({ llcdata, fetchData, id, orgName, designation, projectsAssociated, status }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openeditDetails, setOpeneditDetails] = useState(false);
    const [inactiveModal, setInactiveModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false)
    };

    const handleopeneditModal = () => {
        setOpeneditDetails(true);
    }

    const handleCloseEditModal = () => {
        setOpeneditDetails(false);
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleInactive = () => {
        setInactiveModal(true);
        handleMenuClose();
    };

    const handleCloseInactiveModal = () => {
        setInactiveModal(false);
    }
    const handleMakeInactive = () => {
        console.log("LLC marked as Inactive");
        handleCloseInactiveModal();
    };

    const handleDelete = async () => {
        try {
            if (!id) {
                notify.error("Cannot delete: No item selected.");
                return;
            }
            await deleteUserLLCInformation(id);
            notify.success("Organization deleted successfully!");
            await fetchData();
            handleCloseModal();
        } catch (error) {
            notify.error(`Error deleting organization: ${error.message}`);
        }
    };

    return (
        <>
            <StyledCardBox1>
                <StyledCardBox2 >
                    <StyledCardTitle variant="h6">
                        {llcdata?.llcName}
                    </StyledCardTitle>

                    <OrgStatus
                        sx={{
                            backgroundColor: `${status === "Active" ? '#4caf50' : '#4caf50'}`,
                        }}
                    >
                        {"Active"}
                    </OrgStatus>
                </StyledCardBox2>

                <StyledCardBox3 >
                    <StyledEditIcon
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={handleopeneditModal}
                    >
                        Edit Details
                    </StyledEditIcon>

                    <Modal open={openeditDetails} onClose={handleCloseEditModal}>
                        <OrganizationModal
                            fetchData={fetchData}
                            llcDetails={llcdata}
                            handleCloseModal={handleCloseEditModal} />
                    </Modal >


                    <IconButton size="small" onClick={handleMenuClick}>
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleInactive}>
                            <ListItemIcon>
                                <StyledInactiveIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Inactive" />
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                            <ListItemIcon>
                                <StyledDeleteIcon />
                            </ListItemIcon>
                            <StyledListItemDeleteText primary="Delete" />
                        </MenuItem>
                    </Menu>



                    <Modal
                        open={inactiveModal}
                        onClose={handleCloseInactiveModal}
                    >
                        <OrgStatusModal>
                            <OrgCardModalBox2>
                                <InactiveModalTitle>
                                    Inactive LLC
                                </InactiveModalTitle>
                                <StyledCrossIcon onClick={handleCloseInactiveModal}>
                                    <CloseIcon />
                                </StyledCrossIcon>
                            </OrgCardModalBox2>
                            <InactiveModalSubTitle>
                                You are about to make the LLC inactive.
                            </InactiveModalSubTitle>
                            <InactiveModalButtonBox>
                                <InactiveModalCancleBtn onClick={handleCloseInactiveModal}>
                                    Cancel
                                </InactiveModalCancleBtn>
                                <InactiveModalInactiveBtn onClick={handleMakeInactive}>
                                    Make Inactive
                                </InactiveModalInactiveBtn>
                            </InactiveModalButtonBox>
                        </OrgStatusModal>
                    </Modal>



                </StyledCardBox3>
            </StyledCardBox1>

            <OrgDetailsBox1>
                <OrgDetailsBox2 sx={{ marginBottom: '16px' }}>
                    <Box>
                        <StyledOrgContentKey >
                            Designation:
                        </StyledOrgContentKey>
                        <StyledOrgContentValue>{'Architect'}</StyledOrgContentValue>
                    </Box>

                    <Box sx={{ marginRight: '50%' }}>
                        <StyledOrgContentKey >
                            EIN/License Number:
                        </StyledOrgContentKey>
                        <StyledOrgContentValue>{llcdata?.einNum}</StyledOrgContentValue>
                    </Box>
                </OrgDetailsBox2>

                <Box sx={{ marginBottom: '16px' }}>
                    <StyledOrgContentKey>
                        Address:
                    </StyledOrgContentKey>
                    <StyledOrgContentValue>
                        {llcdata?.address1},{llcdata?.address2 ? llcdata?.address2 + "," : ""}{llcdata?.city},{llcdata?.state},{llcdata?.zipCode},{llcdata?.country}
                    </StyledOrgContentValue>
                </Box>

                <Box sx={{ marginBottom: '16px' }}>
                    <StyledOrgContentKey>
                        Projects Associated:
                    </StyledOrgContentKey>
                    <StyledOrgContentValue>{"-"}</StyledOrgContentValue>
                </Box>
            </OrgDetailsBox1>
            <LinkText
                // onClick={handleOpenModal}
                variant="text"
            >
                View Associated Projects
            </LinkText>




            <Modal open={openModal} onClose={handleCloseModal}>

                <ViewOrganizationModal handleCloseModal={handleCloseModal}
                    orgName={orgName}
                    designation={designation}
                    status={status}
                    llcdata={llcdata} />
            </Modal>
        </>
    )
};

export default OrganizationDetailCard
