import { axiosService } from 'app/services/axiosService';

export const getSession = () => localStorage.getItem('sessionAuth');
export const getAuthToken = () => localStorage.getItem('authToken');

export const setSession = (sessionAuth) => {
  if (!sessionAuth) {
    localStorage.removeItem('sessionAuth');
    return;
  }

  localStorage.setItem('sessionAuth', sessionAuth);
};

export const setSessionFromError = (error) => {
  const session = error?.response?.data?.data?.session;
  setSession(session);
};

export const clearStorages = () => {
  setSession(null);
  setAuthToken(null);
};

export const setAuthToken = (authToken) => {
  if (!authToken) {
    localStorage.removeItem('authToken');
    axiosService.setupAuthToken(null);
    return;
  }
  localStorage.setItem('authToken', authToken);
  axiosService.setupAuthToken(authToken);
};

export const rememberEmail = (email) => {
  localStorage.removeItem('email');
  localStorage.setItem('email', email);
};

export const getRememberedEmail = () => localStorage.getItem('email');
