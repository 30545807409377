import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createProjectRole, updateProjectRole } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { fullAccessPermission } from 'app/utils/constant';

const hasServiceFullAccess = (service, { read: selectedRead, write: selectedWrite }) => {
  const { read, write } = service.actions;

  const totalSelected = selectedRead.length + selectedWrite.length;

  const totalAvailable = Object.keys(read).length + Object.keys(write).length;

  return totalSelected === totalAvailable;
};

const createServicePermission = (permissionId, service) => ({
  resourceId: '*',
  action: permissionId,
  verb:
    service.actions.read[permissionId]?.verbs[0] || service.actions.write[permissionId]?.verbs[0],
});

const buildServicePermissions = (service, permissions) => {
  const { name: serviceName } = service;

  if (hasServiceFullAccess(service, permissions)) {
    return [{ ...fullAccessPermission, resource: serviceName }];
  }

  return [...permissions.read, ...permissions.write].map((permissionId) => ({
    resource: serviceName,
    ...createServicePermission(permissionId, service),
  }));
};

const buildPermissions = (data, allServices) => {
  const normalizedServices = Object.fromEntries(allServices);
  const permissionsEntries = Object.entries(data.permissions);

  const hasFullAccess =
    permissionsEntries.length === Object.keys(normalizedServices).length &&
    permissionsEntries.every(([serviceName, permissions]) =>
      hasServiceFullAccess(normalizedServices[serviceName], permissions),
    );

  if (hasFullAccess) {
    return [fullAccessPermission];
  }

  return permissionsEntries.flatMap(([serviceName, permissions]) =>
    buildServicePermissions(normalizedServices[serviceName], permissions),
  );
};

export function useProjectRolesCreateUpdate(projectId, selectedRole) {
  const queryClient = useQueryClient();

  const isEditingMode = !!selectedRole;

  return useMutation({
    mutationFn: ({ data, allServices }) => {
      const permissions = buildPermissions(data, allServices);

      const payloadData = {
        name: data.name,
        description: data.description,
        permissions,
      };

      return isEditingMode
        ? updateProjectRole(projectId, selectedRole.name, payloadData)
        : createProjectRole(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.roles, projectId],
      }),
  });
}
