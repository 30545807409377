import React, { useState } from 'react';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaleView from './SaleView';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';
import BuyerAddEdit from './BuyerAddEdit';
import { useProjectBuildingUnitSaleDelete } from './hooks/useProjectBuildingUnitSaleDelete';
import { useProject } from 'app/contexts/ProjectContext';

const SaleCard = ({ saleId, unitDetails, selectedBuilding, selectedUnit }) => {
  const [openModal, setOpenModal] = useState(false);

  const { projectId } = useProject();
  const { mutateAsync: deleteSale } = useProjectBuildingUnitSaleDelete(projectId);

  const saleData = unitDetails?.saleDetails;
  const buyerData = unitDetails?.buyerDetails;

  const handleOpenModal = (action) => setOpenModal(action);
  const handleCloseModal = () => setOpenModal(null);

  const handleDelete = async () => await deleteSale(saleId);

  return (
    <div className='sale-card' style={styles.saleCard}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            fontSize='18px'
            justifyContent='space-between'
            color='var(--LeadSaleCard-grid-color)'
            alignItems='center'
          >
            <Typography variant='h6'>
              Sale Space: <strong>{saleData?.saleSpace} Sq fts</strong>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton onClick={() => handleOpenModal('view')}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => handleOpenModal('edit')}>
                <BorderColorOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => handleOpenModal('delete')}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Grid>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'var(--typography-salePrice)',
              fontSize: '14px',
            }}
            variant='body2'
          >
            ₹ {saleData?.salePrice} price
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box style={styles.section} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle2'>CONTACT PERSON</Typography>
          <Typography variant='body2'>
            <strong>{buyerData?.contactPerson}</strong>
          </Typography>
          <Typography variant='body2'>
            {buyerData?.phoneNo} • {buyerData?.email}
          </Typography>
          <Typography variant='body2'>
            {buyerData?.address || 'No Address'} • {buyerData?.city}• {buyerData?.state}
          </Typography>
          <Typography variant='body2'>
            {buyerData?.country || 'No Address'} • {buyerData?.zipCode}
          </Typography>
        </Grid>
      </Grid>
      <SaleView
        open={openModal === 'view'}
        onClose={handleCloseModal}
        saleData={saleData}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
      />
      <BuyerAddEdit
        open={openModal === 'edit'}
        onClose={handleCloseModal}
        saleData={saleData}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        saleId={saleId}
      />
      <LeadDeleteConfirmation
        name={'Sale Space'}
        from={`from building ${selectedBuilding?.buildingName}`}
        open={openModal === 'delete'}
        onClose={handleCloseModal}
        handleSubmit={handleDelete}
      />
    </div>
  );
};

const styles = {
  saleCard: {
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    padding: 16,
    backgroundColor: 'var(--saleCard)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: 16,
    marginTop: 16,
  },
  section: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: 8,
    marginTop: 8,
    width: '100%',
  },
};

export default SaleCard;
