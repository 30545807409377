import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadProjectDocuments } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectDocumentsUpload(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (documents) => {
      const payloadData = new FormData();

      documents.forEach((doc, docIndex) => {
        payloadData.append(`docReq[${docIndex}].file`, doc.file);
        payloadData.append(`docReq[${docIndex}].docName`, doc.docName);
        payloadData.append(`docReq[${docIndex}].type`, doc.type);
      });

      payloadData.append('projectId', projectId);

      return await uploadProjectDocuments(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.documents, projectId],
      }),
  });
}
