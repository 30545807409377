import React from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    InputAdornment,
    FormHelperText,
    FormControl,
    Input,
    styled,
    IconButton
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DeleteIcon, DocIcon, DownloadDragIcon, PdfIcon, } from "app/common/icons";
import { StyledButton, StyledTextField } from "app/common/Typography";
const FileContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    border: '1px solid lightgray',
    backgroundColor: 'var(--fileContainer-bg)',
    borderRadius: '4px',
    marginTop: '10px',
});

const FileContainerDetails = styled('div')({
    maxHeight: '40vh',
    overflowY: 'auto',
});

const FieldLabel = styled('label')({
    fontWeight: '500',
    fontSize: '1rem'
})


function ContractDetails({ formData, setFormData, setDisable }) {
    const validationSchema = Yup.object({
        effectiveDate: Yup.date().required("Effective Date is required"),
        feasibilityDate: Yup.date().required("Feasibility Due Date is required"),
        earnestMoney: Yup.number()
            .required("Earnest Money is required")
            .positive("Must be a positive number"),
        closingDate: Yup.date().required("Estimated Closing Date is required"),
        optionsMoney: Yup.number()
            .required("Options Money is required")
            .positive("Must be a positive number"),
        contractDocument: Yup.mixed()
            .required("A contract document is required")
            .test("fileFormat", "Only PDF files are allowed", (value) => {
                return value && value.type === "application/pdf";
            })
            .test("fileSize", "File size is too large", (value) => {
                return value && value.size <= 10 * 1024 * 1024; // 10MB
            }),
    });

    const initialValues = {
        effectiveDate: formData.effectiveDate,
        feasibilityDate: formData.feasibilityDate,
        earnestMoney: formData.earnestMoney,
        closingDate: formData.closingDate,
        optionsMoney: formData.optionsMoney,
        documents: formData.documents
    };

    React.useEffect(() => {
        const allFieldsFilled = formData.effectiveDate && formData.feasibilityDate &&
            formData.earnestMoney && formData.closingDate &&
            formData.optionsMoney && formData.documents.length > 0;
        const allFieldsTouched = Object.values(formData).every(val => val !== undefined && val !== null);

        if (allFieldsFilled && allFieldsTouched) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [formData, setDisable]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange
                validateOnBlur
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setFormData({ ...formData, ...values });
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
                    const handleFileInput = (event) => {
                        event.preventDefault();
                        console.log(event);

                        const filesAccess = {
                            change: event.target.files,
                            drop: event.dataTransfer?.files,
                        };

                        const newFiles = Array.from(filesAccess[event.type]).map((file) => ({
                            file,
                            docname: '',
                        }));

                        setFieldValue('documents', [...values.documents, ...newFiles]);
                    };

                    const handleDocumentNameChange = (event, index) => {
                        const updatedDocuments = [...values.documents];
                        updatedDocuments[index] = {
                            ...updatedDocuments[index],
                            docname: event.target.value,
                        };

                        setFieldValue('documents', updatedDocuments);
                        setFormData((prevState) => ({
                            ...prevState,
                            documents: updatedDocuments,
                        }));
                    };

                    const handleDeleteFile = (index) => {
                        const updatedDocuments = [...values.documents];
                        updatedDocuments.splice(index, 1);

                        setFieldValue('documents', updatedDocuments);
                    };

                    const handleDragOver = (event) => {
                        event.preventDefault();
                    };

                    return (
                        <Form>
                            <Box p={3}>
                                <Grid container spacing={3} mb={3}>
                                    {/* Left Column */}
                                    <Grid item xs={12} sm={6}>
                                        <FieldLabel>Effective Date</FieldLabel>

                                        <StyledTextField
                                            fullWidth
                                            size='small'
                                            variant='outlined'
                                            // label="Effective Date"
                                            type='date'
                                            name='effectiveDate'
                                            value={values.effectiveDate}
                                            onChange={(e) => {
                                                const date = e.target.value;
                                                setFieldValue("effectiveDate", date);
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    effectiveDate: date,
                                                }));
                                            }}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                                            helperText={touched.effectiveDate && errors.effectiveDate}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FieldLabel>Feasibility Due Date</FieldLabel>

                                        <StyledTextField
                                            fullWidth
                                            size='small'
                                            variant='outlined'
                                            // label="Feasibility Due Date"
                                            type='date'
                                            name='feasibilityDate'
                                            value={values.feasibilityDate}
                                            onChange={(e) => {
                                                const date = e.target.value;
                                                setFieldValue("feasibilityDate", date);
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    feasibilityDate: date,
                                                }));
                                            }}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.feasibilityDate && errors.feasibilityDate)}
                                            helperText={touched.feasibilityDate && errors.feasibilityDate}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FieldLabel>Earnest Money</FieldLabel>
                                        <StyledTextField
                                            type="number"
                                            fullWidth
                                            name="earnestMoney"
                                            value={values.earnestMoney}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    earnestMoney: e.target.value,
                                                }));
                                            }}
                                            onBlur={handleBlur}
                                            error={touched.earnestMoney && Boolean(errors.earnestMoney)}
                                            helperText={touched.earnestMoney && errors.earnestMoney}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FieldLabel>Estimated Closing Date</FieldLabel>
                                        <StyledTextField
                                            fullWidth
                                            size='small'
                                            type='date'
                                            variant='outlined'
                                            // label="Estimated Closing Date"
                                            name='estimatedClosingDate'
                                            value={values.estimatedClosingDate}
                                            onChange={(e) => {
                                                const date = e.target.value;
                                                setFieldValue("closingDate", date);
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    closingDate: date,
                                                }));
                                            }}
                                            onBlur={handleBlur}
                                            error={Boolean(
                                                touched.closingDate && errors.closingDate,
                                            )}
                                            helperText={touched.closingDate && errors.closingDate}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FieldLabel>Options Money</FieldLabel>
                                        <StyledTextField
                                            type="number"
                                            fullWidth
                                            name="optionsMoney"
                                            value={values.optionsMoney}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    optionsMoney: e.target.value,
                                                }));
                                            }}
                                            onBlur={handleBlur}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            error={touched.optionsMoney && Boolean(errors.optionsMoney)}
                                            helperText={touched.optionsMoney && errors.optionsMoney}
                                        />
                                    </Grid>
                                </Grid>

                                <Typography variant="h6" mb={2}>
                                    Contract Document
                                </Typography>

                                <Box
                                    border="2px dashed lightgray"
                                    borderRadius="4px"
                                    p={2}
                                    textAlign="center"
                                    onDragOver={handleDragOver}
                                >
                                    <FormControl
                                        error={touched.documents && Boolean(errors.documents)}
                                        fullWidth
                                    >
                                        <Box
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            borderRadius='4px'
                                            p={2}
                                        >
                                            <Typography variant='subtitle1' color='text.secondary'>
                                                <DownloadDragIcon />
                                                {` Drag and Drop file here or`}
                                            </Typography>
                                            <label htmlFor='outlined-button-file'>
                                                <StyledButton color='primary' variant='text' component='span'>
                                                    Browse files
                                                </StyledButton>
                                            </label>
                                            <Input
                                                id='outlined-button-file'
                                                key={values.documents.length}
                                                sx={{ display: 'none' }}
                                                type='file'
                                                size='small'
                                                name='file'
                                                onChange={handleFileInput}
                                                inputProps={{ accept: '.pdf, .doc, .docx', multiple: true }}
                                            />
                                        </Box>
                                    </FormControl>
                                </Box>
                                <Box sx={{fontWeight:'500',fontSize:'12px'}}>Supported files : .pdf Max file size :10MB</Box>
                                <FileContainerDetails>
                                    {values.documents.map((documentData, index) => (
                                        <FileContainer key={index}>
                                            <Box
                                                display='flex'
                                                justifyContent='space-between'
                                                alignItems='center'
                                                p={1}
                                            >
                                                <Box display='flex' alignItems='center'>
                                                    {documentData.file.type === 'application/pdf' ? (
                                                        <PdfIcon />
                                                    ) : (
                                                        <DocIcon />
                                                    )}
                                                    <Box>
                                                        {documentData.file && (
                                                            <>
                                                                <Typography variant='body1' fontWeight='bold' ml={1}>
                                                                    {documentData.file.name}
                                                                </Typography>
                                                                <Typography variant='caption' ml={1}>
                                                                    {(documentData.file.size / 1024).toFixed(2)} KB
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <IconButton onClick={() => handleDeleteFile(index)}>
                                                    <DeleteIcon style={{ color: 'red' }} />
                                                </IconButton>
                                            </Box>
                                            <StyledTextField
                                                fullWidth
                                                size='small'
                                                variant='standard'
                                                placeholder='Document Name'
                                                name={`documents[${index}].docname`}
                                                value={documentData.docname}
                                                onChange={(e) => handleDocumentNameChange(e, index)}
                                                onBlur={handleBlur}
                                                error={Boolean(
                                                    errors?.documents?.[index]?.docname &&
                                                    touched?.documents?.[index]?.docname,
                                                )}
                                                helperText={
                                                    touched?.documents?.[index]?.docname &&
                                                    errors?.documents?.[index]?.docname
                                                }
                                            />
                                        </FileContainer>
                                    ))}
                                    {touched?.documents && errors?.documents && (
                                        <FormHelperText>
                                            {!Array.isArray(errors.documents) && errors.documents}
                                        </FormHelperText>
                                    )}
                                </FileContainerDetails>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </LocalizationProvider>

    );
}

export default ContractDetails;
