import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { themeColors } from './themeColors';

const Themes = ({ children }) => {
  const activeTheme = createTheme(themeColors.slateDark1);
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Themes;
