import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Link } from '@mui/material';
import GCPaymentAddEdit from './GCPaymentAddEdit';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { dateFormats } from 'app/utils/constant';

const GCPaymentHistory = ({ contractor, payment }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const isBalanceEmpty = payment?.balance === 0;

  const paymentHistory = payment?.paymentHistory;

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '1%' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography>(Invoice: {payment?.paymentId})</Typography>
          <Link
            component='button'
            sx={{
              marginRight: '1%',
              color: isBalanceEmpty ? '#9e9e9e' : '#1e88e5',
              textDecoration: isBalanceEmpty ? 'none' : 'underline',
              cursor: isBalanceEmpty ? 'not-allowed' : 'pointer',
            }}
            onClick={toggleDialog}
          >
            Update Payment
          </Link>
        </Box>
        {paymentHistory.map((paymentHistoryItem) => (
          <Paper
            elevation={3}
            sx={{
              p: 2,
              width: '100%',
              marginBottom: '1%',
            }}
            key={paymentHistoryItem.updatedDate}
          >
            <Typography
              color='#5E6176'
              align='center'
              bgcolor='#EBEAEA'
              fontWeight='500'
              width='155px'
              gutterBottom
            >
              {convertUTCDateToLocal(paymentHistoryItem?.updatedDate, dateFormats.time12.full)}
            </Typography>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  TOTAL AMOUNT
                </Typography>
                <Typography variant='h6'>${paymentHistoryItem?.totalAmount.toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  PAID AMOUNT
                </Typography>
                <Typography variant='h6' color='primary'>
                  ${paymentHistoryItem?.amountPaid.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  BALANCE AMOUNT
                </Typography>
                <Typography variant='h6' color='green'>
                  ${(paymentHistoryItem?.balance).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  PAID BY
                </Typography>
                <Typography variant='h6'>{paymentHistoryItem?.paidBy}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  PAID TO
                </Typography>
                <Typography variant='h6'>{paymentHistoryItem?.paidTo}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  PAYMENT MODE
                </Typography>
                <Typography variant='h6'>{paymentHistoryItem?.paymentType}</Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Box>
      {isOpenDialog && (
        <GCPaymentAddEdit
          contractor={contractor}
          payment={payment}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
};

export default GCPaymentHistory;
