import React, { Fragment, useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Collapse,
  Container,
  TableRow,
  Table,
  Button,
} from '@mui/material';
import { Title } from 'app/common/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GCPaymentHistory from './GCPaymentHistory';
import GCPaymentAddEdit from './GCPaymentAddEdit';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const cellStyle = {
  width: '14%',
  textAlign: 'left',
  padding: '8px',
};

const tableBodyCellStyle = {
  fontWeight: 500,
  fontSize: '14px',
  align: 'left',
  paddingLeft: '0.7%',
};

const GCPaymentsView = ({ contractor }) => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const payments = contractor?.payments;

  return (
    <>
      <SubtitleDivider />
      {payments?.length === 0 ? (
        <>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Payment Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Payment Details.
                </Typography>
                <Button onClick={toggleDialog} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <>
          <Box
            marginLeft={3}
            marginRight={3}
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Title>Payment Details</Title>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Button onClick={toggleDialog}>+ ADD</Button>
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: 3,
              marginRight: 3,
              marginTop: 1,
              marginBottom: 1,
              overflowY: 'auto',
              border: '2px solid #E5E5E7',
              maxHeight: '300px',
              position: 'relative',
            }}
          >
            <Table marginLeft={3} marginRight={3}>
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'var(--tableHead-bg-color)',
                  maxWidth: '100%',
                }}
              >
                <TableRow color='var(--tableHead-row-color)'>
                  <TableCell sx={{ ...cellStyle, width: '15%', paddingLeft: '1.5%' }}>
                    S.NO.
                  </TableCell>
                  <TableCell sx={cellStyle}>TOTAL AMOUNT</TableCell>
                  <TableCell sx={cellStyle}>PAID</TableCell>
                  <TableCell sx={cellStyle}>BALANCE</TableCell>
                  <TableCell sx={cellStyle}>PAID BY</TableCell>
                  <TableCell sx={cellStyle}>PAID TO</TableCell>
                  <TableCell sx={{ ...cellStyle, width: '15%' }}>PAID TYPE</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {payments?.map((payment, index) => (
                  <Fragment key={payment.paymentId}>
                    <TableRow
                      hover
                      key={payment.paymentId}
                      sx={{ cursor: 'pointer', color: 'var(--tableBody-row-color)' }}
                      onClick={() => handleRowClick(payment.paymentId)}
                    >
                      <TableCell sx={{ ...tableBodyCellStyle, paddingLeft: '1%' }}>
                        <div
                          style={{
                            fontWeight: 100,
                            fontSize: '14px',
                            align: 'left',
                            width: '60px',
                            color: 'var(--accordtion-arrow-link)',
                          }}
                        >
                          <span>
                            {openRow === payment.paymentId ? (
                              <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} />
                            ) : (
                              <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                            )}
                          </span>
                          <span style={{ textDecoration: 'underline' }}>
                            {(index + 1).toString().padStart(2, '0')}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell sx={tableBodyCellStyle}>
                        {payment?.paymentHistory?.at(-1)?.totalAmount}
                      </TableCell>
                      <TableCell sx={tableBodyCellStyle}>
                        {payment?.paymentHistory?.at(-1)?.amountPaid}
                      </TableCell>
                      <TableCell sx={tableBodyCellStyle}>
                        {payment?.paymentHistory?.at(-1)?.balance}
                      </TableCell>
                      <TableCell sx={tableBodyCellStyle}>
                        {payment?.paymentHistory?.at(-1)?.paidBy}
                      </TableCell>
                      <TableCell sx={tableBodyCellStyle}>
                        {payment?.paymentHistory?.at(-1)?.paidTo}
                      </TableCell>
                      <TableCell sx={tableBodyCellStyle}>
                        {payment?.paymentHistory?.at(-1)?.paymentType}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                        <Collapse in={openRow === payment?.paymentId} timeOut='auto' unmountOnExit>
                          <Box>
                            <GCPaymentHistory contractor={contractor} payment={payment} />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
      {isOpenDialog && <GCPaymentAddEdit contractor={contractor} closeDialog={toggleDialog} />}
    </>
  );
};

export default GCPaymentsView;
