import { useQuery } from '@tanstack/react-query';
import { getProjectClosingContract } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectClosing(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.closing, projectId],
    queryFn: () => getProjectClosingContract(projectId),
    placeholderData: {
      companyDetails: {},
      lenderDetails: {},
    },
  });
}
