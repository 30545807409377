import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addProjectGCManager,  updateProjectGCManager } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { GCManagerTypes } from 'app/utils/constant';

export const useProjectGCManagerAddUpdate = (contractor, manager, managerKey) => {
  const queryClient = useQueryClient();

  const isEditingMode = !!manager;

  return useMutation({
    mutationFn: async (data) => {
      const payloadData = {
        ...data,
        type: GCManagerTypes[managerKey],
      };

      return isEditingMode
        ? await updateProjectGCManager(contractor.projectId, contractor.id, {
            contactId: manager?.contactId,
            ...payloadData,
          })
        : await addProjectGCManager(contractor.projectId, contractor.id, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.generalContractor, contractor.projectId],
      }),
  });
};
