import { useQuery } from '@tanstack/react-query';
import { getProjectExpensesByInvoice } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export const useProjectExpenseInvoice = (projectId, invoiceNo, expenseId) => {
  return useQuery({
    queryKey: [...queryKeys.project.expenses, projectId, invoiceNo, expenseId],
    queryFn: () => getProjectExpensesByInvoice(projectId, invoiceNo, expenseId),
    placeholderData: [],
  });
};
