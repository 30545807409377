import { getProjectDetails } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { useQuery } from '@tanstack/react-query';

export function useProjectBasic(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.basic, projectId],
    queryFn: () => getProjectDetails(projectId),
    placeholderData: {},
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
