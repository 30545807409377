import React from 'react';
import { Typography, Box, Button, Grid, IconButton, Modal, Link, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { StyledBox1, StyledBox2, ViewOrgTitle, OrgStatus, StyledCrossIcon, VieOrgDetailsBox, VieOrgDetailsTitle, StyledEinNum, VieOrgDetailsTitle1, StyledOrgSubTitle, StyledOrgSubHeading, AssProjectStatus, StyledSubTitleDesig, ViewDetailsLink } from '../StyleComponents';
const ViewOrganizationModal = ({ handleCloseModal, orgName, designation, status, llcdata }) => {
    return (
        <>
            <StyledBox1>
                <StyledBox2 >
                    <ViewOrgTitle>Associated Projects</ViewOrgTitle>
                    <StyledCrossIcon onClick={handleCloseModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <VieOrgDetailsBox>
                    <VieOrgDetailsTitle>
                        {orgName}
                    </VieOrgDetailsTitle>
                    <OrgStatus
                        sx={{
                            backgroundColor: status === "Active" ? "#4caf50" : "#71A4B8",
                        }}
                    >
                        {"Inactive"}
                    </OrgStatus>
                </VieOrgDetailsBox>

                <VieOrgDetailsTitle1>
                    <StyledEinNum>
                        {llcdata?.einNum}
                    </StyledEinNum>
                    <StyledOrgSubTitle>
                        Garden Row (multi-building complex)
                    </StyledOrgSubTitle>
                    <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
                        <Grid item xs={6}>
                            <StyledOrgSubHeading>
                                Status
                            </StyledOrgSubHeading>
                            <AssProjectStatus>
                                New Project
                            </AssProjectStatus>
                        </Grid>

                        <Grid item xs={6}>
                            <StyledOrgSubHeading>
                                Designation
                            </StyledOrgSubHeading>
                            <StyledSubTitleDesig>
                                {"General Partner"}
                            </StyledSubTitleDesig>
                        </Grid>
                    </Grid>

                    <ViewDetailsLink
                        href="#">
                        View Details
                    </ViewDetailsLink>
                </VieOrgDetailsTitle1>
            </StyledBox1>
        </>
    )
}

export default ViewOrganizationModal
