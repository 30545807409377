import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { notify } from 'app/services/notify';
import { requestsTypes } from './constant';
import { dateFormats } from './constant';

dayjs.extend(utc);

export const parseAddressComponents = (place) => {
  let address1 = '';
  let address2 = '';
  let country = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let latitude = null;
  let longitude = null;

  if (place.address_components) {
    const {
      address_components,
      geometry: { location },
      name,
    } = place;
    const { lat, lng } = location;
    latitude = lat();
    longitude = lng();

    address_components.forEach((component) => {
      const [type] = component.types;
      const longName = component.long_name;

      switch (type) {
        case 'landmark':
        case 'subpremise':
        case 'premise':
          address2 += address2 ? `, ${longName}` : longName;
          break;
        case 'route':
        case 'street_address':
        case 'street_number':
          address1 += address1 ? `, ${longName}` : longName;
          break;
        case 'country':
          country = longName;
          break;
        case 'locality':
        case 'postal_town':
        case 'administrative_area_level_3':
          city ||= longName;
          break;
        case 'administrative_area_level_1':
          state = longName;
          break;
        case 'postal_code':
          zipCode = longName;
          break;
        default:
          break;
      }
    });

    if (!address1 && name) {
      address1 = name;
    }
  }
  return {
    address1,
    address2,
    country,
    city,
    state,
    zipCode,
    latitude,
    longitude,
  };
};

export function handleAxiosError(error) {
  let errorMessage;
  console.error("record error comes",error);
  if (error?.response?.data?.message && error.response.data.message.includes("Record not found for given projectId")) {
    
    console.error("record error comes",error);
    return;
  }

  switch (true) {
    case !!error.response: {
      const { status, data } = error.response;
      errorMessage = `Error ${status}: ${
        data?.message || data?.errors?.[0]?.errorMessage || 'Something went wrong on the server.'
      }`;
      break;
    }
    case !!error.request:
      errorMessage = 'No response from the server. Please check your internet connection.';
      break;
    default:
      errorMessage = `Request Error: ${error.message}`;
      break;
  }

  // console.error(error);
  notify.error(errorMessage);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatDecimal = (value, decimalPlaces) => {
  const floatValue = parseFloat(value);

  if (isNaN(floatValue) || !value) {
    return '';
  }

  const [integer, decimal] = floatValue.toString().split('.');

  if (decimal) {
    return `${integer}.${decimal.slice(0, decimalPlaces)}`;
  }

  return integer;
};

export const formatDateToLocalISOString = (date) => {
  return date
    ? dayjs(date).startOf('day').add(dayjs(date).utcOffset(), 'minute').toISOString()
    : null;
};

export const convertUTCDateToLocal = (date, format = dateFormats.full) => {
  const localDate = dayjs.utc(date).local().toDate();

  const formattedDate = dayjs(localDate).format(format);
  return formattedDate;
};

export const convertDayJSDateToUTC = (date) => {
  if (!date) return null; 
  return dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};


export const calculateTomorrow = (date) => {
  const tomorrow = dayjs(date).add(1, 'day');

  return tomorrow;
};

export const formatCurrency = (amount) => {
  const num = parseFloat(amount); // Convert to number
  if (isNaN(num)) return '0.00'; // Handle invalid numbers gracefully
  return num.toFixed(2); // Ensure two decimal places
};

export const checkIsInvestmentRequest = (invitationType) =>
  invitationType === requestsTypes.INVESTMENT_MAIL ||
  invitationType === requestsTypes.PAYMENT_REQUEST_MAIL;

export const calculateFileKBSize = (content) => {
  const decodedContent = atob(content);
  const sizeInBytes = decodedContent.length;
  const sizeInKB = (sizeInBytes / 1024).toFixed(2);

  return `${sizeInKB} KB`;
};

export const base64ToFile = (base64String, fileName, mimeType) => {
  const byteString = atob(base64String);
  const byteNumbers = new Array(byteString.length)
    .fill(null)
    .map((_, i) => byteString.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);

  return new File([byteArray], fileName, { type: mimeType });
};

export const getAddressString = (location) => {
  if (!location) return '';
  return [
      location.address1,
      location.address2,
      location.city,
      location.state,
      location.country,
      location.zipCode,
  ]
      .filter(Boolean)
      .join(', ');
};

export const convertDateArrayToFormattedString = (dateArray) => {
  if (!Array.isArray(dateArray) || dateArray.length < 3) {
    return null;
  }

  const [year, month, day, hour = 0, minute = 0] = dateArray;
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute));
  return dayjs(date);
};
