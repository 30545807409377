import React, { useEffect, useRef } from 'react';
import { Grid, TextField } from '@mui/material';
import { CustomLabel, H1, Paragraph, StyledTextField } from 'app/common/Typography';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';

const validationSchema = Yup.object().shape({
  llcName: Yup.string().required('LLC Name is required'),
  einNum: Yup.string().required('EIN Number is required'),
  address1: Yup.string().required('Address1 is required'),
  country: Yup.string().required('country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  zipCode: Yup.string().required('Zip Code is required'),
});

const LlcDetails = ({ formData, setFormData, setDisable }) => {
  const searchInputRef = useRef(null);

  useEffect(() => {
    const filteredFormData = Object.keys(formData).reduce((acc, key) => {
      if (key !== 'latitude' && key !== 'longitude' && key !== 'address2') {
        acc[key] = formData[key];
      }
      return acc;
    }, {});
    const isEmpty = Object.values(filteredFormData).some((value) => value === '');
    setDisable(isEmpty);
  }, [formData, setDisable]);

  const initialValues = {
    llcName: formData.llcName,
    einNum: formData.einNum,
    address1: formData.address1,
    address2: formData.address2,
    country: formData.country,
    state: formData.state,
    city: formData.city,
    zipCode: formData.zipCode,
    latitude: formData.latitude,
    longitude: formData.longitude,
  };

  const handleChanged = () => {
    const [place] = searchInputRef.current.getPlaces();

    const addressData = parseAddressComponents(place);

    setFormData((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  return (
    <div className='lead'>
      <Paragraph>The details will not be shared with outside people</Paragraph>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setFormData({ ...formData, location: values }); // BUG: not triggered because submit button is outside of formik
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <CustomLabel>LLC Name</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  placeholder='e.g. Executive'
                  name='llcName'
                  value={values.llcName}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                    handleChange(e);
                  }}
                  error={touched.llcName && errors.llcName}
                  helperText={touched.llcName && errors.llcName}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <CustomLabel>EIN Number</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  placeholder='e.g. 123456789'
                  name='einNum'
                  value={values.einNum}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  error={touched.einNum && errors.einNum}
                  helperText={touched.einNum && errors.einNum}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>Address1</CustomLabel>
                <StandaloneSearchBox
                  onLoad={(ref) => (searchInputRef.current = ref)}
                  onUnmount={() => (searchInputRef.current = null)}
                  onPlacesChanged={handleChanged}
                >
                  <StyledTextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='search'
                    placeholder='Search address'
                    id='standard-basic'
                    name='address1'
                    value={values.address1}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) {
                        setFormData((prevState) => ({
                          ...prevState,
                          address1: '',
                          address2: '',
                          country: '',
                          city: '',
                          state: '',
                          zipCode: '',
                          latitude: null,
                          longitude: null,
                        }));
                      }
                      handleChange(e);
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                    error={touched.address1 && errors.address1}
                    helperText={touched.address1 && errors.address1}
                  />
                </StandaloneSearchBox>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>Address2(optional)</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  placeholder='e.g. Road No#7'
                  name='address2'
                  value={values.address2}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>City</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  placeholder='city'
                  type='text'
                  name='city'
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  error={touched.city && errors.city}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>State</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  placeholder='e.g. Texas'
                  name='state'
                  value={values.state}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  error={touched.state && errors.state}
                  helperText={touched.state && errors.state}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>Zip Code</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  placeholder='zip code'
                  name='zipCode'
                  value={values.zipCode}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  error={touched.zipCode && errors.zipCode}
                  helperText={touched.zipCode && errors.zipCode}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomLabel>Country</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  placeholder='country'
                  name='country'
                  value={values.country}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  error={touched.country && errors.country}
                  helperText={touched.country && errors.country}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LlcDetails;
