import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProjectMemberRole } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectMemberUpdateRole(projectId, selectedMember) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (memberData) =>
      updateProjectMemberRole(projectId, memberData, selectedMember.email),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.members, projectId],
      }),
  });
}
