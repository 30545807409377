import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Modal, styled, Typography } from '@mui/material';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectRoleDelete } from './hooks/useProjectRoleDelete';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const RoleDeleteConfirmation = ({ role, closeModal }) => {
  const {
    basic: { projectTitle },
    projectId,
  } = useProject();
  const { mutate: deleteRole, isPending: isDeletingRole } = useProjectRoleDelete(projectId);

  const handleYesProceed = () => {
    deleteRole(role.name);
    closeModal();
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '32%',
        }}
      >
        <SubtitleDivider />
        <Box
          sx={{
            py: 1,
            px: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Title>Role Delete</Title>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            marginLeft: '4px',
          }}
        >
          <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
            <Typography style={{ fontSize: '16px', fontWeight: '450' }}>
              Are you sure want to delete role {role?.name} from the {projectTitle}?
            </Typography>
          </Box>

          <ModalDivider />
          <Box
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              gap: 2,
              p: 2,
            }}
          >
            <LoadingButton color='error' variant='outlined' onClick={closeModal}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={handleYesProceed}
              loading={isDeletingRole}
            >
              Yes Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RoleDeleteConfirmation;
