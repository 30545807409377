import React, { useState } from 'react';
import { Box, CardContent, Modal, Grid, CircularProgress } from '@mui/material';
import PersonalInfoModal from './PersonalInfoModal';
import EditIcon from '@mui/icons-material/Edit';
import {
  StyledCard,
  StyledBox,
  DataGrid1,
  AvatarImg,
  StyledTitle,
  ProfileValue,
  ProfileKey,
  StyledEditIcon,
  DataBox2,
  StyleAddOrgButton,
  StyledDivider,
  StyledIcon,
  LoadingCircle,
  TextBox,
  NoOrgTitle,
  DataBox,
} from '../StyleComponents';
import { useUserAuth } from 'app/hooks/userUserAuth';

const PersonalInfo = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();
  console.log('user', user);
  const formatDate = (dob) => {
    if (!dob) return ""; // Handle cases where DOB is not available
    const date = new Date(dob);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <StyledCard variant='outlined'>
        <CardContent>
          <StyledBox>
            <StyledTitle>Personal Info</StyledTitle>
            {user ? (
              <StyledEditIcon variant='outlined' onClick={handleOpenModal} startIcon={<EditIcon />}>
                Edit Personal Info
              </StyledEditIcon>
            ) : (
              <StyleAddOrgButton onClick={handleOpenModal} startIcon={<StyledIcon />}>
                Personal Info
              </StyleAddOrgButton>
            )}
          </StyledBox>
          <StyledDivider />
          <Box>
            {loading ? (
              <LoadingCircle>
                <CircularProgress />
              </LoadingCircle>
            ) : user && user?.name || user?.surname || user?.dob || user?.ssn ? (
              <DataGrid1>
                <Grid item xs={3}>
                  <AvatarImg
                    alt={`${user?.name} ${user?.surname}`}
                    src={
                      user?.profilePictureUrl ||
                      'https://static.vecteezy.com/system/resources/previews/024/183/502/original/male-avatar-portrait-of-a-young-man-with-a-beard-illustration-of-male-character-in-modern-color-style-vector.jpg'
                    }
                  />
                </Grid>

                <Grid item xs={9}>
                  <DataBox>
                    <ProfileKey>Full Name</ProfileKey>
                    <ProfileValue>{`${user?.name} ${user?.surname}`}</ProfileValue>
                  </DataBox>

                  <DataBox2>
                    <Box>
                      <ProfileKey>Date of Birth</ProfileKey>
                      <ProfileValue>{formatDate(user?.dob)}</ProfileValue>
                    </Box>

                    <Box>
                      <ProfileKey>Social Security Number</ProfileKey>
                      <ProfileValue>{user.ssn && `xxx-xx-${user.ssn.slice(-4)}`}</ProfileValue>
                    </Box>
                  </DataBox2>
                </Grid>
              </DataGrid1>
            ) : (
              <TextBox>
                <NoOrgTitle>No details available</NoOrgTitle>
              </TextBox>
            )}
          </Box>
        </CardContent>
      </StyledCard>

      <Modal open={openModal} onClose={handleCloseModal}>
        <PersonalInfoModal handleCloseModal={handleCloseModal} openModal={openModal} />
      </Modal>
    </>
  );
};

export default PersonalInfo;
