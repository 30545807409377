import React from "react";
import {
    Box,
    TextField,
    Typography,
    Grid,
    Paper,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/system";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const HeaderPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#E3F2FD",
    borderLeft: "4px solid #1565C0",
    marginBottom: theme.spacing(3),
    maxWidth: 500,
}));


const StyledTextField = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2),
    "& .MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-20px",
    },
    "&:last-of-type": {
        marginRight: 0,
    },
}));

const FieldLabel = styled('label')({
    fontWeight: '500',
    fontSize: '1rem'
})

const validationSchema = Yup.object({
    estimatedTotalInvestment: Yup.number().required("Total investment is required").positive().integer(),
    landCost: Yup.number().required("Land cost is required").positive().integer(),
    softCost: Yup.number().typeError("Must be a number").required("Required"),
    totalShares: Yup.number().required("Total share is required").positive().integer(),
    eachShareValue: Yup.number().required("Each share price is required").positive().integer(),
});

const InvestMentDetails = ({ formData, setFormData, setDisable }) => {
    const initialValues = {
        estimatedTotalInvestment: formData.estimatedTotalInvestment || 0,
        landCost: formData.landCost || '',
        softCost: formData.softCost || '',
        totalShares: formData.totalShares || '',
        eachShareValue: formData.eachShareValue || '',
    };

    const calculateTotalShares = (totalInvestment, sharePrice) => {
        return sharePrice > 0 ? totalInvestment / sharePrice : 0;
    };

    const calculateTotalInvestment = (landCost, softCost) => {
        return parseFloat(landCost || 0) + parseFloat(softCost || 0);
    };

    const updateFormData = (key, value) => {
        if (!isNaN(value) && value >= 0) {
            const updatedFormData = { ...formData, [key]: value };
            const totalInvestment = calculateTotalInvestment(
                updatedFormData.landCost,
                updatedFormData.softCost
            );
            const totalShares = calculateTotalShares(
                totalInvestment,
                updatedFormData.eachShareValue
            );

            setFormData({
                ...updatedFormData,
                estimatedTotalInvestment: totalInvestment,
                totalShares,
            });
        }
    };

    React.useEffect(() => {
        const allFieldsFilled =
            formData.estimatedTotalInvestment &&
            formData.landCost &&
            formData.softCost &&
            formData.totalShares &&
            formData.eachShareValue;
        const allFieldsTouched = Object.values(formData).every(
            (val) => val !== undefined && val !== null
        );

        setDisable(!(allFieldsFilled && allFieldsTouched));
    }, [formData, setDisable]);

    return (
        <>
            <HeaderPaper elevation={1}>
                <InfoOutlinedIcon color="info" sx={{ marginRight: 1 }} />
                <Typography sx={{ color: "#1565C0", fontWeight: "bold" }}>
                    Total Investment = Land Cost + Soft Cost
                </Typography>
            </HeaderPaper>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setFormData({ ...formData, ...values });
                }}
            >
                {({ handleChange, handleBlur, values, errors, touched }) => (
                    <Form>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <Box>
                                    <FieldLabel>Estimated Total Investment</FieldLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        name="estimatedTotalInvestment"
                                        value={formData.estimatedTotalInvestment}
                                        InputProps={{
                                            readOnly: true,
                                            style: {
                                                backgroundColor: "#f5f5f5",
                                                color: "#17181B",
                                                cursor: "not-allowed",
                                            },
                                        }}
                                        error={Boolean(
                                            touched.estimatedTotalInvestment &&
                                            errors.estimatedTotalInvestment
                                        )}
                                        helperText={
                                            touched.estimatedTotalInvestment &&
                                            errors.estimatedTotalInvestment
                                        }
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box>
                                    <FieldLabel>Land Cost</FieldLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        name="landCost"
                                        value={values.landCost}
                                        onChange={(e) => {
                                            const inputValue = parseFloat(e.target.value);
                                            updateFormData("landCost", inputValue);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.landCost && errors.landCost)}
                                        helperText={touched.landCost && errors.landCost}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h6" align="center" sx={{ marginTop: '2rem' }}>
                                    +
                                </Typography>
                            </Grid>

                            <Grid item xs={3}>
                                <Box>
                                    <FieldLabel>Soft Cost</FieldLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        name="softCost"
                                        value={values.softCost}
                                        onChange={(e) => {
                                            const inputValue = parseFloat(e.target.value);
                                            updateFormData("softCost", inputValue);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.softCost && errors.softCost)}
                                        helperText={touched.softCost && errors.softCost}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box>
                            <Typography variant="h6" sx={{ marginBottom: '2%', marginTop: '2%', fontSize: '24px', fontWeight: '600' }}>
                                Estimated Shares
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>

                                    <FieldLabel>Total Shares</FieldLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        name="totalShares"
                                        value={formData.totalShares}
                                        InputProps={{
                                            readOnly: true,
                                            style: {
                                                backgroundColor: "#f5f5f5",
                                                color: "#17181B",
                                                cursor: "not-allowed",
                                            },
                                        }}
                                        error={Boolean(touched.totalShares && errors.totalShares)}
                                        helperText={touched.totalShares && errors.totalShares}
                                    />
                                </Grid>

                                {/* Each Share Value */}
                                <Grid item xs={6}>
                                    <FieldLabel>Each Share Value</FieldLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        name="eachShareValue"
                                        value={values.eachShareValue}
                                        onChange={(e) => {
                                            const inputValue = parseFloat(e.target.value);
                                            updateFormData("eachShareValue", inputValue);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                            touched.eachShareValue && errors.eachShareValue
                                        )}
                                        helperText={touched.eachShareValue && errors.eachShareValue}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik >
        </>
    );
};

export default InvestMentDetails;
