import { Box, Button, Grid, styled, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const StyledBox = styled(Box)(({ texttransformstyle, ellipsis }) => ({
  textTransform: texttransformstyle || 'none',
  whiteSpace: ellipsis ? 'nowrap' : 'normal',
  overflow: ellipsis ? 'hidden' : '',
  textOverflow: ellipsis ? 'ellipsis' : '',
}));

export const CenteredText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const Title = styled('h1')(() => ({
  fontSize: '20px',
  fontWeight: '600',
  color: 'var(--Title-color)',
  textTransform: 'capitalize',
}));
export const H1 = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      component='h1'
      mb={0}
      mt={0}
      fontSize='28px'
      fontWeight='500'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Paragraph = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='p'
      mb={0}
      mt={0}
      fontSize='14px'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Small = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='small'
      fontSize='12px'
      fontWeight='500'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Span = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='span'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const CustomGCExpensesInputText = ({ placeholder, error, touched, ...props }) => {
  return (
    <>
      <input
        type='text'
        placeholder={placeholder}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '0px',
          outline: 'none',
          width: '100%',
          backgroundColor: '#F8F8F8',
        }}
        {...props}
      />
      {error && (
        <Typography color='error' variant='body2'>
          {props.helperText}
        </Typography>
      )}
    </>
  );
};

export const CustomInputText = ({ placeholder, ...props }) => {
  return (
    <>
      <input
        type='text'
        placeholder={placeholder}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '0px',
          outline: 'none',
          width: '100%',
          backgroundColor: '#F8F8F8',
        }}
        {...props}
      />
    </>
  );
};

export const CustomTextInput = ({ placeholder, ...props }) => {
  return (
    <TextField
      type='text'
      placeholder={placeholder}
      sx={{
        '& .MuiInputBase-root': {
          height: '40px',
        },
      }}
      {...props}
    />
  );
};

export const customNumberInput = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  width: '31vw',
  marginTop: '10px',
  border: '2px dashed lightgray',
});
export const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

export const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
});

export const FileContainer = styled('div')({
  alignItems: 'center',
  // padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
  marginLeft: '20px',
});
export const FileContainerDetails = styled('div')({
  maxHeight: '20vh',
  overflowY: 'auto',
  width: '100%',
});

export const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // marginLeft: '8px',
  flexGrow: 1,
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D9D9D9',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: '#0277BD',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0277BD',
    },
    '&.Mui-error fieldset': {
      borderColor: '#F61410',
    },
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-input:not(:placeholder-shown)': {
    borderColor: '#0277BD',
  },
}));

export const CustomLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  color: theme.palette.text.textfield,
  marginBottom: '8px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}));

export const ContainerHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
}));

export const Header = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '38px',
}));


export const StyleAddOrgButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.text.lavendorblue,
  color: theme.palette.text.white,
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "0.875rem",
  fontWeight: "500",
  cursor: 'pointer',
  padding:'8px 1rem 8px 1rem',
  "&:hover": {
      backgroundColor: theme.palette.text.lavendorblue,
  },
  [theme.breakpoints.down('sm')]: {
      width: '100%',
  },
}));

export const StyledIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.text.white,
  fontSize: "1.25rem",
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  letterSpacing: "-1%",
  color: theme.palette.text.title,
  marginLeft: "0.5rem",
  [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
      marginLeft: "1%",
  },
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: '100%',  // Ensures the divider spans the full width of its parent container
  borderTop: '1px solid #D3D3D3',  // Light grey border
  margin: '20px 0',  // Space before and after the divider
}));

export const ModalBox = styled('div')({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  width: "35%",
  borderRadius: "8px",
  boxShadow: 24,
});

export const ModalTitle = styled('h2')({
  fontWeight: "bold",
  textAlign: "left",
  margin: 0,
});

export const ModalSubtitle = styled('p')({
  textAlign: "left",
  margin: "10px 0",
});

export const RadioGroupStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',  // Arrange radio buttons vertically (columnwise)
  margin: "20px 0",
});

export const ModalButtons = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  gap: 10
});

export const CloseIcon = styled('span')({
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
});