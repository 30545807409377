import { useQuery } from '@tanstack/react-query';
import { fetchCountsOfProjects } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

const initialCounts = {
  lead: 0,
  newproject: 0,
  ongoing: 0,
  onhold: 0,
  cancelled: 0,
  completed: 0,
};

export function useProjectsCounts() {
  return useQuery({
    queryKey: queryKeys.projectsCounts,
    queryFn: fetchCountsOfProjects,
    staleTime: Infinity,
    gcTime: Infinity,
    placeholderData: initialCounts,
    select: (data) => ({
      ...initialCounts,
      ...data,
    }),
  });
}
