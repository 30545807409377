import React, { useMemo, useState } from 'react';
import { Box, Grid, Button, styled, Card, IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CLLoanWithdrawals from './CLLoanWithdrawals';
import CLLoanPayments from './CLLoanPayments';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConstructionLenderAddEdit from './ConstructionLenderAddEdit';
import { DownloadIcon } from 'app/common/icons';
import { documentTypes } from 'app/utils/constant';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { useProject } from 'app/contexts/ProjectContext';

const Title = styled('span')({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '120px',
  marginTop: '-1px',
  rowGap: '20px',
  borderRadius: '5px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'var(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const ConstructionLenderView = ({ lender, handleBack }) => {
  const { projectId } = useProject();
  const { data: documents } = useProjectDocuments(projectId);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const lenderDocuments = useMemo(
    () =>
      documents.filter((doc) => doc.metadata.documentType === documentTypes.CONSTRUCTION_LENDER),
    [documents],
  );

  const contract = [
    { key: 'LENDER NAME', value: lender?.lenderName },
    { key: 'EMAIL ADDRESS', value: lender?.lenderEmail },
    { key: 'PHONE NUMBER', value: lender?.lenderPhone },
    {
      key: 'ADDRESS',
      value: [
        [lender?.lenderAddress, lender?.lenderCity].filter(Boolean).join(', '),
        [lender?.lenderState, lender?.lenderZipCode, lender?.lenderCountry]
          .filter(Boolean)
          .join(', '),
      ]
        .filter(Boolean)
        .join('\n'),
    },
  ];

  const contactPerson = [
    { key: 'LENDER CONTACT NAME', value: lender?.lenderContactPerson?.lenderContactPersonName },
    { key: 'EMAIL ADDRESS', value: lender?.lenderContactPerson?.lenderContactPersonEmail },
    { key: 'PHONE NUMBER', value: lender?.lenderContactPerson?.lenderContactPersonPhone },
  ];

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  const handleInvoiceDownload = (initialDocuments, clDocs) => {
    try {
      initialDocuments.forEach((doc) => {
        if (clDocs?.loanDocuments?.includes(doc.metadata.fileId)) {
          handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <IconButton style={{ margin: '1%' }} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Box marginLeft={3} marginRight={3}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Title>Contruction Lender Details</Title>
              <Button
                style={{ color: 'var(--editButton)', fontSize: '14px' }}
                onClick={toggleDialog}
              >
                <BorderColorIcon sx={{ fontSize: '13.5px' }} />
                Edit
              </Button>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <InfoContainer>
                {contract?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Box marginLeft={3} marginRight={3} marginTop={5}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Title>Lender Contact Person Details</Title>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <InfoContainer>
                {contactPerson?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Box marginLeft={3} marginRight={3} marginTop={5}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Title>Loan Details</Title>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Key>LOAN APPROVED AMOUNT</Key>
                  <Value>{lender?.loanDetails?.loanApprovedAmount}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>LOAN WITHDRAWAL AMOUNT</Key>
                  <Value>{lender?.loanDetails?.loanWithdrawalAmount}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>INTEREST RATE</Key>
                  <Value>{lender?.loanDetails?.interestRate}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>LOAN TYPE</Key>
                  <Value>{lender?.loanDetails?.loanType}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>LOAN TERM</Key>
                  <Value>{lender?.loanDetails?.loanTerm}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>LOAN START DATE</Key>
                  <Value>{convertUTCDateToLocal(lender?.loanDetails?.loanStartDate)}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>LOAN DOCUMENT</Key>
                  <Value sx={{ display: 'flex' }}>
                    {lenderDocuments
                      ?.filter((doc) =>
                        lender?.loanDetails?.loanDocuments?.includes(doc.metadata.fileId),
                      )
                      .map((doc) => (
                        <div key={doc.metadata.fileId}>{doc.metadata.documentName}</div>
                      ))}
                    <IconButton
                      onClick={() => handleInvoiceDownload(lenderDocuments, lender?.loanDetails)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>COMMENTS</Key>
                  <Value>{lender?.comments}</Value>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Card
          elevation={3}
          sx={{ mt: '2vh', backgroundColor: 'var(--ConstructionLenderDetails-card-bg)' }}
        >
          <CLLoanPayments lender={lender} />
        </Card>
        <Card
          elevation={3}
          sx={{ mt: '2vh', backgroundColor: 'var(--ConstructionLenderDetails-card-bg)' }}
        >
          <CLLoanWithdrawals lender={lender} />
        </Card>
      </div>
      {isOpenDialog && (
        <ConstructionLenderAddEdit
          lender={lender}
          lenderDocuments={lenderDocuments}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
};

export default ConstructionLenderView;
