import { useUserAuth } from 'app/hooks/userUserAuth';
import { notify } from 'app/services/notify';
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const { userLogout, expirationTokenTime, getAuthToken } = useUserAuth();
  const { pathname } = useLocation();
  const isAuthenticated = !!getAuthToken();
  const isTokenExpired = expirationTokenTime ? Date.now() > expirationTokenTime * 1000 : false;

  if (isTokenExpired) {
    userLogout();
    notify.error('Your session has expired. Please login again.');
  }

  return isTokenExpired || !isAuthenticated ? (
    <Navigate to={`/signin`} state={{ redirect: pathname }} />
  ) : (
    children
  );
};

export default AuthGuard;
