import { useQuery } from '@tanstack/react-query';
import { getProjectCLAll } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectCL(projectID) {
  return useQuery({
    queryKey: [...queryKeys.project.constructionLender, projectID],
    queryFn: () => getProjectCLAll(projectID),
    placeholderData: [],
  });
}
