import { useQuery } from '@tanstack/react-query';
import { getProjectBuildingUnitLeases } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectBuildingUnitLeases(projectId, unitId) {
  return useQuery({
    queryKey: [...queryKeys.project.buildingUnitLeases, projectId, unitId],
    queryFn: () => getProjectBuildingUnitLeases(projectId, unitId),
    placeholderData: [],
    enabled: !!unitId,
  });
}
