import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProjectBuildingUnitSale } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectBuildingUnitSaleDelete(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (saleId) => deleteProjectBuildingUnitSale(projectId, saleId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.buildingUnitSales, projectId],
      }),
  });
}
