import { axiosService } from 'app/services/axiosService';
import { API_ENDPOINTS } from './apiConstants';

export const login = async (email) => {
  const response = await axiosService.Post(API_ENDPOINTS.AUTH.LOGIN, { email });
  return response.data.data.session;
};

export const register = async (userData) =>
  await axiosService.Post(API_ENDPOINTS.REGISTRATION.REGISTER, userData);

export const sendOTPWithEmailAndSession = async (data) => {
  const response = await axiosService.Post(API_ENDPOINTS.AUTH.SEND_OTP, data);
  return response;
};

export const sendConfirmationOTPWithEmail = async (data) =>
  await axiosService.Post(API_ENDPOINTS.REGISTRATION.CONFIRM_REGISTRATION, data);

export const resendConfirmationOTP = async (email) =>
  await axiosService.Post(API_ENDPOINTS.REGISTRATION.RESEND_CONFIRMATION_CODE, { email });

export const getProfileDetails = async () => {
  const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_PROFILE_DETAILS);
  return response.data.data;
};

export const editProfileDetails = async (userNameJWT, profileDetails) =>
  await axiosService.Put(API_ENDPOINTS.USERS.EDIT_PROFILE_DETAILS(userNameJWT), profileDetails);

export const getProfileImage = async () => {
  const response = await axiosService.Get(API_ENDPOINTS.DOCUMENTS.GET_PROFILE_IMAGE);
  return response.data.data;
};

export const uploadProfileImage = async (obj) =>
  await axiosService.Post(API_ENDPOINTS.DOCUMENTS.UPLOAD_PROFILE_IMAGE, obj, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getProfileLLC = async (userId) => {
  const response = await axiosService.Get(API_ENDPOINTS.USERLLC.GET_USER_LLC_DETAILS(userId));
  return response.data.data;
};

export const saveProfileLLC = async (userId, obj) =>
  await axiosService.Post(API_ENDPOINTS.USERLLC.CREATE_UPDATE_USER_LLC(userId), obj);

export const deleteProfileLLC = async (id) =>
  await axiosService.Delete(API_ENDPOINTS.USERLLC.DELETE_USER_LLC(id));

export const getServices = async () => {
  const response = await axiosService.Get(API_ENDPOINTS.SERVICES.GET_SERVICES);
  return response.data;
};
