import Box from '@mui/material/Box';
import InvestmentsDiagram from './InvestmentsDiagram';
import EditInvestments from './EditInvestments';
import { useState } from 'react';
import { useProject } from 'app/contexts/ProjectContext';
import UserInvestments from './UserInvestments';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { investorRoles } from 'app/utils/constant';
import { useProjectInvestments } from './hooks/useProjectInvestments';
import { useProjectMembers } from '../Members/hooks/useProjectMembers';


const MyInvestmentsSection = () => {
  const { user } = useUserAuth();
  const { projectId } = useProject();
  const { data: investments } = useProjectInvestments(projectId);
  const { data: members } = useProjectMembers(projectId);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const currentUserMember = members.find((member) => member?.email === user?.email);
  const isCurrentMemberInvestor = currentUserMember?.userRoles.some((role) =>
    investorRoles.includes(role),
  );


  const toggleModal = () => setIsOpenModal(!isOpenModal);

  return (
    <>
      <div className='viewlead'>
        <Box display='flex' flexDirection='column' gap={2}>
            {isCurrentMemberInvestor && (
              <UserInvestments projectInvestments={investments} member={currentUserMember} />
            )}
            
            {/* <InvestmentsDiagram investments={investments} /> */}
        </Box>
      </div>
      {isOpenModal && <EditInvestments investments={investments} toggleModal={toggleModal} />}
    </>
  );
};

export default MyInvestmentsSection;
