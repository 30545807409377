import { mockBasicData } from './Project/mockBasicData';

export const mockUserRequests = [
  {
    id: '67a1b2c3d4e5f6g7h8i9j0k1',
    email: ['john.doe@example.com'],
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: mockBasicData[0].data.records[0].id,
    projectTitle: mockBasicData[0].data.records[0].projectTitle,
    token: '9876fedc-ba98-7654-3210-fedcba987654',
    status: 'PENDING',
    invitationType: null,
    investorUserName: 'John Doe',
    expirationTime: '2024-10-15 14:30:00',
    createdTime: '2024-10-14 14:30:00',
    roles: ['Engineer', 'Architect'],
  },
  {
    id: '68b2c3d4e5f6g7h8i9j0k1l2',
    email: ['john.doe@example.com'],
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: mockBasicData[1].data.records[0].id,
    projectTitle: mockBasicData[1].data.records[0].projectTitle,
    token: '8765edcb-a987-6543-2109-edcba9876543',
    status: 'ACCEPTED',
    invitationType: null,
    expirationTime: '2024-10-20 10:15:30',
    createdTime: '2024-10-19 10:15:30',
    roles: ['Investor', 'General Partner'],
  },
  {
    id: '69c3d4e5f6g7h8i9j0k1l2m3',
    email: ['john.doe@example.com'],
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: mockBasicData[2].data.records[0].id,
    projectTitle: mockBasicData[2].data.records[0].projectTitle,
    token: '7654dcba-9876-5432-1098-dcba98765432',
    status: 'DECLINED',
    invitationType: null,
    expirationTime: '2024-10-25 09:45:00',
    createdTime: '2024-10-24 09:45:00',
    roles: ['Owner'],
  },
  {
    id: '70d4e5f6g7h8i9j0k1l2m3n4',
    email: ['john.doe@example.com'],
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: mockBasicData[3].data.records[0].id,
    projectTitle: mockBasicData[3].data.records[0].projectTitle,
    token: '6543cba9-8765-4321-0987-cba987654321',
    status: 'PENDING',
    invitationType: 'INVESTMENT_MAIL',
    investorUserName: 'Tom Brown',
    expirationTime: '2024-11-01 16:00:00',
    createdTime: '2024-10-31 16:00:00',
    roles: null,
  },
  {
    id: '71e5f6g7h8i9j0k1l2m3n4o5',
    email: ['john.doe@example.com'],
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: mockBasicData[4].data.records[0].id,
    projectTitle: mockBasicData[4].data.records[0].projectTitle,
    token: '5432ba98-7654-3210-9876-ba9876543210',
    status: 'ACCEPTED',
    invitationType: null,
    expirationTime: '2024-11-05 11:30:00',
    createdTime: '2024-11-04 11:30:00',
    roles: ['Engineer', 'General Partner', 'Investor'],
  },
  {
    id: '72f6g7h8i9j0k1l2m3n4o5p6',
    email: ['john.doe@example.com'],
    userId: '6dad315c-dcd3-443a-ab92-89ceb9d70836',
    projectId: mockBasicData[5].data.records[0].id,
    projectTitle: mockBasicData[5].data.records[0].projectTitle,
    token: '5432ba98-7654-3210-9876-ba9876543210',
    status: 'PENDING',
    invitationType: 'PAYMENT_REQUEST_MAIL',
    investorUserName: 'Denzel Washington',
    expirationTime: '2024-11-05 11:30:00',
    createdTime: '2024-11-04 11:30:00',
    roles: null,
  },
];
