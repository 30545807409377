import React, { useEffect } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { H1, Paragraph } from 'app/common/Typography';
import { Icon } from '@iconify/react';
import './styles.css'; // Import the CSS file

const Images = ({ formData, setFormData, setDisable }) => {
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFormData({
      ...formData,
      images: [...formData.images, ...files],
    });
  };
  useEffect(() => {
    const hasImages = formData.images.length > 0;
    setDisable(!hasImages);
  }, [formData.images, setDisable]);

  const handleDeleteFile = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData({
      ...formData,
      images: updatedImages,
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);
    setFormData({
      ...formData,
      images: [...formData.images, ...files],
    });
  };

  return (
    <div className='lead'>
      <div className='navigation-header'>
        <Paragraph>You need to the images documents with .jpeg format</Paragraph>
        <div className='Container' onDragOver={handleDragOver} onDrop={handleDrop}>
          <img src='/assets/images/uploadImages.png' alt='' />
          <Typography variant='body1'>
            Drag and Drop the files you want to upload from your computer
          </Typography>
          <label htmlFor='outlined-button-file'>
            <Button className='StyledButton' variant='outlined' component='span'>
              Browse files
            </Button>
          </label>
          <input
            accept='image/*'
            className='input'
            id='outlined-button-file'
            multiple
            type='file'
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
        <div
          style={{ border: '1px solid lightgrey', marginTop: '20px', marginBottom: '20px' }}
        ></div>

        {formData &&
          formData.images &&
          formData.images.map((file, index) => (
            <div className='ImageContainer' key={index}>
              <img
                src={URL.createObjectURL(file)}
                alt={`Selected File ${index + 1}`}
                style={{ height: '100%', borderRadius: '4px', width: '70px' }}
              />
              <div className='FileDetails'>
                <Typography variant='body1' style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                  {file.name}
                </Typography>
                <Typography variant='caption' style={{ marginLeft: '10px' }}>
                  {(file.size / 1024).toFixed(2)} KB
                </Typography>
              </div>
              <IconButton onClick={() => handleDeleteFile(index)}>
                <Icon icon='material-symbols-light:delete-rounded' style={{ color: 'var(--deleteIcon)' }} />
              </IconButton>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Images;
