import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomGCExpensesInputText } from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import {
  Box,
  IconButton,
  styled,
  Input,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Modal,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Formik, FieldArray, Form } from 'formik';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { base64ToFile, parseAddressComponents } from 'app/utils/helpers';
import { documentTypes } from 'app/utils/constant';
import { useProjectCLCreateUpdate } from './hooks/useProjectCLCreateUpdate';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '10px',
  marginBottom: '1em',
  paddingRight: '10px',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const SearchBoxContainer = styled('div')({
  width: '100%',
  '& input': {
    padding: '0.3em 0.5em',
    height: '2.710em',
    width: '100%',
    boxSizing: 'border-box',
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  border: '1px solid var(--fileContainer-border)',
  backgroundColor: 'var(--fileContainer-bg)',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '20vh',
  overflowY: 'auto',
  width: '100%',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

const ConstructionLenderAddEdit = ({ lender, lenderDocuments = [], closeDialog }) => {
  const { projectId } = useProject();
  const { mutate: createUpdateProjectCL } = useProjectCLCreateUpdate(projectId, lender);
  const searchInputRef = React.useRef(null);

  const isEditingMode = !!lender;

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  const contactPerson = lender?.lenderContactPerson;
  const loanDetails = lender?.loanDetails;

  const initialValues = {
    lenderName: lender?.lenderName || '',
    lenderPhone: lender?.lenderPhone || '',
    lenderEmail: lender?.lenderEmail || '',
    address1: lender?.lenderAddress || '',
    city: lender?.lenderCity || '',
    state: lender?.lenderState || '',
    country: lender?.lenderCountry || '',
    zipCode: lender?.lenderZipCode || '',
    lenderContactPersonName: contactPerson?.lenderContactPersonName || '',
    lenderContactPersonPhone: contactPerson?.lenderContactPersonPhone || '',
    lenderContactPersonEmail: contactPerson?.lenderContactPersonEmail || '',
    loanApprovedAmount: loanDetails?.loanApprovedAmount || '',
    loanWithdrawalAmount: loanDetails?.loanWithdrawalAmount || '',
    interestRate: loanDetails?.interestRate || '',
    loanType: loanDetails?.loanType || '',
    loanStartDate: loanDetails?.loanStartDate || '',
    loanTerm: loanDetails?.loanTerm || '',
    comments: lender?.comments || '',
    documents: lenderDocuments
      .filter((doc) => loanDetails?.loanDocuments?.[0]?.includes(doc.metadata.fileId))
      .map((doc) => ({
        file: base64ToFile(doc.content, doc.metadata.documentName, doc.metadata.documentType),
        docName: doc?.metadata?.documentName,
        type: doc.metadata.type,
      })) || [],
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const document = values?.documents[0];
    const isDocumentSameAsPrevious = initialValues?.documents?.[0]?.docName === document?.docName;
    const previousDocumentID = loanDetails?.loanDocuments?.[0];

    createUpdateProjectCL(
      {
        data: values,
        isDocumentSameAsPrevious,
        previousDocumentID,
      },
      {
        onSuccess: closeDialog,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <Modal open={true} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '90vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{isEditingMode ? 'Edit' : 'Add'} Construction Lender Details</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon style={{ fontSize: 20, color: 'var(--closeIcon)' }} />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.constructionLender()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setValues,
              setFieldValue,
              isSubmitting,
            }) => {
              const handleFileInput = (event) => {
                event.preventDefault();
                if (isSubmitting) return;

                const fileAccess = {
                  change: event.target.files,
                  drop: event.dataTransfer?.files,
                };

                const inputFile = fileAccess[event.type];
                if (!inputFile || inputFile.length === 0) return;

                const newDocument = Array.from(inputFile).map((file) => ({
                  file,
                  docName: '',
                  type: documentTypes.CONSTRUCTION_LENDER,
                }));
                if (newDocument.length > 0) {
                  setFieldValue('documents', [...(values.documents || []), ...newDocument]);
                }
              };

              const handleDragOver = (event) => event.preventDefault();

              return (
                <Form>
                  <Container>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>LENDER NAME</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g. : John Doe'
                          name='lenderName'
                          value={values.lenderName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.lenderName && errors.lenderName)}
                          helperText={touched.lenderName && errors.lenderName}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>EMAIL ADDRESS</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g.: john.doe@example.com'
                          name='lenderEmail'
                          value={values.lenderEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.lenderEmail && errors.lenderEmail)}
                          helperText={touched.lenderEmail && errors.lenderEmail}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>PHONE NUMBER</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='tel'
                          placeholder='e.g. +98765432102'
                          name='lenderPhone'
                          value={values.lenderPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.lenderPhone && errors.lenderPhone)}
                          helperText={touched.lenderPhone && errors.lenderPhone}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>ADDRESS</InputLabel>
                        <SearchBoxContainer>
                          <StandaloneSearchBox
                            onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                            onUnmount={() => (searchInputRef.current = null)}
                            onPlacesChanged={() => handlePlacesChanged(setValues)}
                          >
                            <TextField
                              sx={{ marginTop: '0px', height: '0px' }}
                              fullWidth
                              margin='normal'
                              variant='outlined'
                              type='search'
                              name='address1'
                              id='standard-basic'
                              placeholder='Search With Place, Landmark'
                              value={values?.address1}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (!value) {
                                  setValues((prevState) => ({
                                    ...prevState,
                                    search: '',
                                    address1: '',
                                    country: '',
                                    city: '',
                                    state: '',
                                    zipCode: '',
                                    latitude: null,
                                    longitude: null,
                                  }));
                                }
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              helperText={touched.address1 && errors.address1}
                              error={Boolean(errors.address1 && touched.address1)}
                              disabled={isSubmitting}
                            />
                          </StandaloneSearchBox>
                        </SearchBoxContainer>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>CITY</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g., Houston'
                          name='city'
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>STATE</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g., Texas'
                          name='state'
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>ZIP CODE</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          placeholder='e.g. 123456'
                          name='zipCode'
                          value={values.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                          helperText={touched.zipCode && errors.zipCode}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>COUNTRY</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g., United States'
                          name='country'
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>LENDER CONTACT NAME</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g. : John Doe'
                          name='lenderContactPersonName'
                          value={values.lenderContactPersonName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.lenderContactPersonName && errors.lenderContactPersonName,
                          )}
                          helperText={
                            touched.lenderContactPersonName && errors.lenderContactPersonName
                          }
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>EMAIL ADDRESS</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g. : john.doe@example.com'
                          name='lenderContactPersonEmail'
                          value={values.lenderContactPersonEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.lenderContactPersonEmail && errors.lenderContactPersonEmail,
                          )}
                          helperText={
                            touched.lenderContactPersonEmail && errors.lenderContactPersonEmail
                          }
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>PHONE NUMBER</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='tel'
                          placeholder='e.g. +29876543210'
                          name='lenderContactPersonPhone'
                          value={values.lenderContactPersonPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.lenderContactPersonPhone && errors.lenderContactPersonPhone,
                          )}
                          helperText={
                            touched.lenderContactPersonPhone && errors.lenderContactPersonPhone
                          }
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>LOAN APPROVED AMOUNT</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            lender?.loanBalance === 0 && {
                              fontWeight: 'bold',
                              backgroundColor: 'var(--TextField-bg-color)',
                            }
                          }
                          placeholder='e.g. 300000'
                          name='loanApprovedAmount'
                          value={values.loanApprovedAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.loanApprovedAmount && errors.loanApprovedAmount)}
                          helperText={touched.loanApprovedAmount && errors.loanApprovedAmount}
                          InputProps={{
                            readOnly: Boolean(lender?.loanBalance),
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>LOAN WITHDRAWAL AMOUNT</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            lender?.loanBalance === 0 && {
                              fontWeight: 'bold',
                              backgroundColor: 'var(--TextField-bg-color)',
                            }
                          }
                          placeholder='e.g. 20000'
                          name='loanWithdrawalAmount'
                          value={values.loanWithdrawalAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: loanDetails?.loanBalance === 0,
                          }}
                          error={Boolean(
                            touched.loanWithdrawalAmount && errors.loanWithdrawalAmount,
                          )}
                          helperText={touched.loanWithdrawalAmount && errors.loanWithdrawalAmount}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>INTEREST RATE (in %)</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='number'
                          placeholder='e.g. 2%'
                          name='interestRate'
                          value={values.interestRate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.interestRate && errors.interestRate)}
                          helperText={touched.interestRate && errors.interestRate}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>LOAN TYPE</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          placeholder='e.g. Home equity'
                          name='loanType'
                          value={values.loanType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.loanType && errors.loanType)}
                          helperText={touched.loanType && errors.loanType}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>LOAN TERM</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          placeholder='e.g. 20 years'
                          name='loanTerm'
                          value={values.loanTerm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.loanTerm && errors.loanTerm)}
                          helperText={touched.loanTerm && errors.loanTerm}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <InputLabel>LOAN START DATE</InputLabel>
                          <DatePicker
                            disablePast
                            name='loanStartDate'
                            value={dayjs(values.loanStartDate)}
                            onChange={(newValue) => setFieldValue('loanStartDate', newValue)}
                            slotProps={{
                              textField: {
                                size: 'small',
                                fullWidth: true,
                                variant: 'outlined',
                                error: Boolean(errors.loanStartDate && touched.loanStartDate),
                                helperText: touched.loanStartDate && errors.loanStartDate,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px', marginLeft: '3%' }} container spacing={2}>
                      <Typography
                        style={{
                          fontSize: '15px',
                          fontWeight: '450',
                          marginTop: '0.8em',
                          marginBottom: '0.5em',
                        }}
                      >
                        Loan Document
                      </Typography>
                      <Container
                        style={{ border: '2px dashed lightgray', width: '97%' }}
                        onDragOver={handleDragOver}
                        onDrop={handleFileInput}
                      >
                        <Typography
                          variant='body1'
                          marginTop={1}
                          marginBottom={1}
                          color={'var(--drag-Drop-Text)'}
                          fontSize={'16px'}
                        >
                          <DownloadDragIcon />
                          {` Drag and Drop file here or `}
                          <label htmlFor='outlined-button-file'>
                            <StyledButton
                              disabled={isSubmitting || values.documents?.length > 0}
                              variant='outlined'
                              component='span'
                            >
                              Browse files
                            </StyledButton>
                          </label>
                        </Typography>
                        <Input
                          key={values.documents.length}
                          id='outlined-button-file'
                          sx={{
                            display: 'none',
                          }}
                          size='small'
                          type='file'
                          name='file'
                          onChange={handleFileInput}
                          inputProps={{ accept: '.pdf, .doc, .docx' }}
                          disabled={isSubmitting || values.documents?.length > 0}
                        />
                      </Container>
                      <Typography
                        variant='body1'
                        color={'var(--fileFormate-Text)'}
                        fontSize={'12px'}
                      >
                        {`File format only word or Pdf, Size should not exceed 10MB`}
                      </Typography>
                    </Grid>
                    <FormControl fullWidth error={touched.documents && Boolean(errors.documents)}>
                      <FileContainerDetails>
                        <FieldArray name='documents'>
                          {({ remove }) =>
                            values?.documents?.map((doc, index) => (
                              <FileContainer key={index}>
                                <ImageContainer>
                                  {doc.file?.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                                  <FileDetails>
                                    <Typography
                                      variant='body1'
                                      style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                    >
                                      {doc.file?.name}
                                    </Typography>
                                    <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                      {doc.file ? `${(doc.file?.size / 1024).toFixed(2)} KB` : ''}
                                    </Typography>
                                  </FileDetails>
                                  <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon style={{ color: 'var(--deleteIcon)' }} />
                                  </IconButton>
                                </ImageContainer>
                                <ModalDivider />
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <CustomGCExpensesInputText
                                      placeholder='Document Name'
                                      name={`documents[${index}].docName`}
                                      value={values.documents[index]?.docName || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={Boolean(
                                        touched.documents?.[index]?.docName &&
                                        errors.documents?.[index]?.docName,
                                      )}
                                      helperText={
                                        touched.documents?.[index]?.docName &&
                                        errors.documents?.[index]?.docName
                                      }
                                      disabled={isSubmitting}
                                    />
                                  </Grid>
                                </Grid>
                              </FileContainer>
                            ))
                          }
                        </FieldArray>
                        {touched?.documents && errors?.documents && (
                          <FormHelperText>
                            {!Array.isArray(errors.documents) && errors.documents}
                          </FormHelperText>
                        )}
                      </FileContainerDetails>
                    </FormControl>
                  </Container>
                  <Grid item xs={8}>
                    <InputLabel>COMMENTS</InputLabel>
                    <TextField
                      fullWidth
                      size='small'
                      sx={{ mb: 1, ml: 1.5, width: '95%' }}
                      variant='outlined'
                      multiline
                      rows={1.5}
                      type='text'
                      placeholder='Write Here'
                      name='comments'
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Box
                    marginLeft={1.5}
                    marginRight={1}
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <LoadingButton
                      className='cancelButton'
                      color='error'
                      variant='outlined'
                      onClick={closeDialog}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '10px' }}
                      type='submit'
                      loading={isSubmitting}
                    >
                      {isEditingMode ? 'Update' : 'Save'}
                    </LoadingButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConstructionLenderAddEdit;
