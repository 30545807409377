import { useUserInvestments } from '../../leads/LeadsData/Investments/hooks/useUserInvestments';
import { useUserDetailsById } from '../../leads/LeadsData/Members/hooks/useUserDetailsById';

export function useInvestmentRequest(projectId, investorUserId) {
  const {
    data: investments,
    isPending: investmentsLoading,
    isError: investmentsError,
  } = useUserInvestments(projectId, investorUserId);

  const {
    data: investor,
    isPending: investorLoading,
    isError: investorError,
  } = useUserDetailsById(investorUserId);

  return {
    data: { investments, investor },
    isPending: investmentsLoading || investorLoading,
    isError: investmentsError || investorError,
  };
}
