import { useQuery } from '@tanstack/react-query';
import { getProjectExpenses } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export const useProjectExpenses = (projectId) => {
  return useQuery({
    queryKey: [...queryKeys.project.expenses, projectId],
    queryFn: () => getProjectExpenses(projectId),
    placeholderData: [],
  });
};
