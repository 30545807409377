import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProjectBasic } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectBasicUpdate(projectId) {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: (obj) => updateProjectBasic(projectId, obj),
      onSuccess: () =>
        queryClient.invalidateQueries({
           queryKey: [...queryKeys.project.basic, projectId] }),
    });
  }
  