import { Divider, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button';
import { Title } from 'app/common/Typography';
import InvestmentsDiagram from './InvestmentsDiagram';
import EditInvestments from './EditInvestments';
import { useState } from 'react';
import { formatCurrency } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import UserInvestments from './UserInvestments';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { investorRoles } from 'app/utils/constant';
import { useProjectInvestments } from './hooks/useProjectInvestments';
import { useProjectMembers } from '../Members/hooks/useProjectMembers';
import { PDSubHeadings, StyledEditIcon } from '../../listings/StyledComponent';
import { EditIcon } from 'app/common/icons';

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: 'var(--subtitleDivider1)',
});

const SectionDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const InvestmentsSection = () => {
  const { user } = useUserAuth();
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: investments } = useProjectInvestments(projectId);
  const { data: members } = useProjectMembers(projectId);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const currentUserMember = members.find((member) => member?.email === user?.email);
  const isCurrentMemberInvestor = currentUserMember?.userRoles.some((role) =>
    investorRoles.includes(role),
  );

  const {
    totalInvestment = 0,
    landCost = 0,
    softCost = 0,
    investedAmount = 0,
    remainingAmount = 0,
  } = investments || {};

  const { totalShare = 0, availableShares = 0, eachSharePrice = 0 } = investments || {};

  const soldShares = totalShare - availableShares;

  const toggleModal = () => setIsOpenModal(!isOpenModal);

  const infoPairs = [
    { key: 'LAND COST', value: formatCurrency(landCost) },
    { key: 'SOFT COST', value: formatCurrency(softCost) },
    { key: 'TOTAL INVESTMENT', value: formatCurrency(totalInvestment) },
    { key: 'INVESTED AMOUNT', value: formatCurrency(investedAmount) },
    { key: 'REMAINING AMOUNT', value: formatCurrency(remainingAmount) },
  ];

  return (
    <>
      <div className='viewlead'>
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ margin: '20px 20px 20px 20px' }}
          >
            <PDSubHeadings sx={{ fontWeight: '600', fontSize: '24px', color: '#17181B' }}>
              Investment Details
            </PDSubHeadings>
            <StyledEditIcon
              variant='outlined'
              size='small'
              startIcon={<EditIcon />}
              onClick={toggleModal}
              disabled={isOnHoldOrCancelled}
            >
              Edit Investment Details
            </StyledEditIcon>
          </Box>
          <Divider />

          {/* Investment Summary Section */}
          <Box display='flex' flexDirection='column' p={2} paddingLeft={3} gap={2}>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Total Investment
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(totalInvestment)}`}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Typography variant='body2' fontWeight={'bold'}>
                1 Share = {`${eachSharePrice}`}
              </Typography>
              <Typography variant='body2' fontWeight={'bold'}>
                Total Shares: {`${totalShare}`}
              </Typography>
              <Typography variant='body2' fontWeight={'bold'}>
                Available Shares: {`${availableShares}`}
              </Typography>
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Typography variant='body2' color='text.secondary'>
                {`$${formatCurrency(landCost)}(Land Cost)`} +{' '}
                {`$${formatCurrency(softCost)}(Soft Cost)`}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            display='flex'
            justifyContent='space-around'
            p={2}
            gap={3}
          >
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Invested Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(investedAmount)}`}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                In shares value: {formatCurrency(investedAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Remaining Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(remainingAmount)}`}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                In shares value: {formatCurrency(remainingAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
      {isOpenModal && <EditInvestments investments={investments} toggleModal={toggleModal} />}
    </>
  );
};

export default InvestmentsSection;
