import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/react-query/queryClient';
import { queryKeys } from 'app/react-query/queryKeys';
import { editProfileDetails } from 'app/contexts/userAuthActions';

export function useProfileEditDetails(userNameJWT) {
  return useMutation({
    mutationFn: (profileData) => editProfileDetails(userNameJWT, profileData),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.currentUser,
      }),
  });
}
