import Box from '@mui/material/Box';
import InvestNow from './InvestNow';
import { StyledIcon, Title } from 'app/common/Typography';
import { formatCurrency } from 'app/utils/helpers';
import UserInvestmentsHistory from './UserInvestmentsHistory';
import { Loading } from 'app/components';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { useUserInvestments } from './hooks/useUserInvestments';
import { IconDivider, StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import { statuses } from 'app/utils/constant';
import { Divider, Typography } from '@mui/material';

function UserInvestments({ projectInvestments, member }) {
  const { user } = useUserAuth();

  const { data: memberInvestmentLatest, isPending } = useUserInvestments(
    member.projectId,
    member.userId,
  );


  const isCurrentUser = member.email === user.email;

  const isDeclinedInvestor =
    memberInvestmentLatest?.status === statuses.DECLINED ||
    memberInvestmentLatest?.paymentDetails.at(-1)?.status === statuses.DECLINED;

  const isInvestmentPending =
    memberInvestmentLatest?.status === statuses.PENDING ||
    memberInvestmentLatest?.paymentDetails.at(-1)?.status === statuses.PENDING;

  const { totalShare = 0, availableShares = 0, eachSharePrice = 0 } = projectInvestments || {};
  const infoPairs = [
    {
      key: 'INTERESTED AMOUNT',
      value: formatCurrency(memberInvestmentLatest?.interestedAmount),
    },
    {
      key: 'INTERESTED SHARES',
      value: memberInvestmentLatest?.interestedShares || 0,
    },
    {
      key: 'TOTAL INVESTED AMOUNT',
      value: formatCurrency(memberInvestmentLatest?.accumulatedAmount),
    },
    {
      key: 'TOTAL INVESTED SHARES',
      value: memberInvestmentLatest?.accumulatedShares || 0,
    },
    {
      key: 'REMAINING AMOUNT',
      value: formatCurrency(memberInvestmentLatest?.remainingAmount),
    },
  ];

  return isPending ? (
    <Loading />
  ) : (
    <>
      <Box>
        <Box
          sx={{ margin: "20px 38px 20px 20px" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <StledCardTitle>
          {isCurrentUser ? 'My Investments' : `Investments:`}
          </StledCardTitle>
          <StyledAddButton
            startIcon={<StyledIcon />}
            disabled={!availableShares || isInvestmentPending || isDeclinedInvestor}>
            <InvestNow
              projectInvestments={projectInvestments}
              memberInvestmentLatest={memberInvestmentLatest}
            />
          </StyledAddButton>
        </Box>

        <IconDivider orientation="vertical" />
        <Box display='flex' flexDirection='column' p={2} paddingLeft={3} gap={2}>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Interested Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {formatCurrency(memberInvestmentLatest?.interestedAmount)}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Typography variant='body2' fontWeight={'bold'}>
                In Share = {formatCurrency(memberInvestmentLatest?.interestedAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            display='flex'
            justifyContent='space-around'
            p={2}
            gap={3}
          >
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Total Invested Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(memberInvestmentLatest?.accumulatedAmount)}`}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                In shares value: {formatCurrency(memberInvestmentLatest?.accumulatedAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Remaining Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(memberInvestmentLatest?.remainingAmount)}`}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                In shares value: {formatCurrency(memberInvestmentLatest?.remainingAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
          </Box>
        {memberInvestmentLatest?.paymentDetails?.length > 0 && (
          <UserInvestmentsHistory paymentDetails={memberInvestmentLatest?.paymentDetails} />
        )}
      </Box>
    </>
  );
}

export default UserInvestments;
