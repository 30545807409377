import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addProjectCLLoanWithdrawal,
  updateProjectCLLoanWithdrawal,
} from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { convertDayJSDateToUTC } from 'app/utils/helpers';

export function useProjectCLLoanWithdrawalAddEdit(lender, withdrawal) {
  const queryClient = useQueryClient();

  const isEditingMode = !!withdrawal;

  return useMutation({
    mutationFn: async (data) => {
      const payloadData = {
        withdrawalAmount: data.withdrawalAmount,
        invoiceNumber: data.invoiceNumber,
        paidDate: convertDayJSDateToUTC(data.paidDate),
        paidTo: data.paidTo,
      };

      return isEditingMode
        ? updateProjectCLLoanWithdrawal(lender?.projectId, lender?.id, {
            ...payloadData,
            withdrawalId: withdrawal?.withdrawalId,
          })
        : addProjectCLLoanWithdrawal(lender?.projectId, lender?.id, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.constructionLender, lender?.projectId],
      }),
  });
}
