import { useQuery } from '@tanstack/react-query';
import { getProjectInvitations } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectRequests(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.requests, projectId],
    queryFn: () => getProjectInvitations(projectId),
    placeholderData: [],
  });
}
