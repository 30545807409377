import React from 'react'
import { Typography, Grid, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
    StyledBox1, StyledModalTitle, StyledCrossIcon,
    StyledBox2, StyledBox3, StyledBox4,
    ModalButtonCancle, ModalButtonSave, StyledBox,
    SectionContainer, SectionTitle, PDSubHeadings, PDSubValues
} from './StyledComponents';

const organizations = [
    {
        category: 'Assigned Company',
        items: [
            {
                name: 'Rockwell Ventures',
                ein: '1234567890',
                address: '132 My Street, Kingston, New York 12401.',
            },
        ],
    },
    {
        category: 'Available Companies',
        items: [
            {
                name: 'Vedhanth Ventures',
                ein: '1234567890',
                address: '132 My Street, Kingston, New York 12401.',
            },
            {
                name: 'Triolet Ventures',
                ein: '1234567890',
                address: '132 My Street, Kingston, New York 12401.',
            },
            {
                name: 'Homespace Constructions',
                ein: '1234567890',
                address: '132 My Street, Kingston, New York 12401.',
            },
        ],
    },
];

const ChangeOrgModal = ({ handleCloseModal }) => {
    const [selectedOrganization, setSelectedOrganization] = React.useState('Rockwell Ventures');

    const handleSave = () => {
        if (selectedOrganization) {
            console.log('Selected Organization Details:', selectedOrganization);
        } else {
            console.log('No organization selected.');
        }
        handleCloseModal();
    };

    return (
        <>
            <StyledBox1>
                <StyledBox2  >
                    <StyledModalTitle>Change Organization</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <StyledBox3>
                    {organizations.map((section) => (
                        <SectionContainer key={section.category}>
                            <SectionTitle>{section.category}</SectionTitle>
                            {section.items.map((item) => (
                                <StyledBox
                                    key={`${section.category}-${item.name}`}
                                    isSelected={selectedOrganization.name === item.name}
                                    onClick={() => setSelectedOrganization(item)}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ fontWeight: '600', fontSize: '20px', color: '#17181B' }}>
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PDSubHeadings>EIN/License Number:</PDSubHeadings>
                                            <PDSubValues>{item.ein}</PDSubValues>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PDSubHeadings>Address:</PDSubHeadings>
                                            <PDSubValues>{item.address}</PDSubValues>
                                        </Grid>
                                    </Grid>
                                </StyledBox>
                            ))}
                        </SectionContainer>
                    ))}
                </StyledBox3>

                <StyledBox4  >
                    <ModalButtonCancle
                        variant='outlined'
                        onClick={handleCloseModal}
                    // disabled={isSubmitting}
                    >
                        Cancel
                    </ModalButtonCancle>
                    <ModalButtonSave
                        type='submit'
                        onClick={handleSave}
                    // loading={isSubmitting}
                    >
                        Save
                    </ModalButtonSave>
                </StyledBox4>
            </StyledBox1>
        </>
    )
}

export default ChangeOrgModal;
