import React, { useState } from 'react';
import { Box, Autocomplete, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { propertyType, SubType } from 'app/utils/constant';
import { notify } from 'app/services/notify';
import {
    StyledBox1, StyledModalTitle, StyledCrossIcon,
    StyledBox2, StyledBox3, StyledTextField, StyledLabel,
    StyledTextAreaField, StyledBox4, ModalButtonCancle,
    ModalButtonSave
} from './StyledComponents';
import { PropertyDetailsSchema } from 'app/utils/validationSchemas';
import { useProjectBasicUpdate } from '../hooks/useProjectBasicUpdate';

const EditPropertyDetailsModal = ({ handleCloseEditModal, property, fetchPropertyDetails }) => {
    const [selectPropType, setSelectPropType] = useState(null);
    const [selectSubType, setSelectSubType] = useState(null);
    const { mutate: updateBasicDetails, isPending: isUpdating } = useProjectBasicUpdate(property?.id);

    console.log(property, "property");
    const initialValues = {
        PropertyType: property?.projectType || "",
        subType: property?.subType || '',
        Area: property?.size || '',
        Zone: '-',
        EstimatedPrice: property?.estimatedPrice || '',
        Description: property?.description || ''
    }

    React.useEffect(() => {
        if (property?.subType) {
            const initialSubType = SubType.find((option) => option.label === property.subType);
            setSelectSubType(initialSubType || null);
        }
        if (property?.projectType) {
            const initialPropType = propertyType.find((option) => option.label === property.propertyType);
            setSelectPropType(initialPropType || null);
        }
    }, [property]);

    const handleFormSubmit = (values, { setSubmitting }) => {
        const updatedPropertyDetails = {
            ...property,
            estimatedPrice: values.EstimatedPrice,
            size: values.Area,
            propertyType: values.PropertyType,
            subType: values.subType,
            description: values.Description
        }
        updateBasicDetails(updatedPropertyDetails, {
            onSuccess: handleCloseEditModal(),
            onSettled: () => setSubmitting(false)
        });
    }

    return (
        <>
            <StyledBox1>
                <StyledBox2  >
                    <StyledModalTitle>Property Details</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseEditModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>

                <StyledBox3>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validationSchema={PropertyDetailsSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <StyledLabel>
                                        Property Type
                                    </StyledLabel>
                                    <Autocomplete
                                        size="small"
                                        value={selectPropType}
                                        onChange={(event, newValue) => {
                                            setSelectPropType(newValue || null);
                                            setFieldValue('PropertyType', newValue ? newValue.label : '');
                                        }}
                                        options={propertyType}
                                        getOptionLabel={(option) => option.label || ''}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Select Property Type"
                                                error={touched.PropertyType && !!errors.PropertyType}
                                                helperText={touched.PropertyType && errors.PropertyType}
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    />
                                    <StyledLabel>
                                        Sub Type
                                    </StyledLabel>
                                    <Autocomplete
                                        size="small"
                                        value={selectSubType}
                                        onChange={(event, newValue) => {
                                            setSelectSubType(newValue || null);
                                            setFieldValue('subType', newValue ? newValue.label : '');
                                        }}
                                        options={SubType}
                                        getOptionLabel={(option) => option.label || ''}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Select Sub Type"
                                                error={touched.subType && !!errors.subType}
                                                helperText={touched.subType && errors.subType}
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    />


                                    <StyledLabel>Area(in acres)</StyledLabel>
                                    <StyledTextField
                                        type='text'
                                        name='Area'
                                        placeholder='e.g. : 100.6'
                                        onChange={handleChange}
                                        value={values.Area}
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        error={touched.Area && !!errors.Area}
                                        helperText={touched.Area && errors.Area}
                                        disabled={isSubmitting}
                                    />

                                    <StyledLabel>Zone</StyledLabel>
                                    <StyledTextField
                                        type='text'
                                        name='Zone'
                                        placeholder='e.g. : Zone'
                                        onChange={handleChange}
                                        value={values.Zone}
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        error={touched.Zone && !!errors.Zone}
                                        helperText={touched.Zone && errors.Zone}
                                        disabled={isSubmitting}
                                    />

                                    <StyledLabel>Estimated Price</StyledLabel>
                                    <StyledTextField
                                        type='text'
                                        name='EstimatedPrice'
                                        placeholder='e.g. : $200,000,000.00'
                                        onChange={handleChange}
                                        value={values.EstimatedPrice}
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        error={touched.EstimatedPrice && !!errors.EstimatedPrice}
                                        helperText={touched.EstimatedPrice && errors.EstimatedPrice}
                                        disabled={isSubmitting}
                                    />


                                    <StyledLabel>Description</StyledLabel>
                                    <StyledTextAreaField
                                        type='text'
                                        name='Description'
                                        placeholder='e.g. : sample Description'
                                        onChange={handleChange}
                                        value={values.Description}
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        error={touched.Description && !!errors.Country}
                                        helperText={touched.Country && errors.Country}
                                        disabled={isSubmitting}
                                    />

                                    <StyledBox4  >
                                        <ModalButtonCancle
                                            variant='outlined'
                                            onClick={handleCloseEditModal}
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </ModalButtonCancle>
                                        <ModalButtonSave
                                            type='submit'
                                            loading={isSubmitting}
                                        >
                                            {true ? 'Update' : 'Save'}
                                        </ModalButtonSave>
                                    </StyledBox4>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </StyledBox3>
            </StyledBox1>
        </>
    )
}

export default EditPropertyDetailsModal
