export const mockBasicData = [
  {
    data: {
      records: [
        {
          id: '66df41c671ad46003d52dbc1',
          projectTitle: 'Green Energy Initiative',
          description: 'A modern commercial complex with office spaces and retail units',
          propertyType: 'Land',
          subType: 'Commercial',
          estimatedPrice: 25000000.0,
          size: 50000.0,
          referenceLink: 'https://example.com/newcommercial',
          reason: null,
          projectType: 'lead',
          previousProjectType: 'lead',
        },
      ],
    },
  },
  {
    data: {
      records: [
        {
          id: '66df41c671ad46003d52dbc2',
          projectTitle: 'Urban Development Fund',
          description: 'A large-scale residential development with various housing types',
          propertyType: 'Land',
          subType: 'Residential',
          estimatedPrice: 75000000.0,
          size: 100000.0,
          referenceLink: 'https://example.com/residentialdevelopment',
          reason: null,
          projectType: 'newproject',
          previousProjectType: 'newproject',
        },
      ],
    },
  },
  {
    data: {
      records: [
        {
          id: '66df41c671ad46003d52dbc3',
          projectTitle: 'Tech Startup Incubator',
          description:
            'Development of an industrial park with manufacturing and logistics facilities',
          propertyType: 'Land',
          subType: 'Industrial',
          estimatedPrice: 40000000.0,
          size: 75000.0,
          referenceLink: 'https://example.com/industrialpark',
          reason: null,
          projectType: 'ongoing',
          previousProjectType: 'ongoing',
        },
      ],
    },
  },
  {
    data: {
      records: [
        {
          id: '66df41c671ad46003d52dbc4',
          projectTitle: 'Sustainable Agriculture Fund',
          description: 'A mixed-use development combining commercial and residential spaces',
          propertyType: 'Land',
          subType: 'Commercial',
          estimatedPrice: 100000000.0,
          size: 150000.0,
          referenceLink: 'https://example.com/mixeduse',
          reason: 'Funding issues',
          projectType: 'cancelled',
          previousProjectType: 'ongoing',
        },
      ],
    },
  },
  {
    data: {
      records: [
        {
          id: '66df41c671ad46003d52dbc5',
          projectTitle: 'AI Research Consortium',
          description:
            'Development of an eco-friendly residential community with sustainable features',
          propertyType: 'Land',
          subType: 'Residential',
          estimatedPrice: 60000000.0,
          size: 200000.0,
          referenceLink: 'https://example.com/ecoresidential',
          reason: 'Pending environmental assessment',
          projectType: 'onhold',
          previousProjectType: 'newproject',
        },
      ],
    },
  },
  {
    data: {
      records: [
        {
          id: '66df41c671ad46003d52dbc6',
          projectTitle: 'Green Energy Initiative',
          description: 'A modern commercial complex with office spaces and retail units',
          propertyType: 'Land',
          subType: 'Commercial',
          estimatedPrice: 25000000.0,
          size: 50000.0,
          referenceLink: 'https://example.com/newcommercial',
          reason: null,
          projectType: 'lead',
          previousProjectType: 'lead',
        },
      ],
    },
  },
];
