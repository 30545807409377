import { useState, useEffect } from 'react';
import CommentForm from './commentForm';
import Comment from './comment';
import {
  getComments as getCommentsApi,
  createComment as createCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
} from '../../contexts/comments';
import { handleAxiosError } from 'app/utils/helpers';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Avatar,
  styled
} from "@mui/material";

// const Typography = styled('div')({
//   marginTop: '80px',
//   overflow: 'hidden',
// });

const NoComments = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px', // Adjust height as needed
  fontSize: '20px',
  color: 'var(--no-Comments-color)',
});

const CommentsList = ({ currentUserId, projectId }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const { data } = await getCommentsApi(projectId);

        const commentsWithParentId = data.data.map((comment) => ({
          ...comment,
          parentId: comment.parentComment?.id || null,
        }));

        setBackendComments(commentsWithParentId);
      } catch (error) {
        handleAxiosError(error);
      }
    };

    fetchComments();
  }, [projectId, activeComment]);

  const rootComments = backendComments.filter((backendComment) => backendComment.parentId === null);

  const getReplies = (commentId) => {
    const replies = backendComments.filter(
      (backendComment) => backendComment.parentId === commentId,
    );
    return replies;
  };

  const addComment = async (text, parentId = null) => {
    const commentData = { content: text };

    try {
      await createCommentApi(projectId, parentId, commentData);
      const { data } = await getCommentsApi(projectId);

      const commentsWithParentId = data.data.map((comment) => ({
        ...comment,
        parentId: comment.parentComment?.id || null,
      }));

      setBackendComments(commentsWithParentId);
      setActiveComment(null);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const updateComment = async (text, commentId) => {
    const commentData = { content: text };

    try {
      await updateCommentApi(commentId, projectId, commentData);

      const updatedBackendComments = backendComments.map((backendComment) =>
        backendComment.id === commentId ? { ...backendComment, content: text } : backendComment,
      );

      setBackendComments(updatedBackendComments);
      setActiveComment(null);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const deleteComment = async (commentId) => {
    const confirmed = window.confirm('Are you sure you want to remove this comment?');

    if (!confirmed) return;

    try {
      await deleteCommentApi(commentId, projectId);

      const updatedBackendComments = backendComments.filter(
        (backendComment) => backendComment.id !== commentId,
      );

      setBackendComments(updatedBackendComments);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  return (
    <>
      <Box display="flex" sx={{ flexDirection: "column", width: "94%", marginLeft: "2rem", marginBottom: "2rem", marginTop: '1.5rem' }}>
        <CommentForm handleSubmit={addComment} />
      </Box>
      <Box sx={{ marginLeft: "2rem" }}>
        {rootComments.length === 0 ? (
          <NoComments>No comments added</NoComments>
        ) : (
          rootComments.map((rootComment) => (
            <Comment
              key={rootComment.id}
              comment={rootComment}
              replies={getReplies(rootComment.id)}
              activeComment={activeComment}
              setActiveComment={setActiveComment}
              addComment={addComment}
              deleteComment={deleteComment}
              updateComment={updateComment}
              currentUserId={currentUserId}
            />
          ))
        )}
      </Box>
    </>
  );
};

export default CommentsList;
