import { Loading } from 'app/components';
import { useProjectBasic } from 'app/views/dashboard/leads/LeadsData/Basic/hooks/useProjectBasic';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
  const { projectId } = useParams();
  const { data: basic, isPending } = useProjectBasic(projectId);

  const projectType = basic?.projectType;
  const isLead = projectType === 'lead';
  const isCancelled = projectType === 'cancelled';
  const isOnHoldOrCancelled = projectType === 'onhold' || projectType === 'cancelled';

  if (isPending) {
    return <Loading />;
  }

  return (
    <ProjectContext.Provider
      value={{
        basic,
        projectId,
        isLead,
        isCancelled,
        isOnHoldOrCancelled,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) throw new Error('Project context was used outside of provider');
  return context;
};

export default ProjectProvider;
