import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  StyledBox1,
  StyledModalTitle,
  StyledBox2,
  StyledCrossIcon,
  StyledBox3,
  StyledTextField,
  StyledLabel,
  StyledBox4,
  ModalButtonCancle,
  ModalButtonSave,
} from '../StyleComponents';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { handleAxiosError, parseAddressComponents } from 'app/utils/helpers';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { useProfileEditDetails } from '../hooks/useProfileEditDetails';

const AddressModal = ({ handleCloseModal }) => {
  const [leadLocationData, setLeadLocationData] = useState({
    search: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: null,
    longitude: null,
  });

  const initialValues = {
    search: leadLocationData.search,
    address1: leadLocationData.address1,
    address2: leadLocationData.address2,
    state: leadLocationData.state,
    zipCode: leadLocationData.zipCode,
    city: leadLocationData.city,
    country: leadLocationData.country,
    latitude: leadLocationData.latitude,
    longitude: leadLocationData.longitude,
  };

  const searchInputRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user, userNameJWT } = useUserAuth();
  const { mutate: userEditDetails } = useProfileEditDetails(userNameJWT);

  useEffect(() => {
    if (user) {
      setLeadLocationData({
        address1: user.address1 || '',
        address2: user.address2 || '',
        city: user.city || '',
        state: user.state || '',
        country: user.country || '',
        zipCode: user.zipCode || '',
        latitude: user.latitude || null,
        longitude: user.longitude || null,
        search: user.address1 || '',
      });
    }
  }, [user]);
  const validationSchema = Yup.object().shape({
    address1: Yup.string().required('Address1 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string()
      .required('ZIP Code is required')
      .min(5, 'ZIP Code must be at least 5 characters'),
    country: Yup.string().required('Country is required'),
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
    console.log('Form Values:', values);
    try {
      setIsLoading(true);
      await userEditDetails(values);
        handleCloseModal();
    } catch (error) {
      console.error('Error submitting contact number:', error);
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
    setSubmitting(false);
  };

  const handlePlacesChanged = (setFieldValue) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const addressData = parseAddressComponents(place);

    setLeadLocationData((prevState) => {
      const updatedData = {
        ...prevState,
        ...addressData,
        search: place.formatted_address,
      };

      setFieldValue('address1', updatedData.address1);
      setFieldValue('address2', updatedData.address2);
      setFieldValue('city', updatedData.city);
      setFieldValue('state', updatedData.state);
      setFieldValue('zipCode', updatedData.zipCode);
      setFieldValue('country', updatedData.country);
      setFieldValue('latitude', updatedData.latitude);
      setFieldValue('longitude', updatedData.longitude);

      return updatedData;
    });

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const handleChangeWithValidation = (e, handleChange) => {
    setLeadLocationData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    handleChange(e);
  };

  return (
    <StyledBox1>
      <StyledBox2>
        <StyledModalTitle>{user?.address1 ? 'Update' : 'Add'} Address</StyledModalTitle>
        <StyledCrossIcon onClick={handleCloseModal}>
          <CloseIcon />
        </StyledCrossIcon>
      </StyledBox2>

      <StyledBox3>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <StyledLabel>Address1</StyledLabel>
                <StandaloneSearchBox
                  onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                  onUnmount={() => (searchInputRef.current = null)}
                  onPlacesChanged={() => handlePlacesChanged(setFieldValue)}
                >
                  <StyledTextField
                    fullWidth
                    type='search'
                    name='address1'
                    size='small'
                    id='standard-basic'
                    placeholder='Search address'
                    variant='outlined'
                    value={values.address1}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      if (!e.target.value) {
                        setLeadLocationData((prevState) => ({
                          ...prevState,
                          search: '',
                          address1: '',
                          address2: '',
                          country: '',
                          city: '',
                          state: '',
                          zipCode: '',
                          latitude: null,
                          longitude: null,
                        }));
                      }
                    }}
                    error={Boolean(errors.address1 && touched.address1)}
                    helperText={touched.address1 && errors.address1}
                  />
                </StandaloneSearchBox>

                <StyledLabel>Address2 (optional)</StyledLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  name='address2'
                  value={values.address2}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  disabled={isLoading}
                />

                <StyledLabel>City</StyledLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  name='city'
                  value={values.city}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.city && errors.city}
                  error={Boolean(errors.city && touched.city)}
                  disabled={isLoading}
                />

                <StyledLabel>State</StyledLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  name='state'
                  value={values.state}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.state && errors.state}
                  error={Boolean(errors.state && touched.state)}
                  disabled={isLoading}
                />

                <StyledLabel>Zip Code</StyledLabel>
                <StyledTextField
                  sx={{ mb: 2 }}
                  fullWidth
                  size='small'
                  variant='outlined'
                  type='text'
                  name='zipCode'
                  value={values.zipCode}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.zipCode && errors.zipCode}
                  error={Boolean(errors.zipCode && touched.zipCode)}
                  disabled={isLoading}
                />

                <StyledLabel>Country</StyledLabel>
                <StyledTextField
                  fullWidth
                  sx={{ mb: 2 }}
                  size='small'
                  variant='outlined'
                  name='country'
                  value={values.country}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  helperText={touched.country && errors.country}
                  error={Boolean(errors.country && touched.country)}
                  disabled={isLoading}
                />

                <StyledBox4 sx={{ marginTop: '7rem' }}>
                  <ModalButtonCancle
                    variant='outlined'
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </ModalButtonCancle>
                  <ModalButtonSave type='submit' loading={isLoading}>
                    {user?.address1 ? 'Update' : 'Save'}
                  </ModalButtonSave>
                </StyledBox4>
              </Box>
            </form>
          )}
        </Formik>
      </StyledBox3>
    </StyledBox1>
  );
};

export default AddressModal;
