import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProjectRole } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectRoleDelete(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleName) => deleteProjectRole(projectId, roleName),
    onMutate: (roleName) => {
      const previousRoles = queryClient.getQueryData([...queryKeys.project.roles, projectId]);

      queryClient.setQueryData([...queryKeys.project.roles, projectId], (oldData) =>
        oldData.filter((role) => role.name !== roleName),
      );

      return { previousRoles };
    },
    onError: (_, __, { previousRoles }) => {
      queryClient.setQueryData([...queryKeys.project.roles, projectId], previousRoles);
    },
  });
}
