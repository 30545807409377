import React from 'react';
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const LeadLeaseSuccess = ({ open, buildingName, handleClose }) => {
  
  const handleClick = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClick}>
      <DialogTitle
        style={{
          width: '580px',
          textAlign: 'center',
          height: '120px',
          borderRadius: '2px 0px 0px 0px',
        }}
      >
        <CheckCircleIcon
          color='success'
          style={{
            width: '79px',
            height: '79px',
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' align='center' gutterBottom>
          Units added successfully under the
        </Typography>
        <Typography variant='h5' align='center' fontWeight='600'>
          {buildingName}
        </Typography>
        <Typography
          variant='body1'
          align='center'
          onClick={handleClick}
          style={{
            marginTop: '20px',
            fontSize: '22px',
            color: 'var(--typography-color)',
            fontWeight: '600',
            lineHeight: '23px',
            letterSpacing: '-0.02em',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          Go to Individual unit to add Lease or Sale details.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
      </DialogActions>
    </Dialog>
  );
};

export default LeadLeaseSuccess;
