import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './slices/searchSlice';

export const store = configureStore({
  reducer: {
    search: searchReducer,
  },
});

export { setSearchValue, selectSearchValue } from './slices/searchSlice';
