import { useState } from 'react';

export function usePermissionsExpansion() {
  const [expandedPermissions, setExpandedPermissions] = useState([]);

  const handlePermissionsExpand = (accordionName, shouldExpand) =>
    setExpandedPermissions((prev) =>
      shouldExpand
        ? [...prev, accordionName]
        : prev.filter((accordion) => accordion !== accordionName),
    );

  return [expandedPermissions, handlePermissionsExpand];
}
