import React, { useRef, useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Container,
  Table,
  Button,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import GCManagerAddEdit from './GCManagerAddEdit';
import UpdateIcon from '@mui/icons-material/Update';
import { GCManagerKeys, managersTitles } from 'app/utils/constant';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const GCManagersView = ({ contractor, managerKey }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const selectedManager = useRef(null);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickOpen = () => {
    selectedManager.current = null;
    toggleDialog();
  };

  const handleClickUpdate = (manager) => {
    selectedManager.current = manager;
    toggleDialog();
  };

  const managers = contractor?.[managerKey];
  const title = managersTitles[managerKey];

  return (
    <>
      <SubtitleDivider />
      {managers?.length === 0 ? (
        <>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>{title}</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to {title}
                </Typography>
                <Button onClick={handleClickOpen} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <>
          <Box
            marginLeft={3}
            marginRight={3}
            marginTop={1}
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Title sx={{ marginLeft: '0px' }}>{title}</Title>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Button
                style={{ color: 'var(--addButton)', fontSize: '14px' }}
                onClick={handleClickOpen}
              >
                + ADD
              </Button>
            </Box>
          </Box>
          <Box
            marginLeft={3}
            marginRight={3}
            marginTop={1}
            marginBottom={1}
            overflow='auto'
            border={'2px solid #E5E5E7'}
          >
            <ProductTable marginLeft={3} marginRight={3}>
              <TableHead style={{ backgroundColor: 'var(--tableHead-bg-color)' }}>
                <TableRow color='var(--tableHead-row-color)'>
                  <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.NO.</TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                    CONTACT PERSON
                  </TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                    EMAIL ADDRESS
                  </TableCell>
                  <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                    PHONE NUMBER
                  </TableCell>
                  <TableCell
                    sx={{ px: 0, width: '5%', color: 'var(--tableHead-row-color)' }}
                  ></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {managers?.map((manager, index) => (
                  <TableRow hover key={manager.contactId}>
                    <TableCell
                      align='left'
                      sx={{
                        px: 2,
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      {manager.contactPerson}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {manager.emailId}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {manager.phoneNo}
                    </TableCell>
                    <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => handleClickUpdate(manager)}>
                          <Icon>
                            <UpdateIcon />
                          </Icon>
                          <Span>Update</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </ProductTable>
          </Box>
        </>
      )}
      {isOpenDialog && (
        <GCManagerAddEdit
          contractor={contractor}
          manager={selectedManager.current}
          managerKey={managerKey}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
};

export default GCManagersView;
