import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addProjectCLLoanPayment, updateProjectCLLoanPayment } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { convertDayJSDateToUTC } from 'app/utils/helpers';

export default function useProjectCLLoanPaymentAddEdit(lender, payment) {
  const queryClient = useQueryClient();

  const isEditingMode = !!payment;
  
  return useMutation({
    mutationFn: async (data) => {
      const payloadData = {
        loanMonthlyPayment: Number(data.loanMonthlyPayment),
        loanPayment: convertDayJSDateToUTC(data.loanPayment),
        paidBy: data.paidBy,
      };

      return isEditingMode
        ? updateProjectCLLoanPayment(lender?.projectId, lender?.id, {
            ...payloadData,
            paymentId: payment?.paymentId,
          })
        : addProjectCLLoanPayment(lender?.projectId, lender?.id, payloadData);
    },
    onSuccess: () => 
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.constructionLender, lender?.projectId],
      })
  });
}
