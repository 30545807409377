import CommentForm from './commentForm';
import React from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Avatar,
  styled
} from "@mui/material";
const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = null,
  currentUserId,
}) => {

  const isEditing =
    activeComment && activeComment.id === comment.id && activeComment.type === 'editing';
  const isReplying =
    activeComment && activeComment.id === comment.id && activeComment.type === 'replying';
  const canDelete = currentUserId === comment.userId && replies.length === 0;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.userId;
  const replyId = parentId ? parentId : comment.id;
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const createdAt = new Date(comment.createdAt).toLocaleDateString('en-US', options);
  return (
    <Box key={comment.id} sx={{ marginBottom: 2, paddingLeft: 2,marginTop:2 }}>
      {/* <div className='comment-container'></div>
      <div className='comment-right-part'>
        <div className='comment-content'> */}
      <Box display="flex" alignItems="center">
        {/* <div className='comment-author'>{'Name`'}</div> */}
        <Avatar
          src="https://tse3.mm.bing.net/th?id=OIP.U2ieG5W_KES2sKSDNgEpbwHaHa&pid=Api&P=0&h=180"
          alt="User"
          sx={{ width: 32, height: 32, marginRight: "5px" }}
        />
        {/* <div className='comment-date'>{createdAt}</div> */}
        <Box>
          <Typography sx={{ color: "#2E3134", fontWeight: "600", fontSize: "16px" }}>
            {comment.projectId}
          </Typography>
          <Typography sx={{ color: "#5F5F64", fontWeight: "400", fontSize: "10px" }}>
            {createdAt}
          </Typography>
        </Box>
      </Box>
      {!isEditing &&
        // <div className='comment-text'>{comment.content}</div>
        <Typography sx={{ marginTop: "7px", color: "#5F6368", fontWeight: "400", fontSize: "14px" }}>
          {comment?.content}
        </Typography>
      }
      <Typography sx={{ marginTop: "7px", color: "#5F6368", fontWeight: "400", fontSize: "14px" }}>
        {isEditing && (
          <CommentForm
            initialText={comment.content}
            handleSubmit={(text) => updateComment(text, comment.id)}
          />
        )}
      </Typography>
      {/* <div className='comment-actions'> */}
      <Box display="flex" gap="20px" mt="7px">
        {canReply && (
          <div
            className='comment-action'
            onClick={() => setActiveComment({ id: comment.id, type: 'replying' })}
          >
            Reply
          </div>
        )}
        {canEdit && (
          <div
            className='comment-action'
            onClick={() => setActiveComment({ id: comment.id, type: 'editing' })}
          >
            Edit
          </div>
        )}
        {canDelete && (
          <div className='comment-action-delete' onClick={() => deleteComment(comment.id)}>
            Delete
          </div>
        )}
      </Box>
      {/* </div> */}
      {isReplying && <CommentForm handleSubmit={(text) => addComment(text, replyId)} />}
      {replies.length > 0 && (
        <div className='replies'>
          {replies.map((reply) => (
            <Comment
              comment={reply}
              key={reply.id}
              setActiveComment={setActiveComment}
              activeComment={activeComment}
              updateComment={updateComment}
              deleteComment={deleteComment}
              addComment={addComment}
              parentId={comment.id}
              replies={[]}
              currentUserId={currentUserId}
            />
          ))}
        </div>
      )}
      {/* </div> */}
    </Box>
  );
};

export default Comment;
