const KEY_ROOT = {
  USER: 'user',
  PROFILE: 'profile',
  PROJECTS_COUNTS: 'projectsCounts',
  PROJECT: 'project',
  REQUESTS: 'requests',
  SERVICES: 'services',
};

const PROJECT_SECTIONS = {
  BASIC: 'basic',
  LOCATION: 'location',
  DOCUMENTS: 'documents',
  LLC: 'llc',
  MEMBERS: 'members',
  REQUESTS: 'requests',
  ROLES: 'roles',
  CONTRACT: 'contract',
  INVESTMENTS: 'investments',
  USER_INVESTMENTS: 'userInvestments',
  EXPENSES: 'expenses',
  LEASE_SALES: 'leaseSales',
  BUILDINGS: 'buildings',
  UNIT_LEASES: 'unitLeases',
  UNIT_SALES: 'unitSales',
  GENERAL_CONTRACTOR: 'generalContractor',
  PROPERTY_MANAGEMENT: 'propertyManagement',
  CONSTRUCTION_LENDER: 'constructionLender',
  CLOSING: 'closing',
};

export const queryKeys = {
  currentUser: [KEY_ROOT.USER],
  allUsers: [KEY_ROOT.USER, 'all'],
  profileImage: [KEY_ROOT.PROFILE, 'image'],
  profileLLC: [KEY_ROOT.PROFILE, PROJECT_SECTIONS.LLC],
  userInfo: [KEY_ROOT.USER, 'info'],
  projectsCounts: [KEY_ROOT.PROJECTS_COUNTS],
  requests: [KEY_ROOT.USER, KEY_ROOT.REQUESTS],
  services: [KEY_ROOT.SERVICES],
  project: {
    basic: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.BASIC],
    location: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.LOCATION],
    documents: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.DOCUMENTS],
    llc: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.LLC],
    members: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.MEMBERS],
    requests: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.REQUESTS],
    roles: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.ROLES],
    contract: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.CONTRACT],
    investments: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.INVESTMENTS],
    userInvestments: [
      KEY_ROOT.PROJECT,
      PROJECT_SECTIONS.INVESTMENTS,
      PROJECT_SECTIONS.USER_INVESTMENTS,
    ],
    expenses: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.EXPENSES],
    buildings: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.LEASE_SALES, PROJECT_SECTIONS.BUILDINGS],
    buildingUnitLeases: [
      KEY_ROOT.PROJECT,
      PROJECT_SECTIONS.LEASE_SALES,
      PROJECT_SECTIONS.BUILDINGS,
      PROJECT_SECTIONS.UNIT_LEASES,
    ],
    buildingUnitSales: [
      KEY_ROOT.PROJECT,
      PROJECT_SECTIONS.LEASE_SALES,
      PROJECT_SECTIONS.BUILDINGS,
      PROJECT_SECTIONS.UNIT_SALES,
    ],
    leaseSales: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.LEASE_SALES],
    generalContractor: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.GENERAL_CONTRACTOR],
    propertyManagement: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.PROPERTY_MANAGEMENT],
    constructionLender: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.CONSTRUCTION_LENDER],
    closing: [KEY_ROOT.PROJECT, PROJECT_SECTIONS.CLOSING],
  },
};
