import { queryKeys } from 'app/react-query/queryKeys';
import { getProfileDetails } from 'app/contexts/userAuthActions';
import { useQuery } from '@tanstack/react-query';
import { getAuthToken, setAuthToken } from 'app/contexts/localStorage';
import { customQueryOptions } from 'app/react-query/customQueryOptions';

export function useProfileDetails(isTokenFresh) {
  const authToken = getAuthToken();
  setAuthToken(authToken);

  return useQuery({
    queryKey: queryKeys.currentUser,
    queryFn: getProfileDetails,
    enabled: isTokenFresh,
    ...customQueryOptions.userData,
  });
}
