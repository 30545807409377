import { styled } from '@mui/material';

export const Avatar = styled('div')({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: 'lightblue',
  marginRight: '5px',
  marginLeft: '10px',
});

export const SendIcon = styled('span')({
  cursor: 'pointer',
  fontSize: '20px',
  color: 'var(--SendIcon-color)',
  marginRight: '5px',
});
