export const mockServices = [
  {
    name: 'lenderDetail',
    displayName: 'LenderDetails',
    description: 'APIs for assigning LenderDetails with projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get LenderDetails',
        description: 'Get LenderDetails  based on Id',
        identifier: 'getLenderDetails',
        verbs: ['GET'],
      },
      {
        displayName: 'Update LoanWithdrawal',
        description: 'update LoanWithdrawal based on details',
        identifier: 'updateLoanWithdrawal',
        verbs: ['PUT'],
      },
      {
        displayName: 'Save Lender',
        description: 'Save Lender based on lender details',
        identifier: 'saveLenderDetails',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete lenderDetails',
        description: 'Delete lenderDetails based on id',
        identifier: 'deleteLenderDetails',
        verbs: ['DELETE'],
      },
      {
        displayName: 'AddLoanPayment',
        description: 'Add LoanPayment based on LoanPayment',
        identifier: 'addLoanPayment',
        verbs: ['POST'],
      },
      {
        displayName: 'Add LoanWithdrawal',
        description: 'Add LoanWithdrawal based on details',
        identifier: 'addLoanWithdrawal',
        verbs: ['POST'],
      },
      {
        displayName: 'Update LoanPayments',
        description: 'update LoanPayments based on details',
        identifier: 'updateLoanPayments',
        verbs: ['PUT'],
      },
    ],
  },
  {
    name: 'comment',
    displayName: 'Comments',
    description: 'APIs for assigning Comment',
    group: 'projects',
    actions: [
      {
        displayName: 'Update Comment',
        description: 'Update comment for a project based on commentId',
        identifier: 'getComment',
        verbs: ['PUT'],
      },
      {
        displayName: 'Get Comment',
        description: 'Get comment for a project',
        identifier: 'getComment',
        verbs: ['GET'],
      },
      {
        displayName: 'Create reply',
        description: 'Create reply for a comment',
        identifier: 'createReplyForComment',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete comment and replies',
        description: 'Delete comment and replies for a project based on commentId',
        identifier: 'deleteComment',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Create comment',
        description: 'Create comment for a project',
        identifier: 'createComment',
        verbs: ['POST'],
      },
      {
        displayName: 'Get All Comments',
        description: 'Get All comments for a project',
        identifier: 'getAllComments',
        verbs: ['GET'],
      },
    ],
  },
  {
    name: 'projects',
    displayName: 'Project',
    description: 'APIs for creating projects. ',
    group: 'projects',
    actions: [
      {
        displayName: 'Delete Project',
        description: 'Delete project based on provided project id ',
        identifier: 'deleteProject',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Get Project',
        description:
          'Get project based on project id. If null is passed then all projects will be returned',
        identifier: 'getProjectDetails',
        verbs: ['GET'],
      },
      {
        displayName: 'Update Project',
        description: 'update project based on provided request',
        identifier: 'updateProject',
        verbs: ['PUT'],
      },
      {
        displayName: 'Update Project type',
        description: 'update project type based on provided request',
        identifier: 'updateProjectType',
        verbs: ['PUT'],
      },
    ],
  },
  {
    name: 'location',
    displayName: 'Locations',
    description: " API's for Assigning Locations with Projects",
    group: 'projects',
    actions: [
      {
        displayName: 'Get Locations',
        description: 'Get Locations based on projectId',
        identifier: 'getLocation',
        verbs: ['GET'],
      },
      {
        displayName: 'Delete Locations',
        description: 'Delete Locations based on projectId',
        identifier: 'deleteLocation',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Save Location',
        description: 'Save Location based on project id',
        identifier: 'saveLocations',
        verbs: ['POST'],
      },
    ],
  },
  {
    name: 'investments',
    displayName: 'Investments',
    description: 'APIs for assigning investments with projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Add investment',
        description: 'Add investment based on project id & userId',
        identifier: 'addInvestment',
        verbs: ['POST'],
      },
      {
        displayName: 'Get investment details',
        description: 'Get investment details based on projectId',
        identifier: 'getInvestmentDetails',
        verbs: ['GET'],
      },
      {
        displayName: 'Update  investments',
        description: 'Update investments based on project id',
        identifier: 'updateInvestment',
        verbs: ['PUT'],
      },
      {
        displayName: 'Add investor payment',
        description: 'Add investor payment based on project id & investorUserId',
        identifier: 'addInvestmentPayment',
        verbs: ['POST'],
      },
      {
        displayName: 'Save investments',
        description: 'Save investments based on project id',
        identifier: 'saveInvestments',
        verbs: ['POST'],
      },
      {
        displayName: 'Get investments',
        description: 'Get investments based on projectId',
        identifier: 'getInvestments',
        verbs: ['GET'],
      },
      {
        displayName: 'Delete investments',
        description: 'Delete investments based on projectId',
        identifier: 'deleteInvestments',
        verbs: ['DELETE'],
      },
    ],
  },
  {
    name: 'sale',
    displayName: 'Sales',
    description: " API's for Assigning Sales with Projects",
    group: 'projects',
    actions: [
      {
        displayName: 'Save sales',
        description: 'Save sales based on project id',
        identifier: 'createSales',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete Sales',
        description: 'Delete Sales based on projectId',
        identifier: 'deleteSales',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Get sales',
        description: 'Get sales based on projectId',
        identifier: 'getSales',
        verbs: ['GET'],
      },
      {
        displayName: 'Get All Sales',
        description: 'Get All Sales based on projectId',
        identifier: 'getAllSales',
        verbs: ['GET'],
      },
    ],
  },
  {
    name: 'general-contractor',
    displayName: 'General-Contractor',
    description: " API's for Assigning GeneralContractors with Projects",
    group: 'projects',
    actions: [
      {
        displayName: 'Add Payments',
        description: 'Add Payments based on projectId and generalContractorId',
        identifier: 'addPayment',
        verbs: ['POST'],
      },
      {
        displayName: 'Update Change Order',
        description: 'Update Change Order based on projectId and generalContractorId',
        identifier: 'updateChangeOrder',
        verbs: ['POST'],
      },
      {
        displayName: 'Add Manager Details',
        description: 'Add Manager Details based on projectId and generalContractorId',
        identifier: 'addManagerDetails',
        verbs: ['POST'],
      },
      {
        displayName: 'Update Manager Details',
        description: 'Update Manager Details based on projectId and generalContractorId',
        identifier: 'updateManagerDetails',
        verbs: ['POST'],
      },
      {
        displayName: 'Get general contractor',
        description: 'Get general contractor based on projectId',
        identifier: 'getGeneralContractor',
        verbs: ['GET'],
      },
      {
        displayName: 'Get All General Contractor',
        description: 'Get All General Contractor based on projectId',
        identifier: 'getAllGeneralContractor',
        verbs: ['GET'],
      },
      {
        displayName: 'Update Payments Details',
        description: 'Update Payments based on projectId and generalContractorId and paymentId',
        identifier: 'updatePaymentDetails',
        verbs: ['PUT'],
      },
      {
        displayName: 'getPaymentHistory',
        description: 'Get paymentHistory based on projectId and generalContractorId and paymentId',
        identifier: 'getPaymentHistory',
        verbs: ['GET'],
      },
      {
        displayName: 'getAllPayments',
        description: 'Get getAllPayments based on projectId and generalContractorId',
        identifier: 'getAllPayments',
        verbs: ['GET'],
      },
      {
        displayName: 'Save General Contractor',
        description: 'Save general contractor based on project id',
        identifier: 'createGeneralContractor',
        verbs: ['POST'],
      },
      {
        displayName: 'Add Change Order',
        description: 'Add Change Order based on projectId and generalContractorId',
        identifier: 'addChangeOrder',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete general contract',
        description: 'Delete general based on projectId',
        identifier: 'deleteGeneralContractor',
        verbs: ['DELETE'],
      },
    ],
  },
  {
    name: 'property-management',
    displayName: 'PropertyManagement',
    description: 'APIs for assigning propertyManagement with projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get All PropertyManagement',
        description: 'Get All PropertyManagement based on projectId',
        identifier: 'getAllPropertyManagement',
        verbs: ['GET'],
      },
      {
        displayName: 'add contactPerson',
        description: 'add contactPerson based on projectId',
        identifier: 'addContactPerson',
        verbs: ['POST'],
      },
      {
        displayName: 'update contactPerson',
        description: 'update contactPerson based on projectId',
        identifier: 'updateContactPerson',
        verbs: ['PUT'],
      },
      {
        displayName: 'Save PropertyManagement',
        description: 'Save PropertyManagement based on project id',
        identifier: 'createPropertyManagement',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete PropertyManagement',
        description: 'Delete PropertyManagement based on projectId',
        identifier: 'deletePropertyManagement',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Get PropertyManagement',
        description: 'Get PropertyManagement based on projectId',
        identifier: 'getPropertyManagement',
        verbs: ['GET'],
      },
    ],
  },
  {
    name: 'llc',
    displayName: 'Project Llc',
    description: 'APIs for assigning llc with projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get User Llc',
        description: 'Get User llc based on userId',
        identifier: 'getUserLlc',
        verbs: ['GET'],
      },
      {
        displayName: 'Delete User llc',
        description: 'Delete User llc based on id',
        identifier: 'deleteUserLlc',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Save llc',
        description: 'Save Llc based on project id',
        identifier: 'saveLlc',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete llc',
        description: 'Delete llc based on einNum',
        identifier: 'deleteLlc',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Get llc',
        description: 'Get llc based on projectId',
        identifier: 'getLlc',
        verbs: ['GET'],
      },
    ],
  },
  {
    name: 'document',
    displayName: 'Documents',
    description: 'APIs for assigning document with projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get Documents',
        description: 'Get documents based on project id',
        identifier: 'downloadFilesBasedOnProjectId',
        verbs: ['GET'],
      },
      {
        displayName: 'Upload Documents',
        description: 'Upload documents based on user id & project id',
        identifier: 'uploadDocuments',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete Document',
        description: 'Delete document based on fileId',
        identifier: 'deleteFileByFileId',
        verbs: ['DELETE'],
      },
    ],
  },
  {
    name: 'member',
    displayName: 'Members',
    description: 'APIs for assigning members with projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get Members',
        description: 'Get Members based on project id',
        identifier: 'getMembers',
        verbs: ['GET'],
      },
      {
        displayName: 'Assign Members',
        description: 'Assign members based on provided projectId',
        identifier: 'assignMembers',
        verbs: ['POST'],
      },
      {
        displayName: 'Update role of existing member',
        description: 'Update role of existing member',
        identifier: 'updateMemberRole',
        verbs: ['PUT'],
      },
      {
        displayName: 'Delete Members',
        description: 'Delete Members based on provided project id ',
        identifier: 'deleteProject',
        verbs: ['DELETE'],
      },
    ],
  },
  {
    name: 'expense',
    displayName: 'Expenses',
    description: 'APIs for adding expenses to the projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get expenses',
        description: 'Get expenses based on projectId',
        identifier: 'getExpenses',
        verbs: ['GET'],
      },
      {
        displayName: 'Get All Expenses',
        description: 'Get All Expenses based on projectId',
        identifier: 'getAllExpenses',
        verbs: ['GET'],
      },
      {
        displayName: 'Save expenses',
        description: 'Save expenses based on project id',
        identifier: 'createExpenses',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete Expenses',
        description: 'Delete Expenses based on projectId',
        identifier: 'deleteExpenses',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Add paymentDetails',
        description: 'Add paymentDetails based on id and invoiceNo',
        identifier: 'updateBalance',
        verbs: ['POST'],
      },
      {
        displayName: 'Get paymentDetails',
        description: 'Get paymentDetails based on id and invoiceNo',
        identifier: 'getPaymentDetails',
        verbs: ['GET'],
      },
    ],
  },
  {
    name: 'contract-detail',
    displayName: 'Contract Details',
    description: "Contract Details API's Assigning to Projects",
    group: 'projects',
    actions: [
      {
        displayName: 'Save ContractDetails',
        description: 'Save ContractDetails based on project id',
        identifier: 'saveContractDetails',
        verbs: ['POST'],
      },
      {
        displayName: 'Delete ContractDetails',
        description: 'Delete ContractDetails based on projectId/contractId',
        identifier: 'deleteContractDetails',
        verbs: ['DELETE'],
      },
      {
        displayName: 'Get ContractDetails',
        description: 'Get ContractDetails based on projectId',
        identifier: 'getContractDetails',
        verbs: ['GET'],
      },
    ],
  },
  {
    name: 'invitation',
    displayName: 'Invitations',
    description: 'APIs for Invitations.',
    group: 'projects',
    actions: [
      {
        displayName: 'GetInvitationsForProject',
        description: 'API to get invitations for project',
        identifier: 'getInvitationsForProject',
        verbs: ['GET'],
      },
      {
        displayName: 'sendInvitation',
        description: 'This API used to send invitation',
        identifier: 'sendInvitation',
        verbs: ['POST'],
      },
    ],
  },
  {
    name: 'lease',
    displayName: 'Lease',
    description: 'APIs for creating a lease for the projects.',
    group: 'projects',
    actions: [
      {
        displayName: 'Get Unit',
        description: 'Get unit for building based on project id',
        identifier: 'getUnits',
        verbs: ['POST'],
      },
      {
        displayName: 'Add Unit',
        description: 'Add unit for building based on project id',
        identifier: 'addUnits',
        verbs: ['POST'],
      },
      {
        displayName: 'Get lease',
        description: 'Get lease based on projectId',
        identifier: 'getLease',
        verbs: ['GET'],
      },
      {
        displayName: 'Get All Lease',
        description: 'Get All Lease based on projectId',
        identifier: 'getAllLease',
        verbs: ['GET'],
      },
      {
        displayName: 'Save Lease',
        description: 'Save lease based on project id',
        identifier: 'createLease',
        verbs: ['POST'],
      },
      {
        displayName: 'Add Building',
        description: 'Add Building for provided project id',
        identifier: 'addBuilding',
        verbs: ['POST'],
      },
      {
        displayName: 'Get Building',
        description: 'Get building based on projectId',
        identifier: 'getBuilding',
        verbs: ['GET'],
      },
      {
        displayName: 'Delete lease',
        description: 'Delete lease based on projectId',
        identifier: 'deleteLease',
        verbs: ['DELETE'],
      },
    ],
  },
];
