import React from 'react';
import { Grid } from "@mui/material";
import { StyleAddOrgButton, StyledIcon, StyledTitle } from "./Typography";

const Header = ({ buttonVisible, buttonName, headerName, onButtonClick }) => {

    return (
        <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <StyledTitle>{headerName}</StyledTitle>

            {buttonVisible && (
                <StyleAddOrgButton
                    startIcon={<StyledIcon />}
                    onClick={onButtonClick}
                >
                    {buttonName}
                </StyleAddOrgButton>
            )}
        </Grid>
    );
};

export default Header;
