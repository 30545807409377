import { useQuery } from '@tanstack/react-query';
import { getProjectInvestments } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectInvestments(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.investments, projectId],
    queryFn: () => getProjectInvestments(projectId),
    placeholderData: {},
  });
}
