import React from "react";
import {
  Box,
  CardContent,
  CardActions,
  Avatar,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmailIcon from "@mui/icons-material/Email";
import { useProjectGCAll } from "../LeadsData/GC/hooks/useProjectGCAll";
import GeneralContractorAddEdit from "../LeadsData/GC/GeneralContractorAddEdit";
import { useParams } from "react-router-dom";
import {
  StyledAddButton, StyledIcon, IconDivider,
  StledCardTitle, GPCardBody, GPBodyCard, StyledAvatar,
  GPName, GPRole, GPPhoneNumber, GPEmailId, GPContactButton, StyledMenuIcon
} from "./StyledComponent";


const GPSection = () => {

  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [selectedContractor, setSelectedContractor] = React.useState(null);
  const { projectId } = useParams();
  const { data: generalContractors } = useProjectGCAll(projectId);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleMoreVertClick = (contractor) => {
    setSelectedContractor(contractor);
    setIsOpenDialog(true);
  };

  const handleAddGeneralPartner = () => {
    setSelectedContractor(null);
    setIsOpenDialog(true);
  };

  return (
    <>
      <Box sx={{ margin: '20px 38px 20px 20px' }} display="flex" justifyContent="space-between" alignItems="center">
        <StledCardTitle>General Partners</StledCardTitle>

        <StyledAddButton
          startIcon={
            <StyledIcon />
          }
          onClick={handleAddGeneralPartner}
        >
          General Partner
        </StyledAddButton>

      </Box>
      <IconDivider orientation="vertical" />
      <Box sx={{ overflow: 'auto', display: 'flex' }}>
        <GPCardBody>
          {generalContractors && generalContractors.length > 0 ? (
            generalContractors.map((partner) => (
              <GPBodyCard key={partner.id}>
                <CardContent sx={{ textAlign: "center" }}>

                  {partner?.avatarUrl ? (
                    <Avatar
                      src="https://th.bing.com/th/id/R.70511d5d811763466ab10d1b049c821a?rik=vfoZemsxnNW4cg&riu=http%3a%2f%2fwww.floridapublicmedia.org%2fwp-content%2fuploads%2f2017%2f03%2fexplorer.png&ehk=ZE02GJZXgIepaFGWfE54zhvVy44jGK0zRfh7LzximU8%3d&risl=&pid=ImgRaw&r=0"
                      alt={partner.contractorDetails.contractName}
                      sx={{ width: "90px", height: '90px', margin: "0 auto" }}
                    />
                  ) : (
                    <StyledAvatar>
                      {partner?.initials}
                    </StyledAvatar>
                  )}

                  <GPName>
                    {partner?.contractorDetails.contractName}
                  </GPName>
                  <GPRole>
                    {"General Partner"}
                  </GPRole>
                  <GPPhoneNumber>
                    {partner?.contractorDetails.phoneNum}
                  </GPPhoneNumber>
                  <GPEmailId>
                    {partner?.contractorDetails.emailId}
                  </GPEmailId>
                </CardContent>

                <CardActions sx={{ justifyContent: "center" }}>
                  <GPContactButton
                    variant="contained"
                    startIcon={<EmailIcon />}
                    size="small"
                  >
                    Contact
                  </GPContactButton>
                </CardActions>

                <Tooltip>
                  <StyledMenuIcon>
                    <MoreVertIcon onClick={() => handleMoreVertClick(partner)} />
                  </StyledMenuIcon>
                </Tooltip>
              </GPBodyCard>
            ))
          ) : (
            <Box sx={{ width: "100%", padding: "7rem" }}>
              No General Partners Added
            </Box>
          )}
        </GPCardBody>
      </Box>
      {isOpenDialog && <GeneralContractorAddEdit projectId={projectId} contractor={selectedContractor} closeDialog={toggleDialog} />}
    </>
  );
};

export default GPSection;

