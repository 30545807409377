import { useQuery, useQueries, useQueryClient } from '@tanstack/react-query';
import { invitationsGetAll } from 'app/contexts/requestsActions';
import { getProjectDetails } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useRequests() {
  const queryClient = useQueryClient();

  const { data: requests, isLoading: isLoadingRequests } = useQuery({
    queryKey: queryKeys.requests,
    queryFn: invitationsGetAll,
    placeholderData: [],
  });

  const pendingRequests = requests.filter((request) => request.status === 'PENDING');

  const detailsQueries = useQueries({
    queries: pendingRequests.map((request) => ({
      queryKey: [...queryKeys.project.basic, request.projectId],
      queryFn: () => getProjectDetails(request.projectId),
      enabled: pendingRequests.length > 0,
      staleTime: Infinity,
      gcTime: Infinity,
    })),
  });

  const requestsWithDetails = pendingRequests.map((request) => {
    const detailsQuery = queryClient.getQueryData([...queryKeys.project.basic, request.projectId]);

    return {
      ...request,
      projectTitle: detailsQuery?.projectTitle,
    };
  });

  return {
    data: requestsWithDetails,
    isPending: isLoadingRequests || detailsQueries.some((query) => query.isPending),
  };
}
