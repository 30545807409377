import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CommentsList from "app/components/Activities/commentsList";
import { useUserAuth } from "app/hooks/userUserAuth";
import { useParams } from "react-router-dom";
import { StyledTabs, StyledTab, IconDivider } from "./StyledComponent";
import AuditLog from "app/components/Activities/AuditLog";

function AuditLogsSection() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { userNameJWT } = useUserAuth();
  const { projectId } = useParams();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Typography sx={{ color: "#17181B", fontWeight: "600", fontSize: "24px", margin: "20px" }}>
        Audit Log
      </Typography>
      <IconDivider orientation="vertical" />

      <StyledTabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          marginLeft: '26px',
          "& .MuiTabs-indicator": {
            backgroundColor: "#82B485",
            height: "3px",
            borderRadius: "2px",
          },
          "& .MuiTab-root": {
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "500",
            color: '#80868B'
          },
          "& .Mui-selected": {
            color: "#17181B",
            fontWeight: 700
          }
        }}
      >
        <StyledTab label="Comments" />
        <StyledTab label="All Activities" />

      </StyledTabs >

      <IconDivider orientation="vertical" />

      {
        selectedTab === 0 && (
          <>
            <CommentsList currentUserId={userNameJWT} projectId={projectId} />
          </>
        )
      }
      {
        selectedTab === 1 && (
          <Box sx={{ marginLeft: "2rem", marginTop: "20px" }}>
            <Typography sx={{ fontStyle: "italic", color: "#5F6368" }}>
              {/* All activities will appear here (this section is currently under development). */}
              <AuditLog />
            </Typography>
          </Box>
        )
      }
    </>
  );
}

export default AuditLogsSection;
