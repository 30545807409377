import React, { useState } from 'react';
import {
    Box, TextField, FormControl,
    List,
    ListItem,
    Autocomplete,
    ListItemText,
    ListItemIcon, Typography, IconButton, Divider, styled
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { designationOptions } from 'app/utils/constant';
import { DescriptionIcon, LCDeleteIcon } from 'app/common/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { StyledBox1,StyledModalTitle,StyledCrossIcon,StyledBox2,FileDropArea,
    StyledLabel,StyledTextField,UploadedList,StyledBox4,ModalButtonCancle,ModalButtonSave,
    StyledBox3,
} from './StyledComponents';

const DocumentModal = ({ handleCloseModal }) => {
    const [documentType, setDocumentType] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectSubType, setSelectSubType] = useState(null);

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles((prev) => [...prev, ...files]);
    };

    const handleRemoveFile = (index) => {
        setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSave = () => {
        console.log("Files saved:", uploadedFiles);
        handleCloseModal();
    };
    return (
        <>
            <StyledBox1>
                <StyledBox2  >
                    <StyledModalTitle>Add Document</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <StyledBox3>
                    <FormControl fullWidth margin="normal">
                        <StyledLabel>
                            Document Type
                        </StyledLabel>

                        <Autocomplete
                            size="small"
                            value={selectSubType}
                            onChange={(event, newValue) => {
                                setSelectSubType(newValue);
                            }}
                            options={designationOptions}
                            getOptionLabel={(option) => option.label || ""}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Select Document Type"
                                />
                            )}
                        />

                    </FormControl>
                    <FileDropArea>
                        <input
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            id="file-upload"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="file-upload">
                            <Typography sx={{ cursor: 'pointer', fontWeight: '500', fontSize: '14px', color: '#282A2D' }}>
                                Drag and Drop or <span style={{ textDecoration: "underline" }}>Click To Upload</span>
                            </Typography>
                        </label>
                    </FileDropArea>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#282A2D', marginLeft: '4%', marginTop: '0.5rem', marginBottom: '2rem' }}>
                        Supported files: .pdf, .doc, .xls, .text | Max file size: 10MB
                    </Typography>
                    <Divider />
                    <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#282A2D', marginLeft: '4%', marginTop: '2rem' }}>
                        Uploaded Documents
                    </Typography>
                    <UploadedList>
                        {uploadedFiles.map((doc, index) => (
                            <ListItem key={doc.id} sx={{
                                display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, marginBottom: '13px', backgroundColor: "#F9FBFC",
                                borderRadius: "4px",
                            }}>
                                <ListItemIcon>
                                    <DescriptionIcon sx={{ color: "#4A90E2", fontSize: "1.75rem" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{ fontSize: "16px", color: '#2A3C4E', fontWeight: '600' }}
                                        >
                                            {doc?.name}
                                        </Typography>
                                    }
                                />
                                <IconButton edge="end" onClick={() => handleRemoveFile(index)}>
                                    <LCDeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </UploadedList>
                </StyledBox3>

                <StyledBox4  >
                    <ModalButtonCancle
                        variant='outlined'
                        onClick={handleCloseModal}
                    // disabled={isSubmitting}
                    >
                        Cancel
                    </ModalButtonCancle>
                    <ModalButtonSave
                        type='submit'
                    // loading={isSubmitting}
                    >
                        {true ? 'Update' : 'Save'}
                    </ModalButtonSave>
                </StyledBox4>
            </StyledBox1>
        </>
    )
}

export default DocumentModal
