import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createProjectGC, updateProjectGC } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectGCCreateUpdate(projectId, contractor) {
  const queryClient = useQueryClient();

  const isEditingMode = !!contractor;

  return useMutation({
    mutationFn: async (data) => {
      const payloadData = {
        contractorDetails: data,
        gcManager: contractor?.gcManager || [],
        gcSiteManager: contractor?.gcSiteManager || [],
        changeOrders: contractor?.changeOrders || [],
        payments: contractor?.payments || [],
      };

      return isEditingMode
        ? await updateProjectGC(projectId, contractor?.id, {
            ...payloadData,
            projectId,
            id: contractor?.id,
          })
        : await createProjectGC(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.generalContractor, projectId],
      }),
  });
}
