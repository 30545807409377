import React, { useState } from 'react';
import {
  DialogTitle,
  IconButton,
  Box,
  Grid,
  Button,
  FormControl,
  Input,
  Typography,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectUpdateStage } from './hooks/useProjectUpdateStage';
import { notify } from 'app/services/notify';
import { useProject } from 'app/contexts/ProjectContext';
import {
  StyledBox1,
  StyledBox2,
  StyledBox3,
  StyledCrossIcon,
  StyledLabel,
  StyledModalTitle,
} from 'app/views/dashboard/profile/StyleComponents';
import {
  Divider,
  StyledButton,
  StyledTextField,
} from 'app/common/Typography';
import { DeleteIcon, DocIcon, DownloadDragIcon, PdfIcon } from 'app/common/icons';
import { convertDayJSDateToUTC } from 'app/utils/helpers';
import { projectTypes } from 'app/utils/constant';
import { useNavigate } from 'react-router-dom';

const StyledTypography = styled(Typography)({
  fontSize: '18px',
  fontWeight: '500',
});
const DialogHeader = styled(Typography)({
  fontSize: '18px',
  fontWeight: '500',
  paddingLeft: '10px'
});
const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--dialogDivider)',
});

const StepButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
});
const FileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: 'var(--fileContainer-bg)',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '40vh',
  overflowY: 'auto',
});

const NewProjectDialog = ({ closeModal,projectId,previousPType }) => {
  const [currentStep, setCurrentStep] = useState(1);
  // const { projectId } = useProject();
  const navigate = useNavigate();
  
  const { mutate: projectMoveToNew } = useProjectUpdateStage(projectId, projectTypes.NEW_PROJECT,previousPType);
  const calculateTotalInvestment = (landCost, softCost) => {
    return parseFloat(landCost) + parseFloat(softCost);
  };

  const calculateTotalShares = (totalInvestment, sharePrice) => {
    return totalInvestment / sharePrice;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const {
      landCost,
      softCost,
      totalInvestment,
      eachSharePrice,
      totalShare,
      effectiveDate,
      feasibilityEndDate,
      estimatedClosingDate,
      earnestMoney,
      optionsMoney,
      documents,
    } = values;

    const availableShares = totalShare;

    const contract = {
      effectiveDate: convertDayJSDateToUTC(effectiveDate),
      estimatedClosingDate: convertDayJSDateToUTC(estimatedClosingDate),
      feasibilityEndDate: convertDayJSDateToUTC(feasibilityEndDate),
      earnestMoney,
      optionsMoney,
      extended: false,
    };

    const investments = {
      landCost,
      softCost,
      totalInvestment,
      eachSharePrice,
      totalShare,
      availableShares,
    };

    projectMoveToNew(
      { documents, contract, investments },
      {
        onSuccess: () => {
          closeModal();
          notify.success(
            <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
              Project successfully moved to
              <Grid>{`the New Project`}</Grid>
            </Typography>,
          );
          navigate(`/myprojects/${projectId}`);
        },
        onSettled: () => setSubmitting(false),
      },
    );
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    }
  };

  const handlePrevStep = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  return (
    <StyledBox1 open onClose={closeModal}>
      <StyledBox2>
        <StyledModalTitle>Converting Listing to New Project</StyledModalTitle>
        <StyledCrossIcon onClick={closeModal}>
          <CloseIcon />
        </StyledCrossIcon>
      </StyledBox2>
      <DialogTitle>
        {currentStep === 1
          ? 'Step 1/2: Contract Details'
          : 'Step 2/2: Estimated Investment details'}
      </DialogTitle>
      <StyledBox3>
        <Formik
          initialValues={{
            landCost: '',
            softCost: '',
            totalInvestment: '',
            eachSharePrice: '',
            totalShare: '',
            effectiveDate: null,
            estimatedClosingDate: null,
            feasibilityEndDate: null,
            earnestMoney: '',
            optionsMoney: '',
            documents: [],
          }}
          validationSchema={
            currentStep === 1
              ? validationSchemas.newProjectStep1()
              : validationSchemas.newProjectStep2()
          }
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => {
            const handleFileInput = (event) => {
              event.preventDefault();
              console.log(event);

              const filesAccess = {
                change: event.target.files,
                drop: event.dataTransfer?.files,
              };

              const newFiles = Array.from(filesAccess[event.type]).map((file) => ({
                file,
                docname: '',
              }));

              setFieldValue('documents', [...values.documents, ...newFiles]);
            };

            const handleDocumentNameChange = (event, index) => {
              const updatedDocuments = [...values.documents];
              updatedDocuments[index] = {
                ...updatedDocuments[index],
                docname: event.target.value,
              };

              setFieldValue('documents', updatedDocuments);
            };

            const handleDeleteFile = (index) => {
              const updatedDocuments = [...values.documents];
              updatedDocuments.splice(index, 1);

              setFieldValue('documents', updatedDocuments);
            };

            const handleDragOver = (event) => {
              event.preventDefault();
            };
            return (
              <Form>
                <Box sx={{ p: 2, overflowY: 'auto' }}>
                  {currentStep === 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <StyledLabel>Effective Date</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Effective Date"
                          type='date'
                          name='effectiveDate'
                          value={values.effectiveDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                          helperText={touched.effectiveDate && errors.effectiveDate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Feasibility Due Date</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Feasibility Due Date"
                          type='date'
                          name='feasibilityEndDate'
                          value={values.feasibilityEndDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.feasibilityEndDate && errors.feasibilityEndDate)}
                          helperText={touched.feasibilityEndDate && errors.feasibilityEndDate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Earnest Money</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Earnest Money"
                          name='earnestMoney'
                          value={values.earnestMoney}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.earnestMoney && errors.earnestMoney)}
                          helperText={touched.earnestMoney && errors.earnestMoney}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Estimated Closing Date</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          type='date'
                          variant='outlined'
                          // label="Estimated Closing Date"
                          name='estimatedClosingDate'
                          value={values.estimatedClosingDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.estimatedClosingDate && errors.estimatedClosingDate,
                          )}
                          helperText={touched.estimatedClosingDate && errors.estimatedClosingDate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Options Money</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Options Money"
                          name='optionsMoney'
                          value={values.optionsMoney}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.optionsMoney && errors.optionsMoney)}
                          helperText={touched.optionsMoney && errors.optionsMoney}
                        />
                      </Grid>
                      <Grid container spacing={2} mb={0} mt={0.5}>
                        <Grid item xs={12}>
                          <StyledTypography>Contract Documents</StyledTypography>
                        </Grid>
                        <Grid item xs={12} onDragOver={handleDragOver} onDrop={handleFileInput}>
                          <FormControl
                            error={touched.documents && Boolean(errors.documents)}
                            fullWidth
                          >
                            <Box
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              border='2px dashed lightgray'
                              borderRadius='4px'
                              p={2}
                            >
                              <Typography variant='subtitle1' color='text.secondary'>
                                <DownloadDragIcon />
                                {` Drag and Drop file here or `}
                              </Typography>
                              <label htmlFor='outlined-button-file'>
                                <StyledButton color='primary' variant='text' component='span'>
                                  Browse files
                                </StyledButton>
                              </label>
                              <Input
                                id='outlined-button-file'
                                key={values.documents.length}
                                sx={{ display: 'none' }}
                                type='file'
                                size='small'
                                name='file'
                                onChange={handleFileInput}
                                inputProps={{ accept: '.pdf, .doc, .docx', multiple: true }}
                              />
                            </Box>
                            <FileContainerDetails>
                              {values.documents.map((documentData, index) => (
                                <FileContainer key={index}>
                                  <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    p={1}
                                  >
                                    <Box display='flex' alignItems='center'>
                                      {documentData.file.type === 'application/pdf' ? (
                                        <PdfIcon />
                                      ) : (
                                        <DocIcon />
                                      )}
                                      <Box>
                                        {documentData.file && (
                                          <>
                                            <Typography variant='body1' fontWeight='bold' ml={1}>
                                              {documentData.file.name}
                                            </Typography>
                                            <Typography variant='caption' ml={1}>
                                              {(documentData.file.size / 1024).toFixed(2)} KB
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                    </Box>
                                    <IconButton onClick={() => handleDeleteFile(index)}>
                                      <DeleteIcon style={{ color: 'red' }} />
                                    </IconButton>
                                  </Box>
                                  <StyledTextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    placeholder='Document Name'
                                    name={`documents[${index}].docname`}
                                    value={documentData.docname}
                                    onChange={(e) => handleDocumentNameChange(e, index)}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      errors?.documents?.[index]?.docname &&
                                        touched?.documents?.[index]?.docname,
                                    )}
                                    helperText={
                                      touched?.documents?.[index]?.docname &&
                                      errors?.documents?.[index]?.docname
                                    }
                                  />
                                </FileContainer>
                              ))}
                              {touched?.documents && errors?.documents && (
                                <FormHelperText>
                                  {!Array.isArray(errors.documents) && errors.documents}
                                </FormHelperText>
                              )}
                            </FileContainerDetails>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <DialogDivider />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {currentStep === 2 && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <StyledLabel>Land Cost</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Land Cost"
                          type='number'
                          name='landCost'
                          value={values.landCost}
                          onChange={(e) => {
                            handleChange(e);
                            const totalInvestment = calculateTotalInvestment(
                              e.target.value,
                              values.softCost,
                            );
                            const totalShares = calculateTotalShares(
                              totalInvestment,
                              values.eachSharePrice,
                            );
                            setFieldValue('landCost', e.target.value);
                            setFieldValue('totalInvestment', totalInvestment);
                            setFieldValue('totalShare', totalShares);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.landCost && errors.landCost)}
                          helperText={touched.landCost && errors.landCost}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledLabel>Soft Cost</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Soft Cost"
                          type='number'
                          name='softCost'
                          value={values.softCost}
                          onChange={(e) => {
                            handleChange(e);
                            const totalInvestment = calculateTotalInvestment(
                              values.landCost,
                              e.target.value,
                            );
                            const totalShares = calculateTotalShares(
                              totalInvestment,
                              values.eachSharePrice,
                            );
                            setFieldValue('softCost', e.target.value);
                            setFieldValue('totalInvestment', totalInvestment);
                            setFieldValue('totalShare', totalShares);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.softCost && errors.softCost)}
                          helperText={touched.softCost && errors.softCost}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Total Investment</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Total Investment"
                          type='number'
                          name='totalInvestment'
                          value={values.totalInvestment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.totalInvestment && errors.totalInvestment)}
                          helperText={touched.totalInvestment && errors.totalInvestment}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Divider />
                      <DialogHeader>Estimates Shares</DialogHeader>
                      <Grid item xs={12}>
                        <StyledLabel>Each Share Value</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Total Share"
                          type='number'
                          name='eachSharePrice'
                          value={values.eachSharePrice}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleChange(e); // Call the existing handleChange
                            const parsedSharePrice = parseFloat(value);

                            // Calculate total investment and total shares based on the updated eachSharePrice
                            const totalInvestment = calculateTotalInvestment(
                              values.landCost,
                              values.softCost,
                            );
                            const totalShares = calculateTotalShares(
                              totalInvestment,
                              parsedSharePrice,
                            );

                            // Update the corresponding fields
                            setFieldValue('eachSharePrice', value);
                            setFieldValue('totalInvestment', totalInvestment);
                            setFieldValue('totalShare', totalShares);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.eachSharePrice && errors.eachSharePrice)}
                          helperText={touched.eachSharePrice && errors.eachSharePrice}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Total Share</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          // label="Total Share"
                          type='number'
                          name='totalShare'
                          value={values.totalShare}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.totalShare && errors.totalShare)}
                          helperText={touched.totalShare && errors.totalShare}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <StepButtonContainer>
                    <Button
                      variant='outlined'
                      color='secondary'
                      onClick={handlePrevStep}
                      disabled={currentStep === 1}
                    >
                      Previous
                    </Button>
                    <Box>
                      {currentStep === 1 ? (
                        <Button variant='contained' color='primary' onClick={handleNextStep}>
                          Next
                        </Button>
                      ) : (
                        <LoadingButton
                          variant='contained'
                          color='primary'
                          type='submit'
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      )}
                    </Box>
                  </StepButtonContainer>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledBox3>
    </StyledBox1>
  );
};

export default NewProjectDialog;
