import StepperForm from 'app/common/stepperForm';
import { ContainerHeader } from 'app/common/Typography';
import Header from 'app/common/Header';

const NewLead = ({type}) => {
  return (
    <ContainerHeader>
      <Header
        buttonVisible={false}
        headerName={type==='lead'?"New Listing":"New Project"}
      />
      <StepperForm type={type} />
    </ContainerHeader>
  );
};

export default NewLead;
