import { useQuery } from '@tanstack/react-query';
import { getProjectRoles } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';
import { useCallback } from 'react';

const buildServicePermissions = (service) => ({
  read: Object.keys(service.actions.read),
  write: Object.keys(service.actions.write),
});

const buildFullAccessRole = (role, allServices) => {
  const roleData = allServices.reduce(
    (acc, [serviceName, service]) => {
      acc.services[serviceName] = service;
      acc.permissions[serviceName] = buildServicePermissions(service);
      return acc;
    },
    {
      services: {},
      permissions: {},
    },
  );

  return {
    ...role,
    ...roleData,
    services: Object.entries(roleData.services),
  };
};

const buildPermissionsByResource = (accumulator, permission, normalizedServices) => {
  const { resource, verb, action } = permission;

  if (action === '*') {
    accumulator.permissions[resource] = buildServicePermissions(normalizedServices[resource]);
    return accumulator;
  }

  const permissionType = verb === 'GET' ? 'read' : 'write';

  accumulator.permissions[resource] ||= {
    read: [],
    write: [],
  };

  accumulator.permissions[resource][permissionType].push(action);

  return accumulator;
};

const buildRoleStructure = (role, allServices) => {
  if (role.permissions.length === 1 && role.permissions[0].resource === '*') {
    return buildFullAccessRole(role, allServices);
  }

  const normalizedServices = Object.fromEntries(allServices);

  const roleData = role.permissions.reduce(
    (accumulator, permission) => {
      buildPermissionsByResource(accumulator, permission, normalizedServices);
      accumulator.services[permission.resource] = normalizedServices[permission.resource];
      return accumulator;
    },
    {
      services: {},
      permissions: {},
    },
  );

  return {
    ...role,
    ...roleData,
    services: Object.entries(roleData.services),
  };
};

const transformRoles = (roles, allServices) =>
  roles.map((role) => buildRoleStructure(role, allServices));

export function useProjectRoles(projectId, allServices) {
  const selectFunction = useCallback((roles) => transformRoles(roles, allServices), [allServices]);

  return useQuery({
    queryKey: [...queryKeys.project.roles, projectId],
    queryFn: () => getProjectRoles(projectId),
    placeholderData: [{}],
    select: selectFunction,
    enabled: allServices.length > 0,
  });
}
