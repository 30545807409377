import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tabs, Tab, Typography, Grid } from '@mui/material';
import { MoveIcon } from 'app/common/icons';
import { useProject } from 'app/contexts/ProjectContext';
import StatusChangeModal from "app/common/StatusChangeModal";
import OnHoldDialog from "../LeadsData/Stages/OnHoldDialog";
import LeadsCancel from "../LeadsData/Stages/CancelDialog";
import NewProjectDialog from '../LeadsData/Stages/NewProjectDialog';
import { HeaderContainer, StatusChip, ActionButton } from './StyledComponent';
import OnGoingConfirmationModal from '../LeadsData/Stages/OnGoingConfirmationDialog';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
const ProjectTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const ProjectTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [modalState, setModalState] = useState({
    open: false,
    onHold: false,
    cancel: false,
    newProject: false,
    onGoing: false
  });
  const tabsContainerRef = useRef(null);
  const { basic, projectId, isCancelled, isOnHoldOrCancelled } = useProject();


  let radioOptions = [];
  if (location.pathname.includes("/listing")) {
    radioOptions = [
      { value: "new_project", label: "New Project" },
      { value: "on_hold", label: "On Hold" },
      { value: "cancelled", label: "Cancelled" },
    ];
  } else if (location.pathname.includes("/myprojects")) {
    radioOptions = [
      { value: "on_going", label: "Ongoing Project" },
      { value: "on_hold", label: "On Hold" },
      { value: "cancelled", label: "Cancelled" },
    ];
  }

  const getTabsForType = () => {
    const baseTabs = [{ label: 'Property Information', path: 'propertyinformation' },
    { label: 'Requests', path: 'requests' },
    { label: 'Roles', path: 'roles' }];

    const tabMapping = {
      lead: [...baseTabs],
      newproject: [
        ...baseTabs,
        { label: 'Contract Details', path: 'contractdetails' },
        { label: 'Expenses', path: 'expenses' },
        { label: 'General Contractor', path: 'generalcontractor' },
        { label: 'Lease & Sale', path: 'lease&sale' },
        { label: 'Property Management', path: 'propertymanagement' },
        { label: 'Construction Lender', path: 'constructionlender' },
      ],
      ongoing: [
        ...baseTabs,
        { label: 'Contract Details', path: 'contractdetails' },
        { label: 'Closing Details', path: 'closingdetails' },
        { label: 'Expenses', path: 'expenses' },
        { label: 'General Contractor', path: 'generalcontractor' },
        { label: 'Lease & Sale', path: 'lease&sale' },
        { label: 'Property Management', path: 'propertymanagement' },
        { label: 'Construction Lender', path: 'constructionlender' },
      ],
    };

    return tabMapping[basic.projectType] || [];
  };
  const tabs = getTabsForType();

  useEffect(() => {
    const currentTab = tabs.findIndex((tab) =>
      location.pathname.includes(tab.path)
    );
    if (currentTab >= 0) setTabIndex(currentTab);
  }, [location.pathname, tabs]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    navigate(tabs[newIndex].path);
  };

  const handleOpenModal = () => setModalState({ ...modalState, open: true });
  const handleCloseModal = () => setModalState({ open: false, onHold: false, cancel: false, newProject: false });

  const handleProceed = (option) => {
    if (option === "on_hold") {
      setModalState({ ...modalState, onHold: true });
    } else if (option === "cancelled") {
      setModalState({ ...modalState, cancel: true });
    } else if (option === "new_project") {
      setModalState({ ...modalState, newProject: true });
    } else if (option === "on_going") {
      setModalState({ ...modalState, onGoing: true });
    }
    setModalState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <Box sx={{ height: '100vh', overflowY: 'auto', backgroundColor: '#f4f4f4' }}>
      <HeaderContainer>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs={12} sm={8} container direction='column' alignItems='flex-start' spacing={2}>
            <Grid item>
              <ProjectTitle variant='h6'>{basic.projectTitle}</ProjectTitle>
            </Grid>
            <Grid item>
              <StatusChip label='Active' color='success' size='small' />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} container justifyContent='flex-end'>
            <ActionButton variant='contained' color='primary' size='small' startIcon={<MoveIcon />} onClick={handleOpenModal}>
              Move to
            </ActionButton>
          </Grid>
        </Grid>
      </HeaderContainer>

      <Box
        ref={tabsContainerRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <Tabs
          onChange={handleTabChange}
          value={tabIndex}

          sx={{
            marginLeft: '26px',
            marginTop: '1.2rem',
            '& .MuiTabs-indicator': { display: 'none' },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '500',
              color: '#17181B',
              minHeight: '32px',
              padding: '4px 12px',
            },
            '& .Mui-selected': {
              borderRadius: '5px',
              color: '#FFFFFF !important',
              fontWeight: '700',
              fontSize: '14px',
              backgroundColor: '#17181B',
              minHeight: '28px',
              padding: '2px 10px',
            },
          }}
        >

          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <Outlet />
      </Box>

      <StatusChangeModal
        open={modalState.open}
        onClose={handleCloseModal}
        onProceed={handleProceed}
        radioOptions={radioOptions}
        proceedText="Proceed"
      />
      <OnHoldDialog openModal={modalState.onHold} projectTitle={basic.projectTitle} projectId={projectId} handleCancel={() => setModalState({ ...modalState, onHold: false })} />
      <LeadsCancel openModal={modalState.cancel} projectTitle={basic.projectTitle} projectId={projectId} handleCancel={() => setModalState({ ...modalState, cancel: false })} />
      {modalState.newProject && <NewProjectDialog
        openModal={modalState.newProject}
        closeModal={() => setModalState({ ...modalState, newProject: false })}
        projectId={projectId}
      />}
      {modalState.onGoing && (
        <OnGoingConfirmationModal
          openModal={modalState.onGoing}
          handleCancel={() => setModalState({ ...modalState, onGoing: false })}
          projectId={projectId}
        />
      )}
    </Box>
  );
};

export default ProjectTabs;
