import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  IconButton,
  styled,
  Grid,
  InputLabel,
  TextField,
  Modal,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { Title } from 'app/common/Typography';
import { paymentOptions } from 'app/utils/constant';
import { useProjectGCPaymentAddUpdate } from './hooks/useProjectGCPaymentAddUpdate';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '0.6250em',
  paddingRight: '0.6250em',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const GCPaymentAddEdit = ({ contractor, payment, closeDialog }) => {
  const { mutate: addUpdateProjectGCPayment } = useProjectGCPaymentAddUpdate(contractor, payment);

  const isEditingMode = !!payment;
  const latestPayment = payment?.paymentHistory?.at(-1);

  const initialValues = {
    totalAmount: latestPayment?.totalAmount || '',
    amountPaid: latestPayment?.amountPaid || '',
    balance: latestPayment?.balance,
    paidBy: latestPayment?.paidBy || '',
    paidTo: latestPayment?.paidTo || '',
    paymentType: latestPayment?.paymentType || '',
    comments: latestPayment?.comments || '',
  };

  const handleSubmit = (values, { setSubmitting }) =>
    addUpdateProjectGCPayment(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });

  return (
    <Modal open={true} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{isEditingMode ? 'Edit' : 'Add'} Payment Details</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.gcPayment()}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>TOTAL AMOUNT</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        sx={
                          Boolean(initialValues.totalAmount) && {
                            fontWeight: 'bold',
                            backgroundColor: 'var(--TextField-bg-color)',
                          }
                        }
                        type='number'
                        placeholder='e.g. $2000.00'
                        name='totalAmount'
                        value={values.totalAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.totalAmount && errors.totalAmount)}
                        helperText={touched.totalAmount && errors.totalAmount}
                        InputProps={{
                          readOnly: Boolean(initialValues.totalAmount),
                          min: 0,
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>PAID AMOUNT</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='number'
                        placeholder='e.g. $1800.00'
                        name='amountPaid'
                        value={values.amountPaid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.amountPaid && errors.amountPaid)}
                        helperText={touched.amountPaid && errors.amountPaid}
                        inputProps={{ min: 0 }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>BALANCE AMOUNT</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: 'var(--TextField-bg-color1)',
                        }}
                        type='number'
                        placeholder='e.g. $200.00'
                        name='balance'
                        value={
                          isEditingMode ? values?.balance : values.totalAmount - values.amountPaid
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>PAID BY</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        placeholder='e.g. James'
                        name='paidBy'
                        value={values.paidBy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.paidBy && errors.paidBy)}
                        helperText={touched.paidBy && errors.paidBy}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>PAID TO</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        name='paidTo'
                        placeholder='e.g. John'
                        value={values.paidTo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.paidTo && errors.paidTo)}
                        helperText={touched.paidTo && errors.paidTo}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>MODE OF PAYMENT</InputLabel>
                      <FormControl
                        fullWidth
                        error={touched.paymentType && Boolean(errors.paymentType)}
                      >
                        <Select
                          fullWidth
                          size='small'
                          name='paymentType'
                          value={values.paymentType || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                        >
                          {paymentOptions.map(({ label }) => (
                            <MenuItem key={label} value={label}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.paymentType && errors.paymentType && (
                          <FormHelperText>{errors.paymentType}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>COMMENTS</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        name='comments'
                        placeholder='e.g. Initial payment'
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.comments && errors.comments)}
                        helperText={touched.comments && errors.comments}
                        disabled={isSubmitting}
                        />
                    </Grid>
                        </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '16px',
                        marginBottom: '10px',
                      }}
                    >
                      <LoadingButton
                        className='cancelButton'
                        color='error'
                        variant='outlined'
                        onClick={closeDialog}
                        style={{ boxShadow: 'none' }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        color='primary'
                        type='submit'
                        loading={isSubmitting}
                      >
                        {isEditingMode ? 'Update' : 'Save'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default GCPaymentAddEdit;
