import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import { Form, Formik } from 'formik';
import { formatCurrency, formatDecimal } from 'app/utils/helpers';
import { validationSchemas } from 'app/utils/validationSchemas';
import { notify } from 'app/services/notify';
import { paymentOptions, statuses } from 'app/utils/constant';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { useUserInvestmentsAdd } from './hooks/useUserInvestmentsAdd';
import { StyledAddButton } from '../../listings/StyledComponent';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--dialogDivider)',
});

const initialValues = {
  interestedAmount: '',
  interestedShares: '',
  investedAmount: '',
  investedShares: '',
  paymentDate: null,
  paymentMode: '',
};

const InvestmentDetailItem = ({ value, label }) => (
  <Box>
    <Typography variant='h6' fontWeight='bold'>
      {value}
    </Typography>
    <Typography variant='subtitle1' color='text.secondary'>
      {label}
    </Typography>
  </Box>
);

function InvestNow({ projectInvestments, memberInvestmentLatest }) {
  const { user, userNameJWT } = useUserAuth();
  const { projectId } = useProject();
  const { mutate: addInvestments } = useUserInvestmentsAdd(projectId);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [remainingAmount, eachSharePrice, availableShares, totalShare] = [
    projectInvestments?.remainingAmount,
    projectInvestments?.eachSharePrice,
    projectInvestments?.availableShares,
    projectInvestments?.totalShare,
  ];
  const remainingShares = totalShare - availableShares;

  const userRemainingAmount = memberInvestmentLatest?.remainingAmount;

  const isAcceptedInvestor = memberInvestmentLatest?.status === statuses.ACCEPTED;
  const isDeclinedInvestor =
    memberInvestmentLatest?.status === statuses.DECLINED ||
    memberInvestmentLatest?.paymentDetails.at(-1)?.status === statuses.DECLINED;
  const isInvestmentPending =
    memberInvestmentLatest?.status === statuses.PENDING ||
    memberInvestmentLatest?.paymentDetails.at(-1)?.status === statuses.PENDING;

  const investmentDetails = [
    {
      value: formatCurrency(remainingAmount),
      label: 'Remaining Investment',
    },
    {
      value: formatCurrency(userRemainingAmount),
      label: 'Remaining interested amount',
    },
    {
      value: formatCurrency(eachSharePrice),
      label: 'Share Price',
    },
    {
      value: `${availableShares} / ${totalShare}`,
      label: 'Available shares',
    },
  ];

  const toggleDialog = () => setIsOpenDialog((prev) => !prev);

  const handleSubmit = (values, { setSubmitting }) => {
    const investorUserId = memberInvestmentLatest?.investorUserId || userNameJWT;
    const fullName = `${user.name} ${user.surname}`;

    const dataWithCredentials = {
      ...values,
      isAcceptedInvestor,
      investorUserId,
      fullName,
    };

    addInvestments(dataWithCredentials, {
      onSuccess: () => {
        toggleDialog();
        notify.success('Investment request sent successfully. Please wait for approval.');
      },
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <>
      {/* <StyledAddButton> */}
        {/* Add Members */}
      {/* <Button
        variant='contained'
        onClick={toggleDialog}
        disabled={!availableShares || isInvestmentPending || isDeclinedInvestor}
      > */}
      <div onClick={toggleDialog}>
        {isInvestmentPending
          ? 'Wait for approval'
          : isDeclinedInvestor
          ? 'Investment declined'
          : isAcceptedInvestor
          ? 'Add investment'
          : 'Invest now'}
      </div>
      <Dialog
        open={isOpenDialog}
        onClose={toggleDialog}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '95vw', sm: '80vw', md: '65vw', lg: '55vw' },
            },
          },
        }}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ p: 1 }}>
          <DialogTitle>Investment details</DialogTitle>
          <IconButton onClick={toggleDialog}>
            <Close />
          </IconButton>
        </Box>
        <DialogDivider />
        <Box
          sx={{
            mx: 3,
            my: 2,
            mb: 0,
            p: 3,
            bgcolor: 'rgba(242, 243, 245, 1)',
            borderRadius: 1,
          }}
        >
          <Grid container spacing={2}>
            {investmentDetails.map((detail) => (
              <Grid
                item
                xs={6}
                md={3}
                key={detail.label}
                sx={{
                  '& .MuiTypography-h6': {
                    fontSize: { xs: '1rem', md: '1.25rem' },
                  },
                  '& .MuiTypography-subtitle1': {
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  },
                }}
              >
                <InvestmentDetailItem {...detail} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchemas.investNow({
            isAcceptedInvestor,
            remainingAmount,
            eachSharePrice,
            availableShares,
          })}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            setValues,
            setFieldValue,
          }) => {
            const handleInvestmentChange = (e) => {
              const { name, value } = e.target;
              const parsedValue = parseFloat(value);

              const validShares = isNaN(parsedValue / eachSharePrice)
                ? ''
                : parsedValue / eachSharePrice;

              const validAmount = isNaN(parsedValue * eachSharePrice)
                ? ''
                : parsedValue * eachSharePrice;

              const calculationMap = {
                interestedAmount: (() => ({
                  interestedAmount: formatDecimal(value, 2),
                  interestedShares: validShares,
                }))(),
                interestedShares: (() => ({
                  interestedShares: value,
                  interestedAmount: validAmount,
                }))(),
                investedAmount: (() => ({
                  investedAmount: formatDecimal(value, 2),
                  investedShares: validShares,
                }))(),
                investedShares: (() => ({
                  investedShares: value,
                  investedAmount: validAmount,
                }))(),
              };

              setValues({
                ...values,
                ...calculationMap[name],
              });
            };

            return (
              <Form>
                <DialogContent>
                  <Grid container spacing={2}>
                    {!isAcceptedInvestor && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: 'var(--inputLabel-color)',
                            }}
                          >
                            Interested amount
                          </InputLabel>
                          <TextField
                            fullWidth
                            name='interestedAmount'
                            type='number'
                            value={values.interestedAmount}
                            placeholder={`e.g. ${formatCurrency(remainingAmount)}`}
                            onChange={handleInvestmentChange}
                            onBlur={handleBlur}
                            error={touched.interestedAmount && Boolean(errors.interestedAmount)}
                            helperText={touched.interestedAmount && errors.interestedAmount}
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: 'var(--inputLabel-color)',
                            }}
                          >
                            Interested shares
                          </InputLabel>
                          <TextField
                            fullWidth
                            name='interestedShares'
                            type='number'
                            value={values.interestedShares}
                            placeholder={`e.g. ${formatCurrency(remainingShares)}`}
                            onChange={handleInvestmentChange}
                            onBlur={handleBlur}
                            error={touched.interestedShares && Boolean(errors.interestedShares)}
                            helperText={touched.interestedShares && errors.interestedShares}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                      >
                        Invested amount {isAcceptedInvestor ? '' : '(optional)'}
                      </InputLabel>
                      <TextField
                        fullWidth
                        name='investedAmount'
                        type='number'
                        value={values.investedAmount}
                        placeholder={`e.g. ${formatCurrency(remainingAmount)}`}
                        onChange={handleInvestmentChange}
                        onBlur={handleBlur}
                        error={touched.investedAmount && Boolean(errors.investedAmount)}
                        helperText={touched.investedAmount && errors.investedAmount}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                      >
                        Invested shares {isAcceptedInvestor ? '' : '(optional)'}
                      </InputLabel>
                      <TextField
                        fullWidth
                        name='investedShares'
                        type='number'
                        value={values.investedShares}
                        placeholder={`e.g. ${formatCurrency(remainingShares)}`}
                        onChange={handleInvestmentChange}
                        onBlur={handleBlur}
                        error={touched.investedShares && Boolean(errors.investedShares)}
                        helperText={touched.investedShares && errors.investedShares}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    {values.investedAmount !== '' && values.investedShares !== '' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: 'var(--inputLabel-color)',
                            }}
                          >
                            Payment date {isAcceptedInvestor ? '' : '(optional)'}
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disableFuture
                              name='paymentDate'
                              value={values.paymentDate}
                              onChange={(value) => setFieldValue('paymentDate', value)}
                              slotProps={{
                                textField: {
                                  onBlur: handleBlur,
                                  error: Boolean(errors.paymentDate && touched.paymentDate),
                                  helperText: touched.paymentDate && errors.paymentDate,
                                  fullWidth: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: 'var(--inputLabel-color)',
                            }}
                          >
                            Payment mode {isAcceptedInvestor ? '' : '(optional)'}
                          </InputLabel>
                          <FormControl
                            fullWidth
                            error={touched.paymentMode && Boolean(errors.paymentMode)}
                          >
                            <Select
                              fullWidth
                              name='paymentMode'
                              value={values.paymentMode || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={isSubmitting}
                            >
                              {paymentOptions.map(({ label }) => (
                                <MenuItem key={label} value={label}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.paymentMode && errors.paymentMode && (
                              <FormHelperText>{errors.paymentMode}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 2,
                          flexDirection: { xs: 'column', sm: 'row' },
                          gap: 2,
                        }}
                      >
                        <LoadingButton
                          color='error'
                          size='large'
                          className='cancelButton'
                          disabled={isSubmitting}
                          onClick={toggleDialog}
                        >
                          Cancel
                        </LoadingButton>
                        <LoadingButton
                          color='primary'
                          size='large'
                          variant='contained'
                          type='submit'
                          loading={isSubmitting}
                        >
                          {isAcceptedInvestor ? 'Add investment' : 'Send request'}
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}

export default InvestNow;
