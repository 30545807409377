import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField, Modal } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Formik } from 'formik';
import { Title } from 'app/common/Typography';
import useProjectCLLoanPaymentAddEdit from './hooks/useProjectCLLoanPaymentAddEdit';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '0.6250em',
  paddingRight: '0.6250em',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const CLLoanPaymentAddEdit = ({ lender, payment, closeDialog }) => {
  const { mutate: addEditCLLoanPayment } = useProjectCLLoanPaymentAddEdit(lender, payment);
  const isEditingMode = !!payment;

  const initialValues = {
    loanMonthlyPayment: payment?.loanMonthlyPayment || '',
    loanPayment: payment?.loanPayment || '',
    paidBy: payment?.paidBy || '',
  };

  const handleSubmit = (values, { setSubmitting }) =>
    addEditCLLoanPayment(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });

  return (
    <Modal open={true} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{isEditingMode ? 'Edit' : 'Add'} Loan Payment Details</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.clLoanPayment()}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Loan Monthly Payment</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='number'
                        placeholder='e.g. $2000.00'
                        name='loanMonthlyPayment'
                        value={values.loanMonthlyPayment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.loanMonthlyPayment && errors.loanMonthlyPayment)}
                        helperText={touched.loanMonthlyPayment && errors.loanMonthlyPayment}
                        inputProps={{ min: 0 }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputLabel>Loan Payment Date</InputLabel>
                        <DatePicker
                          name='loanPayment'
                          disablePast
                          value={dayjs(values.loanPayment)}
                          onChange={(value) =>
                            handleChange({
                              target: { name: 'loanPayment', value },
                            })
                          }
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(errors.loanPayment && touched.loanPayment),
                              helperText: touched.loanPayment && errors.loanPayment,
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Paid By</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        placeholder='e.g. James'
                        name='paidBy'
                        value={values.paidBy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.paidBy && errors.paidBy)}
                        helperText={touched.paidBy && errors.paidBy}
                        inputProps={{ min: 0 }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '16px',
                        marginBottom: '10px',
                      }}
                    >
                      <LoadingButton
                        className='cancelButton'
                        color='error'
                        variant='outlined'
                        onClick={closeDialog}
                        style={{ boxShadow: 'none' }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        color='primary'
                        type='submit'
                        loading={isSubmitting}
                      >
                        {isEditingMode ? 'Update' : 'Save'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default CLLoanPaymentAddEdit;
