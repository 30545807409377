import React from 'react';
import { styled } from "@mui/material/styles";
import { Box, Grid, Modal } from "@mui/material";
import { ChangeIcon } from 'app/common/icons';
import ChangeOrgModal from './Modals/ChangeOrgModal';
import {
    StyledEditIcon, StledCardTitle, StyledGrid,
    OrgSubHeading, OrgSubValue, OrgMainHeading,
    IconDivider,OrgSec
} from './StyledComponent';


// const IconDivider = styled(Box)(({ theme }) => ({
//     width: '100%',
//     border: '1px solid #DADCE0',
//     marginTop: '1rem',
// }));

const OrganizationSection = () => {
    const [openModal, setOpenModal] = React.useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
    }
    const handleOpenModal = () => {
        setOpenModal(true);
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: '1.25rem', marginBottom: '2.875rem' }}>
                <Grid item xs={12} container justifyContent="space-between" alignItems="center" sx={{ marginLeft: '22px', marginRight: '1.375rem',marginBottom:'1.375rem' }}>
                    <StledCardTitle>
                        Organization
                    </StledCardTitle>
                    <StyledEditIcon
                        variant="outlined"
                        size="small"
                        startIcon={<ChangeIcon />}
                        onClick={handleOpenModal}
                    >
                        Change Organiza..
                    </StyledEditIcon>
                </Grid>
                <Modal open={openModal} onClose={handleCloseModal}>
                    <ChangeOrgModal handleCloseModal={handleCloseModal} />
                </Modal>

                <IconDivider orientation="vertical" />

                <StyledGrid item xs={12} >
                    <OrgMainHeading>
                        Vedhanth Ventures
                    </OrgMainHeading>
                </StyledGrid>

                <StyledGrid item xs={12} >
                    <OrgSubHeading>
                        EIN/License Number
                    </OrgSubHeading>
                    <OrgSubValue>123456678</OrgSubValue>
                </StyledGrid>

                <StyledGrid item xs={12}>
                    <OrgSubHeading>
                        Address
                    </OrgSubHeading>
                    <OrgSubValue>
                        401 East Benton Place Chicago, Cook County, Illinois, 60601, USA
                    </OrgSubValue>
                </StyledGrid>
            </Grid>
        </>
    )
}

export default OrganizationSection
