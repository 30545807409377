import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import NavigationHeader from 'app/common/navigationHeader';
import { requestStatusMessages, topBarHeight } from 'app/utils/constant';
import styles from './RequestsLayout.module.css';
import NoRequests from 'app/views/dashboard/Requests/NoRequests';
import RequestsList from 'app/views/dashboard/Requests/RequestsList';
import { useRequests } from 'app/views/dashboard/Requests/hooks/useRequests';

const currentType = 'PENDING';

function RequestsLayout() {
  const { data: requests } = useRequests();

  return (
    <Box className={styles.container} style={{ height: `calc(100vh - ${topBarHeight}px)` }}>
      <NavigationHeader requests={true} requestsCount={requests.length} />
      <Card elevation={3} sx={{ m: 1, borderRadius: 1 }} className={styles.card}>
        <Box className={styles.content}>
          {requests.length > 0 ? (
            <RequestsList requests={requests} />
          ) : (
            <NoRequests type={currentType} message={requestStatusMessages[currentType].message} />
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default RequestsLayout;
