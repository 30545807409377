import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField, Modal } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Formik } from 'formik';
import { Title } from 'app/common/Typography';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectCLLoanWithdrawalAddEdit } from './hooks/useProjectCLLoanWithdrawalAddEdit';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '0.1em',
  backgroundColor: 'var(--modalDivider)',
});

const CLLoanWithdrawalAddEdit = ({ lender, withdrawal, closeDialog }) => {
  const { mutate: addEditCLLoanWithdrawal } = useProjectCLLoanWithdrawalAddEdit(lender, withdrawal);

  const isEditingMode = !!withdrawal;

  const initialValues = {
    balanceLoanAmount: lender?.loanBalance,
    withdrawalAmount: withdrawal?.withdrawalAmount || '',
    invoiceNumber: withdrawal?.invoiceNumber || '',
    paidDate: withdrawal?.paidDate || '',
    paidTo: withdrawal?.paidTo || '',
  };

  const handleSubmit = (values, { setSubmitting }) => {
    addEditCLLoanWithdrawal(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Modal open={true} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{isEditingMode ? 'Edit' : 'Add'} Loan Withdrawal Details</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.clLoanWithdrawal()}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, handleChange, handleBlur }) => (
              <Form>
                <Container>
                  <Grid container sx={{ marginTop: '0.1em' }} spacing={1.5}>
                    <Grid item xs={6}>
                      <InputLabel>Loan Available Amount</InputLabel>
                      <TextField
                        fullWidth
                        size='small'
                        variant='outlined'
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: 'var(--TextField-bg-color)',
                        }}
                        name='balanceLoanAmount'
                        value={values.balanceLoanAmount}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Withdrawal Amount</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        sx={
                          withdrawal?.balance === 0 && {
                            fontWeight: 'bold',
                            backgroundColor: 'var(--TextField-bg-color)',
                          }
                        }
                        placeholder='e.g. : 20000'
                        name='withdrawalAmount'
                        value={values.withdrawalAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.withdrawalAmount && errors.withdrawalAmount)}
                        helperText={touched.withdrawalAmount && errors.withdrawalAmount}
                        InputProps={{
                          readOnly: withdrawal?.balance === 0,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                    <Grid item xs={6}>
                      <InputLabel>Invoice No</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        placeholder='e.g: INC1234'
                        name='invoiceNumber'
                        value={values.invoiceNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                        helperText={touched.invoiceNumber && errors.invoiceNumber}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputLabel>Paid Date</InputLabel>
                        <DatePicker
                          name='paidDate'
                          value={dayjs(values.paidDate)}
                          onChange={(value) =>
                            handleChange({
                              target: { name: 'paidDate', value },
                            })
                          }
                          onBlur={handleBlur}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(errors.paidDate && touched.paidDate),
                              helperText: touched.paidDate && errors.paidDate,
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                    <Grid item xs={6}>
                      <InputLabel>Paid To</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        placeholder='e.g: John'
                        name='paidTo'
                        value={values.paidTo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.paidTo && errors.paidTo)}
                        helperText={touched.paidTo && errors.paidTo}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <Box
                  marginTop={1.5}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <LoadingButton
                    className='cancelButton'
                    color='error'
                    variant='outlined'
                    onClick={closeDialog}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '0.1em' }}
                    type='submit'
                    loading={isSubmitting}
                  >
                    {isEditingMode ? 'Update' : 'Save'}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default CLLoanWithdrawalAddEdit;
