import React from "react";
import {
    Box,
    ListItemIcon,
    ListItemText,
    IconButton,
    Modal,
    Divider,
    Typography,
} from "@mui/material";
import {
    StyledAddButton, StyledIcon,
    IconDivider, StledCardTitle,
    StyledTab, StyledTabs, DocumetCardBody,
    StyledDocList, DocTextPrimary, DocTextSecondary,
    DocListIconSec, DocFooterSec, MoreText, ViewAllLink
} from "./StyledComponent";
import { Downloadicon, ViewIcon, LCDeleteIcon, DescriptionIcon } from "app/common/icons";
import DocumentModal from "./Modals/DocumentModal";
import { useProjectDocuments } from "./hooks/useProjectDocuments";
import { useParams } from "react-router-dom";

const arrayOfDocs = [];

const DocumentSection = () => {
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [openModal, setOpenModal] = React.useState(false);
    const { projectId } = useParams();
    const { data: documents } = useProjectDocuments(projectId);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    }
    const handleOpenModal = () => {
        setOpenModal(true);
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ margin: '20px 38px 20px 20px' }}>
                <StledCardTitle>
                    Documents
                </StledCardTitle>
                <StyledAddButton
                    startIcon={
                        <StyledIcon />
                    }
                    onClick={handleOpenModal}
                >
                    Document
                </StyledAddButton>
                <Modal open={openModal} onClose={handleCloseModal}>
                    <DocumentModal handleCloseModal={handleCloseModal} />
                </Modal>
            </Box>
            <IconDivider orientation="vertical" />

            {arrayOfDocs.length === 0 ? (
                // If no documents, display the message in the center
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "200px" }} // Adjust height as needed
                >
                    <Typography variant="h6" color="textSecondary">
                        No Documents Added
                    </Typography>
                </Box>
            ) : (
                <>
                    {/* Tabs only appear when documents exist */}
                    <StyledTabs
                        value={selectedTab}
                        onChange={handleTabChange}
                    >
                        <StyledTab label="Due Diligence" />
                    </StyledTabs>

                    <IconDivider orientation="vertical" />

                    <DocumetCardBody>
                        {arrayOfDocs.map((doc) => (
                            <StyledDocList key={doc.id}>
                                <ListItemIcon>
                                    <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <DocTextPrimary>
                                            {doc?.name}
                                        </DocTextPrimary>
                                    }
                                    secondary={
                                        <DocTextSecondary>
                                            {doc?.userName} | {doc?.Date}
                                        </DocTextSecondary>
                                    }
                                />
                                <DocListIconSec>
                                    <IconButton
                                        color="primary">
                                        <Downloadicon fontSize="small" />
                                    </IconButton>

                                    <Divider orientation="vertical" flexItem />

                                    <IconButton>
                                        <ViewIcon fontSize="small" />
                                    </IconButton>
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton>
                                        <LCDeleteIcon fontSize="small" />
                                    </IconButton>
                                </DocListIconSec>
                            </StyledDocList>
                        ))}
                    </DocumetCardBody>

                    <DocFooterSec>
                        <MoreText>
                            + 8 more..
                        </MoreText>
                        <ViewAllLink
                            href="#"
                        >
                            View All
                        </ViewAllLink>
                    </DocFooterSec>
                </>
            )}
        </>
    );
};

export default DocumentSection;
