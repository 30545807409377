import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createProjectPM, updateProjectPM } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export const useProjectPMCreateUpdate = (projectId, property) => {
  const queryClient = useQueryClient();

  const isEditingMode = !!property;

  return useMutation({
    mutationFn: async (data) => {
      const payloadData = {
        propertyName: data.propertyName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        address: data.address1,
        country: data.country,
        state: data.state,
        city: data.city,
        zipCode: data.zipCode,
        projectId,
        id: property?.id,
        contactPersons: property?.contactPersons || [],
      };

      return isEditingMode
        ? await updateProjectPM(projectId, property?.id, payloadData)
        : await createProjectPM(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.propertyManagement, projectId],
      }),
  });
};
