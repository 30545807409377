import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUpdateProjectLocation } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectLocationCreateUpdate(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (obj) => createUpdateProjectLocation(projectId, obj),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.location, projectId],
      }),
  });
}
