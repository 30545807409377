import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addProjectPMContactPerson,
  updateProjectPMContactPerson,
} from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export const useProjectPMContactPersonCreateUpdate = (property, contactId) => {
  const queryClient = useQueryClient();

  const isEditingMode = !!contactId;

  return useMutation({
    mutationFn: async (data) =>
      isEditingMode
        ? await updateProjectPMContactPerson(property?.projectId, property?.id, {
            ...data,
            contactId,
          })
        : await addProjectPMContactPerson(property?.projectId, property?.id, data),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.propertyManagement, property?.projectId],
      }),
  });
};
