import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField, Modal } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';
import { Form, Formik } from 'formik';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectPMCreateUpdate } from './hooks/useProjectPMCreateUpdate';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '10px',
  marginBottom: '1em',
  paddingRight: '10px',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const SearchBoxContainer = styled('div')({
  width: '100%',
  '& input': {
    padding: '0.3em 0.5em',
    height: '2.710em',
    width: '100%',
    boxSizing: 'border-box',
  },
});

const PropertyAddEdit = ({ isOpenDialog, closeDialog, property }) => {
  const { projectId } = useProject();
  const { mutate: createUpdateProperty } = useProjectPMCreateUpdate(projectId, property);
  const searchInputRef = React.useRef(null);

  const isEditingMode = !!property;

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  const initialValues = {
    propertyName: property?.propertyName || '',
    email: property?.email || '',
    phoneNumber: property?.phoneNumber || '',
    address1: property?.address || '',
    country: property?.country || '',
    state: property?.state || '',
    city: property?.city || '',
    zipCode: property?.zipCode,
  };

  const handleSubmit = (values, { setSubmitting }) =>
    createUpdateProperty(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });

  return (
    <Modal open={isOpenDialog} onClose={closeDialog}>
      <div>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '40%',
            maxHeight: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 2,
              pb: 0,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>{isEditingMode ? 'Edit' : 'Add'} Property Management Details</Title>
            <IconButton onClick={closeDialog}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              p: 2,
              pt: 0,
              marginLeft: '4px',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas.property()}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur, setValues, isSubmitting }) => (
                <Form>
                  <Container>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>PROPERTY NAME</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g. : John Doe LLC'
                          name='propertyName'
                          value={values.propertyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.propertyName && errors.propertyName)}
                          helperText={touched.propertyName && errors.propertyName}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>EMAIL ADDRESS</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g. : john.doe@example.com'
                          name='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>PHONE NUMBER</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          placeholder='e.g. +98765432102'
                          type='tel'
                          name='phoneNumber'
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>ADDRESS</InputLabel>
                        </Inputlabel>
                        <SearchBoxContainer>
                          <StandaloneSearchBox
                            onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                            onUnmount={() => (searchInputRef.current = null)}
                            onPlacesChanged={() => handlePlacesChanged(setValues)}
                          >
                            <TextField
                              sx={{ marginTop: '0px', height: '0px' }}
                              fullWidth
                              margin='normal'
                              variant='outlined'
                              type='search'
                              name='address1'
                              id='standard-basic'
                              placeholder='Search With Place, Landmark'
                              value={values.address1}
                              onChange={(e) => {
                                const { value } = e.target;

                                if (!value) {
                                  setValues((prevState) => ({
                                    ...prevState,
                                    search: '',
                                    address1: '',
                                    country: '',
                                    city: '',
                                    state: '',
                                    zipCode: '',
                                    latitude: null,
                                    longitude: null,
                                  }));
                                }
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              helperText={touched.address1 && errors.address1}
                              error={Boolean(errors.address1 && touched.address1)}
                              disabled={isSubmitting}
                            />
                          </StandaloneSearchBox>
                        </SearchBoxContainer>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>CITY</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          name='city'
                          placeholder='e.g., Texas'
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>STATE</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          name='state'
                          placeholder='e.g., Texas'
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>ZIP CODE</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          name='zipCode'
                          placeholder='e.g. 123456'
                          value={values.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                          helperText={touched.zipCode && errors.zipCode}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Inputlabel>
                          <InputLabel>COUNTRY</InputLabel>
                        </Inputlabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g., Texas'
                          name='country'
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                  <Box
                    marginLeft={1.5}
                    marginRight={1}
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <LoadingButton
                      className='cancelButton'
                      color='error'
                      variant='outlined'
                      onClick={closeDialog}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '10px' }}
                      type='submit'
                      loading={isSubmitting}
                    >
                      {isEditingMode ? 'Update' : 'Save'}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default PropertyAddEdit;
