import React from "react";
import { InputAdornment, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { Container, SearchInput, FilterButton } from "./StyledComponent";

const GPSearchFilter = () => {
  return (
    <Container>
      <SearchInput
        placeholder="Search with Project name or Project ID"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" style={{ color: "#6B7280" }} />
            </InputAdornment>
          ),
        }}
      />

      <FilterButton variant="outlined" size="small" startIcon={<TuneIcon fontSize="small" />}>
        Filters
      </FilterButton>
    </Container>
  );
};

export default GPSearchFilter;