import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProjectDocumentsUpload } from '../../Documents/hooks/useProjectDocumentsUpload';
import { convertDayJSDateToUTC } from 'app/utils/helpers';
import { createProjectCL, updateProjectCL } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectCLCreateUpdate(projectId, lender) {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadDocument } = useProjectDocumentsUpload(projectId);

  const isUpdatingMode = !!lender;

  return useMutation({
    mutationFn: async ({ data, isDocumentSameAsPrevious, previousDocumentID }) => {
      const documentID = [];

      // if (!isDocumentSameAsPrevious) {
        // const response = await uploadDocument(data.documents);
        // const parsedDocumentID = response.match(/Document IDs: \[([^\]]+)\]/)[1];

        // documentID.push(parsedDocumentID);
      // } else {
        // documentID.push(previousDocumentID);
      // }

      const contractorDetails = {
        lenderName: data.lenderName,
        lenderPhone: data.lenderPhone,
        lenderEmail: data.lenderEmail,
        lenderAddress: data.address1,
        lenderCity: data.city,
        lenderState: data.state,
        lenderCountry: data.country,
        lenderZipCode: data.zipCode,
        lenderContactPerson: {
          lenderContactPersonName: data.lenderContactPersonName,
          lenderContactPersonPhone: data.lenderContactPersonPhone,
          lenderContactPersonEmail: data.lenderContactPersonEmail,
        },
        loanDetails: {
          loanApprovedAmount: data.loanApprovedAmount,
          loanWithdrawalAmount: data.loanWithdrawalAmount,
          interestRate: data.interestRate,
          loanType: data.loanType,
          loanStartDate: convertDayJSDateToUTC(data.loanStartDate),
          loanTerm: data.loanTerm,
          loanDocuments: documentID,
        },
        loanPayments: lender?.loanPayments || [],
        loanWithdrawal: lender?.loanWithdrawal || [],
        comments: data.comments,
      };

      return isUpdatingMode
        ? await updateProjectCL(projectId, lender?.id, {
            ...contractorDetails,
            projectId,
            id: lender?.id,
          })
        : await createProjectCL(projectId, contractorDetails);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.constructionLender, projectId],
      }),
  });
}
